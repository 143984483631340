import React from 'react'
import IconHome from '../assets/img/ic-home.svg'
import IconDoctor from '../assets/img/ic-doctor.svg'
import IconDoctorWord from '../assets/img/ic-doctor-word.svg'
import IconProfile from '../assets/img/ic-profile.svg'
import IconHomeActive from '../assets/img/ic-home-active.svg'
import IconDoctorActive from '../assets/img/ic-doctor-active.svg'
import IconDoctorWordActive from '../assets/img/ic-doctor-word-active.svg'
import IconProfileCircleActive from '../assets/img/ic-profile-circle-active.svg'
import { useNavigate } from 'react-router-dom'

function Nav(props) {
    const { currentPage } = props
    const navigate = useNavigate()
    return (
        <nav className="z-20 flex justify-between fixed w-full bottom-0 left-0 right-0 bg-white max-w-md mx-auto">
            {[
                ['หน้าหลัก', '/home', IconHome, IconHomeActive, (currentPage === 'home')?'active':null],
                ['ค้นหาแพทย์', '/search', IconDoctor, IconDoctorActive, (currentPage === 'search')?'active':null],
                ['ผลการตรวจ', '/resulthealthcheck', IconDoctorWord, IconDoctorWordActive, (currentPage === 'resulthealthcheck')?'active':null],
                ['โปรไฟล์', '/profile', IconProfile, IconProfileCircleActive, (currentPage === 'profile')?'active':null],
            ].map(([title, url, icon, iconActive, active], i) => (
                <button key={i} onClick={()=>navigate(url)} className={`${(active==='active')?'text-darkblue':'text-gray-6'} rounded-lg grow px-3 py-2 text-center text-xs sm:text-md`}>
                    <img src={(active==='active') ? iconActive: icon} className="mx-auto" alt="home button" />
                    {title}
                </button>
            ))}
        </nav>
    )
}

export default Nav