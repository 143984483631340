import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Modal from 'react-modal'
// import { format } from 'date-fns'
// import th from "date-fns/locale/th";
import moment from 'moment'
import { useLiff } from 'react-liff';

import { TokenContext } from '../../App'

import IconDocGreen from '../../assets/img/ic-document-green.svg'
import IconBack from '../../assets/img/ic-back.svg'
import { BtnTransparent, BtnPrimary } from '../../components/FormItems';

import IconFailed from '../../assets/img/ic-failed.svg'

function SummaryHealthCheck() {
    const { token, setCurrentPage } = useContext(TokenContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { liff } = useLiff();

    const [loading, setLoading] = useState(true)
    const [modalErr, setModalErr] = useState(false)
    const [errMsg, setErrMsg] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg, setErrSubMsg] = useState('กรุณาลองใหม่อีกครั้ง')
    const [healthCheckList, setHealthCheckList] = useState({})
    const [prescriptionList, setPrescriptionList] = useState([])

    const [errLiff, setErrLiff] = useState('')
    const [hasErrLiff, setHasErrLiff] = useState(false)

    let appointmentID = location?.state?.appointment_id
    let type = location?.state?.type
    let displayStatus = location?.state?.display_status

    const fetchSummaryHealthCheck = useCallback(async () => {
        try {
            setLoading(true)

            const resSummaryHealthCheck = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/visit_summary`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                }
            });
            // console.log('SummaryHealthCheck: Status[',resSummaryHealthCheck.status,']',resSummaryHealthCheck)

            if(resSummaryHealthCheck.status === 200) {
                resSummaryHealthCheck
                .json()
                .then((res)=>{
                    setHealthCheckList(res)
                    setLoading(false)
                }).catch((err)=> { console.log(err); setLoading(false); setModalErr(true); })
            } else if(resSummaryHealthCheck.status === 400) {
                setModalErr(true)
                setErrSubMsg('แพทย์กำลังสรุปผลตรวจ')
                setErrMsg('กรุณารอสักครู่')
            } else {
                setModalErr(true)
            }

           
            
        } catch (error) {
            // setModalErr(true)
            setLoading(false)
            // navigate('/');
        }
    },[appointmentID, token])

    const fetchListPrescription= useCallback(async () => {
        try {
            setLoading(true)

            const resListPrescriptoin = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/prescription`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                }
            });
            // console.log('SummaryHealthCheck: Status[',resListPrescriptoin.status,']',resListPrescriptoin)

            if(resListPrescriptoin.status === 200) {
                resListPrescriptoin
                .json()
                .then((res)=>{
                    setPrescriptionList(res)
                    // setPrescriptionList([
                    //     {
                    //         "id": 1,
                    //         "name_en": "Paracetamol",
                    //         "usage_th": "วันละ 3 เวลาหลังอาหาร",
                    //         "quantity": "10 เม็ด"
                    //     },
                    //     {
                    //         "id": 4,
                    //         "name_en": "Amoxycilin",
                    //         "usage_th": "วันละ 3 เวลาหลังอาหาร",
                    //         "quantity": "10 เม็ด"
                    //     }
                    // ])
                    setLoading(false)
                }).catch((err)=> { console.log(err); setLoading(false); setModalErr(true); })
            } else {
                setModalErr(true)
            }

           
            
        } catch (error) {
            // setModalErr(true)
            setLoading(false)
            // navigate('/');
        }
    },[appointmentID, token])

    useEffect(() => {
        (async()=>{
            setLoading(true)
            setCurrentPage('/resulthealthcheck')

            // check token
            if(!token) {
            navigate('/')
            }

            fetchSummaryHealthCheck()

            if(displayStatus==='ended') {
                fetchListPrescription()
            }
            console.log('displayStatus: ', displayStatus)
        })()
    
        return () => {
            
        }
    }, [displayStatus, fetchListPrescription, fetchSummaryHealthCheck, navigate, setCurrentPage, token])

    const convertDate = (value) => {

        let selectYearValue = Number(moment(value).format('yyyy')) + 543
        let time = moment(value).format('HH:mm')
        let result = moment(value).format('DD') + '/' + moment(value).format('MM') + '/' + selectYearValue + ' ' + time 
        return result
    }

    const gotoSummaryAppointment = (id) => {
        navigate('/summaryappointment', {
          state: {
            appointment_id: appointmentID,
            from_historyappointment: 1,
            type: type
          }
        })
      }

    const closeLiffWindow = () => {
        liff
        .sendMessages([
            {
              type: "text",
              text: `ต้องการติดต่อเรื่องยา case ID ${appointmentID}`,
            },
        ])
        .then(() => {
            console.log("message sent");
            liff.closeWindow()
        })
        .catch((err) => {
            console.log("error", err);
            setHasErrLiff(true)
            setErrLiff(err.toString())
        });

    }
    
    Modal.setAppElement('#root');

    return (
        <div className="summaryhealthcheck-page p-5">
            {
                loading ? 
                <p className="text-center grid place-items-center text-gray-2 w-full" style={{height: window.innerHeight - 80+'px' }}>...Loading...</p>
                : 
                <div>
                    <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/summaryappointment', { state: { appointment_id: appointmentID, type: type}})} }/>
        
                    <div className="flex flex-row mb-2">
                        <img src={IconDocGreen} className="mr-2" alt="icon calendar"  />
                        <h1 className="font-promptsemibold">สรุปผลการตรวจ</h1>
                    </div>
                    <div className="p-4 overflow-auto" style={{height: window.innerHeight - 140+'px'}}>
                        {
                            healthCheckList ?
                                healthCheckList.map((item,i) => {
                                    return (
                                        <div key={i} className="mb-5 pt-5 border-t border-t-gray-light">
                                            <p className="font-promptsemibold">สรุปผลการตรวจ ครั้งที่ {i+1}</p> 
                                            <p className="text-xs text-left text-gray-2 mb-3">เมื่อ {convertDate(item.modified_timestamp)}</p>
                                            <p key={i} className="mb-3" dangerouslySetInnerHTML={{__html: item.summary }}></p>
                                        </div>
                                    )
                                    
                                })
                            : <p>ไม่พบข้อมูลการตรวจ</p>
                        }
                        {
                            prescriptionList.length ? 
                                <div className="border-t border-form-bd pt-4">
                                    <h1 className="font-promptsemibold">รายการยา</h1>
                                    <p className="mb-4 text-green-soft">รายการนี้เอาไว้ใช้อ้างอิงเท่านั้น หากมีคำถาม หรือ ต้องการให้ทางคลินิกจัดส่งยาให้ กรุณากดปุ่ม "ติดต่อเรื่องยา" ด้านล่าง</p>
                                    {
                                         prescriptionList.map((item,i) => {
                                            return (
                                                <div key={i} className="py-3">
                                                    <p>ลำดับที่ {i+1}.</p>
                                                    <p className="font-promptsemibold">{item.name_en}</p> 
                                                    <p>{item.usage_th}</p>
                                                    <p>จำนวน {item.quantity}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <p className="mt-4 text-green-soft">กรุณาศึกษาข้อบ่งใช้การใช้ยาจากเภสัชให้เข้าใจก่อนใช้ยา</p>
                                    <div className="mt-4">
                                        <BtnPrimary type="button" text="ติดต่อเรื่องยา" action={()=> { closeLiffWindow() }} /> 
                                    </div>
                                    {
                                        hasErrLiff ? <p className="text-sm text-gray-3">{errLiff}</p> : null
                                    }
                                </div>
                               
                            : null
                        }
                    </div>
                </div>
            }


            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false); gotoSummaryAppointment()}} /> 
            </Modal>

        </div>
    )
}

export default SummaryHealthCheck