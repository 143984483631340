import React, { useEffect, useContext, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { TokenContext } from '../../App'
import {
    BtnPrimary,
    BtnTransparent 
} from '../../components/FormItems'

import IconBack from '../../assets/img/ic-back.svg'
import IconBank from '../../assets/img/ic-bank.svg'
import IconBankBag from '../../assets/img/ic-bankbag.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import IconVisa from '../../assets/img/ic-payment-visa.svg'
import IconJcb from '../../assets/img/ic-payment-jcb.svg'
import IconMastercard from '../../assets/img/ic-payment-mastercard.svg'
import IconPromptPay from '../../assets/img/ic-payment-promptpay.svg'

function Payment() {
    const navigate = useNavigate()
    const location = useLocation()
    const { token, dataAppointment } = useContext(TokenContext)
    const { register, handleSubmit,  formState: { errors } } = useForm();
    // const onSubmit = (data) => { console.log(data)};

    const [btnActive, setBtnActive] = useState(false)
    const [modalErr, setModalErr] = useState(false)
    const [modalErrOmise, setModalErrOmise] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg , setErrSubMsg] = useState('กรุณาลองใหม่อีกครั้ง')

    const [choosePayment, setChoosePayment] = useState(null)
    // const [omisePublicKey, setOmisePublicKey] = useState('')


    const doctorID = location?.state?.doctor_id
    const appointmentID = location?.state?.id
    const amount = location?.state?.amount
    const currency = location?.state?.currency

    const listMonth = [ '',
        '01','02','03','04','05','06','07','08','09','10','11','12'
    ]

    const listYears = (amount) => {
        var max = new Date().getFullYear()
        var min = max + amount
        var years = ['']

        for (var i = max; i <= min; i++) {
            years.push(i)
        }
        return years
    }

    const handleChoosePayment = (e) => {
        setChoosePayment(e.target.value)
    }

    // Call Omise JS
    const Omise = window.Omise

    useEffect(() => {
        console.log('dataAppointment',dataAppointment)
        // Check token
        if(!token) {
            navigate('/')
            // console.log(token)
        } else {
            // Fetch Apppoint
            (async () => {
                // Fetch detail doctor
                const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/payment/omise/key`, {
                    method: 'GET',
                    headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                    }
                });
            
                resDetailDoctor
                    .json()
                    .then((response) => {
                        console.log('get key success!!')
                        // console.log('payment key : ',response)
                        // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                        if(response) {
                            // setOmisePublicKey(response.public_key)

                            Omise.setPublicKey(response.public_key);
                        } else {
                        }
                    })
                    .catch((err)=> { console.log('get key failure!! ',err); })
            })();
        }
        
        return () => {
            
        }
    }, [Omise, dataAppointment, navigate, token])

    const omiseCreateToken = (data) => {
        // console.log('data card: ',data)
        // console.log('dataAppointment.appointment_id: ',dataAppointment.appointment_id)
        // console.log('appointmentID',appointmentID)
        let id = dataAppointment.appointment_id || appointmentID // is appointment_id

        if(choosePayment === 'creditcard') { 
            localStorage.setItem('payment', JSON.stringify({ doctor_id: doctorID, appointment_id: id, amount: amount, currency: currency, status: 'waiting', type: 'credit'}))
            let card = {
                "name": data.name,
                "number": Number(data.number),
                "expiration_month": Number(data.expiration_month),
                "expiration_year": Number(data.expiration_year),
                "security_code": Number(data.security_code)
            };

            // console.log('card detail:',card)

            // console.log('id:',id)

            Omise.createToken("card", card, function (statusCode, response) {
                if (response.object === "error") {
                    // Display an error message.
                //   var message_text = "SET YOUR SECURITY CODE CHECK FAILED MESSAGE";
                //   if(response.object === "error") {
                    console.log(response.message);
                //   }

                    setModalErrOmise(true)
                    setErrSubMsg('เนื่องจากข้อมูลไม่ถูกต้อง กรุณาแก้ไขให้ถูกต้อง')
            
                } else {
                
                    (async()=>{
                        setBtnActive(true)
                        // Then fill the omise_token.
                        // console.log('omise_token:',response.id)
                        // Fetch detail doctor
                        const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${id}/payment/omise/credit_card`, {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    token: response.id,
                                    return_uri: process.env.REACT_APP_WEB_URL+"/paymentstatus/?appointment_id="+id+"&doctor_id="+doctorID+"&amount="+amount+"&currency="+currency
                                }
                            ),
                            headers: {
                            "Content-Type": "application/json",
                            // "Accept": "application/json",
                            'Authorization': 'Bearer ' + token
                            }
                        });

                        if(resDetailDoctor.status === 200) {
                            resDetailDoctor
                            .json()
                            .then((response) => {
                                // console.log('payment key : ',response)
                                // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                                if(response) {
                                    window.location = response.authorize_uri
                                    console.log('Checking omise authorize')
                                } else {
                                    setModalErr(true)
                                    setErrSubMsg('ไม่สามารถติดต่อชำระเงินได้')
                                }

                                // setBtnActive(false)
                            })
                            .catch((err)=> { console.log(err); })
                        } else {
                            setModalErr(true)
                            setErrSubMsg('ไม่สามารถติดต่อชำระเงินได้')
                            console.log('error code: ',resDetailDoctor.status)
                        }
                    
                        
                    })()
                };
            });
        } else {
            localStorage.setItem('payment', JSON.stringify({ doctor_id: doctorID, appointment_id: id, amount: amount, currency: currency, status: 'waiting', type: 'promptpay'}))
            navigate('/paymentpromptpay', {state: { doctor_id: doctorID, appointment_id: id, amount: amount, currency: currency}})
        }
    }

    const gotoDetailDoctor = () => {
        // Check payment status for back button
        if(!!localStorage.getItem('payment')) {
            let paymentObj =  JSON.parse(localStorage.getItem('payment'))
            if(paymentObj.status === 'waiting') {
                navigate('/home')
                // for call GraphQL
                // window.location.href = '/'
            } 

        } else {
            let doctor_id = dataAppointment.doctor_id || doctorID
    
            navigate('/detaildoctor', {
              state: {
                doctor_id: doctor_id ,
                clinic_id: '',
                specialty_id: '',
                keyword: '',
                item: '',
                type: '',
                searchinput: '',
                title: '',
                backlink: 'home',
                step: 'payment'
              }
            })
        }
    }
    
    Modal.setAppElement('#root');

    return (
        <div className="payment-page p-5">
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" 
              action={()=>{ gotoDetailDoctor()}}
            />
            <div className="flex flex-row mb-5">
                <img src={IconBankBag} className="mr-2" alt="icon bankbag"  />
                <h1 className="font-promptsemibold">ช่องทางการชำระเงิน</h1>
            </div>
            {/* { omisePublicKey } */}
            <form onSubmit={handleSubmit(omiseCreateToken)}>
                <div className="pl-4">
                    <label className="flex container-radio-btn" htmlFor="creditcard" onChange={handleChoosePayment}>
                        <p className="mb-3">บัตรเครดิด / เดบิต</p>
                        <input
                            type="radio"
                            value="creditcard"
                            id="creditcard"
                            checked={choosePayment === "creditcard"}
                            onChange={()=>{}}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <div className="mb-3 ml-5">
                        <img className="inline-block" src={IconVisa} alt="visa" />
                        <img className="inline-block" src={IconJcb} alt="jcb" />
                        <img className="inline-block" src={IconMastercard} alt="mastercard" />
                    </div>
                    {
                        choosePayment === 'creditcard' ?
                            <div className="ml-5">
                                {/* Input Card Name */}
                                <div className="flex flex-col text-left mb-3">
                                    <input 
                                        placeholder="ชื่อบนบัตร" 
                                        className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${(errors?.name?.type === "required" || errors?.name?.type === "pattern") && 'border-danger'}`}
                                        {...register("name", { required: true, pattern: { value: /^[A-Za-z/\sA-Za-z]+$/i } })} 
                                    />
                                    {errors?.name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>}
                                    {errors?.name?.type === 'pattern' && <p className="text-danger text-xs">กรุณาใส่ตัวอักษร</p>}
                                </div>

                                {/* Input Card Number */}
                                <div className="flex flex-col text-left mb-3">
                                    <input 
                                        placeholder="รหัสบัตร" 
                                        className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${
                                            (errors?.number?.type === "required" || errors?.number?.type === "minLength" || errors?.number?.type === "maxLength" || errors?.number?.type === "pattern") && 'border-danger'
                                        }`}
                                        // {...register("number", { 
                                        //     pattern: {
                                        //         value: /^[A-Za-z]+$/i,
                                        //         message: "invalid email address"
                                        //     },
                                        //     min: 16, max: 16,
                                        //     required: true })
                                        // } 
                                        { ...register("number", { required: true, pattern: /^[0-9]+$/i , minLength: {value: 16 }, maxLength: { value: 16 } }) }/>
                                        {errors?.number?.type === 'pattern' && <p className="text-danger text-xs">กรุณาใส่ตัวเลข 0-9.</p>}
                                        {errors?.number?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>}
                                        {errors?.number?.type === 'minLength' && <p className="text-danger text-xs">กรุณาใส่จำนวนตัวเลข 16 หลัก</p>}
                                        {errors?.number?.type === 'maxLength' && <p className="text-danger text-xs">กรุณาใส่จำนวนตัวเลขไม่เกิน 16 หลัก</p>}
                                </div>

                                <div className="flex flex-col text-left mb-3">
                                    <div className="grid gap-4 grid-cols-2">
                                        {/* <div>
                                            <input row="4" type="number"
                                                placeholder="วันหมดอายุ (ดด/ปป)" 
                                                className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${(errors?.expiration?.type === "required" || errors?.expiration?.type === "pattern") && 'border-danger'}`}
                                                {...register("expiration", { required: true, pattern: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/i })} 
                                            />
                                            {errors?.expiration?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>}
                                            {errors?.expiration?.type === "pattern" && <p className="text-xs text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</p>}
                                        </div> */}
                                        <div className="select-section grow">
                                            {/* <select className={`p-2.5 pr-6 mx-1.5 mb-1 w-full border border-form-bd rounded-lg outline-0`} value={birthDate.mm} name="mm" onChange={handleBirthDate}> */}
                                            <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.expiration_month?.type === "required" && 'border-danger')}`} 
                                                {...register("expiration_month" , { required: true } )}
                                            >
                                            {
                                                listMonth.map((item,i)=> {
                                                return (
                                                    <option key={i} value={(item==='')?'':i}>{(item==='')?'เดือนหมดอายุ':item}</option>
                                                )
                                                })
                                            }
                                            </select>
                                            {
                                                errors?.expiration_month?.type === "required" && <p className="text-xs text-danger">กรุณาเลือกเดือนหมดอายุ</p>
                                            }
                                        </div>
                                        <div className="select-section grow">
                                            {/* <select className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0`}  value={birthDate.yyyy} name="yyyy" onChange={handleBirthDate}> */}
                                            <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.expiration_year?.type === "required" && 'border-danger')}`} 
                                                {...register("expiration_year" , { required: true } )}
                                            >
                                            {
                                                listYears(10).map((item,i)=> {
                                                return (
                                                    <option key={i} value={item}>{(item==='')? 'ปีหมดอายุ' : item}</option>
                                                )
                                                })
                                            }
                                            </select>
                                            {
                                                errors?.expiration_year?.type === "required" && <p className="text-xs text-danger">กรุณาเลือกปีหมดอายุ</p>
                                            }
                                        </div>
                                        <div>
                                            <input row="4" type="number"
                                                placeholder="CCV" 
                                                className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${(errors?.security_code?.type === "required" || errors?.security_code?.type === "minLength" || errors?.security_code?.type === "maxLength") && 'border-danger'}`}
                                                {...register("security_code", { required: true , minLength: {value: 3 }, maxLength: { value: 3 } })} 
                                            />
                                            {errors?.security_code?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>}
                                            {errors?.security_code?.type === 'minLength' && <p className="text-danger text-xs">กรุณาใส่จำนวนตัวเลข 3 หลัก</p>}
                                            {errors?.security_code?.type === 'maxLength' && <p className="text-danger text-xs">กรุณาใส่จำนวนตัวเลขไม่เกิน 3 หลัก</p>}
                                        </div>
                                    </div>
                                </div>

                                {/* Choose Type Pay */}
                                {/* <p className="font-promptsemibold mb-2">เพศ</p>
                                <div className="flex flex-row text-left mb-3">
                                    <label className="flex container-radio-btn" htmlFor="gender-male">
                                        ชาย
                                        <input
                                            {...register("gender")}
                                            type="radio"
                                            value="male"
                                            id="gender-male"
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="flex container-radio-btn" htmlFor="gender-female">
                                        หญิง
                                        <input
                                            {...register("gender")}
                                            type="radio"
                                            value="female"
                                            id="gender-female"
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="flex container-radio-btn" htmlFor="gender-other">
                                        ไม่ระบุ
                                        <input
                                            {...register("gender")}
                                            type="radio"
                                            value="unspecified"
                                            id="gender-other"
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div> */}
                            </div>
                        : null
                    }

                    <label className="flex container-radio-btn mt-8" htmlFor="promptpay" onChange={handleChoosePayment}>
                        <p className="mb-3">PromptPay / พร้อมเพย์</p>
                        <input
                            type="radio"
                            value="promptpay"
                            id="promptpay"
                            checked={choosePayment === "promptpay"}
                            onChange={()=>{}}
                        />
                        <span className="checkmark"></span>
                    </label>

                    <div className="mb-3 ml-5">
                        <div className="rounded border-form-bd p-2 border flex w-fit h-fit">
                            <img className="inline-block w-14" src={IconPromptPay} alt="promptpay" />
                        </div>
                    </div>
                </div>


                <div className="border-t border-t-form-bd py-6 mt-6">
                    <div className="flex flex-row mb-3 opa">
                        <img src={IconBank} className="mr-2" alt="icon calendar"  />
                        <h1 className="font-promptsemibold">ค่าบริการ</h1>
                    </div>
                    <p className="flex justify-between"><span className="text-gray-2">ค่าบริการให้คำปรึกษา</span> <span className="text-gray-2 font-promptsemibold">{(dataAppointment.amount || amount).toLocaleString('en-US')} {dataAppointment.currency || currency}</span></p>
                    <p className="flex justify-between"><span className="font-promptsemibold">รวม</span> <span className="text-orange font-promptsemibold">{(dataAppointment.amount || amount).toLocaleString('en-US')} {dataAppointment.currency || currency}</span></p>
                </div>
                    {
                        btnActive ? 
                        <BtnPrimary type="button" text="...Loading..." addclass="mb-10 opacity-60 pointer-events-none" />
                        :
                        (choosePayment === 'creditcard' || choosePayment === 'promptpay') ?
                            <BtnPrimary type="submit" text="ชำระเงิน" addclass="mb-10" />
                            :
                            <BtnPrimary type="button" text="ชำระเงิน" addclass="mb-10 pointer-events-none opacity-25" /> 
                    }
                    
            </form> 
            {/* <button type="button" onClick={omiseCreateToken}>GO GO</button> */}

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false); navigate('/')}} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

            {/* Modal Error */}
            <Modal
                isOpen={modalErrOmise}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErrOmise(false); }} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>
            
        </div>
    )
}

export default Payment