import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import { TokenContext } from '../../App'
import {
  BtnPrimary,
} from '../../components/FormItems'
import IconFailed from '../../assets/img/ic-failed.svg'
// import VideoCall from './VideoCall'


// Video waiting room ------------------------------------//
function VideoWaitingRoom() {
    const navigate = useNavigate()
    const location = useLocation()
    const { token, profile, setStatusRoom } = useContext(TokenContext)
    const [modalErr, setModalErr] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg /*, setErrSubMsg*/] = useState('กรุณาลองใหม่อีกครั้ง')

    const [videoChannelName, setVideoChannelName] = useState(null)
    const [videoAppID, setVideoAppID] = useState(null)
    const [videoToken, setVideoToken] = useState(null)
    const [videoUserID, setVideoUserID] = useState(null)

    let appointmentID = location.state?.appointmentID
    let doctorFirstName = location.state?.doctorFirstName
    let doctorLastName = location.state?.doctorLastName
    let doctorTitle = location.state?.doctorTitle
    let doctorImg = location.state?.doctorImg
    // let patientImg = profile.pictureUrl

    useEffect(() => {

        (async () => {
            console.log('patient image: ',profile)
            // Fetch enter_waiting_room
            const resEnterWaitingRoom = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/enter_waiting_room`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
            }
            });
        
            resEnterWaitingRoom
                .json()
                .then((response) => {
                    console.log('enter_waiting_room : ',response)
                    localStorage.setItem('accessToken',response.graphQL_token)
                    // setAppID(response.app_id)
                    // Check meeting: true /false


                    if(response.meeting) {
                        console.log('*** enter_waiting_room[statusRoom] : start_room ***')
                        setStatusRoom('start_room')

                        setVideoChannelName(response.video_channel)
                        setVideoAppID(response.app_id)
                        setVideoToken(response.video_token)
                        setVideoUserID(response.video_user_id)

                        navigate('/videocall', { state: { 
                            videoChannelName: response.video_channel, 
                            videoAppID: response.app_id, 
                            videoToken: response.video_token, 
                            videoUserID: response.video_user_id ,
                            appointmentID: appointmentID,
                            doctorFirstName: doctorFirstName,
                            doctorLastName: doctorLastName,
                            doctorTitle: doctorTitle,
                            doctorImg: doctorImg,
                            chatChannel: response.chat_channel,
                            chatToken: response.chat_token,
                            chatUId: response.chat_user_id,
                            chatAppId: response.app_id,
                            patientImg: profile.pictureUrl,
                        } })

                    } else {
                        console.log('*** enter_waiting_room[statusRoom] : waiting_room ***')
                        setStatusRoom('waiting_room')

                        navigate('/videocall', { state: { 
                            videoChannelName: null, 
                            videoAppID: null, 
                            videoToken: null, 
                            videoUserID: null,
                            appointmentID: appointmentID,
                            doctorFirstName: doctorFirstName,
                            doctorLastName: doctorLastName,
                            doctorTitle: doctorTitle,
                            doctorImg: doctorImg,
                            chatChannel: response.chat_channel,
                            chatToken: response.chat_token,
                            chatUId: response.chat_user_id,
                            chatAppId: response.app_id,
                            patientImg: profile.pictureUrl,
                        } })
                    }
                    
                })
                .catch((err)=> { console.log(err); setModalErr(true); })
        })();

      
    
        return () => {
            // localStorage.removeItem('accessToken')
            // tracks[1].close(); tracks[1].stop(); client.leave(); client.removeAllListeners();
        }
    }, [appointmentID, doctorFirstName, doctorImg, doctorLastName, doctorTitle, navigate, profile, profile.pictureUrl, setStatusRoom, token, videoAppID, videoChannelName, videoToken, videoUserID])

    Modal.setAppElement('#root');
    
    return (
        <div>
            {/* <VideoCall appointmentID={appointmentID} vdoChannelName={videoChannelName} vdoAppID={videoAppID} vdoToken={videoToken} vdoUserID={videoUserID} /> */}

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false); navigate('/')}} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

        </div>
    )
}

export default VideoWaitingRoom