import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-modal'
import { useNavigate, useLocation } from 'react-router-dom'

import logoThaiDoctor from '../../assets/img/logo-thaidoctor.svg'
import IconBack from '../../assets/img/ic-back.svg'
import { BtnTransparent } from '../../components/FormItems'


import { TokenContext } from '../../App'
import ItemDoctor from '../../components/ItemDoctor'

// import { LiffLogout } from '../../components/LiffLogout'

function Home() {
  const location = useLocation()
  const navigate = useNavigate()
  const { token, consentTerm, consentPrivacy, setCurrentPage, firstRegister, setFirstRegister } = useContext(TokenContext)
  const [modalSuccess, setModalSuccess] = useState(false)
  // const [showFastTrack, setShowFastTrack] = useState(false)
  const [showAppointment, setShowAppointment] = useState(false)
  const [dataItemAppointment, setDataItemAppointment] = useState('')
  const [fetchMsgAppointment, setFetchMsgAppointment] = useState('...Loading...')
  // const [loadingHome, setLoadingHome] = useState(true)


  let dataRegisterSuccess = location.state?.success

  useEffect(() => {
    setCurrentPage('/home')
    
    // check token
    if(!token) {
      navigate('/')
    } else {
      // Fetch Apppoint
      (async () => {
        setShowAppointment(true)
        // Fetch detail doctor
        const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment?show_all=1`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            // "Accept": "application/json",
            'Authorization': 'Bearer ' + token
          }
        });
    
        resDetailDoctor
            .json()
            .then((response) => {
                console.log('appointment : ',response)
                // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                if(response) {
                  // setFetchMsg('')
                  if(!response.total) {
                    setShowAppointment(false)
                    setFetchMsgAppointment('ไม่พบข้อมูล')
                  }
                  setDataItemAppointment(response)
                } else {
                  setShowAppointment(false)
                  setFetchMsgAppointment('ไม่พบข้อมูล')
                }
            })
            .catch((err)=> { console.log(err); setShowAppointment(false);})
      })();
      
      
    }

    // console.log('Login check => term: ',consentTerm,', privacy: ',consentPrivacy)
    // console.log('Home token: ',token)

    // check term
    if(!consentTerm) {
      navigate('/termcondition')
    } else {
      // check privacy
      if(!consentPrivacy) {
        navigate('/privacy')
      }
    }
    // console.log('Hello, Home')
    let interval

    if(firstRegister) {
      setModalSuccess(true)

      // countdown otp input
      interval = setInterval(() => {
        setModalSuccess(false);
        setFirstRegister(false)

        // clear location state
        navigate(location.pathname, {}); 
      }, 3000); 
    } 

    return () => {
      clearInterval(interval)
      setModalSuccess(false)
    }
  }, [consentPrivacy, consentTerm, dataRegisterSuccess, firstRegister, location.pathname, navigate, setCurrentPage, setFirstRegister, token])


  const gotoSummaryAppointment = (id) => {
    navigate('/summaryappointment', {
      state: {
        appointment_id: id,
        from_historyappointment: 1,
        type: 'fromhistoryappointment'
      }
    })
  }
  
  Modal.setAppElement('#root');

  return (
    <div className="home-page relative">
      <div className="p-4 bg-form-bg"> 
        <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/home')} }/>
        
        {/* Appointment */}
        {
          showAppointment ? 
            <div className="mt-2">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <h1 className="font-promptsemibold">ประวัติการนัดหมาย</h1>
                </div>
              </div>
              <div className="overflow-y-auto mt-2" style={{height: window.innerHeight-120+'px'}}>
              {
                dataItemAppointment?.total ?
                  dataItemAppointment?.data.map((item,i)=> {
                    return (        
                      <ItemDoctor key={i} dataItem={item} type="appointment" onclick={()=> gotoSummaryAppointment(item.id)}  />
                    )
                  })
                : <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsgAppointment }}></p>
              }
              </div>
            </div>
          : null
        }
      </div>

      {/* Modal Success */}
      <Modal
        isOpen={modalSuccess}
        className="Modal"
        overlayClassName="Overlay"
        style={{overlay: {zIndex: 20}}}
      >
        <img src={logoThaiDoctor} alt="logo thaidoctor" className="mx-auto my-4" />
        <h1 className="font-promptsemibold mb-0 text-lg">ยินดีด้วย</h1>
        <p className="text-gray-2 mb-4">คุณสามารถใช้งานระบบได้แล้ว</p>
      </Modal>
    </div>
  )
}

export default Home