import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IconPin from '../assets/img/ic-pin.svg'
import IconProfile from '../assets/img/ic-profile-green.svg'
import IconNoImg from '../assets/img/logo-thaidoctor-small.svg'

import { TokenContext } from '../App'

let option = ''

function V2ItemClinic(props) {
    const navigate = useNavigate()
    const { token, dataLatLng } = useContext(TokenContext)
    const { backlink } = props

    const [clinicCurrent, setClinicCurrent] = useState({})
    const [hasClinicCurrent, setHasClinicCurrent] = useState(false)

    useEffect(() => {
        (async()=>{
            if(dataLatLng.latitude && dataLatLng.longitude) {
                option =`/?latitude=${dataLatLng.latitude}&longitude=${dataLatLng.longitude}`
            }

            // Fetch detail doctor
            const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/clinic/current${option}`, {
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
                }
            });

            if(resDetailDoctor.status === 200) {
                resDetailDoctor
                .json()
                .then((response) => {
                    console.log('clinic current : ',response)
                        setClinicCurrent(response)
                        setHasClinicCurrent(true)
                })
                .catch((err)=> { console.log(err); })
            } else {
                setHasClinicCurrent(false)
            }
      
        })()
        
        return () => {
            
        }
    }, [dataLatLng.latitude, dataLatLng.longitude, token])
    
    

    return (
        <div className="pt-4 flex flex-row w-full">
            {
                hasClinicCurrent ?
                <>
                    <div className="relative mb-2 mr-4" onClick={onclick}>
                        <div className="bg-gray-4 overflow-hidden relative h-14 w-14 xs:w-12 xs:h-12 rounded-full ring-2 ring-white mx-auto flex-none">
                            <img className="bg-gray-4 relative mb-2 mx-auto mr-4 flex-none" src={!!clinicCurrent?.logo_url?clinicCurrent?.logo_url:IconNoImg} alt="" />
                        </div>
                    </div>
                    <div className="flex flex-col grow">
                        <h1 className="mb-1 font-promptsemibold" onClick={onclick}>{clinicCurrent?.name}</h1>
                        <div className="flex flex-col">
                            <div className="flex flex-row mb-0" 
                                onClick={()=> 
                                    navigate(
                                        '/map', { state: 
                                            { 
                                                searchinput: '',
                                                dataItem: clinicCurrent, 
                                                backlink: backlink, 
                                                title: clinicCurrent.name, 
                                                clinic_id: clinicCurrent.id, 
                                                type: 'clinic', 
                                                keyword: '', 
                                                item: clinicCurrent
                                            } 
                                        }
                                    )
                                }
                            >
                                <div className="flex flex-row grow">
                                    <img className="w-3 sm:w-4 mr-2 self-start pt-1" src={IconPin} alt="icon location" />
                                    <span className="self-center text-blue text-sm sm:text-md">{clinicCurrent?.address} {clinicCurrent?.subdistrict} {clinicCurrent?.district} {clinicCurrent?.province} {clinicCurrent?.zipcode}</span>
                                </div>
                            </div>
                            <div className="flex flex-row mb-0" onClick={onclick}>
                                <div className="flex flex-row grow">
                                    <img className="w-3 sm:w-4 mr-2" src={IconProfile} alt="icon rating" />
                                    <span className="self-center text-gray-2 text-sm sm:text-md">แพทย์ {clinicCurrent?.num_doctor} คน</span>
                                </div>
                                <div className="grow-0">
                                    <p className="text-gray-2 text-sm sm:text-md">{(clinicCurrent?.distance)? Number(clinicCurrent?.distance / 1000).toLocaleString('en-US')+'กม.': null}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </>
                : <p className="flex items-center justify-center text-gray-2 w-full">ไม่พบข้อมูลคลินิค</p>
            }
        </div>
    )
}

export default V2ItemClinic