import React, { useContext, useState, useEffect } from 'react'
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom'
import { BtnPrimary, BtnSecondary } from '../../components/FormItems'
import { TokenContext } from '../../App'
import Modal from 'react-modal'
import Select from 'react-select'
import { format } from 'date-fns'
import th from "date-fns/locale/th";
import IconCalendar from '../../assets/img/ic-calendar-bold.svg'
import IconFrequency from '../../assets/img/ic-frequency.svg'
import IconClock from '../../assets/img/ic-clock.svg'
import IconBank from '../../assets/img/ic-bank.svg'
import IconSuccess from '../../assets/img/ic-success.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import IconCouponSelected from '../../assets/img/ic-coupon-selected.svg'
// import IconRemove from '../../assets/img/ic-remove.svg'

let listCouponOptions = []
function DetailDoctorSummary() {
    const navigate = useNavigate()
    const { token, dataAppointment, setDataAppointment } = useContext(TokenContext)
    const [checkBoxCert, setCheckBoxCert] = useState(false)
    const [checkBoxCoupon, setCheckBoxCoupon] = useState(false)
    const [selectInsertCoupon, setSelectInsertCoupon] = useState(false)
    const [iconCouponActive, setIconCouponActive] = useState(false)
    const [selectCouponBtn, setSelectCouponBtn] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [modalRemoveCoupon, setModalRemoveCoupon] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)
    const [modalErr, setModalErr] = useState(false)
    const [errMsg, setErrMsg] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg, setErrSubMsg] = useState('กรุณาลองใหม่อีกครั้ง')
    const [listCoupon, setListCoupon] = useState([
        // {
        //     coupon_id: 1,
        //     amount: 1,
        //     value: 300,
        //     clinic_name: 'คลินิกนานา',
        //     doctor_name: 'น.พ.สมศักดิ์ ธีระสกุล',
        //     expire_date: '2023-01-10'
        // },{
        //     coupon_id: 2,
        //     amount: 1,
        //     value: 400,
        //     clinic_name: 'คลินิกนานา 2',
        //     doctor_name: 'น.พ.สมชาย หายห่วง',
        //     expire_date: '2023-01-19'
        // }
    ])

    const [title, setTitle] = useState('')
    // const [titleOther, setTitleOther] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [age, setAge] = useState('')
    const [idCard, setIdCard] = useState('')

    useEffect(() => {

      (async () => {
        // Fetch detail doctor
        const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/${dataAppointment.doctor_id}/coupon_list/${dataAppointment.type==='fasttrack'? 'fast_track':dataAppointment.type}`, {
          method: 'POST',
          body: JSON.stringify({
            language: dataAppointment.language,
          }),
          headers: {
            "Content-Type": "application/json",
            // "Accept": "application/json",
            'Authorization': 'Bearer ' + token
          }
        });
    
        resDetailDoctor
            .json()
            .then((response) => {
                listCouponOptions = []
                // console.log('listCoupon(',dataAppointment.type,'):',response)
                // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                if(response) {
                  // setFetchMsg('')
                    setListCoupon(response)
                    // Set List Coupon Options
                    // listCouponOptions.push({value: '', label: 'ระบุตัวเลือก'},)

                    for(let i=0; i<response.length; i++) {
                        // Check language / value / currency
                        let value = (dataAppointment.language==='Thai') ?
                                !!response[i].max_coupon_value_thb?Number(response[i].max_coupon_value_thb).toLocaleString('en-US')
                                :'ไม่ระบุจำนวน' 
                            : (dataAppointment.language==='English') ?
                                 !!response[i].max_coupon_value_usd?Number(response[i].max_coupon_value_usd).toLocaleString('en-US')
                                 :'ไม่ระบุจำนวน'
                            : !!response[i].max_coupon_value_cny?Number(response[i].max_coupon_value_cny).toLocaleString('en-US')
                                :'ไม่ระบุจำนวน'
                        let clinicName = !!response[i].clinic_name?response[i].clinic_name:'ไม่ระบุคลินิก'
                        let doctorTitle = !!response[i].doctor_title?response[i].doctor_title:'ไม่ระบุแพทย์'
                        let doctorFirstName = !!response[i].doctor_first_name?' '+response[i].doctor_first_name:''
                        let doctorLastName = !!response[i].doctor_last_name?' '+response[i].doctor_last_name:''
                        let doctor = doctorTitle+doctorFirstName+doctorLastName
                        let expireDate = !!response[i].expire_date?'หมดอายุ '+convertExpire(response[i].expire_date):'ไม่ระบุวันหมดอายุ'

                        listCouponOptions.push({
                            value: response[i].coupon_code,
                            label: `คูปอง 1 ครั้ง ${value+' '+dataAppointment.currency} ${clinicName} ${doctor} ${expireDate}`
                        })
                    }

                    listCouponOptions.push({value: 'self', label: 'พิมพ์รหัสคูปอง'})
                } else {
                    setModalErr(true)
                }
            })
            .catch((err)=> console.log(err))
  
      })();
    
      return () => {
        listCouponOptions = []
        setCouponCode('')
        setCheckBoxCoupon(false)
      }
    }, [dataAppointment.currency, dataAppointment.doctor_id, dataAppointment.language, dataAppointment.type, setDataAppointment, token])
    


    const onCheckCouponCode = async () => {
        let dataCheck = {}
        if(dataAppointment.type==='fasttrack') {
            dataCheck = {
                language: dataAppointment.language,
                medical_certificate: !checkBoxCert?0:1,
                coupon_code: couponCode
            }
        } else {
            dataCheck = {
                language: dataAppointment.language,
                date: dataAppointment.date, 
                time: dataAppointment.time, 
                medical_certificate: !checkBoxCert?0:1,
                coupon_code: couponCode
              }
        }

        const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/${dataAppointment.doctor_id}/reserve_coupon_${dataAppointment.type==='fasttrack'? 'fast_track':dataAppointment.type}`, {
            method: 'POST',
            body: JSON.stringify(dataCheck),
            headers: {
              "Content-Type": "application/json",
              // "Accept": "application/json",
              'Authorization': 'Bearer ' + token
            }
        });
      
        if(resDetailDoctor.status === 200) {
            console.log(resDetailDoctor)
            resDetailDoctor
            .text()
            .then(response => {
                console.log(response)
                setIconCouponActive(true)
            })
    
        } else {
            console.error('Error Code:(',resDetailDoctor.status,')')
            resDetailDoctor
            .text()
            .then(response => {
                setIconCouponActive(false)
                console.log('Error Msg:(',response,')')
                setErrSubMsg('กรุณาเลือกคูปองใหม่')
                switch (response) {
                    // For Normal
                    case 'Not enough value':
                        setErrMsg('คูปองที่คุณเลือกมีมูลค่าไม่เพียงพอกับการทำนัดหมายนี้')
                        break;

                    case 'Wrong clinic':
                        setErrMsg('คูปองที่คุณเลือกไม่สามารถใช้กับการนัดหมายแพทย์จากคลีนิกนี้ได้')
                        break;

                    case 'Invalid coupon':
                        setErrMsg('รหัสคูปองผิด')
                        break;

                    case 'Wrong doctor':
                        setErrMsg('หมอไม่ตรงกับที่คูปองกำหนด')
                        break;

                    case 'No coupon left':
                        setErrMsg('ใช้คูปองเต็มโควต้าแล้ว')
                        break;

                    case 'Coupon is already used':
                        setErrMsg('คนไข้ใช้คูปองนี้แล้ว')
                        break;

                    // For FastTrack
                    case 'Coupon is expired':
                        setErrMsg('คูปองหมดอายุแล้ว')
                        break;
                
                    case 'Coupon usage has not started yet':
                        setErrMsg('คูปองยังไม่ถึงวันเริ่มใช้งาน')
                        break;

                    default:
                        setErrMsg('คูปองที่คุณเลือกไม่สามารถใช้กับการนัดหมายนี้ได้')
                        break;
                }
            })
            setModalErr(true)
        }
        //   resDetailDoctor
        //       .json()
        //       .then((response) => {
        //           console.log('CheckCoupon(',dataAppointment.type,'):',response)
        //           // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                  
        //       })
        //       .catch((err)=> { console.log(err); setModalErr(true)})
    }


    const convertDate = (value) => {
        let selectYearValue = Number(format(new Date(value), 'yyyy')) + 543
        let result = format(new Date(value), 'dd MMM', { locale: th}) + ' ' + selectYearValue
        return result
    }

    const convertExpire = (value) => {
        let selectYearValue = Number(format(new Date(value), 'yyyy')) + 543
        let result = format(new Date(value), 'dd MMM', { locale: th}) + ' ' + selectYearValue
        return result
    }

    const handleCheckBoxCoupon = (value) => {
        setCouponCode('')
        setCheckBoxCoupon(value)
        // console.log(value)
        if(value && !listCoupon.length) {
            setSelectInsertCoupon(true)
        } else {
            setSelectInsertCoupon(false)
        }
        setIconCouponActive(false)
        setSelectCouponBtn(false)
    }

    // const handleSelectCoupon = (e) => {
    //     console.log(e.target.value)
    //     let value = e.target.value
    //     if(value === 'self') { 
    //         setCouponCode('')
    //         setSelectInsertCoupon(true) 
    //     } else { 
    //         setSelectInsertCoupon(false) 
    //         setCouponCode(value)
    //     }
    // }

    const handleSelectCouponOption = (data) => {
        console.log('couponCode:',data.value)
        let value = data.value
        if(value === 'self') { 
            setCouponCode('')
            setSelectInsertCoupon(true) 
        } else { 
            setSelectInsertCoupon(false) 
            setCouponCode(value)
        }
    }

    const handleSelectCouponBtn = (value) => {
        setIconCouponActive(false)
        if(value) {
            setSelectCouponBtn(true)
        } else {
            setSelectCouponBtn(false)
        }
    }

    const handleCheckCouponCode = () => {
        console.log(couponCode)
        if(couponCode) {
            onCheckCouponCode()
        } 
    }

    const handleRemoveCoupon = () => {
        setModalRemoveCoupon(false)
        // clear coupon code
    }

    // const handleTitle = (e) => {
    //     // console.log(e.target.value)
    //     setTitle(e.target.value)
    // }
    
    // const handleTitleOther = (e) => {
    //     // console.log(e.target.value)
    //     setTitleOther(e.target.value)
    // }

    const handleMedicalCert = async () => {
        setCheckBoxCert(!checkBoxCert); 
        // setTitleOther(''); 
        setAge(''); 
        setIdCard('') 

        const resProfile = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              // "Accept": "application/json",
              'Authorization': 'Bearer ' + token
            }
        });

        if(resProfile.status === 200 ) {
            resProfile
                .json()
                .then((response) => {
                    setTitle( (!!response.title) ? response.title : '' )
                    setFirstName( (!!response.first_name) ? response.first_name : '' )
                    setLastName( (!!response.last_name) ? response.last_name : '' )
                })
        } else {

        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        let optionPath = '/reserve_fast_track'
        let optionBody = {
            language: dataAppointment.language,
            medical_certificate: !checkBoxCert?0:1,
            coupon_code: couponCode
        }
        
        if(dataAppointment.type !== 'fasttrack') {
            optionPath = '/reserve_appointment'
            optionBody.time = dataAppointment.time
            optionBody.date = dataAppointment.date
        }

        // Check Coupon Empty
        if(!couponCode || !checkBoxCoupon) {
            delete optionBody.coupon_code;
        }

        // Assign data
        if(checkBoxCert) {
            optionBody.title = title
            optionBody.first_name = firstName
            optionBody.last_name = lastName
            optionBody.age = Number(age)
            optionBody.citizen_id = idCard.split('-').join('')
        }

        // Check error cert data
        if(checkBoxCert && (optionBody.title==='' || optionBody.first_name==='' || optionBody.last_name==='' || optionBody.age==='' || optionBody.citizen_id==='' || optionBody.citizen_id.length !== 13)) {
            // console.log(optionBody.citizen_id.length)
            setErrMsg('ข้อมูลขอใบรับรองแพทย์ไม่ครบ')
            setErrSubMsg('กรุณาระบุรายละเอียดให้ครบ')
            setModalErr(true)
        } else {
            console.log(optionBody)

            // Check if reserve via don't use coupon && have appointment id
            if(!!dataAppointment.appointment_id && (!couponCode || !checkBoxCoupon) ) {
                navigate('/payment', {state: { doctor_id: dataAppointment.doctor_id, id: dataAppointment.appointment_id, amount: dataAppointment.amount, currency: dataAppointment.currency}})
                            
            } else {
                // OnSubmit on First [ not have appointment id]
                const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/${dataAppointment.doctor_id}${optionPath}`, {
                    method: 'POST',
                    body: JSON.stringify(optionBody),
                    headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                    }
                });
        
                if(resDetailDoctor.status === 200) {
                resDetailDoctor
                    .json()
                    .then((response) => {
                        // console.log('appointment : ',response)
                        // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                        if(response) {
                            // setFetchMsg('')
                            // console.log('response reserve: ',response)
                            setDataAppointment({
                                ...dataAppointment,
                                appointment_id: response.appointment_id,
                                need_payment: response.need_payment,
                            })
                            
                            // Check don't use coupon && have need payment
                            if((!couponCode || !checkBoxCoupon) && response.need_payment) {
                                navigate('/payment', {state: { doctor_id: dataAppointment.doctor_id, id: dataAppointment.appointment_id, amount: dataAppointment.amount, currency: dataAppointment.currency}})
                            } else {
                                setModalSuccess(true)
                            }

                        } else {
                            // setFetchMsg('ไม่พบข้อมูล')
                            console.log('DetailDoctorSummary: ',response)
                        }
                    })
                    .catch((err)=> console.log(err))
            
                } else {
                    // console.log(resDetailDoctor.response)
                    
                    resDetailDoctor
                    .text()
                    .then(response => {
                        console.error('(',resDetailDoctor.status,')',response)
                        switch (response) {
                            // For Normal
                            case 'Appointment not accepted by doctor':
                                setErrMsg('หมอไม่เปิดรับนัดล่วงหน้า')
                                break;
        
                            case 'Date is holiday':
                                setErrMsg('เป็นวันหยุดของหมอ')
                                break;
        
                            case 'Appointment beyond day boundary not allowed':
                                setErrMsg('ทำนัดข้ามเวลาวัน ')
                                break;
        
                            case 'Date is after period open for appointment':
                                setErrMsg('วันที่หลังวันที่เปิดให้นัดล่วงหน้า')
                                break;
        
                            case 'Time is before now':
                                setErrMsg('เวลานัดก่อนเวลาปัจจุบัน')
                                break;
        
                            case 'No schedule for doctor':
                                setErrMsg('หมอไม่ได้เปิดรับนัดช่วงเวลาที่กำหนด')
                                break;
        
                            // For FastTrack
                            case 'Existing fast track incomplete':
                                setErrMsg('การปรึกษาด่วนครั้งที่แล้วยังไม่จบ')
                                break;
                        
                            case 'Fast track not accepted by doctor':
                                setErrMsg('หมอไม่รับปรึกษาด่วน')
                                break;
        
                            case 'No rate available':
                                setErrMsg('ไม่เจอค่าตรวจสำหรับภาษาที่ต้องการ')
                                break;
        
                            case 'Doctor not online':
                                setErrMsg('หมอไม่ online')
                                break;
        
                            case 'Existing reservation incomplete':
                                setErrMsg('การจองครั้งที่แล้วยังชำระเงินไม่เสร็จ')
                                break;
        
                            case 'Overlapped schedule':
                                setErrMsg('ช่วงเวลานี้มีการทำนัดหมายไปแล้ว')
                                setErrSubMsg('กรุณาเลือกช่วงเวลาใหม่')
                                break;
        
                            case 'Invalid coupon':
                                setErrMsg('คูปองใช้ไม่ได้')
                                break;
                        
                            default:
                                setErrMsg('ไม่สามารถทำรายการได้')
                                break;
                        }
                    })
                    setModalErr(true)
                }
            }
        }
        
        // console.log(optionBody)
    }

    // const listCouponItem = (item, i) => {
    //     let value = dataAppointment.language==='Thai'?item.max_coupon_value_thb:item.max_coupon_value_usd,
    //         clinic_name = (!!item.clinic_name)?item.clinic_name:'ไม่ระบุคลีนิก',
    //         doctor_title = (!!item.doctor_title)?item.doctor_title:'ไม่ระบุแพทย์',
    //         doctor_first_name = (!!item.doctor_first_name)?item.doctor_first_name:'',
    //         doctor_last_name = (!!item.doctor_last_name)?item.doctor_last_name:'',
    //         expire_date = (!!item.expire_date)?'หมดอายุ '+convertExpire(item.expire_date):'ไม่ระบุวันหมดอายุ',
    //         doctor = doctor_title+' '+doctor_first_name+' '+doctor_last_name
    //     // console.log(doctor)
    //     return (
    //         <option key={i+1} value={item.coupon_code}>
    //             คูปอง 1 ครั้ง {Number(value).toLocaleString('en-US')} บาท {clinic_name} {doctor} {expire_date}
    //         </option>
    //     )
    // }

    Modal.setAppElement('#root');

    return (
        <div className="detail-doctor-page _summary">
            <div className="border border-card-bd-active rounded-lg shadow-lg shadow-card-shadow p-5 mb-7">
                <div className="flex flex-col">
                    <div className="flex flex-row my-1">
                        <div className="flex flex-row grow-0 ">
                            <img className="w-4 sm:w-5 mr-3 self-auto" src={IconCalendar} alt="icon location" />
                        </div>
                        <div className="grow">

                        {
                            dataAppointment.type==='fasttrack' ? <p className="self-center text-gray-2 text-sm sm:text-md">ปรึกษาทันที </p>:
                            <p className="self-center text-gray-2 text-sm sm:text-md">เวลานัดหมาย <span className="font-promptsemibold text-orange text-base">{ !!dataAppointment.date? convertDate(dataAppointment.date) : '-'}</span> | <span className="font-promptsemibold text-orange text-base">{!!dataAppointment.time ?(dataAppointment.time).substring(0, dataAppointment.time.length -3) : '-'} น.</span></p>
                        }
                    </div>
                    </div>
                    <div className="flex flex-row my-1">
                        <div className="flex flex-row grow-0 ">
                            <img className="w-4 sm:w-5 mr-3 self-auto" src={IconClock} alt="icon location" />
                        </div>
                        <div className="grow">
                            <p className="self-center text-gray-2 text-sm sm:text-md">ระยะเวลารับคำปรึกษา <span className="font-promptsemibold text-orange text-base">{dataAppointment.timeconsult} นาที</span></p>
                        </div>
                    </div>
                    <div className="flex flex-row my-1">
                        <div className="flex flex-row grow-0 ">
                            <img className="w-5 sm:w-6 mr-2 self-auto" src={IconFrequency} alt="icon location" />
                        </div>
                        <div className="grow">
                            <p className="self-center text-gray-2 text-sm sm:text-md">ภาษาที่ใช้บริการ <span className="font-promptsemibold text-orange text-base">{dataAppointment.language}</span></p>
                        </div>
                    </div>
                    <div className="flex flex-row my-1">
                        <div className="flex flex-row grow-0 ">
                            <img className="w-5 sm:w-6 mr-2 self-auto" src={IconBank} alt="icon location" />
                        </div>
                        <div className="grow">
                            <p className="self-center text-gray-2 text-sm sm:text-md">ค่าบริการให้คำปรึกษา <span className="font-promptsemibold text-orange text-base">{Number(dataAppointment.amount).toLocaleString('en-US')} {dataAppointment.currency}</span></p>
                        </div>
                    </div>
                    <div className="border-t border-form-bd pt-4 mt-4">
                        <label className="container-checkbox">ต้องการใบรับรองแพทย์
                            <input type="checkbox" checked={checkBoxCert} name="cert" value={checkBoxCert} onChange={()=> { handleMedicalCert() }}/>
                            <span className="checkmark"></span>
                        </label>
                        {
                            checkBoxCert ?
                                <div className="mb-3 pl-7">
                                    {/* <div className="grid grid-cols-3 gap-2" onChange={(e)=> handleTitle(e)}>
                                        <label className="flex container-radio-btn text-sm text-gray-2" htmlFor="title-male">
                                            นาย
                                            <input
                                                type="radio"
                                                value="นาย"
                                                id="title-male"
                                                name="title"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="flex container-radio-btn text-sm text-gray-2" htmlFor="title-female">
                                            นาง
                                            <input
                                                type="radio"
                                                value="นาง"
                                                id="title-female"
                                                name="title"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="flex container-radio-btn text-sm text-gray-2" htmlFor="title-female2">
                                            นางสาว
                                            <input
                                                type="radio"
                                                value="นางสาว"
                                                id="title-female2"
                                                name="title"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2 items-center mb-3">
                                        <label className="flex container-radio-btn !mr-2 text-sm text-gray-2" htmlFor="title-other" onChange={(e)=> handleTitle(e)}>
                                            อื่นๆ โปรดระบุ
                                            <input
                                                type="radio"
                                                value="other"
                                                id="title-other"
                                                name="title"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <input type="text" disabled={title!=='other'} className={`p-2.5 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 text-gray-2`} value={titleOther} onChange={(e)=>{ handleTitleOther(e)}} />
                                    </div> */}

                                    <div className="grid gap-2 mb-2">
                                        <div className="">
                                            <p className=" text-sm text-gray-2 mb-1">เลขบัตรประชาขน</p>
                                            <InputMask mask="9-9999-99999-99-9" maskChar={null} onChange={(e)=>{ setIdCard(e.target.value)}} value={idCard} className={`p-2.5 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 text-gray-2`} />
                                            {/* <input type="number" className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 `} value={idCard} onChange={(e)=>{ setIdCard(e.target.value)}} /> */}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 mb-2">
                                        <div className="col-span-2">
                                            <p className=" text-sm text-gray-2 mb-1">อายุ (ปี)</p>
                                            {/* <input type="number" min="0" max="99" className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 `} value={age} onChange={(e)=>{ setAge(e.target.value)}} /> */}
                                            <InputMask mask="99" maskChar={null} onChange={(e)=>{ setAge(e.target.value)}} value={age} className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 text-gray-2`} />
                                        </div>
                                        <div className="col-span-2">
                                            <p className=" text-sm text-gray-2 mb-1">คำนำหน้า</p>
                                            <input type="text" className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 `} value={title} onChange={(e)=>{ setTitle(e.target.value)}} />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 mb-2">
                                        <div className="col-span-4">
                                            <p className=" text-sm text-gray-2 mb-1">ชื่อจริง</p>
                                            <input type="text" className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 `} value={firstName} onChange={(e)=>{ setFirstName(e.target.value)}} />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 mb-2">
                                        <div className="col-span-4">
                                            <p className=" text-sm text-gray-2 mb-1">นามสกุล</p>
                                            <input type="text" className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 `} value={lastName} onChange={(e)=>{ setLastName(e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                        <label className="container-checkbox">ใช้ Coupon Code
                            <input type="checkbox" checked={checkBoxCoupon} name="coupon" value={checkBoxCoupon} onChange={(e)=> handleCheckBoxCoupon(e.target.checked)}/>
                            <span className="checkmark"></span>
                        </label>
                        {
                            checkBoxCoupon && listCoupon.length ?
                                <div className="flex flex-col text-left">
                                    {/* <select onChange={handleSelectCoupon} className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 max-w-lg `}>
                                        <option key={0} value={'start'} className="text-gray-6">ระบุตัวเลือก</option>
                                        {
                                        listCoupon?.map((item,i)=> {
                                            return (
                                                listCouponItem(item, i)
                                            // <option key={i+1} value={item.coupon_id}>
                                            //     คูปอง {item.amount} ครั้ง {item.value} บาท {item.clinic_name} {item.doctor_name} หมดอายุ {convertExpire(item.expire_date)}
                                            // </option>
                                            )
                                        })
                                        }
                                        <option key={listCoupon.length + 1} value={'self'}>พิมพ์รหัสคูปอง</option>
                                    </select> */}

                                    <Select 
                                        // defaultInputValue=''
                                        // value={couponCode}
                                        options={listCouponOptions} 
                                        placeholder={'ระบุตัวเลือก'} 
                                        className={'listcoupon'}
                                        classNamePrefix={'listcoupon'} 
                                        maxMenuHeight={'10rem'}
                                        isSearchable={false}
                                        blurInputOnSelect={true}
                                        // theme={(theme) => ({
                                        //     ...theme,
                                        //     borderRadius: 4,
                                        //     borderColor: 'red',
                                        //     colors: {
                                        //       ...theme.colors,
                                        //       primary: '#E8F5F4',
                                        //     },
                                        // })}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused?'#e5e5e5':'#e5e5e5',
                                                borderRadius: '.5rem',
                                                height: '3rem',
                                            }),
                                            option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: state.isSelected?'#199690':'#616161',
                                                backgroundColor: state.isSelected?'#E8F5F4':'transparent',
                                            }),
                                          
                                        }}
                                        defaultValue={couponCode}
                                        onChange={handleSelectCouponOption}
                                    />
                                </div>
                            : null
                        }

                        {
                            selectInsertCoupon && checkBoxCoupon ? 
                            <div className="flex flex-col">
                                <div className="flex mt-2 gap-2">
                                    <input 
                                        value={couponCode}
                                        placeholder="Coupon Code" 
                                        className={`grow-2 p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0`}
                                        onChange={(e)=> {setCouponCode(e.target.value); handleSelectCouponBtn(e.target.value) }}
                                        onFocus={()=> { setCouponCode(''); setIconCouponActive(false); setSelectCouponBtn(false) }}
                                    />
                                    {
                                        iconCouponActive ?
                                            <img className="w-7 sm:w-8 mr-2 self-auto" src={IconCouponSelected} alt="icon coupon" />
                                        :
                                            selectCouponBtn ?
                                                <BtnPrimary type="button" text="ใช้โค้ด" action={()=> { handleCheckCouponCode()}} addclass="max-w-fit" />
                                            :
                                                <BtnSecondary type="button" text="ใช้โค้ด" addclass="max-w-fit pointer-events-none opacity-70" />
                                    }
                                </div>
                                {/* <div className="flex mt-2 p-2 bg-green-soft-light rounded justify-between">
                                    <div className="flex">
                                        <div className="flex flex-row grow-0 ">
                                            <img className="w-5 sm:w-6 mr-2 self-auto" src={IconCouponSelected} alt="icon coupon" />
                                        </div>
                                        <div className="grow">
                                            <p className="self-center text-gray-2 text-sm sm:text-md">ThaiDoctorFREE</p>
                                        </div>
                                    </div>
                                    <div onClick={()=> { setModalRemoveCoupon(true) }}>
                                        <img className="w-4 sm:w-5 mr-2 self-auto opacity-30" src={IconRemove} alt="icon remove" />
                                    </div>
                                </div> */}
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>

            {
                (checkBoxCoupon && !couponCode) || (checkBoxCoupon && selectInsertCoupon && !iconCouponActive ) ?
                <div className="pointer-events-none">
                    <BtnSecondary type="button" text={`ยืนยัน`} />
                </div>
                :
                <BtnPrimary type="submit" text={`ยืนยัน`} action={(e)=>onSubmit(e)} />
            }

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

            {/* Modal Success */}
            <Modal
                isOpen={modalSuccess}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconSuccess} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">คุณได้ทำการนัดหมายสำเร็จแล้ว</h1>
                <p className="text-gray-2 mb-4 text-md">กรุณากรอกประวัติอาการเพื่อเป็นข้อมูลให้แพทย์</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalSuccess(false); navigate('/historymedical')}} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

            {/* Modal Remove Coupon */}
            <Modal
                isOpen={modalRemoveCoupon}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconCouponSelected} alt="icon coupon" className="mx-auto my-4 w-20" />
                <h1 className="font-promptsemibold mb-0 text-lg">ลบคูปอง</h1>
                <p className="text-gray-2 mb-4 text-md">คุณต้องการลบคูปองหรือไม่ ?</p>
                <div className="grid grid-cols-2 gap-2">
                <BtnSecondary type="button" text="ยกเลิก" addclass="text-black" action={()=> { setModalRemoveCoupon(false) }} /> 
                <BtnPrimary type="button" text="ลบคูปอง" addclass="bg-danger" action={()=> { handleRemoveCoupon() }} /> 
                </div>
            </Modal>
        </div>
    )
}

export default DetailDoctorSummary