import React, { useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MapContainer, TileLayer, ZoomControl, Marker, Popup } from 'react-leaflet'
import IconBack from '../../assets/img/ic-back.svg'
import IconPin from '../../assets/img/ic-pin.svg'
import IconProfile from '../../assets/img/ic-profile-green.svg'
import { BtnTransparent } from '../../components/FormItems'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function Map() {
    const navigate = useNavigate()
    const location = useLocation()
    const markerRef = useRef(null)
    const { dataItem, searchinput, backlink, title, clinic_id, specialty_id, appointment_id, keyword, doctor_id, type, item, from } = location?.state

    useEffect(() => {
        setTimeout(() => {
            const marker = markerRef.current
            if (marker) {
                marker.openPopup()
            }
            // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id,', from: ',from,', appointment_id: ',appointment_id)
        }, 500);
        
    
        return () => {
            
        }
    }, [])
    

    
    return (
        <div className="map-page">
            <div className="w-full">
                <div id="map">
                    <MapContainer center={!!dataItem.clinic_latitude && !!dataItem.clinic_longitude?[dataItem.clinic_latitude, dataItem.clinic_longitude]:[dataItem.latitude, dataItem.longitude]} zoom={16} zoomControl={false} scrollWheelZoom={false} style={{height: (window.innerHeight)+'px'}}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center bg-form-bg rounded-md border-2 border-gray-3 absolute top-3 left-3 z-[1000] px-2 py-2" 
                            action={()=>{ 
                                navigate(
                                    type==='searchbydoctor' || type==='specialty' || type==='symptom' || (type==='clinic'&&from==='/detaildoctor') || type==='home'?'/detaildoctor':backlink, 
                                    { state: 
                                        { 
                                            searchinput: searchinput, 
                                            item: item,
                                            title: title, 
                                            clinic_id: clinic_id, 
                                            doctor_id: doctor_id,
                                            specialty_id: specialty_id,
                                            appointment_id: appointment_id,
                                            keyword: keyword,
                                            type: type,
                                            backlink: backlink,
                                        }
                                    })
                                } 
                            }
                        />
                        <ZoomControl position="topright" />
                        <Marker ref={markerRef} position={!!dataItem.clinic_latitude && !!dataItem.clinic_longitude?[dataItem.clinic_latitude, dataItem.clinic_longitude]:[dataItem.latitude, dataItem.longitude]}>
                            <Popup>
                                <div className="flex flex-col grow">
                                    <h1 className="mb-1 font-promptsemibold text-lg">{!!dataItem.clinic_name?dataItem.clinic_name:dataItem?.name}</h1>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row mb-0">
                                            <div className="flex flex-row grow">
                                                <img className="w-3 sm:w-4 mr-2 self-start pt-1" src={IconPin} alt="icon location" />
                                                <span className="self-center text-blue text-sm sm:text-md">{!!dataItem.clinic_address?dataItem.clinic_address:dataItem?.address} {!!dataItem.clinic_subdistrict?dataItem.clinic_subdistrict:dataItem?.subdistrict} {!!dataItem.clinic_district?dataItem.clinic_district:dataItem?.district} {!!dataItem.clinic_province?dataItem.clinic_province:dataItem?.province} {!!dataItem.clinic_zipcode?dataItem.clinic_zipcode:dataItem?.zipcode}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-row mb-0">
                                            <div className="flex flex-row grow">
                                                <img className="w-3 sm:w-4 mr-2" src={IconProfile} alt="icon rating" />
                                                <span className="self-center text-gray-2 text-sm sm:text-md">แพทย์ {!!dataItem.clinic_num_doctor?dataItem.clinic_num_doctor:dataItem?.num_doctor} คน</span>
                                            </div>
                                            <div className="grow-0">
                                                <p className="text-gray-2 text-sm sm:text-md">{(!!dataItem.clinic_distance?dataItem.clinic_distance:dataItem?.distance)? Number(!!dataItem.clinic_distance?dataItem.clinic_distance:dataItem?.distance / 1000).toLocaleString('en-US')+'กม.': null}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
        </div>
    )
}

export default Map