import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-modal'
import { useNavigate, useLocation } from 'react-router-dom'

import logoThaiDoctor from '../../assets/img/logo-thaidoctor.svg'
// import IconSearch from '../../assets/img/ic-search.svg'
import IconCalendarActive from '../../assets/img/ic-calendar-active.svg'

import { TokenContext } from '../../App'
import Nav from '../../components/Nav'
import ItemDoctor from '../../components/ItemDoctor'
import V2ItemClinic from '../../components/V2ItemClinic'

// import { LiffLogout } from '../../components/LiffLogout'
// import { BtnTransparent } from '../../components/FormItems'

function V2Home() {
  const location = useLocation()
  const navigate = useNavigate()
  const { token, consentTerm, consentPrivacy, setCurrentPage, firstRegister, setFirstRegister, setDataLatLng } = useContext(TokenContext)
  const [modalSuccess, setModalSuccess] = useState(false)
  // const [showFastTrack, setShowFastTrack] = useState(false)
  const [showAppointment, setShowAppointment] = useState(false)
  const [dataItemFastTrack, setDataItemFastTrack] = useState('')
  const [dataItemAppointment, setDataItemAppointment] = useState('')
  const [fetchMsgFasttrack, setFetchMsgFasttrack] = useState('...Loading...')
  const [fetchMsgAppointment, setFetchMsgAppointment] = useState('...Loading...')
  // const [loadingHome, setLoadingHome] = useState(true)


  let dataRegisterSuccess = location.state?.success

  useEffect(() => {
    setCurrentPage('/home')
    
    // check token
    if(!token) {
      navigate('/')
    } else {
      // Fetch Apppoint
      (async () => {
        // Fetch detail doctor
        const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment?show_all=0`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            // "Accept": "application/json",
            'Authorization': 'Bearer ' + token
          }
        });
    
        resDetailDoctor
            .json()
            .then((response) => {
                console.log('appointment : ',response)
                // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                if(response) {
                  // setFetchMsg('')
                  if(!response.total) {
                    setShowAppointment(false)
                    setFetchMsgAppointment('ไม่พบข้อมูล')
                  } else {
                    setShowAppointment(true)
                  }
                  setDataItemAppointment(response)

                  // Check call or cancel GraphQLPaymentStatus
                  if (response.data.filter(item => item.display_status === 'need_payment').length > 0) {
                    console.log('has need_payment')
                  } else {
                    console.log('hasnt need_payment')

                    // Remove localStorage 'payment'
                    localStorage.removeItem('payment')
                  }
                  
                  
                } else {
                  setShowAppointment(false)
                  setFetchMsgAppointment('ไม่พบข้อมูล')
                }
            })
            .catch((err)=> { console.log(err); setShowAppointment(false);})
      })();
      
      // Fetch Fast Track
      (async () => {
        // Fetch detail doctor
        const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/front_page`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            // "Accept": "application/json",
            'Authorization': 'Bearer ' + token
          }
        });
    
        resDetailDoctor
            .json()
            .then((response) => {
                console.log('fastTrack : ',response)
                // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                if(response) {
                  if(!response.total) {
                    // setShowAppointment(false)
                    setFetchMsgFasttrack('ไม่พบข้อมูล')
                  }
                  setDataItemFastTrack(response)
                } else {
                  setFetchMsgFasttrack('ไม่พบข้อมูล')
                }
            })
            .catch((err)=> console.log(err))
      })();
    }

    // console.log('Login check => term: ',consentTerm,', privacy: ',consentPrivacy)
    // console.log('Home token: ',token)

    // check term
    if(!consentTerm) {
      navigate('/termcondition')
    } else {
      // check privacy
      if(!consentPrivacy) {
        navigate('/privacy')
      }
    }
    // console.log('Hello, Home')
    let interval

    if(firstRegister) {
      setModalSuccess(true)

      // countdown otp input
      interval = setInterval(() => {
        setModalSuccess(false);
        setFirstRegister(false)

        // clear location state
        navigate(location.pathname, {}); 
      }, 3000); 
    } 


    return () => {
      clearInterval(interval)
      setModalSuccess(false)
    }
  }, [consentPrivacy, consentTerm, dataRegisterSuccess, firstRegister, location.pathname, navigate, setCurrentPage, setDataLatLng, setFirstRegister, token])

  const gotoDetailDoctor = (id) => {
    navigate('/detaildoctor', {
      state: {
        doctor_id: id,
        clinic_id: '',
        specialty_id: '',
        item: '',
        type: 'home',
        searchinput: '',
        title: '',
        backlink: 'home',
      }
    })
  }

  const gotoSummaryAppointment = (id) => {
    navigate('/summaryappointment', {
      state: {
        appointment_id: id
      }
    })
  }
  
  Modal.setAppElement('#root');

  return (
    <div className="home-page relative">
      <div className="p-4 pb-0">
        <V2ItemClinic backlink="/home" />
      </div>
      <div className=" bg-form-bg">
        {/* Search keyword */}
        {/* {
          !showAppointment?
            <div className={`flex flex-row border border-form-bd rounded-lg `}>
                <div className="flex pl-3 pr-1">
                  <img src={IconSearch} alt="icon search"  />
                </div>
                <input 
                  placeholder="ค้นหา คลินิก, แพทย์, อาการ" 
                  className={`p-2.5 pr-6 w-full rounded-r-lg outline-0 `}
                  
                />
            </div>
          : null
        } */}
        
        {/* Appointment */}
        {
          showAppointment ? 
            <div className="p-4 mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <img src={IconCalendarActive} className="mr-2" alt="icon calendar"  />
                  <h1 className="font-promptsemibold">การนัดหมาย</h1>
                </div>
                <p className="underline text-gray-2 text-sm" onClick={()=> {navigate('/historyappointment')}}>ประวัติการนัดหมาย</p>
              </div>
              {
                dataItemAppointment?.total ?
                  dataItemAppointment?.data.map((item,i)=> {
                    return (        
                      <ItemDoctor key={i} dataItem={item} type="appointment" onclick={()=> gotoSummaryAppointment(item.id)} />
                    )
                  })
                : <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsgAppointment }}></p>
              }
            </div>
          : null
        }
      </div>
      <div className="px-4 bg-white">
        {/* Fast Track */}
        <div className="mt-4 mb-16">
          <h1 className="font-promptsemibold text-red">ปรึกษาด่วน</h1>
          {
            dataItemFastTrack?.total ?
              dataItemFastTrack?.data.map((item,i)=> {
                return (        
                  <ItemDoctor key={i} dataItem={item} type="consult" onclick={()=> gotoDetailDoctor(item.id)}/>
                )
              })
            : <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsgFasttrack }}></p>
          }

          {/* <LiffLogout />
          
          <BtnTransparent text="PAYMENT"  addclass="flex items-center pl-0" 
              action={()=>{ navigate('/paymentstatus')}}
            /> */}
        </div>
        
      </div>

      {/* Nav */}
      <Nav currentPage="home" />

      {/* Modal Success */}
      <Modal
        isOpen={modalSuccess}
        className="Modal"
        overlayClassName="Overlay"
        style={{overlay: {zIndex: 20}}}
      >
        <img src={logoThaiDoctor} alt="logo thaidoctor" className="mx-auto my-4" />
        <h1 className="font-promptsemibold mb-0 text-lg">ยินดีด้วย</h1>
        <p className="text-gray-2 mb-4">คุณสามารถใช้งานระบบได้แล้ว</p>
      </Modal>
    </div>
  )
}

export default V2Home