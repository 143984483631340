import React, { useEffect, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { TokenContext } from '../../App'

import Logo from '../../assets/img/logo-thaidoctor.svg'

// import {
//   ApolloClient,
//   ApolloProvider,
//   HttpLink,
//   InMemoryCache,
//   split,
// } from '@apollo/client';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { createClient as createClientWs } from 'graphql-ws';

// import { gql } from "@apollo/client/core";
// import { useSubscription } from "@apollo/react-hooks";

import IconFailed from '../../assets/img/ic-failed.svg'
import IconSuccess from '../../assets/img/ic-success.svg'
import LoadingNoLogo from '../../components/LoadingNoLogo';
import { BtnPrimary } from '../../components/FormItems';
// console.log(localStorage.getItem("accessTokenApi"))

// Get & Set appointment_id, doctor_id, amount, currency from Omise return page
const urlSearchParams = new URLSearchParams(window.location.search);
let appointment_id = ''
let doctor_id = ''
let amount = ''
let currency = ''

if(urlSearchParams.has('appointment_id')) {
    appointment_id = urlSearchParams.get('appointment_id')

  console.log('get appointment_id [Payment Status]: ', appointment_id)

} else {
  console.log('has not appointment_id [Payment Status]')
}

if(urlSearchParams.has('doctor_id')) {
    doctor_id = urlSearchParams.get('doctor_id')

  console.log('get doctor_id [Payment Status]: ', doctor_id)

} else {
  console.log('has not doctor_id [Payment Status]')
}

if(urlSearchParams.has('amount')) {
    amount = urlSearchParams.get('amount')

  console.log('get amount [Payment Status]: ', amount)

} else {
  console.log('has not amount [Payment Status]')
}

if(urlSearchParams.has('currency')) {
    currency = urlSearchParams.get('currency')

  console.log('get currency [Payment Status]: ', currency)

} else {
  console.log('has not currency [Payment Status]')
}


function PaymentStatus() {
    const navigate = useNavigate()
    const { setToken } = useContext(TokenContext)

    // Set Modal ---------------------------------------------//
    const modalEvent = useCallback( (action, id) => {
        if(action === 'open') {
            document.getElementById('modal-overlay-paymentstatus').style.display = 'block'
            document.getElementById(id).style.display = 'block'

            // Countdown 3 sec for close modal end
            // setTimeout(() => {
            //     if(id==='modal-paymentstatus-success') {
            //         navigate('/historymedical', {
            //             state: {
            //                 id: appointment_id,
            //                 symptom: null,
            //                 symptom_time: null,
            //                 has_allergy: null,
            //                 allergy_detail: null,
            //                 chronic_condition: null,
            //                 height: null,
            //                 weight: null,
            //                 type: null
            //             }
            //         })
            //     } else {
            //         navigate('/')
            //     }
            // }, 3000);
        } else {
            document.getElementById('modal-overlay-paymentstatus').style.display = 'none'
            document.getElementById(id).style.display = 'none'
        }
    },[])

    

    // Feteh Payment Status
    const fetchPaymentStatue = useCallback (() => {
        (async () => {
            // Fetch detail doctor
            const resPaymentStatus = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointment_id}/payment/status`, {
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem("accessTokenApi")
                }
            });
            
        if(resPaymentStatus.status === 200) {
            resPaymentStatus
            .json()
            .then((response) => {
                console.log('success [PaymentStatus]!!')
                // console.log('payment status : ',response)
                // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                if(!response.success && !response.pending_credit_card) { // success==0 && pending_credit_card==0
                    modalEvent('close','modal-paymentstatus-success')
                    modalEvent('open','modal-paymentstatus-failure')
                } 
                if(response.success && !response.pending_credit_card) { // success==1 && pending_credit_card==0
                    modalEvent('close','modal-paymentstatus-failure')
                    modalEvent('open','modal-paymentstatus-success')
                }
                if(!response.success && response.pending_credit_card) { // success==0 && pending_credit_card==1
                    modalEvent('close','modal-paymentstatus-success')
                    modalEvent('open','modal-paymentstatus-failure')
                } 
            })
            .catch((err)=> { console.log('error [PaymentStatus]: ',err); })
        } else {
            modalEvent('close','modal-paymentstatus-success')
            modalEvent('open','modal-paymentstatus-failure')
        }
            
        })();
    },[modalEvent])

    useEffect(() => {
        setToken(localStorage.getItem('accessTokenApi'))
        fetchPaymentStatue()
    
        return () => {
            
        }
    }, [fetchPaymentStatue, setToken])
    

    // // GraphQL -----------------------------------------------//
    // function callgraphql () {
    //     console.log('*** Call GRAPH QL (Payment Status) ***')
    //     const httpLink = new HttpLink({
    //         // You should use an absolute URL here
    //         uri: process.env.REACT_APP_GRAPHQL_HTTPS || '',
    //         headers: {
    //             Authorization: `Bearer ${localStorage.getItem("accessTokenApi")}`,
    //         },
    //     });

    //     // Create the subscription websocket link
    //     const wsLink = new GraphQLWsLink(
    //         createClientWs({
    //             url: process.env.REACT_APP_GRAPHQL_WS || '',
    //             retryAttempts: Infinity,
    //             shouldRetry: () => true,
    //             keepAlive: 10000,
    //             connectionParams: () => {
    //                 return {
    //                     Authorization: `Bearer ${localStorage.getItem("accessTokenApi")}`,
    //                 };
    //             },
    //         })
    //     );

    //     const link = split(
    //         // split based on operation type
    //         ({ query }) => {
    //         const definition = getMainDefinition(query);
    //         return (
    //             definition.kind === 'OperationDefinition' &&
    //             definition.operation === 'subscription'
    //         );
    //         },
    //         wsLink,
    //         httpLink
    //     );

    //     // Create the apollo client
    //     const apolloClient = new ApolloClient({
    //         link,
    //         cache: new InMemoryCache(),
    //         connectToDevTools: true,
    //     });

    //     return apolloClient

    // }

    // // Subscription ------------------------------------------//
    // const USERS_ADDED_SUBSCRIPTION = gql`
    // subscription onEventAddedType {
    //     eventAdded {
    //         event
    //         doctor_id
    //         patient_id
    //         datetime_from
    //         datetime_to
    //         appointment_id
    //         clinic_id
    //     }
    // }
    // `;

    // function EventSubscription() {
    //     const { loading, error, data } = useSubscription(USERS_ADDED_SUBSCRIPTION);

    //     if (loading) return null;
    //         if (error) {
    //             console.log(`Error! ${error.message}`);
    //             return false
    //         }

    //     if (!loading && data) {
    //         // const { added } = data.eventAdded;
    //         console.log('[Event]:', data.eventAdded.event+' !! (Checking Payment Status)',)
    //         // if(data.eventAdded.event === 'keepalive'){
    //         //     modalEvent('open', 'modal-time-warning')
    //         // }

    //         if(data.eventAdded.event === 'payment_success_credit_card'){
    //             modalEvent('close','modal-paymentstatus-failure')
    //             modalEvent('open', 'modal-paymentstatus-success')
    //         }

    //         if(data.eventAdded.event === 'payment_success_promptpay') {
    //             modalEvent('close','modal-paymentstatus-failure')
    //             modalEvent('open', 'modal-paymentstatus-success')
    //         }

    //         if(data.eventAdded.event === 'payment_failure_credit_card') {
    //             modalEvent('close','modal-paymentstatus-success')
    //             modalEvent('open','modal-paymentstatus-failure')
    //         }

    //         // Fetch Payment status
    //         fetchPaymentStatue()

    //     } else {
    //         console.log('Listening...')
    //         return (<h4>Listening...</h4>);
    //     }
    // }
  

    return (
        <div className="flex flex-col h-full justify-center items-center">
            {/* <ApolloProvider client={callgraphql()}>
                <EventSubscription /> */}
                <div className="w-full mx-auto">
                    <img src={Logo} alt="Logo ThaiDoctor" className="w-48 mx-auto my-8" />
                </div>
                <LoadingNoLogo style={{ justifyContent: 'normal', alignItems: 'normal', height: 'fit-content', marginBottom: '10px' }} />
                <p>Payment Status</p>
                <p>...Checking...</p>

                {/* Modal & Overlay */}
                <div id="modal-overlay-paymentstatus" className="fixed w-full h-full left-0 top-0 right-0 bottom-0 m-auto z-20 p-10 bg-black/50 hidden">
                    {/* Modal Payment Failure */}
                    <div id="modal-paymentstatus-failure" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                        <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                        <h1 className="font-promptsemibold mb-0 text-lg">การชำระเงินไม่สำเร็จ</h1>
                        <p className="text-gray-2 mb-4 text-md">กรุณาติดต่อธนาคารของท่านหรือลองอีกครั้ง</p>

                        <BtnPrimary type="button" text="กลับไป"  action={()=>{ 
                            navigate('/payment', {state: { doctor_id: doctor_id, id: appointment_id, amount: amount, currency: currency}})
                        }}
                        />
                    </div>

                    {/* Modal Payment Success */}
                    <div id="modal-paymentstatus-success" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                        <img src={IconSuccess} alt="logo thaidoctor error" className="mx-auto my-4" />
                        <h1 className="font-promptsemibold mb-0 text-lg">การชำระเงินสำเร็จ</h1>
                        <p className="text-gray-2 mb-4 text-md">กรุณาอธิบายอาการเบื้องต้น</p>

                        <BtnPrimary type="button" text="ถัดไป"  action={()=>{ 
                            navigate('/historymedical', {
                                state: {
                                    id: appointment_id,
                                    symptom: null,
                                    symptom_time: null,
                                    has_allergy: null,
                                    allergy_detail: null,
                                    chronic_condition: null,
                                    height: null,
                                    weight: null,
                                    type: null
                                }
                            })
                        }}
                        />
                    </div>
                </div>


            {/* </ApolloProvider> */}
        </div>
    )
}

export default PaymentStatus