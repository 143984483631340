import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import IconNoImg from '../assets/img/ic-img.svg'
import V2ItemClinic from './V2ItemClinic'

function V2NavSearch(props) {
    const { currentPage, innerRef } = props
    const navigate = useNavigate()
    
    useEffect(() => {
        // console.log(currentPage)
        if(!currentPage) {
            navigate('/searchbydoctor', { state: { searchinput: ''}})
        }
        
        return () => {
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, navigate])
    

    return (
        <div ref={innerRef}>
            <div className="pt-4">
                <V2ItemClinic backlink="/search" />
            </div>

            <div className="flex flex-row pt-6 gap-x-3 gap-y-3 mb-6">
                {[
                    ['รายชื่อแพทย์', '/searchbydoctor', IconNoImg, (currentPage === 'searchbydoctor')?'active':null],
                    ['ความเชี่ยวชาญ', '/searchbyspecialty', IconNoImg, (currentPage === 'searchbyspecialty')?'active':null],
                    ['อาการ', '/searchbysymptom', IconNoImg,  (currentPage === 'searchbysymptom')?'active':null],
                ].map(([title, url, icon, active], i) => (
                    <button key={i} onClick={()=> navigate(url, { state: { searchinput: ''} })} className={`${(active==='active')?'border-card-active':'border-card-bd'} w-1/3 rounded-lg shadow-lg shadow-card-shadow border-2 text-center p-3`}>
                        <div className={`${(active==='active')?'bg-card-active':'bg-gray-4'} relative h-12 w-12 rounded-full ring-2 ring-white mb-2 mx-auto `}>
                            <img className={`inline-block absolute m-auto left-0 right-0 top-0 bottom-0 `} src={icon} alt="icon search menu" />
                        </div>
                        {
                            i === 0 ?
                            <p className="text-xs"> {title}</p>
                            : <p className="text-xs">ค้นหาจาก <br/> {title}</p>
                        }
                    </button>
                ))}
            </div>
        </div>
    )
}

export default V2NavSearch