import React, { useEffect, useContext, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TokenContext } from '../../App'
import {
    BtnPrimary,
    BtnTransparent 
} from '../../components/FormItems'

import { useLiff } from 'react-liff';

import IconBack from '../../assets/img/ic-back.svg'
import IconBankBag from '../../assets/img/ic-bankbag.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import IconSuccess from '../../assets/img/ic-success.svg'
// import GraphQLPaymentStatus from './GraphQLPaymentStatus';

function PaymentPromptPay() {
    const { liff } = useLiff();
    const location = useLocation()
    const navigate = useNavigate()
    const { token } = useContext(TokenContext)
    const { doctor_id, appointment_id, amount, currency,  } = location?.state || ''
    const [dataPromptPay, setDataPromptPay] = useState({})
    // const [errLiff, setErrLiff] = useState('')
    // const [hasErrLiff, setHasErrLiff] = useState(false)

    // Set Modal ---------------------------------------------//
    const modalEvent = useCallback( (action, id) => {
        if(action === 'open') {
            // Countdown 1 sec waiting for prevent open modal duplicate
            setTimeout(() => {
                document.getElementById('modal-overlay-promptpay').style.display = 'block'
                document.getElementById(id).style.display = 'block'

                // Auto Close popup and go to home
                if(id==='modal-payment-omise-failure') {
                    setTimeout(() => {
                        document.getElementById('modal-overlay-promptpay').style.display = 'none'
                        document.getElementById(id).style.display = 'none'

                        navigate('/')
                    }, 3000);
                }

            }, 500);

        } else {
            document.getElementById('modal-overlay-promptpay').style.display = 'none'
            document.getElementById(id).style.display = 'none'
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if(!token) {
            navigate('/')
            // console.log(token)
        } else {
            // Fetch Apppoint
            (async () => {
                // Fetch detail doctor
                const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointment_id}/payment/omise/promptpay`, {
                    method: 'POST',
                    headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                    }
                });
            
                if(resDetailDoctor.status === 200) {
                    resDetailDoctor
                        .json()
                        .then((response) => {
                            // console.log('get key success!!')
                            console.log('payment promptpay : ',response)
                            // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                            if(response) {
                                setDataPromptPay(response)
                                
                            } else {
                            }
                        })
                        .catch((err)=> { console.log('get key failure!! ',err); })
                } else {
                    console.error('Omise Promptpay failure')
                    modalEvent('open','modal-payment-omise-failure')
                    modalEvent('close','modal-payment-promptpay-success')
                }

            })();
        }
        
        return () => {
            
        }
    }, [appointment_id, modalEvent, navigate, token])
    
    const handleDownloadQrCode = () => {
        // window.open(dataPromptPay.download_uri)
        if(liff.isInClient()) {
            liff.openWindow({
                url: `${window.location.origin}/download/?urlname=${dataPromptPay.download_uri}&filename=${dataPromptPay.fileName}`,
                external: true
            })
        } else {
            const urlDownload = dataPromptPay.download_uri;
            const downloadLink = document.createElement('a');
            const fileName = 'qrcode.svg';

            downloadLink.href = urlDownload;
            downloadLink.download = fileName;
            downloadLink.click();
        }
        
        // liff
        // .sendMessages([
        //     {
        //         "type": "image",
        //         "originalContentUrl": "https://picsum.photos/200/300?grayscale",
        //         "previewImageUrl": "https://picsum.photos/200/300?grayscale"
        //     },{
        //         "type": "image",
        //         "originalContentUrl": dataPromptPay.download_uri,
        //         "previewImageUrl": dataPromptPay.download_uri
        //     },
        // ])
        // .then(() => {
        //     console.log("message sent");
        //     liff.closeWindow()
        // })
        // .catch((err) => {
        //     console.log("error", err);
        //     setHasErrLiff(true)
        //     setErrLiff(err.toString())
        // });
    }

    return (
        <div className="payment-promptpay-page p-5">
                {/* <GraphQLPaymentStatus appointment_id={appointment_id} /> */}
            

                <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" 
                    action={()=>{ navigate('/payment', {state: { doctor_id: doctor_id, id: appointment_id, amount: amount, currency: currency}}) }}
                />

                <div className="flex flex-row mb-5">
                    <img src={IconBankBag} className="mr-2" alt="icon bankbag"  />
                    <h1 className="font-promptsemibold">ชำระผ่าน PromptPay / พร้อมเพย์</h1>
                </div>
                <div className="my-6 text-gray-2">
                    <p>1. ดาวน์โหลดภาพคิวอาร์โค้ดที่ด้านล่าง</p>
                    <p>2. เปิดแอปธนาคารที่คุณต้องการใช้เป็นช่องทางชำระเงิน</p>
                    <p>3. เลือกเมนูคิวอาร์โค้ด</p>
                    <p>4. เลือกสแกนคิวอาร์โค้ด</p>
                    <p>5. กดชำระเงิน</p>
                    <p>6. ระบบจะแจ้งเตือนหลังจากได้รับการชำระเงินเรียบร้อยแล้ว</p>
                </div>

                <div className="w-3/4 h-64 mx-auto flex mb-10">
                    {
                        (Object.keys(dataPromptPay).length === 0 && dataPromptPay.constructor === Object) ?
                        <p className="py-8 w-full text-center">...Loading...</p>
                        : 
                        <img className="w-full" src={dataPromptPay.download_uri} alt="Prompt Pay QR Code" />
                    }
                </div>

                

                {/* <a className="mb-5" href={dataPromptPay.download_uri} download={`qrcode.svg`}> */}
                    {/* <BtnPrimary type="button" text="ดาวน์โหลดภาพคิวอาร์โค้ด" addclass="mb-5" action={handleDownloadQrCode} />  */}
                {/* </a> */}
                {
                    (Object.keys(dataPromptPay).length === 0 && dataPromptPay.constructor === Object) ? null
                    :
                    <div>
                        <p className="text-softred text-sm sm:text-md mt-5 mb-3 text-center">กรุณาชำระค่าบริการโดยใช้ QR code นี้ภายใน 5 นาที</p>
                        {/* <a href={dataPromptPay.download_uri} download="qrcode" className={`flex justify-center items-center rounded-lg font-promptsemibold p-3 text-white text-center w-full bg-primary`}>
                            <p>ดาวน์โหลดภาพคิวอาร์โค้ด(tag a)</p>
                        </a> */}
                        <BtnPrimary type="button" text="ดาวน์โหลดภาพคิวอาร์โค้ด" addclass="mb-5" action={handleDownloadQrCode} />
                        {/* <button onClick={handleDownloadQrCode}  className={`flex justify-center items-center rounded-lg font-promptsemibold p-3 text-white text-center w-full bg-primary`}>Download</button> */}
                    </div>
                    
                }


                {/* {
                    hasErrLiff ? <p className="text-sm text-gray-3">{errLiff}</p> : null
                } */}

            {/* Modal & Overlay */}
            <div id="modal-overlay-promptpay" className="fixed w-full h-full left-0 top-0 right-0 bottom-0 m-auto z-20 p-10 bg-black/50 hidden">
                {/* Modal Payment Failure */}
                <div id="modal-payment-promptpay-failure" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">การชำระเงินไม่สำเร็จ</h1>
                    <p className="text-gray-2 mb-4 text-md">กรุณาติดต่อธนาคารของท่านหรือลองอีกครั้ง</p>

                    <BtnPrimary type="button" text="กลับไป"   action={()=>{
                        navigate('/payment', {state: { doctor_id: doctor_id, id: appointment_id, amount: amount, currency: currency}})
                    }} />
                </div>

                {/* Modal Omise Failure */}
                <div id="modal-payment-omise-failure" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">การนัดหมายถูกยกเลิก </h1>
                    <p className="text-gray-2 mb-4 text-md">กรุณาลองอีกครั้ง</p>

                    <BtnPrimary type="button" text="กลับไป"   action={()=>{
                        navigate('/')
                    }} />
                </div>

                {/* Modal Payment Success */}
                <div id="modal-payment-promptpay-success" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconSuccess} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">การชำระเงินสำเร็จ</h1>
                    <p className="text-gray-2 mb-4 text-md">กรุณาอธิบายอาการเบื้องต้น</p>

                    <BtnPrimary type="button" text="ถัดไป"   action={()=>{
                        navigate('/historymedical', {
                            state: {
                                id: appointment_id,
                                symptom: null,
                                symptom_time: null,
                                has_allergy: null,
                                allergy_detail: null,
                                chronic_condition: null,
                                height: null,
                                weight: null,
                                type: null
                            }
                        })
                    }} />
                </div>
            </div>
        </div>
    )
}

export default PaymentPromptPay