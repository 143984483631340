import React, { useEffect, useContext, useState, useCallback } from 'react'
import Nav from '../../components/Nav'
import { useNavigate, useLocation } from 'react-router-dom';
import { TokenContext } from '../../App'
import { BtnTransparent } from '../../components/FormItems'

import IconBack from '../../assets/img/ic-back.svg'

function ConditionDetail() {
    const navigate = useNavigate()
    const location = useLocation()
    const { token } = useContext(TokenContext)
    const { conditionName, conditionId } = location?.state

    const [dataCondition, setDataCondition] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchConditionDetail = useCallback(async () => {
        try {
            setLoading(true)
            let path = '/api/consent/term/'
            if(conditionName==='privacy') {
                path = '/api/consent/privacy/'
            }

            const resConditionDetail = await fetch(`${process.env.REACT_APP_API_URL}${path}${conditionId}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                }
            });
            console.log('ConditionDetail: Status[',resConditionDetail.status,']',resConditionDetail)

            resConditionDetail
                .json()
                .then((res)=>{
                    setDataCondition(res)

                    setLoading(false)
                }).catch((err)=> { console.log(err); setLoading(false); navigate('/');/* setModalErr(true); */ })
            
        } catch (error) {
            // setModalErr(true)
            setLoading(false)
            navigate('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ navigate, token])

    useEffect(() => {
        // check token
        if(!token) {
            navigate('/')
        }

        fetchConditionDetail()

        console.log(conditionName, conditionId)
    
        return () => {
            
        }
    }, [conditionId, conditionName, fetchConditionDetail, navigate, token])
    

    return (
        <div className="condition-detail-page p-5 relative">
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" 
              action={()=>{ navigate('/profile')}}
            />
            <div>
                <h1 className="font-semibold pt-4 text-center">{ conditionName==='privacy'?'นโยบายความเป็นส่วนตัว':'ข้อกำหนดและเงื่อนไขการให้บริการ'}</h1>
                {
                    loading ? 
                    <p className="text-center mt-8 text-gray-2 w-full">...Loading...</p>
                    : 
                    <div className="overflow-auto mt-4" style={{height: window.innerHeight - 216+'px'}}>
                        <p className="text-left text-gray-2 text-xs" dangerouslySetInnerHTML={{__html: dataCondition.content }}></p>
                    </div>
                }
            </div>

            {/* Nav */}
            <Nav currentPage="profile" />
        </div>
    )
}

export default ConditionDetail