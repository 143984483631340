import React, { useEffect, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ItemClinic from '../../components/ItemClinic'
import IconBack from '../../assets/img/ic-back.svg'
import { BtnTransparent } from '../../components/FormItems'

import { TokenContext } from '../../App'

let option = ''
function SearchResult() {
  const navigate = useNavigate()
  const location = useLocation()
  const { token } = useContext(TokenContext)
  const { searchinput, title } = location.state
  const [searchResultList, setSearchResultList] = useState({})
  const [fetchMsg, setFetchMsg] = useState(`...Loading...`)
  // console.log(searchinput)

  useEffect(() => {
    // check token
    if(!token) {
      navigate('/')
    }

    (async () => {
      if(searchinput.search) {
        option = option+`&search=${searchinput.search}`
      }
      if(searchinput.start || searchinput.start === 0) {
        option = option+`&start=${searchinput.start}`
      }
      if(searchinput.count) {
        option = option+`&count=${searchinput.count}`
      }
      if(searchinput.id) {
        option = option+`&id=${searchinput.id}`
      }
      if(searchinput.province) {
        option = option+`&province=${searchinput.province}`
      }
      if(searchinput.district) {
        option = option+`&district=${searchinput.district}`
      } 
      if(searchinput.latitude) {
        option = option+`&latitude=${searchinput.latitude}`
      }
      if(searchinput.longitude) {
        option = option+`&longitude=${searchinput.longitude}`
      }
  
      // console.log('option: ',option.substring(1))
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/search/clinic_by_name${!!option?'?'+option.substring(1):''}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          // "Accept": "application/json",
          'Authorization': 'Bearer ' + token
        }
      });
  
      res
          .json()
          .then((response) => {
              if(!response.total) {
                setFetchMsg('ไม่พบข้อมูล')
              } else {
                // console.log('searchResult : ',response)
                setSearchResultList(response)
              }
          })
          .catch((err)=> console.log(err))
    })();
  
    return () => {
      option = '' // clear fetch option
    }
  }, [navigate, searchinput, searchinput.count, searchinput.district, searchinput.id, searchinput.latitude, searchinput.longitude, searchinput.province, searchinput.search, searchinput.start, token])
  

  const gotoSearchResultDoctor = (item) => {
    navigate('/searchresultdoctor', {
      state: {
        searchinput: searchinput,
        title: 'ผลการค้นหาจากคลินิก', 
        item: item,
        clinic_id: item.id,
        type: 'clinic',
      }
    })
  }

  return (
    <div className="search-result-page p-5">
      <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/searchbyclinic', { state: { searchinput: ''} })} }/>
      <h1>
        <span className="font-promptsemibold">{title}</span>&nbsp;
        <span className="text-darkblue">"{ !!searchinput.search ? `${searchinput.search}` : null  }{ !!searchinput.district ? ` ${searchinput.district}` : null}{ !!searchinput.province ? ` ${searchinput.province}` : null}"</span>
      </h1>
      {
        searchResultList.total ? 
          searchResultList?.data?.map((item,i)=> {
            return (        
              <ItemClinic key={i} dataItem={item} searchinput={searchinput} onclick={()=> gotoSearchResultDoctor(item)} backlink="/searchresult" title={title} />
            )
          })
        : <p className="text-center text-gray-2 w-full mt-8">{fetchMsg}</p>
      }
    </div>
  )
}

export default SearchResult