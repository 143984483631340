import React, { useEffect, useContext, useCallback, useState, useRef } from 'react'
// import { useGeolocated } from "react-geolocated";
import { useNavigate, useLocation } from 'react-router-dom'
import Nav from '../../components/Nav'
import IconSearch from '../../assets/img/ic-search.svg'
import IconDelete from '../../assets/img/ic-delete.svg'
import IconArrowDown from '../../assets/img/ic-arrowdown.svg'

import { TokenContext } from '../../App'
import NavSearch from '../../components/NavSearch'
import ItemClinic from '../../components/ItemClinic'
import PVData from '../../DataProvinces.json'
import APData from '../../DataDistricts.json'

// let allowGeoRecall = true;
// let countLocationAttempts = 0;

// const options = {
//   enableHighAccuracy: true,
//   timeout: 5000,
//   maximumAge: 0
// }

let callingApi = false
let option = ''
let getLatitude = ''
let getLongitude = ''
const startAddValue = 20 // for Lazy load
const countValue = 20 // for Lazy load
let searchInput = {
  search: '',
  start: 0, // ''
  count: countValue, // ''
  id: '',
  province: '',
  district: '',
  latitude: '',
  longitude: ''
}

function SearchByClinic() {
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
  //   positionOptions: {
  //       enableHighAccuracy: false,
  //   },
  //   userDecisionTimeout: 5000,
  // });
  const scrollRef = useRef(null)
  const searchRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const { token, setCurrentPage, setDataLatLng } = useContext(TokenContext)
  const { searchinput } = (location?.state)?location.state:''
  const [searchResultList, setSearchResultList] = useState('')
  const [searchResultListData, setSearchResultListData] = useState([])
  // const [searchInput, setSearchInput] = useState({
  //   search: '',
  //   start: 0, // ''
  //   count: countValue, // ''
  //   id: '',
  //   province: '',
  //   district: '',
  //   latitude: '',
  //   longitude: ''
  // })
  const [fetchMsg, setFetchMsg] = useState(`...Loading...`)
  const [isLazyLoading, setIsLazyLoading] = useState(false)
  const [windowH] = useState(window.innerHeight)
  const [provinceSelect, setProvinceSelect] = useState('เลือกจังหวัด')
  const [districtSelect, setDistrictSelect] = useState('')
  const [districtList, setDistrictList] = useState([])
  const [openFilterProvince, setOpenFilterProvince] = useState(true)
  const [openFilterBox, setOpenFilterBox] = useState(false)



  const fetchSearch = useCallback(async (type, latitude, longitude, startlazy) => {
    callingApi = true
    searchInput.start = searchInput.start + (startlazy ? Number(startlazy) : 0);
    
    if(searchInput.search) {
      option = option+`&search=${searchInput.search}`
    }
    if(searchInput.start || searchInput.start === 0) {
      option = option+`&start=${searchInput.start}`
    }
    if(searchInput.count) {
      option = option+`&count=${searchInput.count}`
    }
    if(searchInput.id) {
      option = option+`&id=${searchInput.id}`
    }
    if(searchInput.province) {
      option = option+`&province=${searchInput.province}`
    }
    if(searchInput.district) {
      option = option+`&district=${searchInput.district}`
    } 
    if(latitude) {
      option = option+`&latitude=${latitude}`
    }
    if(longitude) {
      option = option+`&longitude=${longitude}`
    }

    // console.log('option: ',option.substring(1))
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/search/clinic_by_name${!!option?'?'+option.substring(1):''}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        // "Accept": "application/json",
        'Authorization': 'Bearer ' + token
      }
    });

   res
      .json()
      .then((response) => {
          callingApi = false
          // console.log('searchbyclinic : ',response)
          if(type==='page') { 
            navigate('/searchresult', { 
              state: { 
                result: response, 
                title: 'ผลการค้นหาจากคลินิก', 
                word: searchInput.search, 
                district: searchInput.district, 
                province: searchInput.province 
              }
            }) 
          } else { 
            if(response.total) {
              setSearchResultList(response) 

              if(type==='lazy') {
                setSearchResultListData([...searchResultListData, ...response.data])
              } else {
                setSearchResultListData(response.data)
              }

              // setSearchInput({...searchInput, id: response.id})
              searchInput['id'] = response.id
              setFetchMsg('')
            } else {
              setFetchMsg('ไม่พบข้อมูล')
              setSearchResultList([])
            }
            setIsLazyLoading(false)
            setOpenFilterBox(false)
          }
      })
      .catch((err)=> {console.log(err); setIsLazyLoading(false); navigate('/')})

    // clear param
    option = ''
  },[navigate, searchResultListData, token])

  // if(!isGeolocationAvailable) {
  //   console.error('*** Your browser does not support Geolocation ***')
  // }

  useEffect(() => {
    setCurrentPage('/searchbyclinic')

    // check token
    if(!token) {
      navigate('/')
    }

    // console.log('searchinput: ',searchinput)
    // Check back state
    if(!!searchinput) {
      searchInput = searchinput
      if(!!searchinput.search) {
        // console.log('searchinput: ',searchinput.search)
        // searchInput['search'] = searchinput.search
        // searchRef.current.focus()
        searchRef.current.value = searchinput.search
      }
      
      if(!!searchinput.province) {
        setDistrictSelect(searchinput.district===''?'ทุกเขต/อำเภอ':searchinput.district)
        setProvinceSelect(searchinput.province===''?'ทุกจังหวัด':searchinput.province)

        // get district list
        let PV_ID = 10
        let APlist = []
        for(let i=0; i<APData.length; i++) {
          if(Number(PV_ID) === APData[i].ProvinceID) {
            APlist.push({'name': APData[i].name, 'id': APData[i].id})
          }
        }
        setDistrictList(APlist)
        setOpenFilterProvince(false)
      }
  
    }

    // Call location -----------------------------
    // function getLocation() {   
    //   console.info('getLocation was called') 
    //   if(navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(showPosition, positionError, options);
    //   } else {
    //       // hideLoadingDiv()
    //       console.error('Geolocation is not supported by this device')
    //   }
    // }

    // function positionError() {    
    //     console.warn('Geolocation is not enabled. Please enable to use this feature')
        
            
    //     if(allowGeoRecall && countLocationAttempts < 2) {
    //         countLocationAttempts += 1;
    //         getLocation();
    //     } 
    // }

    // function showPosition(position){
    //     console.log('posititon accepted ', position.coords)
    //     allowGeoRecall = false;
    //     getLatitude = position.coords.latitude
    //     getLongitude = position.coords.longitude
    //     fetchSearch('list', position.coords.latitude, position.coords.longitude)
    // }

    // getLocation()
    // fetchSearch('list')
    // --------------------------------------------


   

    // check geolocation enable
    // if(isGeolocationEnabled) {
    //   console.info('!!! Geolocation is enabled !!!')
    //   // check coords
    //   if(coords) {
    //     console.info('ReactGeo =>lat: ',coords?.latitude,', lng: ',coords?.longitude)
    //     getLatitude = coords.latitude
    //     getLongitude = coords.longitude
    //     fetchSearch('list', coords.latitude, coords.longitude)
    //   }
      
    //   // fetchSearch('list')
    // } else {
    //   console.warn('*** Geolocation is not enabled ***')

      // fetchSearch('list',null,null)
    // }

    const getLocation = () => {
      if (!navigator.geolocation) {
        console.log('Geolocation is not supported by your browser');
      } else {
        console.log('Locating...');
        navigator.geolocation.getCurrentPosition(showPosition, showError, {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0
        })
      }
    }

    const showPosition = (position) => {
      console.log(position.coords.latitude,', ',position.coords.longitude);
      setDataLatLng({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      })

      if(!callingApi) {
        fetchSearch('list', position.coords.latitude, position.coords.longitude)
      }
    }
    
    const showError = (error) => {
      // eslint-disable-next-line default-case
      switch(error.code) {
        case error.PERMISSION_DENIED:
          fetchSearch('list',null,null)
          console.warn("Geolocation [warning]: User denied the request for Geolocation.")
          break;
        case error.POSITION_UNAVAILABLE:
          fetchSearch('list',null,null)
          console.warn("Geolocation [warning]: Location information is unavailable.")
          break;
        case error.TIMEOUT:
          fetchSearch('list',null,null)
          console.warn("Geolocation [warning]: The request to get user location timed out.")
          break;
        case error.UNKNOWN_ERROR:
          fetchSearch('list',null,null)
          console.warn("Geolocation [warning]: An unknown error occurred.")
          break;

        default:
          fetchSearch('list',null,null)
          console.warn("Geolocation [warning]: An unknown error occurred.")
          break;
      }
    }

    getLocation()
    

    // console.log('SearchByClinic token: ',token)
    
    return () => {
      option = '' // clear fetch option
      searchInput = {
        search: '',
        start: 0, // ''
        count: countValue, // ''
        id: '',
        province: '',
        district: '',
        latitude: '',
        longitude: ''
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, searchinput, setCurrentPage, token])

  const handleDistrictSelectOption = (val) => {
    let AP_NAME = val
    if(AP_NAME !== 'back') {
      // console.log('districtSelect: ',val)
      
      if(AP_NAME === '') {
        // Select all district
        setDistrictSelect('ทุกเขต/อำเภอ')
        searchInput['district'] = ''
        setOpenFilterProvince(false)
      } else {
        // Select district
        setDistrictSelect(AP_NAME)
        searchInput['district'] = AP_NAME
        setOpenFilterProvince(false)
      }
    } else {
      // Back for select province
      setDistrictSelect(null)
      searchInput['district'] = null
      searchInput['province'] = null
      setOpenFilterProvince(true)
    }

    if(!callingApi) {
      fetchSearch('list',getLatitude,getLongitude)
    }
    setOpenFilterBox(false)
  }

  const handleProvinceSelectOption = (val) => {
    // console.log('provinceSelect: ',e.target.value)

    setDistrictSelect(null)
    searchInput['district'] = null
    // console.log(e.target.value)
    let PV_ID = val
    if(PV_ID) {
      // Select province
      for(let i=0; i<PVData.length; i++) {
        if(Number(PV_ID) === PVData[i].id) {
          setProvinceSelect(PVData[i].name)
          searchInput['province'] = PVData[i].name
          setOpenFilterProvince(false)
        } 
      }
    } else if(PV_ID==='') {
      // Select all province
      searchInput['province'] = ''
      setProvinceSelect('ทุกจังหวัด')
      if(!callingApi) {
        fetchSearch('list',getLatitude,getLongitude)
      }
    } else {
      // Non select province
      setProvinceSelect(null)
      searchInput['province'] = null
      setOpenFilterProvince(true)
    }
    
    // get district list
    let APlist = []
    for(let i=0; i<APData.length; i++) {
      if(Number(PV_ID) === APData[i].ProvinceID) {
        APlist.push({'name': APData[i].name, 'id': APData[i].id})
      }
    }
    setDistrictList(APlist)

    // fetchSearch('list',getLatitude,getLongitude)
  }
  
  const handleSearchInput = (e) => {
    // setSearchInput({...searchInput, [e.target.name]: e.target.value})
    searchInput[e.target.name] = e.target.value
    if(!callingApi) {
      fetchSearch('list',getLatitude,getLongitude)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // console.log(searchInput)
    
    // fetchSearch('page')
    navigate('/searchresult', {
      state: {
        searchinput: searchInput,
        title: 'ผลการค้นหาจากคลินิก', 
      }
    })
  }

  // goto SearchResultDoctor
  const gotoSearchResultDoctor = (item) => {
    navigate('/searchresultdoctor', {
      state: {
        searchinput: searchInput,
        title: 'ผลการค้นหาจากคลินิก', 
        item: item,
        clinic_id: item.id,
        backlink: '/searchbyclinic',
        type: 'clinic'
      }
    })
  }

  // Lazy Scrolling 
  const scrolling = () => {

    let scrollH = scrollRef.current.scrollHeight
    let scrollT = scrollRef.current.scrollTop
    let elementH = scrollRef.current.clientHeight
    let scrollD = Math.ceil(scrollT + elementH) + 120

    if(searchResultListData.length >= searchResultList.total) {
      setIsLazyLoading(false)
    } else {
        // Scrolling
        if(scrollD >= scrollH) {
            // Scroll Finish
            // alert('Scroll Finish!!')
            setIsLazyLoading(true)

            if(!callingApi) {
              fetchSearch('lazy',getLatitude, getLongitude, startAddValue ) 
            }
        } else {
            setIsLazyLoading(false)
        }
    }
}

  
  return (
    <div className="search-page  px-5">
        <NavSearch currentPage="searchbyclinic" />

        {/* search  */}
        <form onSubmit={handleSubmit}>
          <div className={`flex flex-row border border-form-bd rounded-lg mb-2`}>
              <div className="flex pl-3 pr-1">
                <img src={IconSearch} alt="icon search"  />
              </div>
              <input 
                type="search"
                placeholder="ค้นหาคลินิก" 
                className={`p-2.5 pr-2 w-full rounded-r-lg outline-0 `}
                name="search"
                onChange={handleSearchInput}
                ref={searchRef}
              />
          </div>
          <div className="flex flex-col gap-x-2">
            <div className="p-3 rounded-md bg-gray-light">
              <p className="text-sm text-gray-3 mb-1">กรองค้นหาจาก จังหวัด / อำเภอ</p>
              <div className="relative z-20">
                {/* <input 
                    type="search"
                    placeholder="จังหวัด" 
                    className={`p-2.5 pr-2 w-full border border-form-bd rounded-lg outline-0 `}
                    name="search"
                    onChange={handleSearchInput}
                    value={provinceSelect}
                  /> */}
                <div onClick={()=> openFilterBox? setOpenFilterBox(false) : setOpenFilterBox(true)} className={`${provinceSelect==='เลือกจังหวัด'?'text-gray-3':''} p-2.5 h-11 flex flew-row border border-form-bd bg-white rounded-lg outline-0 pr-9 break-all overflow-hidden`}>
                  { provinceSelect+(!!districtSelect? ' - '+districtSelect:'')}
                 
                </div>
                {
                    (!provinceSelect || provinceSelect==='เลือกจังหวัด') ?
                        //check filter box open/close
                        openFilterBox ? 
                        <img src={IconArrowDown} onClick={()=> setOpenFilterBox(false)} alt="icon arrowdown" className="w-4 absolute top-4 right-3 rotate-180" />
                        :
                        <img src={IconArrowDown} onClick={()=> setOpenFilterBox(true)} alt="icon arrowdown" className="w-4 absolute top-4 right-3" />
                    :
                      <img src={IconDelete} alt="icon close" className="w-4 absolute top-3 right-3"
                        onClick={()=> { 
                          setProvinceSelect('เลือกจังหวัด'); 
                          setDistrictSelect(''); 
                          setOpenFilterProvince(true)
                          searchInput['province'] = null
                          searchInput['district'] = null
                          fetchSearch('list',getLatitude,getLongitude)
                        }} 
                      />
                  }

                {
                  openFilterBox ? 
                    <div className={`absolute top-12 left-0 border border-form-bd bg-white w-full p-2 overflow-x-auto shadow-md shadow-gray-4 `} style={{maxHeight: window.innerHeight-440+'px'}}>
                      <ul>
                        {
                          openFilterProvince? <li className="py-1" onClick={()=> handleProvinceSelectOption('')}>ทุกจังหวัด</li> : <li className="py-1" onClick={()=> handleDistrictSelectOption('')}>ทุกเขต/อำเภอ</li>
                        }
                        {
                        openFilterProvince? 
                          PVData.map((item,i)=> {
                            return (
                              <li className="py-1" key={i} onClick={()=> handleProvinceSelectOption(item.id)}>{item.name}</li>
                            )
                          })
                        : 
                          districtList.map((item,i)=> {
                            return (
                              <li className="py-1" key={i} onClick={()=> handleDistrictSelectOption(item.name)}>{item.name}</li>
                            )
                          })
                        }
                      </ul>
                    {/* {
                      openFilterProvince ?
                      <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${!!provinceSelect?'':'text-gray-3'}`} onChange={handleProvinceSelectOption}>
                        <option value={''}>ทุกจังหวัด</option>
                        {
                          PVData.map((item,i)=> {
                            return (
                              <option key={i} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      :
                      <div className="flex flex-row gap-x-2"> 
                        <select value={!!districtSelect?districtSelect:''} className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 `} onChange={handleDistrictSelectOption}>
                          <option value={''}>{provinceSelect} - ทุกเขต/ทุกอำเภอ</option>
                          {
                            districtList.map((item,i)=> {
                              return (
                                <option key={i} value={item.name}>{provinceSelect} - {item.name}</option>
                              )
                            })
                          }
                          <option value={'back'}>เลือกจังหวัด</option>
                        </select>
                      </div>
                    } */}
                    </div>
                  : null
                  
                }
             
              </div>
            </div>
           
           
            {/* <div className={`flex flex-row border ${!!searchInput.province? '':'bg-gray-light'} border-form-bd rounded-lg `}>
                <div className="flex pl-3 pr-1">
                  <img src={IconSearch} alt="icon search"  />
                </div>
                {
                  !!searchInput.province? 
                  <input 
                    type="search"
                    placeholder="ค้นหาจากอำเภอ" 
                    className={`p-2.5 pr-2 w-full rounded-r-lg outline-0 `}
                    name="district"
                    onChange={handleSearchInput}
                  />
                  : <input disabled placeholder="ค้นหาจากอำเภอ" className={`p-2.5 pr-6 w-full rounded-r-lg outline-0 placeholder-form-bd disabled:bg-gray-light disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none`} />
                
                }
            </div>
            <div className={`flex flex-row border border-form-bd rounded-lg `}>
                <div className="flex pl-3 pr-1">
                  <img src={IconSearch} alt="icon search"  />
                </div>
                <input 
                  type="search"
                  placeholder="ค้นหาจากจังหวัด" 
                  className={`p-2.5 pr-1 w-full rounded-r-lg outline-0 `}
                  name="province"
                  onChange={handleSearchInput}
                />
            </div> */}
            <input type="submit" hidden />
          </div>
        </form>

        {/* List search */}
        <div className="mt-6 mb-16">
          <h1 className="font-promptsemibold">ค้นหาจากคลินิก</h1>
          <div ref={scrollRef} onScroll={scrolling} style={isLazyLoading ? {height: windowH - 430+'px', overflowY: 'hidden', overflowX: 'hidden'} : {height: windowH - 430+'px', overflowY: 'auto', overflowX: 'hidden'}}>
            {
              searchResultList.total ? 
                searchResultListData?.map((item,i)=> {
                  return (        
                    <ItemClinic key={i} searchinput={searchInput} dataItem={item} onclick={()=> gotoSearchResultDoctor(item)} backlink="/searchbyclinic" />
                  )
                })
              : 
              <React.Fragment>
                <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsg }}></p>
              </React.Fragment>
            }
            {
              isLazyLoading ? <p className="text-center mt-8 text-gray-2 w-full">...Loading...</p> : null
            }
          </div>
         
        </div>

        {/* Nav */}
        <Nav currentPage="search" />

        {
            isLazyLoading ? <div className="absolute z-40 bg-black/25 top-0 left-0 w-full h-screen flex items-center justify-center text-white">...Loading...</div> : null
        }
    </div>
  )
}

export default SearchByClinic