import React, { useEffect, useContext, useState, useRef } from 'react'
import Modal from 'react-modal'
import { useNavigate, useLocation } from 'react-router-dom'
import { TokenContext } from '../../App';
import moment from 'moment'
import { format } from 'date-fns'
import th from "date-fns/locale/th";
import IconCalendar from '../../assets/img/ic-calendar-bold.svg'
import IconFrequency from '../../assets/img/ic-frequency.svg'
import IconClock from '../../assets/img/ic-clock.svg'
import IconBank from '../../assets/img/ic-bank.svg'
import IconCalendarActive from '../../assets/img/ic-calendar-active.svg'
import IconStatus from '../../assets/img/ic-doctor-word-green-active.svg'
import IconDocument from '../../assets/img/ic-document-green.svg'
import IconNext from '../../assets/img/ic-next.svg'
import IconBack from '../../assets/img/ic-back.svg'
import IconPDF from '../../assets/img/ic-pdf.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import { BtnTransparent, BtnPrimary, BtnSecondary } from '../../components/FormItems'

import ItemDoctor from '../../components/ItemDoctor'
import BtnEnterVideoRoom from '../../components/BtnEnterVideoRoom';
import liff from '@line/liff/dist/lib';

let optionSummaryAppointment = ''
function SummaryAppointment() {
    const linkRef = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const { token, dataLatLng, /*dataAppointment,*/ setDataAppointment } = useContext(TokenContext)

    const [modalErr, setModalErr] = useState(false)
    const [errMsg, setErrMsg] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg , setErrSubMsg] = useState('กรุณาลองใหม่อีกครั้ง')

    const [detailAppointment, setDetailAppointment] = useState('')
    const [type] = useState(location?.state?.type)
    const [afterDate,setAfterDate] = useState(true)
    const [startVideoCall, setStartVideoCall] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const convertDate = (value) => {
        // console.log('convertDate: ',value)
        let selectYearValue = Number(format(new Date(value), 'yyyy')) + 543
        let result = format(new Date(value), 'dd MMM', { locale: th}) + ' ' + selectYearValue
        return result
    }

    // const convertDatePDF = (value) => {
    //     // console.log('convertDate: ',value)
    //     // let selectYearValue = Number(format(new Date(value), 'yyyy')) + 543
    //     // let result = format(new Date(value), 'dd-MMM', { locale: th}) + '-' + selectYearValue
    //     let result = moment(value).format('DD_MM_YYYY')
    //     return result
    // }

    const convertTime = (value) => {
        // console.log('convertTime: ',value)
        let result = value.substring(10,16)
        return result
    }

    const checkNow = (value) => {
        let appointment = moment(value).format('YYYY-MM-DD')
        let nowDate = moment().format('YYYY-MM-DD')
        let result = moment(nowDate).isAfter(appointment)
        // console.log(appointment,'??',nowDate,' => ',result)
        setAfterDate(result)
    }

    const checkStartVideoCall = (value) => {
        let appointmentTime = moment(value).add(-5,'minutes').format('YYYY-MM-DD HH:mm:ss')
        let nowDate = moment().format('YYYY-MM-DD HH:mm:ss')
        let result = moment(nowDate).isAfter(appointmentTime)
        // console.log(appointmentTime,', ',nowDate,', ',result)
        setStartVideoCall(result)
    }

    const convertStatus = (value) => {
        let result = ''
        
        switch (value) {
            case 'pending approval':
                result = 'รออนุมัติ'
                break;

            case 'pending_approval':
                result = 'รออนุมัติ'
                break;

            case 'not_started':
                result = 'ยังไม่เริ่ม'
                break;

            case 'waiting':
                result = 'รอเข้าพบ '
                break;

            case 'in_progress':
                result = 'กำลังเข้าพบ'
                break;

            case 'ended':
                result = 'เสร็จสิ้น'
                break;

            case 'not_arrive':
                result = 'ไม่ได้เข้าพบ'
                break;

            case 'waiting_result':
                result = 'รอผลตรวจ'
                break;
            
            case 'canceled':
                result = 'ยกเลิก'
                break;

            default:
                result = '-'
                break;
        }
        return result
    }

    useEffect(() => {
        // check token
        // if(!token) {
        //     navigate('/')
        // }


        if(dataLatLng.latitude && dataLatLng.longitude) {
            optionSummaryAppointment =`?latitude=${dataLatLng.latitude}&longitude=${dataLatLng.longitude}`
        }
      
        (async () => {
            // Fetch detail doctor
            const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${location?.state?.appointment_id}${optionSummaryAppointment}`, {
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
              }
            });
        
            resDetailDoctor
                .json()
                .then((response) => {
                    // console.log('summaryAppointment : ',response)
                    // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                    if(response) {
                      // setFetchMsg('')
                      setDetailAppointment(response)
                      checkNow(response.appointment_date)
                      checkStartVideoCall(response.datetime_from)
                    //   checkStartVideoCall('2022-12-10 23:45:00')
                    } else {
                    //   setFetchMsg('ไม่พบข้อมูล')
                    }
                })
                .catch((err)=> {console.log(err); navigate('/');})
        })();
    
        return () => {
            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const downloadCert = async () => {
       

        if(!downloading) {

            setDownloading(true)
            // console.log('Appointment ID:',location.state.appointment_id)
            let appointmentID = location.state.appointment_id

            const fetchDownload = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/medical_certificate/url`,{
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
                }
            })
            
            if(fetchDownload.status === 200) {
                fetchDownload
                .json().then(response => {
                    // Check LIFF in-app browser
                    const { userAgent } = navigator
                    console.log(userAgent)
                    // eslint-disable-next-line no-undef
                    if(userAgent.includes('Line')) {
                        liff.openWindow({
                            // url: response.url,
                            url: response.url+"?openExternalBrowser=1",
                            external: true
                        })
                    } else {
                        // window.open(response.url, '_blank')
                        const a = document.createElement('a')
                        // a.setAttribute('target', '_blank');
                        a.href = response.url
                        a.download = response.url.split('/').pop()
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                    }
     
                    setDownloading(false)
                })
            } else {
                setModalErr(true)
                setErrMsg('ไม่สามารถดาวน์โหลดใบรับรองแพทย์ได้')
                setErrSubMsg('เนื่องจากหมดอายุ หรือ เกิดข้อผิดพลาด')
            }
            

            // fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/medical_certificate/pdf`,{
            //     method: 'GET',
            //     headers: {
            //     "Content-Type": "application/json",
            //     // "Accept": "application/json",
            //     'Authorization': 'Bearer ' + token
            //     }
            // }).then(response => {
            //     response.blob().then(blob => {
            //         // Creating new object of PDF file
            //         const fileURL = window.URL.createObjectURL(blob);
            //         // Setting various property values
            //         let alink = document.createElement('a');
            //         alink.href = fileURL;
            //         alink.download = `ใบรับรองแพทย์${!!detailAppointment.appointment_date?'_'+convertDatePDF(detailAppointment.appointment_date):''}.pdf`;
            //         alink.click();

            //         setDownloading(false)
            //     })
            // })
        }
        
     }


    const gotoHome = () => {
        // Clear data appointment
        setDataAppointment('')
        navigate('/home')
    }
    
    Modal.setAppElement('#root');


    return (
        <div className="summary-appointment-page p-4">
            {
                type==='fromhistoryappointment'?
                    <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/historyappointment')} }/>
                : type==='fromresulthealthcheck'? 
                    <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/resulthealthcheck')} }/>
                : null
            }
            <div className="flex flex-row mb-5">
                <img src={IconCalendarActive} className="mr-2" alt="icon calendar"  />
                <h1 className="font-promptsemibold">การนัดหมาย</h1>
            </div>

            <ItemDoctor dataItem={detailAppointment} type={type} appointment_id={detailAppointment.id} backlink={'/summaryappointment'} />

            <div className="detail-doctor-page _summary">
                <div className="border border-card-bd-active rounded-lg shadow-lg shadow-card-shadow p-5 mb-7">
                    <div className="flex flex-col">
                        <div className="flex flex-row mt-1 mb-4 border-b border-b-form-bd pb-4">
                            <div className="flex flex-row grow-0 ">
                                <img className="w-5 sm:w-6 mr-2 self-auto" src={IconStatus} alt="icon status" />
                            </div>
                            <div className="grow">
                                <p className="self-center text-gray-2 text-sm sm:text-md">สถานะ: <span className="font-promptregular">{convertStatus(detailAppointment?.display_status)}</span></p>
                            </div>
                        </div>
                        <div className="flex flex-row my-1">
                            <div className="flex flex-row grow-0 ">
                                <img className="w-4 sm:w-5 mr-3 self-auto" src={IconCalendar} alt="icon calendar" />
                            </div>
                            <div className="grow">
                                {
                                    detailAppointment?.type==='fast_track' ? 
                                    <>
                                        {
                                            detailAppointment.display_status==='ended' ? 
                                                <p className="self-center text-gray-2 text-sm sm:text-md">เวลานัดหมาย&nbsp;
                                                    <span className="font-promptsemibold text-orange text-base">
                                                        { !!detailAppointment?.appointment_date? convertDate(detailAppointment?.appointment_date) : '-' } 
                                                        {/* {convertDate('2022-11-17')} */}
                                                    </span>
                                                    &nbsp;|
                                                    <span className="font-promptsemibold text-orange text-base">
                                                        { !!detailAppointment?.datetime_from ?convertTime(detailAppointment?.datetime_from) : '-' } น.
                                                        {/* {convertTime('2022-11-17 12:50:07')} น. */}
                                                    </span>
                                                    <span> (ปรึกษาทันที)</span>
                                                </p>
                                                : 
                                                'ปรึกษาทันที'
                                        }
                                    </>
                                    :
                                    <p className="self-center text-gray-2 text-sm sm:text-md">เวลานัดหมาย&nbsp;
                                        <span className="font-promptsemibold text-orange text-base">
                                            { !!detailAppointment?.appointment_date? convertDate(detailAppointment?.appointment_date) : '-' } 
                                            {/* {convertDate('2022-11-17')} */}
                                        </span>
                                        &nbsp;|&nbsp;
                                        <span className="font-promptsemibold text-orange text-base">
                                            { !!detailAppointment?.datetime_from ?convertTime(detailAppointment?.datetime_from) : '-' } น.
                                            {/* {convertTime('2022-11-17 12:50:07')} น. */}
                                        </span>
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="flex flex-row my-1">
                            <div className="flex flex-row grow-0 ">
                                <img className="w-4 sm:w-5 mr-3 self-auto" src={IconClock} alt="icon clock" />
                            </div>
                            <div className="grow">
                                <p className="self-center text-gray-2 text-sm sm:text-md">ระยะเวลารับคำปรึกษา <span className="font-promptsemibold text-orange text-base">{detailAppointment?.doctor_min_consult_time} นาที</span></p>
                            </div>
                        </div>
                        <div className="flex flex-row my-1">
                            <div className="flex flex-row grow-0 ">
                                <img className="w-5 sm:w-6 mr-2 self-auto" src={IconFrequency} alt="icon frequency" />
                            </div>
                            <div className="grow">
                                <p className="self-center text-gray-2 text-sm sm:text-md">ภาษาที่ใช้บริการ <span className="font-promptsemibold text-orange text-base">{detailAppointment?.language}</span></p>
                            </div>
                        </div>
                        <div className="flex flex-row my-1">
                            <div className="flex flex-row grow-0 ">
                                <img className="w-5 sm:w-6 mr-2 self-auto" src={IconBank} alt="icon bank" />
                            </div>
                            <div className="grow">
                                <p className="self-center text-gray-2 text-sm sm:text-md">ค่าบริการให้คำปรึกษา <span className="font-promptsemibold text-orange text-base">{Number(detailAppointment?.patient_amount).toLocaleString('en-US')} {detailAppointment?.patient_currency}</span></p>
                            </div>
                        </div>
                        {
                            detailAppointment?.medical_certificate && detailAppointment.display_status!=='ended' ?
                                <div className="flex flex-row mt-4 border-t border-t-form-bd pt-4">
                                    <div className="flex flex-row grow-0 ">
                                        <img className="w-3 sm:w-4 mr-4 self-auto" src={IconDocument} alt="icon document" />
                                    </div>
                                    <div className="grow">
                                        <p className="self-center text-gray-2 text-sm sm:text-md">ต้องการใบรับรองแพทย์</p>
                                    </div>
                                </div>
                            : null
                        }
                        <div className={`flex text-center border-t border-t-form-bd pt-4 mt-4 ${afterDate?'justify-between':'justify-center'}`}
                            onClick={()=> 
                                navigate((detailAppointment.display_status==='waiting' || detailAppointment.display_status==='not_started')?'/historymedical':'/historymedicalview', { state: {
                                        id: detailAppointment.id,
                                        symptom: detailAppointment.symptom,
                                        symptom_time: detailAppointment.symptom_time,
                                        has_allergy: detailAppointment.has_allergy,
                                        allergy_detail: detailAppointment.allergy_detail,
                                        chronic_condition: detailAppointment.chronic_condition,
                                        height: detailAppointment.height,
                                        weight: detailAppointment.weight,
                                        type: type
                                    }
                                })
                            }
                        >
                            <span className=" text-gray-2 text-sm sm:text-md">ข้อมูลประกอบการนัดหมาย</span>
                            <img className="w-2 sm:w-3 ml-2 self-auto" src={IconNext} alt="icon next" />
                        </div>
                        {
                            // afterDate ?
                            detailAppointment.display_status==='ended' ?
                            <div>
                                <div className="flex text-center border-t border-t-form-bd justify-between pt-4 mt-4" 
                                    onClick={()=> 
                                        navigate('/summaryhealthcheck', { state: { appointment_id: location.state.appointment_id, type: type, display_status: detailAppointment.display_status }})
                                    }
                                >
                                    <div className="flex">
                                        <div className="flex flex-row grow-0 ">
                                            <img className="w-3 sm:w-4 mr-4 self-auto" src={IconDocument} alt="icon document" />
                                        </div>
                                        <div className="grow">
                                            <p className="self-center text-gray-2 text-sm sm:text-md">สรุปผลการตรวจ</p>
                                        </div>
                                    </div>
                                    <img className="w-2 sm:w-3 ml-2 self-auto" src={IconNext} alt="icon next" />
                                </div>

                                {
                                    detailAppointment.medical_certificate && detailAppointment.display_status==='ended' ?
                                        !downloading ?
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a className="flex flex-row my-4" onClick={()=>downloadCert()} ref={linkRef}>
                                            <div className="flex flex-row grow-0 ">
                                                <img className="w-3 sm:w-4 mr-4 self-auto" src={IconPDF} alt="icon bank" />
                                            </div>
                                            <div className="grow">
                                                <p className="self-center text-gray-2 text-sm sm:text-md">ดาวน์โหลดใบรับรองแพทย์</p>
                                            </div>
                                        </a>
                                        :
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a className="flex flex-row my-4 opacity-50">
                                            <div className="flex flex-row grow-0 ">
                                                <img className="w-3 sm:w-4 mr-4 self-auto" src={IconPDF} alt="icon bank" />
                                            </div>
                                            <div className="grow">
                                                <p className="self-center text-gray-2 text-sm sm:text-md">ดาวน์โหลดใบรับรองแพทย์</p>
                                            </div>
                                        </a>
                                    : null
                                }



                                {/* <div className="flex flex-row my-4">
                                    <div className="flex flex-row grow-0 ">
                                        <img className="w-3 sm:w-4 mr-4 self-auto" src={IconPDF} alt="icon bank" />
                                    </div>
                                    <div className="grow">
                                        <p className="self-center text-gray-2 text-sm sm:text-md">ดาวน์โหลดใบสั่งยา</p>
                                    </div>
                                </div> */}
                            </div>

                            : null
                        }

                    </div>
                </div>
            </div>

            {
                type==='fromhistoryappointment' ?
                null
                : 
                <div className="text-center w-full">
                    <h1 className="font-promptsemibold underline my-5" onClick={()=> gotoHome() }>กลับหน้าหลัก</h1>
                </div>

            }

            {
                !afterDate && type!=='fromresulthealthcheck' ?
                    startVideoCall ?
                    <div className="mt-4">
                        {/* <BtnPrimary type="button" text="เริ่มการสนทนา" />  */}
                        { detailAppointment.display_status === 'need_payment' ?
                            <BtnPrimary text="ชำระเงิน" action={(e)=> {e.stopPropagation(); navigate('/payment', {state: { doctor_id: detailAppointment.doctor_id, id: detailAppointment.id, amount: detailAppointment.patient_amount, currency: detailAppointment.patient_currency}})}} addclass="w-full px-3 py-1.5 text-sm sm:text-md font-promptregular" /> 
                            :
                            <BtnEnterVideoRoom appointmentID={detailAppointment.id || location?.state?.appointment_id} icon={true}  doctorFirstName={detailAppointment.doctor_first_name} doctorLastName={detailAppointment.doctor_last_name} doctorTitle={detailAppointment.doctor_title} doctorImg={detailAppointment.doctor_profile_picture_url} />

                        }
                     </div>
                    :
                    <div className="mt-4 pointer-events-none">
                        <BtnSecondary type="button" text="เริ่มการสนทนา" /> 
                    </div>
                : null
            }

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} /> 
            </Modal>
        </div>
    )
}

export default SummaryAppointment