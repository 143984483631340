import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BtnPrimary, BtnSecondary,
} from '../../components/FormItems'

import { TokenContext } from '../../App'

function TermCondition() {
    const navigate = useNavigate()
    const { token, registered, setConsentTerm } = useContext(TokenContext)
    const [hasErr, setHasErr] = useState(false)
    const [agreeTermCondition, setAgreeTermCondition] = useState(0)
    const [dataTermCondition, setDataTermCondition] = useState({
        // content: "Privacy consent",
        // timestamp: "2022-10-05 21:57:47",
        // version_id: 1,
        // version_name: "1",
    })

    useEffect(() => {
        // check token
        if(!token) {
            navigate('/')
        }

        // console.log('Term check=> registered: ',registered)
      
        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/consent/term/latest`, {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  // "Accept": "application/json",
                  'Authorization': 'Bearer ' + token
                }
              });
        
             res
                .json()
                .then((response) => {
                    console.log('term : ',response)
                    setConsentTerm(1)
                    setDataTermCondition(response)
                })
                .catch((err)=> console.log(err))
        })();
    
        return () => {
            
        }
    }, [navigate, registered, setConsentTerm, token])
    

    const handleConsentTermCondition = (e) => {
        setHasErr(false)
        setAgreeTermCondition(e.target.checked ? 1 : 0)
    }

    const onSubmit = async (e) => { 
        e.preventDefault(); 
        console.log('consent: ',agreeTermCondition, 'version_id: ', dataTermCondition.version_id); 

        // call fill otp
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/consent/term`, {
            method: 'POST',
            body: JSON.stringify({
                "version_id": dataTermCondition.version_id, 
                "consent": agreeTermCondition
            }),
            headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'Authorization': 'Bearer ' + token
            }
        });
    
        // check status code
        if(res.status === 200) {
            console.log(res)
            res
            .text()
            .then(response => {
                console.log('consent_privacy: ',response)
                if(registered) {
                    navigate('/home')
                } else {
                    navigate('/signup') 
                }
            })
    
        } else {
            res
            .text()
            .then(response => {
                setHasErr(true)
                console.error('consent_privacy: ',response)
            })
    
        }
    }

    return (
        <div className="policy-page px-5 text-center">
            <form onSubmit={onSubmit}>
                <h1 className="font-semibold pt-8">ข้อกำหนดและเงื่อนไขการให้บริการ</h1>
                <div className="overflow-auto mt-4" style={{height: window.innerHeight - 200+'px'}}>
                    <p className="text-left text-gray-2 text-xs" dangerouslySetInnerHTML={{__html: dataTermCondition.content }}></p>
                </div>
                <div id="footer" className="fixed bottom-0 left-0 p-5 w-full text-left">
                    <div>
                        <input type="checkbox" id="agreeTermCondition" checked={agreeTermCondition} onChange={handleConsentTermCondition} className="mr-2" />
                        <label htmlFor="agreeTermCondition" className="text-xs">ฉันยอมรับ <span className="text-darkblue">ข้อกำหนดและเงื่อนไขการให้บริการ</span></label>
                    </div>

                    <div style={{height: '20px'}}>
                    {
                        hasErr ? <p className="text-xs text-danger text-left pl-5">กรุณากดยอมรับเงื่อนไข</p> : null
                    }
                    </div>

                    {
                        agreeTermCondition ? <BtnPrimary type="submit" text="ยืนยัน" addclass={`mt-2`} />
                        : <BtnSecondary type="button" text="ยืนยัน" addclass={`mt-2`} action={()=> setHasErr(true)} />
                    }
                </div>
            </form>
        </div>
    )
}

export default TermCondition