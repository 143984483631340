import React, { useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import logoThaiDoctor from '../../assets/img/logo-thaidoctor.svg'
import IconEmail from '../../assets/img/ic-email-active.svg'
import IconEmailRequired from '../../assets/img/ic-email-required.svg'
import IconNotice from '../../assets/img/ic-notice.svg'
import {
  BtnPrimary,
} from '../../components/FormItems'

import { TokenContext } from '../../App'
import SearchAutoComplete from '../../components/SearchAutoComplete'
import PVData from '../../DataProvinces.json'
import APData from '../../DataDistricts.json'
import TBData from '../../DataSubDistricts.json'

// import { LiffLogout } from '../../components/LiffLogout'

function Signup() {
  const navigate = useNavigate()
  const { token, setFirstRegister } = useContext(TokenContext)
  const { register, handleSubmit, /*watch,*/ formState: { errors }} = useForm({
    defaultValues: { 
      gender: 'unspecified',
      birth_date: null,
      first_name: null,
      last_name: null,
      house_no: null, 
      soi: null, 
      road: null, 
      province: null, 
      district: null,
      subdistrict: null,
      email: null,
      post: null
    }
  });
  const [hasErr, setHasErr] = useState(false)
  // const [hasErrCode, setHasErrCode] = useState(null)
  // const [hasErrMsg, setHasErrMsg] = useState(null)
  const [provinceSelect, setProvinceSelect] = useState(null)
  // const [provinceOnSearch, setProvinceOnSearch] = useState(null)
  const [districtSelect, setDistrictSelect] = useState(null)
  // const [districtOnSearch, setDistrictOnSearch] = useState(null)
  const [subdistrictSelect, setSubdistrictSelect] = useState(null)
  const [subdistrictOnSearch, setSubdistrictOnSearch] = useState(null)
  // const [birthDate, setBirthDate] = useState({
  //   dd: '',
  //   mm: '',
  //   yyyy: ''
  // })

  const [districtList, setDistrictList] = useState([])

  const listDay = [ 'วว',
    '01','02','03','04','05','06','07','08','09','10',
    '11','12','13','14','15','16','17','18','19','20',
    '21','22','23','24','25','26','27','28','29','30','31'
  ]

  const listMonth = [ 'ดด',
    'ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'
  ]

  const listYears = (amount) => {
    var max = new Date().getFullYear()
    var min = max - amount
    var years = ['ปปปป']

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  useEffect(() => {
    // check token
    if(!token) {
      navigate('/')
    }

    return () => {
      
    }
  }, [navigate, token])
  

  // const handleBirthDate = (e) => {
  //   setBirthDate({...birthDate, [e.target.name]: e.target.value})
  // }


  const onSubmit = async (data) => {
    if(data.birth_date_mm < 10 && data.birth_date_mm >= 1) {
      data.birth_date_mm = '0'+data.birth_date_mm
    }

    if(!data.birth_date_yyyy || !data.birth_date_mm || !data.birth_date_dd) {
      // console.log('invalid date')
      data.birth_date = null
    } else {
      // console.log('correct date')
      data.birth_date = data.birth_date_yyyy+'-'+ data.birth_date_mm +'-'+ data.birth_date_dd
    }

    // data.province = (provinceSelect) ? provinceSelect : provinceOnSearch
    // data.district = (districtSelect) ? districtSelect : districtOnSearch
    data.subdistrict = (subdistrictSelect) ? subdistrictSelect : subdistrictOnSearch
    data.district = (districtSelect === '') ? null : districtSelect
    data.province = (provinceSelect === '') ? null : provinceSelect

    data.email = (data.email==='') ? null : data.email

    delete data.birth_date_dd
    delete data.birth_date_mm
    delete data.birth_date_yyyy
    // console.log('fill otp: ',data);
    // console.log('select => PV_NAME: ',provinceSelect)
    // console.log('onSearch => PV_NAME: ',provinceOnSearch)

    // call fill otp
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/register/fill_info`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': 'Bearer ' + token
      }
    });

    // check status code
    if(res.status === 200) {
        console.log(res)
        res
        .text()
        .then(response => {
            console.log('fill_info: ',response)
            setFirstRegister(true)
            navigate('/privacy') 
        }).catch((err)=> console.err(err))

    } else {
        res
        .text()
        .then(response => {
            setHasErr(true)
            console.error('fill_err: ',response)
            // setHasErrMsg(response)

            setTimeout(() => {
              window.location.reload();
            }, 5000);
            console.error(res.status,' ',response)
        })
        // setHasErrCode(res.status)
    }

  };

  // console.log(watch("birth_date_dd"));

  const handleSubdistrictSelect = (item) => {
    setSubdistrictOnSearch(null)
    setSubdistrictSelect(item.name)
  }

  const handleSubdistrictOnSearch = (string, results) => {
    setSubdistrictSelect(null)
    setSubdistrictOnSearch(string, results)
  }

  // const handleDistrictSelect = (item) => {
  //   setDistrictOnSearch(null)
  //   setDistrictSelect(item.name)
  // }

  // const handleDistrictOnSearch = (string, results) => {
  //   setDistrictSelect(null)
  //   setDistrictOnSearch(string, results)
  // }

  // const handleProvinceSelect = (item) => {
  //   setProvinceOnSearch(null)
  //   setProvinceSelect(item.name)
  // }

  // const handleProvinceOnSearch = (string, results) => {
  //   setProvinceSelect(null)
  //   setProvinceOnSearch(string, results)
  // }

  const handleDistrictSelectOption = (e) => {
    let AP_NAME = e.target.value
    if(AP_NAME) {
      setDistrictSelect(AP_NAME)
    } else {
      setDistrictSelect(null)
    }
  }

  const handleProvinceSelectOption = (e) => {
    setDistrictSelect(null)
    // console.log(e.target.value)
    let PV_ID = e.target.value
    if(PV_ID) {
      for(let i=0; i<PVData.length; i++) {
        if(Number(PV_ID) === PVData[i].id) {
          setProvinceSelect(PVData[i].name)
        } 
      }
    } else {
      setProvinceSelect(null)
    }
    
    // get district list
    let APlist = []
    for(let i=0; i<APData.length; i++) {
      if(Number(PV_ID) === APData[i].ProvinceID) {
        APlist.push({'name': APData[i].name, 'id': APData[i].id})
      }
    }
    setDistrictList(APlist)
  }
  return (
    <div className="signup-page px-5">
        {
          hasErr ? 
            <div className="p-4 bg-danger-bg flex flex-row border border-danger rounded-lg my-4">
              <img src={IconNotice} alt="icon notice" />
              {/* <span className="text-gray-1 text-sm ml-3">Something went wrong. Please reload the page and try again.</span> */}
              <span className="text-gray-1 text-sm ml-3">
                Something went wrong. Please wait the page is reloading.
                {/* <i className="text-xs text-gray-4">({hasErrCode}) {hasErrMsg}</i> */}
              </span>
            </div>
          : <img src={logoThaiDoctor} alt="logo thaidoctor" className="mx-auto my-4" />
        }
        
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Input First Name */}
          <div className="flex flex-col text-left mb-3">
            <label className="font-promptsemibold mb-2">ชื่อจริง</label>
            <input 
              placeholder="ชื่อจริง" 
              className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
              {...register("first_name", { required: true })} 
            />
            {
              errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
            }
          </div>

          {/* Input Last Name */}
          <div className="flex flex-col text-left mb-3">
            <label className="font-promptsemibold mb-2">นามสกุล</label>
            <input 
              placeholder="นามสกุล" 
              className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.last_name?.type === "required" && 'border-danger'}`}
              {...register("last_name", { required: true })} 
            />
            {
              errors?.last_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
            }
          </div>

          {/* Choose Gender */}
          <p className="font-promptsemibold mb-2">เพศ</p>
          <div className="flex flex-row text-left mb-3">
            <label className="flex container-radio-btn" htmlFor="gender-male">
                ชาย
                <input
                    {...register("gender")}
                    type="radio"
                    value="male"
                    id="gender-male"
                />
                <span className="checkmark"></span>
            </label>
            <label className="flex container-radio-btn" htmlFor="gender-female">
                หญิง
                <input
                    {...register("gender")}
                    type="radio"
                    value="female"
                    id="gender-female"
                />
                <span className="checkmark"></span>
            </label>
            <label className="flex container-radio-btn" htmlFor="gender-other">
                ไม่ระบุ
                <input
                    {...register("gender")}
                    type="radio"
                    value="unspecified"
                    id="gender-other"
                />
                <span className="checkmark"></span>
            </label>
          </div>

          {/* Input Birth Date */}
          <p className="font-promptsemibold mb-2">วัน / เดือน / ปีเกิด (พ.ศ.)</p>
          <div className="text-left mb-3">
            <div className="flex flex-row ">
              <div className="select-section grow">
                {/* <select className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0`} value={birthDate.dd} name="dd" onChange={handleBirthDate}> */}
                <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.birth_date_dd?.type === "required" && 'border-danger')}`} {...register("birth_date_dd" /*, { required: true } */)}  >
                  {
                    listDay.map((item,i)=> {
                      return (
                        <option key={i} value={(item==='วว')?'':item}>{item}</option>
                      )
                    })
                  }
                </select>
              </div>

              <div className="select-section grow mx-1.5">
                {/* <select className={`p-2.5 pr-6 mx-1.5 mb-1 w-full border border-form-bd rounded-lg outline-0`} value={birthDate.mm} name="mm" onChange={handleBirthDate}> */}
                <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.birth_date_mm?.type === "required" && 'border-danger')}`} {...register("birth_date_mm" /*, { required: true } */)}>
                  {
                    listMonth.map((item,i)=> {
                      return (
                        <option key={i} value={(item==='ดด')?'':i}>{item}</option>
                      )
                    })
                  }
                </select>
              </div>

              <div className="select-section grow">
                {/* <select className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0`}  value={birthDate.yyyy} name="yyyy" onChange={handleBirthDate}> */}
                <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.birth_date_yyyy?.type === "required" && 'border-danger')}`} {...register("birth_date_yyyy" /*, { required: true } */)}>
                  {
                    listYears(100).map((item,i)=> {
                      return (
                        <option key={i} value={(item==='ปปปป')?'': item}>{(item==='ปปปป')? item : item + 543}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            {
              errors?.birth_date_dd?.type === "required" && <p className="text-xs text-danger">กรุณาเลือกวันเกิด</p>
            }
            {
              errors?.birth_date_mm?.type === "required" && <p className="text-xs text-danger">กรุณาเลือกเดือนเกิด</p>
            }
            {
              errors?.birth_date_yyyy?.type === "required" && <p className="text-xs text-danger">กรุณากรอกปีเกิด (พ.ศ.)</p>
            }
          </div>

          {/* Input Address */}
          <div className="flex flex-col text-left mb-3">
            <label className="font-promptsemibold mb-2">ที่อยู่</label>
            <input 
              placeholder="บ้านเลขที่ / อาคาร / หมู่บ้าน" 
              className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
              {...register("house_no")} 
            />
            <div className="flex flex-row mt-1">
              <input 
                placeholder="ซอย" 
                className={`p-2.5 pr-6 mb-1 mr-1.5 w-1/2 border border-form-bd rounded-lg outline-0 `}
                {...register("soi")} 
              />
              <input 
                placeholder="ถนน" 
                className={`p-2.5 pr-6 mb-1 w-1/2 border border-form-bd rounded-lg outline-0 `}
                {...register("road")} 
              />
            </div>
            <div className="flex flex-row mt-1">
              <div className="mb-1 w-1/2 mr-1.5">
                <SearchAutoComplete zindex="5" dataList={TBData} placeholder="แขวง/ตำบล" actionSelect={handleSubdistrictSelect} actionOnSearch={handleSubdistrictOnSearch} />
              </div>
              <div className="mb-1 w-1/2 select-section">
                {/* <SearchAutoComplete zindex="4" dataList={APData} placeholder="เขต/อำเภอ" actionSelect={handleDistrictSelect} actionOnSearch={handleDistrictOnSearch} /> */}
                
                {
                  !!provinceSelect ?
                  <select value={!!districtSelect?districtSelect:''} className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 `} onChange={handleDistrictSelectOption}>
                    <option value={''}>เขต/อำเภอ</option>
                    {
                      districtList.map((item,i)=> {
                        return (
                          <option key={i} value={item.name}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                  :
                  <select style={{pointerEvents: 'none'}} className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 disabled:bg-gray-light disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-gray-3`} >
                    <option value={''}>เขต/อำเภอ</option>
                  </select>
                }
              </div>
              {/* <input 
                placeholder="ตำบล" 
                className={`p-2.5 pr-6 mb-1 mr-1.5 w-1/2 border border-form-bd rounded-lg outline-0 `}
                {...register("subdistrict")} 
              />
              <input 
                placeholder="อำเภอ" 
                className={`p-2.5 pr-6 mb-1 w-1/2 border border-form-bd rounded-lg outline-0 `}
                {...register("district")} 
              /> */}
            </div>
            <div className="flex flex-row mt-1">
              <div className="mb-1 w-1/2 mr-1.5 select-section">
                {/* <SearchAutoComplete zindex="3" dataList={PVData} placeholder="จังหวัด" actionSelect={handleProvinceSelect} actionOnSearch={handleProvinceOnSearch} /> */}
                <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${!!provinceSelect?'':'text-gray-3'}`} onChange={handleProvinceSelectOption}>
                  <option value={''}>จังหวัด</option>
                  {
                    PVData.map((item,i)=> {
                      return (
                        <option key={i} value={item.id}>{item.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              {/* <input 
                placeholder="จังหวัด" 
                className={`p-2.5 pr-6 mb-1 mr-1.5 w-1/2 border border-form-bd rounded-lg outline-0 `}
                {...register("province")} 
              /> */}
              <input 
                placeholder="รหัสไปรษณีย์" 
                className={`p-2.5 pr-6 mb-1 w-1/2 border border-form-bd rounded-lg outline-0 `}
                {...register("post")} 
              />
            </div>
          </div>

          {/* Input Email */}
          <div className="flex flex-col text-left mb-5">
            <label className="font-promptsemibold mb-2">อีเมล</label>
            <div className={`flex flex-row mb-1 border border-form-bd rounded-lg ${(errors?.email?.type === "required" && 'border-danger') || (errors?.email?.type === "pattern" && 'border-danger') }`}>
              <div className="flex pl-3 pr-1">
                <img src={IconEmail} alt="icon email" className={`ic-email ${(errors?.email?.type === "required" && 'true') || (errors?.email?.type === "pattern" && 'true') }`} />
                <img src={IconEmailRequired} alt="icon email" className={`ic-email-required ${(errors?.email?.type === "required" && 'true') || (errors?.email?.type === "pattern" && 'true') }`}  />
              </div>
              <input 
                placeholder="ระบุอีเมล" 
                className={`p-2.5 pr-6 w-full rounded-r-lg outline-0 `}
                {...register("email", { pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address"
                }, /*required: true */ })} 
              />
            </div>
            {
              errors?.email?.type === "pattern" && <p className="text-xs text-danger">กรุณากรอกอีเมลให้ถูกต้อง</p>
            }
            {
              errors?.email?.type === "required" && <p className="text-xs text-danger">กรุณากรอกอีเมล</p>
            }
          </div>

          <BtnPrimary type="submit" text="ยืนยัน" addclass="mb-10" />
        </form>

        {/* <LiffLogout /> */}
    </div>
  )
}

export default Signup