
import React, { useState, useRef, useEffect, useMemo, useContext } from 'react'
import AgoraRTM from 'agora-rtm-sdk'
import axios from 'axios'
import moment from 'moment'
import liff from '@line/liff/dist/lib';
import { TokenContext } from '../../App'
import {
    BtnPrimary,
  } from '../../components/FormItems'

import { MdOutlineAttachFile, MdSend } from "react-icons/md";
import IconFailed from '../../assets/img/ic-failed.svg'
import IconClose from '../../assets/img/ic-close.svg'
// import IconJpg from '../../assets/img/ic-type-jpg.svg'
// import IconGif from '../../assets/img/ic-type-gif.svg'
// import IconPng from '../../assets/img/ic-type-png.svg'
import IconPdf from '../../assets/img/ic-type-pdf.svg'
import IconImg from '../../assets/img/ic-img.svg'
import IconFileDownload from '../../assets/img/ic-file-download-preview.svg'

import LoadingNoLogo from '../../components/LoadingNoLogo';

console.log('!!! On Chat Page !!!')
// Check Browser Type
let userAgent = navigator.userAgent;
let browserName;

if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent) && !/Google Inc/.test(navigator.vendor)) {
    browserName = "Safari"
    console.log('!!! Safari !!!')
}

let client, channel

function VideoChat(props) {
    const { openChat, appointmentID, actionClose, chatAppId, chatToken, chatChannel, chatUId, doctorTitle, doctorFirstName, doctorLastName } = props
    const chatsInputRef = useRef(null);
    const chatsLogRef = useRef(null)
    const { token } = useContext(TokenContext)

    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg , setErrSubMsg] = useState('กรุณาลองใหม่อีกครั้ง')

    const [imgPreview, setImgPreview] = useState('')
    const [imgPreviewFileName, setImgPreviewFileName] = useState('')
    const [imgPreviewOrigFileName, setImgPreviewOrigFileName] = useState('')
    const [imgPreviewMimeType, setImgPreviewMimeType] = useState('')
    const [showFileName, setShowFileName] = useState('')
    const [previewType, setPreviewType] = useState('img')
    const [pdfPreview/*, setPdfPreview*/] = useState('')
    // const [imgMimetype, setImgMimetype] = useState('')

    let currentOption = JSON.parse(localStorage.getItem('currentOption')) || null

    // Params for login
    let options = useMemo(() => { return {
        uid: chatUId || (!!currentOption ? currentOption.chatUId : null),
        token: chatToken || (!!currentOption ? currentOption.chatToken : null),
        appID: chatAppId || (!!currentOption ? currentOption.chatAppId : null),
        channel: chatChannel || (!!currentOption ? currentOption.chatChannel : null)
    }}, [chatAppId, chatChannel, chatToken, chatUId, currentOption])

    // Set Modal
    const modalEvent = (action, id) => {
        if(action === 'open') {
            document.getElementById('modal-chat-overlay').style.display = 'block'
            document.getElementById(id).style.display = 'block'

        } else {
            document.getElementById('modal-chat-overlay').style.display = 'none'
            document.getElementById(id).style.display = 'none'
        }
    } 

    const modalEventLoading = (action) => {
        if(action === 'open') {
            document.getElementById('modal-loading-overlay').style.display = 'block'

        } else {
            document.getElementById('modal-loading-overlay').style.display = 'none'
        }
    } 

    // Set Chat componet [from chat box]
    const chatComponentFromChat = (msg, from) => {
        let channelMessage = msg
        let sender = from 
        let chatLog = chatsLogRef.current
        if(chatLog) {
            chatLog.insertAdjacentHTML('beforeend',`
            <div style="text-align: ${from==='You'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
                <p>${sender}: <span style='color: #9e9e9e;'> ${moment().format('HH:mm A')} </span></p>
                <p style="background-color: ${from==='You'?'#dee2e6':'#008074'}; color: ${from==='You'?'#495057':'#ffffff'}; padding: .3rem .75rem; border-radius: 50px; width: fit-content; float: ${from==='You'?'right':'left'};">${channelMessage}</p>
            </div>
            `)
            chatsLogRef.current.scrollTop = chatsLogRef.current.scrollHeight;
        }
    }

    // Set Chat componet [from Log]
    const chatComponentFromLog = async (data, ind) => {
        let channelMessage = data.message
        let sender = data.sender ==='doctor'? doctorTitle+' '+doctorFirstName+' '+doctorLastName : 'You'
        let time = data.timestamp
        let chatLog = chatsLogRef.current
        
        if(data.type === 'text') {
            chatLog.insertAdjacentHTML('beforeend',`
            <div key="${ind}" style="text-align: ${sender==='You'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
                <p>${sender}: <span style='color: #9e9e9e;'> ${moment(time).format('HH:mm A')} </span></p>
                <p style="background-color: ${sender==='You'?'#dee2e6':'#008074'}; color: ${sender==='You'?'#495057':'#ffffff'}; padding: .3rem .75rem; border-radius: 50px; width: fit-content; float: ${sender==='You'?'right':'left'};">${channelMessage}</p>
            </div>
            `)
            chatsLogRef.current.scrollTop = chatsLogRef.current.scrollHeight;
        }
        
    }

    // Set File componet [from chat box]
    const fileComponentFromChat = async (data, from) => {
        let fileName = data.filename
        let origFileName = data.orig_filename
        let sender = from 
        let chatLog = chatsLogRef.current
        let mimetype = data.mime_type
        let typeName = mimetype.substr(mimetype.length - 3);

        let ind = moment().valueOf()

        // Fetch file upload
        await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file?filename=${fileName}`, {
            headers: {
                // 'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
            },
            responseType: 'arraybuffer',
        })

        .then(function (response) {
            function _arrayBufferToBase64( buffer ) {
                var binary = '';
                var bytes = new Uint8Array( buffer );
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                   binary += String.fromCharCode( bytes[ i ] );
                }
                return window.btoa( binary );
            }

            if(chatLog) {
                if(typeName==='pdf') {
                    chatLog.insertAdjacentHTML('beforeend',` 
                    <div style="text-align: ${from==='You'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
                        <p>${sender==='You'?'You':doctorTitle+' '+doctorFirstName+' '+doctorLastName}: <span style='color: #9e9e9e;'> ${moment().format('HH:mm A')} </span></p>
                        <div id="fileMessage-${ind}" style="display: flex; border-width: 1px; border-color: ${from==='You'?'#dee2e6':'#008074'}; color: ${from==='You'?'#495057':'#008074'}; padding: .3rem .75rem; border-radius: 50px; width: fit-content; float: ${from==='You'?'right':'left'};">
                            <img id="img-fileMessage-${ind}" src="${typeName==='pdf'?IconPdf:IconImg}" alt="icon file" style="width: 1rem; height: 1rem; background-color: #3b82f6; padding: .15rem; border-radius: 2px; margin-right: 4px;" />    
                            <p>${origFileName}</p>
                        </div>
                        <p style="width: 100%; font-size: .8rem; margin: 2px 0 0; float: ${from==='You'?'right':'left'}; color: #9e9e9e;">
                            <span id="btn-download-`+ind+`">บันทึก</span>
                        </p>
                    </div>
                    `)

                    // let btnPreview = document.getElementById('btn-preview-'+ind)
                    // btnPreview.addEventListener('click', () => { getPreviewFile(ind, fileName, origFileName, 'pdf', mimetype) })
    
                    let btnDownload = document.getElementById('btn-download-'+ind)
                    btnDownload.addEventListener('click', () => { fetchDownloadFile(fileName, origFileName, mimetype) })
                } else {

                    chatLog.insertAdjacentHTML('beforeend',` 
                    <div style="text-align: ${from==='You'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
                        <p>${sender==='You'?'You':doctorTitle+' '+doctorFirstName+' '+doctorLastName}: <span style='color: #9e9e9e;'> ${moment().format('HH:mm A')} </span></p>
                        <div id="fileMessage-${ind}" style="display: flex; justify-content: ${from==='You'?'end':'start'};">
                            <img id="img-fileMessage-${ind}" src="${`data:${mimetype};base64,${_arrayBufferToBase64(response.data)}`}" alt="file image" style="width: 25%; ${from==='You'?'margin-left: auto;':''}" />
                        </div>
                        <p style="font-size: 0.8rem; margin: 4px 0; color: #000000;">${origFileName}</p>
                        <p style="width: 100%; font-size: .8rem; margin: 2px 0 0; float: ${from==='You'?'right':'left'}; color: #9e9e9e;">
                            <span id="btn-download-`+ind+`">บันทึก</span>
                            <span>&nbsp;|&nbsp;</span>
                            <span id="btn-preview-`+ind+`">ดูตัวอย่าง</span>
                        </p>
                    </div>
                    `)

                    let btnPreview = document.getElementById('btn-preview-'+ind)
                    btnPreview.addEventListener('click', () => { getPreviewFile(ind, fileName, origFileName, 'img', mimetype) })

                    let btnDownload = document.getElementById('btn-download-'+ind)
                    btnDownload.addEventListener('click', () => { fetchDownloadFile(fileName, origFileName, mimetype) })
                }
            }

            chatsLogRef.current.scrollTop = chatsLogRef.current.scrollHeight;
        })


    }

    // Set File componet [from log]
    const fileComponentFromLog = async (data, ind) => {
        let origFileName = data.orig_filename
        let fileName = data.message
        let sender = data.sender 
        let time = data.timestamp
        let chatLog = chatsLogRef.current
        let mimetype = data.mime_type
        let typeName = mimetype.substr(mimetype.length - 3);

        // Append element chat
        if(chatLog) {
            if(typeName==='pdf') {
                chatLog.insertAdjacentHTML('beforeend',` 
                <div key="${ind}" style="text-align: ${sender==='patient'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
                    <p>${sender==='patient'?'You':doctorTitle+' '+doctorFirstName+' '+doctorLastName}: <span style='color: #9e9e9e;'> ${moment(time).format('HH:mm A')} </span></p>
                    <div id="fileMessage-${ind}" style="display: flex; border-width: 1px; border-color: ${sender==='patient'?'#dee2e6':'#008074'}; color: ${sender==='patient'?'#495057':'#008074'}; padding: .3rem .75rem; border-radius: 50px; width: fit-content; float: ${sender==='patient'?'right':'left'};">
                        <img id="img-fileMessage-${ind}" src="${typeName==='pdf'?IconPdf:IconImg}" alt="icon file" style="width: 1rem; height: 1rem; background-color: #3b82f6; padding: .15rem; border-radius: 2px; margin: 4px 4px 0 0;" />    
                        <p>${origFileName}</p>
                    </div>
                    <p style="width: 100%; font-size: .8rem; margin: 2px 0 0; float: ${sender==='patient'?'right':'left'}; color: #9e9e9e;">
                        <span id="btn-download-`+ind+`">บันทึก</span>
                    </p>
                </div>
                `)
            } else {
                chatLog.insertAdjacentHTML('beforeend',`
                <div key="${ind}" style="text-align: ${sender==='patient'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
                    <p>${sender==='patient'?'You':doctorTitle+' '+doctorFirstName+' '+doctorLastName}: <span style='color: #9e9e9e;'> ${moment(time).format('HH:mm A')} </span></p>
                    <div id="fileMessage-${ind}" style="display: flex; justify-content: ${sender==='patient'?'end':'start'};">
                        
                    </div>
                    <p style="font-size: 0.8rem; margin: 4px 0; color: #000000;">${origFileName}</p>
                    <p style="width: 100%; font-size: .8rem; margin: 2px 0 0; float: ${sender==='patient'?'right':'left'}; color: #9e9e9e;">
                        <span id="btn-download-`+ind+`">บันทึก</span>
                        <span>&nbsp;|&nbsp;</span>
                        <span id="btn-preview-`+ind+`">ดูตัวอย่าง</span>
                    </p>
                </div>
                `)
            }

            chatsLogRef.current.scrollTop = chatsLogRef.current.scrollHeight;
            
        }

        // Fetch file image and insert to element
        if(typeName!=='pdf') {
            // Get Image File
            await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file?filename=${fileName}`,{
                headers: {
                    // 'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
                },
                responseType: 'arraybuffer',
            })
            .then((response) => {
                // var result = response.data;
                console.log('Processing Request');

                function _arrayBufferToBase64( buffer ) {
                    var binary = '';
                    var bytes = new Uint8Array( buffer );
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    return window.btoa( binary );
                }

                console.log('FILE Appended[',ind,']')

                let imgMsg = document.createElement('img')
                imgMsg.style = `width: 25%; ${sender==='patient'?'margin-left: auto;':''};`
                imgMsg.src = `data:${mimetype};base64,${_arrayBufferToBase64(response.data)}`
                imgMsg.alt = origFileName
                imgMsg.id = 'img-fileMessage-'+ind
                let elem = document.getElementById('fileMessage-'+ind)
                // let imgMsg = `<img src="${`data:${mimetype};base64,${_arrayBufferToBase64(response.data)}`}" alt="file image" style="width: 25%;" />`

                let btnPreview = document.getElementById('btn-preview-'+ind)
                btnPreview.addEventListener('click', () => { getPreviewFile(ind, fileName, origFileName, 'img', mimetype) })

                let btnDownload = document.getElementById('btn-download-'+ind)
                btnDownload.addEventListener('click', () => { fetchDownloadFile(fileName, origFileName, mimetype) })

                elem.appendChild(imgMsg)
            })
            .catch((err)=> { 
                // window.location = '/'
                // navigate('/')
                modalEvent('open','modal-error'); 
                setErrSubMsg('ไม่สามารถโหลดไฟล์รูปได้'); 
                console.log(err); 
            })
        } else {
            // Get PDF File

            // let btnPreview = document.getElementById('btn-preview-'+ind)
            // btnPreview.addEventListener('click', () => { getPreviewFile(ind, fileName, origFileName, 'pdf', mimetype) })

            let btnDownload = document.getElementById('btn-download-'+ind)
            btnDownload.addEventListener('click', () => { fetchDownloadFile(fileName, origFileName, mimetype) })

            // await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file/url?filename=${fileName}`,{
            //     headers: {
            //         // 'content-type': 'multipart/form-data',
            //         'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
            //     },
            //     // responseType: 'blob',
            // })
            // .then((response) => {
            //     // var result = response.data;
            //     console.log('Processing Request [PDF]:',response);
            //     // const file = new Blob([response.data], {type: 'application/pdf'});

            //     setPdfPreview(response.data.url)

            //     let btnPreview = document.getElementById('btn-preview-'+ind)
            //     btnPreview.addEventListener('click', () => { getPreviewFile(ind, fileName, origFileName, 'pdf', mimetype) })

            //     let btnDownload = document.getElementById('btn-download-'+ind)
            //     btnDownload.addEventListener('click', () => { fetchDownloadFile(fileName, origFileName, mimetype) })
            // })
            // .catch((err)=> { 
            //     // window.location = '/'
            //     // navigate('/')
            //     modalEvent('open','modal-error'); 
            //     setErrSubMsg('ไม่สามารถโหลดไฟล์​ PDF ได้'); 
            //     console.log(err); 
            // })

        }
    }

    // Set File componet [from log]
//     const fileComponentFromLog = async (data, ind) => {
//         let origFileName = data.orig_filename
//         let fileName = data.message
//         let sender = data.sender 
//         let time = data.timestamp
//         let chatLog = chatsLogRef.current
//         let mimetype = data.mime_type
//         let typeName = mimetype.substr(mimetype.length - 3);

//         // Fetch file upload
//         await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file?filename=${fileName}`, {
//             headers: {
//                 // 'content-type': 'multipart/form-data',
//                 'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
//             },
//             responseType: 'arraybuffer',
//         })

//         .then(function (response) {
//             function _arrayBufferToBase64( buffer ) {
//                 var binary = '';
//                 var bytes = new Uint8Array( buffer );
//                 var len = bytes.byteLength;
//                 for (var i = 0; i < len; i++) {
//                    binary += String.fromCharCode( bytes[ i ] );
//                 }
//                 return window.btoa( binary );
//             }

// console.log('FILE SUCCESSED[',ind,']')
//             if(chatLog) {
//                 if(typeName==='pdf') {
//                     chatLog.innerHTML += ` 
//                     <div key="${ind}" style="text-align: ${sender==='patient'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
//                         <p>${sender==='patient'?'You':doctorTitle+' '+doctorFirstName+' '+doctorLastName}: <span style='color: #9e9e9e;'> ${moment(time).format('HH:mm A')} </span></p>
//                         <div style="display: flex; border-width: 1px; border-color: ${sender==='patient'?'#dee2e6':'#008074'}; color: ${sender==='patient'?'#495057':'#008074'}; padding: .3rem .75rem; border-radius: 50px; width: fit-content; float: ${sender==='patient'?'right':'left'};">
//                             <img src="${typeName==='pdf'?IconPdf:IconImg}" alt="icon file" style="width: 1rem; height: 1rem; background-color: #3b82f6; padding: .15rem; border-radius: 2px; margin: 4px 4px 0 0;" />    
//                             <p>${origFileName}</p>
//                         </div>
//                         <p style="width: 100%; font-size: .8rem; margin: 2px 0 0; float: ${sender==='patient'?'right':'left'}; color: #9e9e9e;">
//                             <span id="btn-download">บันทึก</span><span>&nbsp;|&nbsp;</span><span>ดูตัวอย่าง</span>
//                         </p>
//                     </div>
//                     `
//                 } else {
//                     chatLog.innerHTML += ` 
//                     <div key="${ind}" style="text-align: ${sender==='patient'?'right':'left'}; margin-bottom: 10px; overflow: hidden;">
//                         <p>${sender==='patient'?'You':doctorTitle+' '+doctorFirstName+' '+doctorLastName}: <span style='color: #9e9e9e;'> ${moment(time).format('HH:mm A')} </span></p>
//                         <div style="display: flex; justify-content: ${sender==='patient'?'end':'start'};">
//                             <img src="${`data:${mimetype};base64,${_arrayBufferToBase64(response.data)}`}" alt="file image" style="width: 25%;" />
//                         </div>
//                         <p style="width: 100%; font-size: .8rem; margin: 2px 0 0; float: ${sender==='patient'?'right':'left'}; color: #9e9e9e;">
//                             <span id="btn-download-`+ind+`">บันทึก</span><span>&nbsp;|&nbsp;</span><span id="btn-preview-`+ind+`">ดูตัวอย่าง</span>
//                         </p>
//                     </div>
//                     `
//                 }
                
//                 chatsLogRef.current.scrollTop = chatsLogRef.current.scrollHeight;
//             }
//         })
//     }

    // Set Doctor event component
    const eventFromDoctor = (status) => {
        let doctor = doctorTitle+' '+doctorFirstName+' '+doctorLastName
        let chatLog = chatsLogRef.current
        if(chatLog) {
            chatLog.insertAdjacentHTML('beforeend',`
            <div style="text-align: center; margin-bottom: 10px; overflow: hidden;">
                <p style="color: ${status==='joined'?'#008074':'#9e9e9e'}; padding: .3rem .75rem; width: 100%; font-size: .8rem">${doctor} ${status} the channel</p>
            </div>
            `)
            chatsLogRef.current.scrollTop = chatsLogRef.current.scrollHeight;
        }
    }

    useEffect(() => {
console.log('openChat: ',openChat)
        if(openChat) {
            (async()=>{
                // Initialize client
                client = await AgoraRTM.createInstance(options.appID)
                channel = await client.createChannel(options.channel)

                console.log('openChat: ',openChat)
                // Client Login
                options.uid = options.uid.toString()
                await client.login(options)

                await channel.join().then (() => {
                    console.log(`*** You have successfully joined channel ***`)
                })

                // Doctor - Send Message
                channel.on('ChannelMessage', function (message, memberId) {
                    let doctor = doctorTitle+' '+doctorFirstName+' '+doctorLastName
                    let fileMsg = message.text.substring(0, 1)==='{'?true:false

                    // Check chat type from doctor
                    if(fileMsg) {
                        console.log('addChatDoctorHandler[type]:', fileMsg);
                        let data = JSON.parse(message.text)
                        fileComponentFromChat(data,'Doctor')
                    } else {
                        console.log('addChatDoctorHandler', message, memberId);
                        // Add Chat to Log
                        chatComponentFromChat(message.text, doctor)
                    }
                
                })

                // Display channel member stats
                channel.on('MemberJoined', function (memberId) {
                    console.log(memberId +'to joined')
                    eventFromDoctor('joined')
                })
                // Display channel member stats
                channel.on('MemberLeft', function (memberId) {
                    console.log(memberId +'to left')
                    eventFromDoctor('left')
                })


                console.log('!!! Start Chat !!!')

                // Fetch Chat from Log
                const resChatFromLog = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/log`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        // "Accept": "application/json",
                        'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
                    }
                });
        
                resChatFromLog
                    .json()
                    .then((response) => {
                        chatsLogRef.current.innerHTML = ''
                        console.log('Chat from LOG: ',response)
                        let dataRes = response

                        for (const i in dataRes) {
                            if(dataRes[i].type === 'text') {
                                chatComponentFromLog(dataRes[i], i)
                                console.log('CHAT[',i,']')
                            } else {
                                
                                fileComponentFromLog(dataRes[i],i)
                                console.log('FILE[',i,']')
                            }
                        }

                        // for(let i=0; i<dataRes.length; i++) {
                        //     (async()=>{
                        //         if(dataRes[i].type === 'text') {
                        //             await chatComponentFromLog(dataRes[i], i)
                        //             console.log('CHAT')
                        //         } else {
                        //             await fileComponentFromLog(dataRes[i],i)
                        //             console.log('FILE')
                        //         }

                        //     })()
                        // }
                        // dataRes.map((item,i) =>
                        //     chatComponentFromLog(item, i)
                        // )
                    })
                    .catch((err)=> { 
                        // window.location = '/'
                        // navigate('/')
                        modalEvent('open','modal-error'); 
                        setErrSubMsg('ไม่สามารถโหลดข้อความได้'); 
                        console.log(err); 
                    })

            })()

        }

        return () => {
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
   
    // Pantient - Send Message 
    const sendChatPantientHandler = async (e) => {
        e.preventDefault();

        if(chatsInputRef.current && chatsInputRef.current.value) {
            let channelMessage = chatsInputRef.current.value.toString()
            console.log('Patient send[Message]: ',channelMessage)
            console.log('*** Channel [Before if] ***', channel)
            if (channel !== null) {
                console.log('*** Channel [After if] ***', channel)

                await channel.sendMessage({ text: channelMessage }).then(() => {
                    // Add Chat to chat box
                    chatComponentFromChat(channelMessage, 'You')

                    // Add Chat to log api
                    fetchChatToLog(channelMessage)
                })
            }
            
        }
    }

    // Pantient - File Message 
    const sendFilePantientHandler = async (data) => {
        let filename = data.filename
        let origfilename = data.orig_filename
        let mimetype = data.mime_type
            
        let channelMessage = JSON.stringify({"type": "file", "filename": filename, "orig_filename": origfilename, "mime_type": mimetype})
        console.log('Patient send[File]: ',channelMessage)
            
        if (channel !== null) {
            await channel.sendMessage({ text: channelMessage }).then(() => {

                // Show on chat box [Patient]
                fileComponentFromChat(data, 'You')
            })
        }
    }

    
    // Fetch chat to log
    const fetchChatToLog = (channelMessage) => {
        const resChatToLog = fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/text`, {
            method: 'POST',
            body: JSON.stringify({
                text: channelMessage
            }),
            headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
            }
        });

        resChatToLog
            .then((response) => {
                console.log('Chat from LOG [OK]: ',response.ok)
                // Clear chat
                chatsInputRef.current.value = ''
            })
            .catch((err)=> { modalEvent('open','modal-error'); setErrSubMsg('ไม่สามารถบันทึกข้อความได้'); console.log(err); })
    
    }

    // Fetch file upload
    const fetchUploadFile = (e) => {
        e.preventDefault();

        console.log(e.target.files[0])

        const formData = new FormData();
        formData.append("file", e.target.files[0])
       
        axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file`, formData, {
            headers: {
                // 'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
            }
        })

        .then(function (response) {
            console.log(response.data)
            let data = response.data

            // Send file to Doctor
            sendFilePantientHandler(data)
        })
        
    }

    // Fetch file upload
    const fetchDownloadFile = async (filename, origfilename, mimetype) => {
        modalEventLoading('open')

        const { userAgent } = navigator
        
        console.log('VideoChat browserName: ',browserName)
        console.log('Filename: ',filename,', OrigFilename: ',origfilename, ', MimeType: ',mimetype)
        console.log('LIFF isInClient:', liff.isInClient())
        console.log('Include[Line]:', userAgent.includes('Line'))
        console.log('Include[LIFF]:',userAgent.includes('LIFF'))

        let typeName = mimetype.substr(mimetype.length - 3);
       
        try {
            // Check open app on LIFF or file type PDF
            if((userAgent.includes('Line')) || typeName === 'pdf') {
                // On LIFF
                const fetchDownload = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file/url?filename=${filename}`,{
                    method: 'GET',
                    headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
                    }
                })

                fetchDownload
                .json()
                .then(response => {
                    // Check open app on LIFF 
                    if(userAgent.includes('Line')) {
                        liff.openWindow({
                            url: response.url,
                            external: true
                        })
                    } else {
                        const urlDownload = response.url;
                        const downloadLink = document.createElement('a');
                        const fileName = origfilename;
            
                        downloadLink.href = urlDownload;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    }
                })

            } else {
                // Out LIFF
                // Get Image File 
                await axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file?filename=${filename}`,{
                    headers: {
                        // 'content-type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
                    },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    // var result = response.data;

                    function _arrayBufferToBase64( buffer ) {
                        var binary = '';
                        var bytes = new Uint8Array( buffer );
                        var len = bytes.byteLength;
                        for (var i = 0; i < len; i++) {
                            binary += String.fromCharCode( bytes[ i ] );
                        }
                        return window.btoa( binary );
                    }

                    const urlDownload = `data:${mimetype};base64,${_arrayBufferToBase64(response.data)}`;
                    const downloadLink = document.createElement('a');
                    const fileName = origfilename;
        
                    downloadLink.href = urlDownload;
                    downloadLink.download = fileName;
                    downloadLink.click();
                })
                .catch((err)=> { 
                    // window.location = '/'
                    // navigate('/')
                    modalEvent('open','modal-error'); 
                    setErrSubMsg('ไม่สามารถโหลดไฟล์รูปได้'); 
                    console.log(err); 
                })
            }
            modalEventLoading('close')
        } catch (error) {
            modalEventLoading('close')
            modalEvent('open','modal-error');
            setErrSubMsg('เนื่องจากหมดอายุ หรือ เกิดข้อผิดพลาด')
        }

        // const fetchDownload = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file/?filename=${filename}`,{
        //     method: 'GET',
        //     headers: {
        //     "Content-Type": "application/json",
        //     // "Accept": "application/json",
        //     'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
        //     }
        // })
        
        // if(fetchDownload.status === 200) {
        //     fetchDownload
        //     .json().then(response => {
        //         if(liff.isInClient()) {
        //             liff.openWindow({
        //                 url: response.url,
        //                 external: true
        //             })
        //         } else {
        //             const urlDownload = response.url;
        //             const downloadLink = document.createElement('a');
        //             const fileName = origfilename;
        
        //             downloadLink.href = urlDownload;
        //             downloadLink.download = fileName;
        //             downloadLink.click();
        //         }
        //         // Check LIFF in-app browser
        //         // const { userAgent } = navigator
        //         // console.log(userAgent)
        //         // eslint-disable-next-line no-undef
        //         // if(userAgent.includes('Line') && userAgent.includes('LIFF')) {
        //         //     liff.openWindow({
        //         //         // url: response.url,
        //         //         url: response.url+"?openExternalBrowser=1",
        //         //         external: true
        //         //     })

        //         //     modalEventLoading('close')
        //         // } else {
        //         //     // window.open(response.url, '_blank')
        //         //     const a = document.createElement('a')
        //         //     // a.setAttribute('target', '_blank');
        //         //     a.href = response.url
        //         //     a.download = response.url.split('/').pop()
        //         //     document.body.appendChild(a)
        //         //     a.click()
        //         //     document.body.removeChild(a)

        //         //     modalEventLoading('close')
        //         // }
 
        //     })
        // } else {
        //     modalEvent('open','modal-error');
        //     setErrSubMsg('เนื่องจากหมดอายุ หรือ เกิดข้อผิดพลาด')
        // }

        // if(browserName==='Safari') {
        //     fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file/url?filename=${filename}`,{
        //         method: 'GET',
        //         headers: {
        //         "Content-Type": "application/json",
        //         // "Accept": "application/json",
        //         'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
        //         }
        //     }).then(response => {
        //         response.blob().then(blob => {
        //             // Creating new object of PDF file
        //             const fileURL = window.URL.createObjectURL(blob);
        //             // Setting various property values
        //             let alink = document.createElement('a');
        //             alink.href = fileURL;
        //             alink.download = origfilename;
        //             alink.target = '_blank';
        //             alink.click();
                    
        //         })
        //     })
        // } else {
        //     const fetchDownload = fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file/url?filename=${filename}`,{
        //         method: 'GET',
        //         headers: {
        //         "Content-Type": "application/json",
        //         // "Accept": "application/json",
        //         'Authorization': 'Bearer ' + (!!token ? token : localStorage.getItem('accessTokenApi'))
        //         }
        //     })
        //     console.log(fetchDownload)
        //     if(fetchDownload.status === 200) {
        //         fetchDownload
        //         .json().then(response => {
        //             liff.openWindow({
        //                 url: response.url,
        //                 external: true
        //             })
     
        //             // setDownloading(false)
        //         })
        //     } else {
        //         modalEvent('open', 'modal-error')
        //         setErrSubMsg('ไม่สามารถดาวน์โหลดไฟล์ได้')
        //     }
        // }
        
    }

    // Get Preview Image
    const getPreviewFile = (ind, filename, origfilename, type, mimetype) => {
        let elem = document.getElementById('img-fileMessage-'+ind)
        let imgSrc = elem.getAttribute('src')

        setShowFileName(origfilename)
        setImgPreview(imgSrc)
        setImgPreviewFileName(filename)
        setImgPreviewOrigFileName(origfilename)
        setPreviewType(type)
        setImgPreviewMimeType(mimetype)

        modalEvent('open','modal-preview')
    }

    return (
        <>
            <div className="flex flex-row justify-between">
                <h1 className="font-promptsemibold">Chat with Doctor</h1>
                <div className="">
                    {/* <button onClick={()=> modalEvent('open','modal-fileupload')}> */}
                    <button className="m-auto" onClick={actionClose}><img src={IconClose} alt="icon close" /></button>
                </div>
            </div>
            
            <div ref={chatsLogRef} className="w-full overflow-auto my-3" style={{height: window.innerHeight/3+'px'}}>
                {/* Chat message add here */}
            </div>

            <form onSubmit={sendChatPantientHandler} >
                <div className="overflow-hidden bg-form-bd rounded-full py-3 px-5 flex flex-row justify-between">
                    <input ref={chatsInputRef} type="text" placeholder="Send a message" className="bg-form-bd w-full" id="channelMessage" />
                    <div className="grid grid-cols-2 gap-2ex">
                        <button onClick={sendChatPantientHandler} >
                            {/* <img src={IconSend} alt="icon close"/> */}
                            <MdSend color="#199690" size="22.4px" style={{margin: '3.8px'}} />
                        </button>
                        <div className="ml-2">
                            <input type="file" id="btn-upload" onChange={fetchUploadFile} hidden accept="image/png, image/gif, image/jpeg, application/pdf" />
                            <label htmlFor="btn-upload">
                                <MdOutlineAttachFile color="#616262" size="20px" style={{margin: '3.8px'}} />
                            </label>
                        </div>
                    </div>
                </div>
            </form>     
            {/* Modal & Overlay */}
            <div id="modal-chat-overlay" className="fixed w-full h-full left-0 top-0 right-0 bottom-0 m-auto z-20 p-10 bg-black/50 hidden">
                {/* Modal File Upload */}
                <div id="modal-error" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                    <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                    <BtnPrimary type="button" text="ปิด" action={()=> {modalEvent('close','modal-error');}} /> 
                </div>

                {/* Modal File Preview */}
                <div id="modal-preview" className="absolute left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit max-h-screen py-4 px-4 bg-white rounded-lg">
                    <div className="flex justify-between">
                        {/* <h1 className="font-promptsemibold mb-0 text-lg">File Preview</h1> */}
                        <p className="w-full text-gray-2 text-md items-baseline leading-5">{showFileName}</p>
                        <div className="flex">
                            <img src={IconFileDownload} alt="download" className="w-5 mr-3" onClick={()=> {fetchDownloadFile(imgPreviewFileName, imgPreviewOrigFileName, imgPreviewMimeType)}} />
                            <img src={IconClose} alt="close" onClick={()=> {modalEvent('close','modal-preview');}} />
                        </div>
                    </div>
                    <div className="pt-2">
                        {
                            !!imgPreview ? 
                                previewType===('img') ? 
                                    <div className="relative" style={{width: '100%', height: 'fit-content'}}>
                                        <img className="m-auto max-width-full h-auto" src={imgPreview} alt="preview" style={{maxHeight: '70vh'}} /> 
                                        {/* <p className="w-full text-center text-gray-2 text-md my-3">{showFileName}</p> */}
                                    </div>
                                    : 
                                    <object data={pdfPreview} type="application/pdf" width="100%" height="500px">
                                        <p>Unable to display PDF file. <a href={pdfPreview}>Download</a> instead.</p>
                                    </object> 
                                : 
                                <p className="w-full text-center text-gray-2 text-md my-5">ไม่พบข้อมูล</p>
                        }
                    </div>
                    {/* <img src={`data:${imgMimetype};base64,${imgPreview}`} alt="preview" className="mx-auto my-4" /> */}
                    {/* <BtnPrimary type="button" text="บันทึก" action={()=> {fetchDownloadFile(imgPreviewFileName, imgPreviewOrigFileName, imgPreviewMimeType)}} />  */}
                </div>
            </div>
            
            <div id="modal-loading-overlay" className="fixed w-full h-full left-0 top-0 right-0 bottom-0 m-auto z-20 p-10 bg-black/50 hidden">
                {/* Modal File Upload */}
                <div id="modal-loading" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto w-11/12 h-fit py-5 px-4 rounded-lg">
                    <LoadingNoLogo status="VideoCallLoading" hidelogo={true} style={{height: window.innerHeight-60+'px'}} />
                </div>
            </div>
        </>
    )
}

export default VideoChat;
