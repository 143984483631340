import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Nav from '../../components/Nav'

import { TokenContext } from '../../App'
import NavSearch from '../../components/V2NavSearch'

function Search() {
  const navigate = useNavigate()
  const { token, setCurrentPage, consentTerm, consentPrivacy, } = useContext(TokenContext)

  useEffect(() => {
    setCurrentPage('/search')

    // check token
    if(!token) {
      navigate('/')
    }
    // check term
    if(!consentTerm) {
      navigate('/termcondition')
    } else {
      // check privacy
      if(!consentPrivacy) {
        navigate('/privacy')
      }
    }

    console.log('Search token: ',token)
  
    return () => {
      
    }
  }, [consentPrivacy, consentTerm, navigate, setCurrentPage, token])
  
  return (
    <div className="search-page px-5">
        <NavSearch />

        {/* Nav */}
        <Nav currentPage="search" />
    </div>
  )
}

export default Search