import React from 'react'
import Modal from 'react-modal'
import logoThaiDoctor from '../assets/img/logo-thaidoctor.svg'

const BtnPrimary = (props) => {
    const { type, text, addclass, action, icon } = props
    return (
        <button type={type} onClick={action} className={`flex justify-center items-center rounded-lg font-promptsemibold p-3 text-white text-center w-full bg-primary ${addclass}`}>
            { icon ? <img src={icon} alt={'icon'} className="mr-2" /> : null }
            {text}
        </button>
    )
}

const BtnSecondary = (props) => {
    const { type, text, addclass, action, icon } = props
    return (
        <button type={type} onClick={action} className={`flex justify-center items-center rounded-lg font-promptsemibold p-3 text-white text-center w-full bg-secondary ${addclass}`}>
            { icon ? <img src={icon} alt={'icon'} className="mr-2" /> : null }
            {text}
        </button>
    )
}

const BtnTransparent = (props) => {
    const { type, text, icon, addclass, action } = props
    return (
        <button type={type} onClick={action} className={`font-promptregular p-3 text-black text-center ${addclass}`}>
            {
                icon ? <img className={`pr-2`} src={icon} alt={`icon ${text}`} /> : null
            }
            {text}
        </button>
    )
}

const BtnTransparentIconAfter = (props) => {
    const { type, text, icon, addclass, action } = props
    return (
        <button type={type} onClick={action} className={`flex justify-center items-center rounded-lg font-promptregular p-3 text-black text-center ${addclass}`}>
            {text}
            {
                icon ? <img className={`pr-2 ml-2`} src={icon} alt={`icon ${text}`} /> : null
            }
        </button>
    )
}

const Select = (props) => {
    const { name, dfValue, optValue, optName, change } = props
    return (
        <select name={name} value={dfValue} onChange={change} className="p-2.5 pr-6 mb-3 border border-form-bd rounded-lg outline-0">
            {
                optValue.map((item,i)=>{
                    return (
                        <option key={i} value={optValue[i]}>{optName[i]}</option>
                    )
                }
                )
            }
        </select>
    )
}

const SelectTelCode = (props) => {
    const { name, dfValue, optValue, optName, change } = props
    return (
        <select name={name} value={dfValue} onChange={change} className="p-2.5 pr-6 mb-3 border border-form-bd rounded-l-lg border-r-0 outline-0">
            {
                optValue.map((item,i)=>{
                    return (
                        <option key={i} value={optValue[i]}>{optName[i]}</option>
                    )
                }
                )
            }
        </select>
    )
}

const Input = (props) => {
    // name= name of input, type= type of input, addclass= add new class, change= event controller
    const { name, textplace, type, value, addclass, change, onControl } = props
    return (
        <input name={name} placeholder={textplace} type={type} value={value} {...onControl} onChange={change} className={`p-2.5 mb-3 border  border-form-bd rounded outline-0 ${addclass}`} />
    )
}

const InputTel = (props) => {
    // name= name of input, type= type of input, addclass= add new class, change= event controller
    const { name, type, textplace, value, defaultvalue, addclass, onControl } = props
    return (
        <input name={name} type={type} placeholder={textplace} defaultValue={defaultvalue} value={value} {...onControl} className={`p-2.5 mb-3 border border-form-bd rounded-r-lg outline-0 grow ${addclass}`} />
    )
}

const ShowModal = (props) => {
    const { modalIsOpen, actionsecondary, actionprimary, text, subtext, type } = props

    return (
        <Modal
        isOpen={modalIsOpen}
        className="Modal"
        overlayClassName="Overlay"
      >
        <img src={logoThaiDoctor} alt="logo thaidoctor" className="mx-auto my-4" />
        <h1 className="font-promptsemibold mb-1">{text}</h1>
        <p className="text-gray-2 mb-4">{subtext}</p>
        {
            type === 'confirm' ? 
                <div>
                    <BtnSecondary type="button" text="ยกเลิก" action={actionsecondary} />
                    <BtnPrimary type="button" text="ตกลง" action={actionprimary} />
                </div>
            : <BtnSecondary type="button" text="ยกเลิก" action={actionsecondary} /> 
        }
      </Modal>
    )
}

export {
    BtnPrimary,
    BtnSecondary,
    BtnTransparent,
    BtnTransparentIconAfter,
    Select,
    SelectTelCode,
    Input,
    InputTel,
    ShowModal,
}
