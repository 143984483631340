import React, { useEffect, useMemo, useContext, useState } from 'react'
import Modal from 'react-modal'
import AgoraRTM from 'agora-rtm-sdk'
import moment from 'moment'
import {
  BtnPrimary,
} from '../../components/FormItems'

import { TokenContext } from '../../App'

import IconClose from '../../assets/img/ic-close.svg'
// import IconSend from '../../assets/img/ic-send.svg'
import VideoFileUpload from './VideoFileUpload'

import { MdOutlineAttachFile, MdSend } from "react-icons/md";
import IconFailed from '../../assets/img/ic-failed.svg'


function VideoChatDoctor(props) {
    const { token } = useContext(TokenContext)
    const { appointmentID, actionClose, /* chatAppId, chatToken, chatChannel, chatUId,*/ doctorTitle, doctorFirstName, doctorLastName } = props

    const [modalFileUpload, setModalFileUpload] = useState(false)
    const [modalErr, setModalErr] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg /*, setErrSubMsg*/] = useState('กรุณาลองใหม่อีกครั้ง')

    // Params for login
    let options = useMemo(() => { return {
        uid: "doctor36",
        token: "00658b31596df494ce09f11dd9e8675eb4dIACdvdFMhlMplQTjftk1vcfW0UccKdbeh6P6iJJZ447RFh3drsEAAAAAEAAzD4X9eMUFZAEA6AN4xQVk",
        appID: "58b31596df494ce09f11dd9e8675eb4d",
        channel: "staging-ThaiDoctorRoom662-4088740173",
        appointmentID: 662
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }}, [])

    // Fix scrollbar on Log Text box
    const fixScollbar = () => {
        const boxShow = document.getElementById("cotrol-msg-box")
        const boxLog = document.getElementById("log")
        boxLog.scrollTop = boxLog.scrollHeight - boxShow.clientHeight
        console.log('*** fix scrollbar *** :',boxLog.scrollHeight,' - ', boxShow.clientHeight, ' = ',boxLog.scrollHeight - boxShow.clientHeight)
    }

    // Initialize client
    const client = AgoraRTM.createInstance(options.appID)

    // Client Event listeners
    // Display messages from peer
    client.on('MessageFromPeer', function (message, peerId) {
        console.log('*** Display messages from Patient: ***', message)
        document.getElementById("log").appendChild(document.createElement('div')).append("Message from: " + peerId + " Message: " + message.text)
    })
    // Display connection state changes
    client.on('ConnectionStateChanged', function (state, reason) {
        console.log('*** State changeed to: ', state,', Reason: ',reason,' ***')
        // document.getElementById("log").appendChild(document.createElement('div')).append("State changed To: " + state + " Reason: " + reason)
        // document.getElementById("log").appendChild(document.createElement('br'))

    })

    let channel = client.createChannel(options.channel)

    channel.on('ChannelMessage', function (message, memberId) {
        console.log('*** Display connection state changes: ***', message)
        // document.getElementById("log").appendChild(document.createElement('div')).append("Message received from: " + memberId + " Message: " + message.text)
        console.log('Patient Res:' ,channel)
        console.log('Patient: ',channel.client.session.startTime)
        console.log('Patient send time: ',channel.client.session.messageSentTimes[0])

        document.getElementById("log").appendChild(document.createElement('div')).append(doctorTitle+" "+doctorFirstName+" "+doctorLastName+" "+ moment(channel.client.session.messageSentTimes[0]).format('HH:mm A'))
        document.getElementById("log").appendChild(document.createElement('div')).append(message.text)
        document.getElementById("log").appendChild(document.createElement('br'))

        fixScollbar()
    })
    // Display channel member stats
    channel.on('MemberJoined', function (memberId) {

        document.getElementById("log").appendChild(document.createElement('div')).append(memberId + " joined the channel")
        document.getElementById("log").appendChild(document.createElement('br'))

    })
    // Display channel member stats
    channel.on('MemberLeft', function (memberId) {

        document.getElementById("log").appendChild(document.createElement('div')).append(memberId + " left the channel")
        document.getElementById("log").appendChild(document.createElement('br'))

    })

    const fetchChatToLog = async (id,txt) => {
        // let appointmentID = id
        try {
            const resChatToLog = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${options.appointmentID}/chat/text`, {
                method: 'POST',
                body: JSON.stringify({
                    text: txt
                }),
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                }
            });

            console.log('Chat from LOG: Status[',resChatToLog.status,']')
            
        } catch (error) {
            setModalErr(true)
        }
    }

    const fetchChatFromLog = async (id) => {
        // let appointmentID = id
        const resChatFromLog = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${options.appointmentID}/chat/log`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });

        resChatFromLog
            .json()
            .then((response) => {
                console.log('Chat from LOG: ',response)

            })
            .catch((err)=> { console.log(err); /* setModalErr(true); */ })
    }
    

    useEffect(() => {

        (async () => {
            options.uid = options.uid.toString()
            await client.login(options)

            await channel.join().then (() => {
                const boxLog = document.getElementById("log")

                boxLog.innerHTML += `<p style="color: #646464; font-size: .8rem">You have successfully joined channel:</p>`
                boxLog.innerHTML += `<p style="color: #646464; font-size: .8rem">${channel.channelId}</p>`
                // document.getElementById("log").appendChild(document.createElement('div')).append("You have successfully joined channel " + channel.channelId)
                document.getElementById("log").appendChild(document.createElement('br'))
            })
        })()
    
        return () => {
            
        }
    }, [channel, client, options, props])
    
    const sendMsgToPatient = async (e) => {
        e.preventDefault();

        let channelMessage = document.getElementById("channelMessage").value.toString()

        if (channel != null) {
            await channel.sendMessage({ text: channelMessage, type: 'text' }).then(() => {
                // document.getElementById("log").appendChild(document.createElement('div')).append("Channel message: " + channelMessage + " from " + channel.channelId)
                // Send message to Log
                (async()=>{
                    await fetchChatToLog(appointmentID, channelMessage)
                    await fetchChatFromLog(appointmentID)
                    
                    console.log('channel: ',channel)
                })()
                
                const boxLog = document.getElementById("log")

                // boxLog.appendChild(document.createElement('div')).append("You: " + moment(channel.client.session.messageSentTimes[0]).format('HH:mm A'))
                // boxLog.appendChild(document.createElement('div')).append(channelMessage)
                boxLog.innerHTML += `<p>You: <span style='color: #646464;'> ${moment(channel.client.session.messageSentTimes[0]).format('HH:mm A')} </span></p>`
                boxLog.innerHTML += `<p>${channelMessage}</p>`
                boxLog.appendChild(document.createElement('br'))
                document.getElementById("channelMessage").value = ''

            })
        }

        fixScollbar()
    }
    
    Modal.setAppElement('#root');

    return (
        <div>
            <div className="flex flex-row justify-between">
                <h1 className="font-promptsemibold">Chat with Patient</h1>
                <button onClick={actionClose}><img src={IconClose} alt="icon close" /></button>
            </div>
            <div id="cotrol-msg-box" className="w-full overflow-auto my-3" style={{height: window.innerHeight/3+'px'}}>
                <div id="log"></div>
            </div>
            <form onSubmit={sendMsgToPatient} >
                <div className="overflow-hidden bg-form-bd rounded-full py-3 px-5 flex flex-row justify-between">
                    <input type="text" placeholder="Send a message" className="bg-form-bd" id="channelMessage" />
                    <div className="flex">
                        <button onClick={sendMsgToPatient} >
                            {/* <img src={IconSend} alt="icon close"/> */}
                            <MdSend color="#199690" size="22.4px" style={{margin: '3.8px'}} />
                        </button>
                        <button onClick={()=> setModalFileUpload(true) }>
                            <MdOutlineAttachFile color="#616262" size="22.4px" style={{margin: '3.8px'}} />
                        </button>
                    </div>
                </div>
            </form>

            {/* Modal File Upload */}
            <Modal
                isOpen={modalFileUpload}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="flex flex-row justify-between">
                    <h1 className="font-promptsemibold">Upload File</h1>
                    <button onClick={()=> setModalFileUpload(false)}><img src={IconClose} alt="icon close" /></button>
                </div>
                <VideoFileUpload appointmentID={appointmentID} />

                {/* <BtnPrimary type="button" text="ปิด" action={()=> {setModalFileUpload(false);}} />  */}
            </Modal>

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false);}} /> 
            </Modal>
        </div>
    )
}

export default VideoChatDoctor