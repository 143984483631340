import React, { useContext, useEffect, useState} from 'react'
import Modal from 'react-modal'
import { useNavigate, useLocation } from 'react-router-dom'
import { TokenContext } from '../../App'
import {
  BtnPrimary,
} from '../../components/FormItems'
import IconDocGreen from '../../assets/img/ic-document-green.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import IconBack from '../../assets/img/ic-back.svg'
import { BtnTransparent } from '../../components/FormItems'


function HistoryMedicalView() {
    const navigate = useNavigate()
    const location = useLocation()
    const { token, dataAppointment, /*setDataAppointment*/ historyMedicalStatus, historyMedicalData, setCurrentPage } = useContext(TokenContext)
    const [modalNotice, setModalNotice] = useState(false)
    const [modalErr, setModalErr] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg /*, setErrSubMsg*/] = useState('กรุณาลองใหม่อีกครั้ง')
    const [loading, setLoading] = useState(false)

    const [detailAppointment, setDetailAppointment] = useState({})
    
    const appointmentID = location?.state?.id
    // const [dataSubmit, setDataSubmit] = useState({})

    useEffect(() => {
        
        setLoading(true)
        setCurrentPage('/resulthealthcheck')

        // check token
        if(!token) {
            navigate('/')
        }

        (async () => {
            // Fetch detail doctor
            const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}`, {
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
              }
            });
        
            resDetailDoctor
                .json()
                .then((response) => {
                    // console.log('summaryAppointment : ',response)
                    // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                    if(response) {
                      // setFetchMsg('')
                      setDetailAppointment(response)
                      setLoading(false)
                    //   checkStartVideoCall('2022-12-10 23:45:00')
                    } else {
                    //   setFetchMsg('ไม่พบข้อมูล')
                    }
                })
                .catch((err)=> {console.log(err); })
        })();
        // console.log(location?.state)
        // if(historyMedicalStatus) {
        //     console.log(historyMedicalData)
        //     reset(
        //         {
        //             symptom: historyMedicalData.symptom,
        //             symptom_time: historyMedicalData.symptom_time,
        //             has_allergy: historyMedicalData.has_allergy?'1':'0',
        //             allergy_detail: historyMedicalData.allergy_detail,
        //             chronic_condition: historyMedicalData.chronic_condition,
        //         }
        //     )
        // }

        return () => {

        }
    }, [location.state, historyMedicalData, historyMedicalData.allergy_detail, historyMedicalData.chronic_condition, historyMedicalData.has_allergy, historyMedicalData.symptom, historyMedicalData.symptom_time, historyMedicalStatus, navigate, token, dataAppointment, setCurrentPage, appointmentID])


    // const handleHasAllergy = (value) => {
    //     setHasAllergyStatus(Number(value))
    // }

   

    const gotoSummaryAppointment = () => {
        setModalNotice(false)
    }

    Modal.setAppElement('#root');

    return (
        <div className="medical-history-page p-5">
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/summaryappointment', { state: { appointment_id: location?.state?.id, type: location?.state?.type}}) } }/>

            {
            loading ? 
                <p className="text-center grid place-items-center text-gray-2 w-full" style={{height: window.innerHeight - 120+'px' }}>...Loading...</p>
                : 
                <div>
                    <div className="flex flex-row mb-5">
                        <img src={IconDocGreen} className="mr-2" alt="icon calendar"  />
                        <h1 className="font-promptsemibold">ข้อมูลประกอบการนัดหมาย</h1>
                    </div>
                    <div className="flex flex-col text-left mb-3">
                        <label className="font-promptsemibold mb-2">อธิบายอาการ</label>
                        <textarea row="4" 
                            disabled="disabled"
                            placeholder="ไม่ระบุ" 
                            className={`disabled:opacity-100 placeholder-form-bd  p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
                            value={!!detailAppointment.symptom?detailAppointment.symptom:''}
                            // {...register("first_name", { required: true })} 
                        ></textarea>
                        {/* {
                        errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                        } */}
                    </div>
        
                    <div className="flex flex-col text-left mb-3">
                        <label className="font-promptsemibold mb-2">มีอาการนี้มานานแค่ไหน?</label>
                        <input row="4" 
                            disabled="disabled"
                            placeholder="ไม่ระบุ" 
                            className={`disabled:opacity-100 placeholder-form-bd  p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
                            value={!!detailAppointment.symptom_time?detailAppointment.symptom_time:''}
                            // {...register("first_name", { required: true })} 
                        />
                        {/* {
                        errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                        } */}
                    </div>
        
                    <div className="flex flex-col text-left mb-3">
                        <div className="grid gap-4 grid-cols-2">
                            <div>
                                <label className="font-promptsemibold mb-2">น้ำหนัก (กก.)</label>
                                <input row="4" type="number"
                                    disabled="disabled"
                                    step="0.01"
                                    placeholder="ไม่ระบุ" 
                                    className={`disabled:opacity-100 placeholder-form-bd p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
                                    value={!!detailAppointment.weight?detailAppointment.weight:''}
                                    // {...register("first_name", { required: true })} 
                                />
                                {/* {
                                errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                                } */}
                            </div>
                            <div>
                                <label className="font-promptsemibold mb-2">ส่วนสูง (ซม.)</label>
                                <input row="4" type="number"
                                    disabled="disabled"
                                    step="0.01"
                                    placeholder="ไม่ระบุ" 
                                    className={`disabled:opacity-100 placeholder-form-bd p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
                                    value={!!detailAppointment.height?detailAppointment.height:''}
                                    // {...register("first_name", { required: true })} 
                                />
                                {/* {
                                errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                                } */}
                            </div>
                        </div>
                    </div>
        
                    {/* <p className="font-promptsemibold mb-2">ยาที่แพ้</p> */}
                    <div className="flex flex-col text-left mb-3">
                        <label className="font-promptsemibold mb-2">ยาที่แพ้</label>
                        <input row="4" 
                            placeholder="ไม่ระบุ" 
                            disabled="disabled" 
                            className={`disabled:opacity-100 placeholder-form-bd  p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
                            value={!!detailAppointment.allergy_detail?detailAppointment.allergy_detail:''}
                            // {...register("first_name", { required: true })} 
                        />
                    </div>
        
                    <div className="flex flex-col text-left mb-3">
                        <label className="font-promptsemibold mb-2">โรคประจำตัว</label>
                        <textarea row="4"
                            disabled="disabled" 
                            placeholder="ไม่ระบุ" 
                            className={`disabled:opacity-100 placeholder-form-bd  p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
                            value={!!detailAppointment.chronic_condition?detailAppointment.chronic_condition:''}
                            // {...register("first_name", { required: true })} 
                        ></textarea>
                        {/* {
                        errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                        } */}
                    </div>
                </div>
                }
                        {/* <BtnPrimary type="submit" text="ถัดไป" addclass="mb-10" /> */}
                
            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

            {/* Modal Notice */}
            <Modal
                isOpen={modalNotice}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">กรุณาอธิบายอาการ</h1>
                <p className="text-gray-2 mb-4 text-md">เพื่อให้หมอสามารถให้คำปรึกษาได้ดีขึ้น</p>
                
                <div className="grid grid-cols-2 gap-2">
                    <BtnPrimary type="button" text="กรอกในภายหลัง" action={()=> {gotoSummaryAppointment()}} /> 
                    <BtnPrimary type="button" text="ตกลง" action={()=> {gotoSummaryAppointment()}} /> 
                </div>
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

        </div>
    )
}

export default HistoryMedicalView