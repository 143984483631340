import React, { useEffect } from 'react'

// import {
//     BtnPrimary, 
// } from '../../components/FormItems'

let urlDownload = ''

function Download() {

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlDownload = urlSearchParams.get('urlname')

        const downloadLink = document.createElement('a');
        const fileName = 'qrcode';

        downloadLink.href = urlDownload;
        downloadLink.download = fileName;
        downloadLink.click();
    
        return () => {
            
        }
    }, [])

    // const handleDownload = () => {
    //     // const urlDownload = dataPromptPay.download_uri;
    //     const downloadLink = document.createElement('a');
    //     const fileName = 'qrcode';

    //     downloadLink.href = urlDownload;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    // }
    

    return (
        <div className="payment-promptpay-page p-5">
            <div className="w-3/4 h-64 mx-auto flex mb-10">
                <img className="w-full" src={urlDownload} alt="Prompt Pay QR Code" />
            </div>
            
            <p className="text-softred text-sm sm:text-md mt-5 text-center">กรุณาชำระค่าบริการโดยใช้ QR code นี้ภายใน 5 นาที</p>    
            <p className="text-gray text-sm sm:text-md mt-2 mb-3 text-center">หากไม่สามารถดาวน์โหลดได้ กรุณาบันทึกหน้าจอ(Capture Screen)</p>
                        
            {/* <BtnPrimary type="button" text="ดาวน์โหลดภาพคิวอาร์โค้ด" addclass="mb-5" action={handleDownload} /> */}
        </div>
    )
}

export default Download