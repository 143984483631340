import React from 'react'
import { useNavigate } from 'react-router-dom'
import IconPin from '../assets/img/ic-pin.svg'
import IconProfile from '../assets/img/ic-profile-green.svg'
import IconNoImg from '../assets/img/logo-thaidoctor-small.svg'

function ItemClinic(props) {
    const navigate = useNavigate()
    const { searchinput, dataItem, onclick, backlink, title, clinic_id, type, keyword } = props

    return (
        <div className="py-4 flex flex-row w-full border-b border-form-bd">
            <div className="relative mb-2 mr-4" onClick={onclick}>
                <div className="bg-gray-4 overflow-hidden relative h-14 w-14 xs:w-12 xs:h-12 rounded-full ring-2 ring-white mx-auto flex-none">
                    <img className="bg-gray-4 relative mb-2 mx-auto mr-4 flex-none" src={!!dataItem?.logo_url?dataItem?.logo_url:IconNoImg} alt="" />
                </div>
            </div>
            
            <div className="flex flex-col grow">
                <h1 className="mb-1 font-promptsemibold" onClick={onclick}>{dataItem?.name}</h1>
                <div className="flex flex-col">
                    <div className="flex flex-row mb-0" 
                        onClick={()=> 
                            navigate(
                                '/map', { state: 
                                    { 
                                        searchinput: searchinput,
                                        dataItem: dataItem, 
                                        backlink: backlink, 
                                        title: title, 
                                        clinic_id: clinic_id, 
                                        type: type, 
                                        keyword: keyword, 
                                        item: dataItem
                                    } 
                                }
                            )
                        }
                    >
                        <div className="flex flex-row grow">
                            <img className="w-3 sm:w-4 mr-2 self-start pt-1" src={IconPin} alt="icon location" />
                            <span className="self-center text-blue text-sm sm:text-md">{dataItem?.address} {dataItem?.subdistrict} {dataItem?.district} {dataItem?.province} {dataItem?.zipcode}</span>
                        </div>
                    </div>
                    <div className="flex flex-row mb-0" onClick={onclick}>
                        <div className="flex flex-row grow">
                            <img className="w-3 sm:w-4 mr-2" src={IconProfile} alt="icon rating" />
                            <span className="self-center text-gray-2 text-sm sm:text-md">แพทย์ {dataItem?.num_doctor} คน</span>
                        </div>
                        <div className="grow-0">
                            <p className="text-gray-2 text-sm sm:text-md">{(dataItem?.distance)? Number(dataItem?.distance / 1000).toLocaleString('en-US')+'กม.': null}</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ItemClinic