import React from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function SearchAutoComplete(props) {
    const { dataList, actionSelect, actionOnSearch, zindex, placeholder, inputSearchString } = props

    // const handleOnSearch = (string, results) => {
    //     // onSearch will have as the first callback parameter
    //     // the string searched and for the second the results.
    //     console.log(string, results)
    // }

    // const handleOnHover = (result) => {
    //     // the item hovered
    //     console.log(result)
    // }

    // const handleOnSelect = (item) => {
    //     // the item selected
    //     console.log(item)
    // }

    // const handleOnFocus = () => {
    //     console.log('Focused')
    // }

    const formatResult = (item) => {
        return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        </>
        )
    }

    const customStyle = {
        borderRadius: '0.5rem',
        borderWidth: '1px',
        borderColor: 'rgb(229, 229, 229)',
        boxShadow: 'none',
        fontFamily: 'promptregular',
        zIndex: zindex || 3,
        placeholderColor: '#9e9e9e',
        width: '50%',
        grow: '1',
    }

    return (
        <ReactSearchAutocomplete
            items={dataList}
            onSearch={actionOnSearch}
            // onHover={handleOnHover}
            onSelect={actionSelect}
            // onFocus={handleOnFocus}
            // autoFocus
            formatResult={formatResult}
            showIcon={false}
            styling={customStyle}
            placeholder={placeholder}
            inputSearchString={inputSearchString}
        />
    )
}

export default SearchAutoComplete