import React from 'react'
import Logo from '../assets/img/logo-thaidoctor.svg'

function Loading(props) {
    const { status, color, hidelogo } = props
    return (
        <div className={`${status} flex flex-col h-screen justify-center items-center`}>
            <div className="-translate-y-24 text-center">
                {
                    !hidelogo ? <img src={Logo} alt="Logo ThaiDoctor" className="w-20 mx-auto mb-8" /> : null
                }
                <div className={`spinner-border ${color?color:'text-darkblue'} animate-spin inline-block w-8 h-8 border-4 rounded-full`} role="status">
                    <span className="visually-hidden">...{status} on Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default Loading