import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
// import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  BtnPrimary, BtnTransparent,
} from '../../components/FormItems'
import OtpInput from 'react-otp-input'

import logoThaiDoctor from '../../assets/img/logo-thaidoctor.svg'
import IconBack from '../../assets/img/ic-back.svg'
import IconRedo from '../../assets/img/ic-redo.svg'

import { TokenContext } from '../../App'

function OtpCode() {
    const { token } = useContext(TokenContext)
    const navigate = useNavigate()
    const location = useLocation()
    // const { register, handleSubmit, watch, formState: { errors } } = useForm()
    const [modalErr, setModalErr] = useState(false)
    // const [errCodeSendOtp, setErrCodeSendOtp] = useState(0)
    const [sendOtpErrMsg, setSendOtpErrMsg] = useState('กรุณาติดต่อผู้ดูแลระบบ')
    const [errorWrongOtp, setErrorWrongOtp] = useState(false)

    const [telephoneCode] = useState(location.state?.telephoneCode)
    const [telephone] = useState(location.state?.telephone)
    const [refCode, setRefCode] = useState(location.state?.reference)
    const [otpCode, setOtpCode] = useState('')
    const [countDown, setCountDown] = useState(60)
    const [btnActive, setBtnActive] = useState(false)
    const [disableOtpInput, setDisableOtpInput] = useState(false)


    useEffect(() => {
        // check token
        if(!token) {
            navigate('/')
        }

        // countdown otp input
        const interval = setInterval(() => {
            setCountDown(countDown - 1);
        }, 1000);
        // clear countdown
        if(countDown === 0) {
            setDisableOtpInput(true)
            clearInterval(interval)
        }
        
        return () => {
            clearInterval(interval)
        }
    }, [countDown, navigate, token])

    const requestOtp = async () => {
        setOtpCode('')
        setCountDown(60)
        setBtnActive(false)

        // call api again
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/register/send_otp`, {
            method: 'POST',
            body: JSON.stringify({
                "telephone": telephone
            }),
            headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'Authorization': 'Bearer ' + token
            }
        });
    
        // check status code
        if(res.status === 200) {
            console.log(res)
            res
            .text()
            .then(response => {
                console.log(response)
                const refCodeText = String(response)
                const refCode = refCodeText.substr(refCodeText.length - 4)
                setRefCode(refCode)
                setDisableOtpInput(false)
                setErrorWrongOtp(false)
            })
    
        } else {
            setDisableOtpInput(true)
            setModalErr(true)
            // setErrCodeSendOtp(res.status)
            console.error('Error :(',res.status,')')
            res
            .text()
            .then(response => {
                if(response === 'Maximum OTP reached') {
                    setSendOtpErrMsg('ยื่นขอรหัส OTP เกินจำนวนที่กำหนด')
                } else {
                    setSendOtpErrMsg('ไม่สามารถขอ OTP ใหม่ได้')
                }
            })
    
        }
    
        // check resposne & error
        // res.json()
            // .text()
            // .then(response => {
            //   console.log(response)
            //   setSendOtpErrMsg(response)
            
            // })
            // .catch(err => {
            //   console.log(err);
            //   setModalErr(true)
            // })
    }

    const onSubmit = async (e) => { 
        e.preventDefault(); 
        console.log("reference", refCode,"otp", otpCode,); 


        // call fill otp
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/register/fill_otp`, {
            method: 'POST',
            body: JSON.stringify({
                "reference": refCode,
                "otp": otpCode,
            }),
            headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'Authorization': 'Bearer ' + token
            }
        });
    
        // check status code
        if(res.status === 200) {
            console.log(res)
            res
            .text()
            .then(response => {
                console.log('fill_otp: ',response)
                navigate('/termcondition') 
            })
    
        } else {
            setDisableOtpInput(true)
            // setModalErr(true)
            // setErrCodeSendOtp(res.status)
            console.error('Error :(',res.status,')')
            res
            .text()
            .then(response => {
                if(response === 'Wrong OTP') {
                    setSendOtpErrMsg('กรุณากรอกรหัส OTP ให้ถูกต้อง')
                    setErrorWrongOtp(true)
                    setDisableOtpInput(false)
                } else if(response === 'OTP expired') {
                    setSendOtpErrMsg('รหัส OTP หมดอายุ กรุณากดขอรหัส OTP ใหม่')
                    setErrorWrongOtp(true)
                } else {
                    setSendOtpErrMsg('ไม่สามารถส่งรหัส OTP ได้')
                    setModalErr(true)
                }
            })
    
        }
    }

    const handleOtpChange = (val) => {
        setOtpCode(val)
        setErrorWrongOtp(false)

        if(val.length === 6) {
            setBtnActive(true)
        } else {
            setBtnActive(false)
        }
    }
    // console.log(watch("otpinput"))
    
    Modal.setAppElement('#root');

    return (
        <div className="otp-page px-5">
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/otp')} }/>

            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <form onSubmit={onSubmit}> 
                <div className="my-6 mx-3 text-center">
                    <h1 className="font-semibold text-lg">OTP Verification</h1>
                    <p className="text-gray-2">กรุณากรอกรหัส OTP ที่ส่งไปยัง <br/><span className="font-semibold">{telephoneCode} {telephone}</span></p>
                    <h1 className="text-sm text-gray-2 mt-1">รหัสอ้างอิง {refCode}</h1>
                </div>

                <OtpInput
                    containerStyle={{justifyContent: 'center'}}
                    value={otpCode}
                    onChange={handleOtpChange}
                    numInputs={6}
                    isInputNum={true}
                    isDisabled={disableOtpInput}
                    // separator={<span>-</span>}
                    className={`${ disableOtpInput || !countDown ? `border-form-bd bg-gray-4 ` :  `border-form-bd `}otp-input font-semibold text-lg py-3 px-2.5 mb-3 mx-1 border grow rounded outline-0`}
                />

                {/* <div className="grid gap-x-2 gap-y-2 grid-cols-6">
                    <Input type="tel" addclass="text-center" onControl={ register("telNumber", { required: true }) }/>
                    <Input type="tel" addclass="text-center" onControl={ register("telNumber", { required: true }) }/>
                    <Input type="tel" addclass="text-center" onControl={ register("telNumber", { required: true }) }/>
                    <Input type="tel" addclass="text-center" onControl={ register("telNumber", { required: true }) }/>
                    <Input type="tel" addclass="text-center" onControl={ register("telNumber", { required: true }) }/>
                    <Input type="tel" addclass="text-center" onControl={ register("telNumber", { required: true }) }/>
                </div> */}
                
                

                {/* check error */}
                {/* {errors.telNumber && <p>This field is required</p>} */}
                { errorWrongOtp ? <p className="text-danger text-sm text-center">{sendOtpErrMsg}</p> : null}
                { !countDown ? <p className="text-xs text-danger text-center">คุณไม่ได้กรอกรหัส OTP ภายในเวลาที่กำหนด กรุณากดเพื่อรับรหัสใหม่</p> : null }

                {
                    btnActive ? <BtnPrimary type="submit" text="ยืนยัน" addclass={`mt-10`} />
                    : <BtnPrimary type="button" text="ยืนยัน" addclass={`mt-10 bg-secondary`} />
                }

                <div className="flex">
                    <BtnTransparent type="button" text="ส่งรหัส OTP อีกครั้ง" icon={IconRedo} addclass={`flex grow items-center text-sm text-blue`}f action={()=>{ requestOtp() } }/>
                    <p className="p-3 grow text-right">{ countDown } วินาที</p>
                </div>
            </form>

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={logoThaiDoctor} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">ไม่สามารถทำรายการได้</h1>
                <p className="text-gray-2 mb-4">{sendOtpErrMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} />
                {/* {
                errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } */}
            </Modal>
        </div>
    )
}

export default OtpCode