import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BtnPrimary, BtnSecondary,
} from '../../components/FormItems'

import { TokenContext } from '../../App'

function Privacy() {
    const navigate = useNavigate()
    const { token, consentPrivacy, setConsentPrivacy, registered } = useContext(TokenContext)
    const [hasErr, setHasErr] = useState(false)
    const [agreePrivacy, setAgreePrivacy] = useState(0)
    const [dataPrivacy, setDataPrivacy] = useState({
        // content: "Privacy consent",
        // timestamp: "2022-10-05 21:57:47",
        // version_id: 1,
        // version_name: "1",
    })

    useEffect(() => {
        // check token
        if(!token) {
            navigate('/')
        }

        // check consentPrivacy
        if(consentPrivacy) {
            navigate('/home')
        } else {
      
            (async () => {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/api/consent/privacy/latest`, {
                    method: 'GET',
                    headers: {
                      "Content-Type": "application/json",
                      // "Accept": "application/json",
                      'Authorization': 'Bearer ' + token
                    }
                  });
            
                 res
                    .json()
                    .then((response) => {
                        console.log('policy : ',response)
                        setDataPrivacy(response)
                    })
                    .catch((err)=> console.log(err))
            })();
        }

    
        return () => {
            
        }
    }, [consentPrivacy, navigate, token])
    

    const handleConsentPrivacy = (e) => {
        setHasErr(false)
        setAgreePrivacy(e.target.checked ? 1 : 0)
    }

    const onSubmit = async (e) => { 
        e.preventDefault(); 
        console.log('consent: ',agreePrivacy, 'version_id: ', dataPrivacy.version_id); 

        // call fill otp
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/consent/privacy`, {
            method: 'POST',
            body: JSON.stringify({
                "version_id": dataPrivacy.version_id, 
                "consent": agreePrivacy
            }),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });
    
        // check status code
        if(res.status === 200) {
            console.log(res)
            res
            .text()
            .then(response => {
                console.log('consent_privacy: ',response)
                setConsentPrivacy(1)
                if(registered) {
                    navigate('/home')
                } else {
                    navigate('/home', { state: { success: 1 } }) 
                }
            })
    
        } else {
            res
            .text()
            .then(response => {
                setHasErr(true)
                console.error('consent_privacy: ',response)
            })
    
        }
    }

    return (
        <div className="policy-page px-5 text-center">
            <form onSubmit={onSubmit}>
                <h1 className="font-semibold pt-8">นโยบายความเป็นส่วนตัว</h1>
                <div className="overflow-auto mt-4" style={{height: window.innerHeight - 200+'px'}}>
                    <p className="text-left text-gray-2 text-xs" dangerouslySetInnerHTML={{__html: dataPrivacy.content }}></p>
                </div>
                <div id="footer" className="fixed bottom-0 left-0 p-5 w-full text-left">
                    <div>
                        <input type="checkbox" id="agreePrivacy" checked={agreePrivacy} onChange={handleConsentPrivacy} className="mr-2" />
                        <label htmlFor="agreePrivacy" className="text-xs">ฉันยอมรับ <span className="text-darkblue">นโยบายความเป็นส่วนตัว</span></label>
                    </div>

                    <div style={{height: '20px'}}>
                    {
                        hasErr ? <p className="text-xs text-danger text-left">กรุณากดยอมรับเงื่อนไข</p> : null
                    }
                    </div>

                    {
                        agreePrivacy ? <BtnPrimary type="submit" text="ยืนยัน" addclass={`mt-2`} />
                        : <BtnSecondary type="button" text="ยืนยัน" addclass={`mt-2`} action={()=> setHasErr(true)} />
                    }
                </div>
            </form>
        </div>
    )
}

export default Privacy