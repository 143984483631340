import React, { useContext, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import { TokenContext } from '../App'
import {
  BtnPrimary,
} from '../components/FormItems'

import IconCamera from '../assets/img/ic-camera.svg'
import IconFailed from '../assets/img/ic-failed.svg'

function BtnEnterVideoRoom(props) {
    const { token, profile, setStatusRoom } = useContext(TokenContext)
    const navigate = useNavigate()
    const { appointmentID, addclass, icon,  doctorFirstName, doctorLastName, doctorTitle, doctorImg } = props

    const [modalErr, setModalErr] = useState(false)
    const [errMsg, setErrMsg] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg /*, setErrSubMsg*/] = useState('กรุณาลองใหม่อีกครั้ง')

    const fetchEnterWaitingRoom = async (e) => {
        // console.log(appointmentID)
        e.stopPropagation(); 

        // Fetch enter_waiting_room
        const resEnterWaitingRoom = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/enter_waiting_room`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });

        if(resEnterWaitingRoom.status === 200) {
            console.log(resEnterWaitingRoom.status)

            resEnterWaitingRoom
                .json()
                .then((response)=>{
                    console.log('enter_waiting_room', response)

                    // Check Meeting Status
                    console.log('*** enter_waiting_room[Status]: Start Video Call ***')
                    // localStorage.setItem('graphqlToken',response.graphQL_token)
                    localStorage.setItem('accessToken',response.graphQL_token)

                    if(response.meeting) {
                        console.log('*** enter_waiting_room[statusRoom] : start_room ***')
                        setStatusRoom('start_room')
                        localStorage.setItem('currentStatusRoom','start_room')
                        localStorage.setItem('currentOption', JSON.stringify({
                            videoChannelName: response.video_channel, 
                            videoAppID: response.app_id, 
                            videoToken: response.video_token, 
                            videoUserID: response.video_user_id ,
                            appointmentID: appointmentID,
                            doctorFirstName: doctorFirstName,
                            doctorLastName: doctorLastName,
                            doctorTitle: doctorTitle,
                            doctorImg: doctorImg,
                            chatChannel: response.chat_channel,
                            chatToken: response.chat_token,
                            chatUId: response.chat_user_id,
                            chatAppId: response.app_id,
                        }))

                        // setVideoChannelName(response.video_channel)
                        // setVideoAppID(response.app_id)
                        // setVideoToken(response.video_token)
                        // setVideoUserID(response.video_user_id)

                        navigate('/videocall', { state: { 
                            videoChannelName: response.video_channel, 
                            videoAppID: response.app_id, 
                            videoToken: response.video_token, 
                            videoUserID: response.video_user_id ,
                            appointmentID: appointmentID,
                            doctorFirstName: doctorFirstName,
                            doctorLastName: doctorLastName,
                            doctorTitle: doctorTitle,
                            doctorImg: doctorImg,
                            chatChannel: response.chat_channel,
                            chatToken: response.chat_token,
                            chatUId: response.chat_user_id,
                            chatAppId: response.app_id,
                            patientImg: profile.pictureUrl,
                        } })

                    } else {
                        console.log('*** enter_waiting_room[statusRoom] : waiting_room ***')
                        setStatusRoom('waiting_room')
                        localStorage.setItem('currentStatusRoom','waiting_room')
                        localStorage.setItem('currentOption', JSON.stringify({
                            videoChannelName: null, 
                            videoAppID: null, 
                            videoToken: null, 
                            videoUserID: null,
                            appointmentID: appointmentID,
                            doctorFirstName: doctorFirstName,
                            doctorLastName: doctorLastName,
                            doctorTitle: doctorTitle,
                            doctorImg: doctorImg,
                            chatChannel: response.chat_channel,
                            chatToken: response.chat_token,
                            chatUId: response.chat_user_id,
                            chatAppId: response.app_id,
                        }))

                        navigate('/videocall', { state: { 
                            videoChannelName: null, 
                            videoAppID: null, 
                            videoToken: null, 
                            videoUserID: null,
                            appointmentID: appointmentID,
                            doctorFirstName: doctorFirstName,
                            doctorLastName: doctorLastName,
                            doctorTitle: doctorTitle,
                            doctorImg: doctorImg,
                            chatChannel: response.chat_channel,
                            chatToken: response.chat_token,
                            chatUId: response.chat_user_id,
                            chatAppId: response.app_id,
                            patientImg: profile.pictureUrl,
                        } })
                    }


                    // navigate('/videocall', { state: { 
                    //     graphqlToken: response.graphQL_token,
                    //     meetingStatus: response.meeting,
                    //     options: {
                    //         appId:  !!response.app_id ? response.app_id : null,
                    //         channel: !!response.video_channel ? response.video_channel : null,
                    //         token: !!response.video_token ? response.video_token : null,
                    //         uid: !!response.video_user_id ? response.video_user_id : 2,
                    //     },
                    //     chatOptions: {
                    //         chatChannel: response.chat_channel ,
                    //         chatUId: response.chat_user_id ,
                    //         chatAppId: response.app_id ,
                    //         chatToken: response.chat_token ,
                    //     },
                    //     appointmentID: appointmentID, 
                    //     doctorFirstName: doctorFirstName, 
                    //     doctorLastName: doctorLastName, 
                    //     doctorTitle: doctorTitle, 
                    //     doctorImg: doctorImg 
                    // }})

                    setStatusRoom(response.meeting?'room_start':'waiting_room')
                    localStorage.setItem('currentStatusRoom',response.meeting?'room_start':'waiting_room')
            
                })
                .catch((err)=> { console.log(err); setModalErr(true); })
        } else {
            setErrMsg('ไม่สามารถทำรายการได้')
            setModalErr(true)
        }

        // navigate('/videowaitingroom', { state: {  
       
    }

    Modal.setAppElement('#root');

    return (
        <div>
            <button type={'button'} onClick={(e)=> fetchEnterWaitingRoom(e)} className={`flex justify-center items-center rounded-lg font-promptsemibold p-3 text-white text-center w-full bg-primary ${addclass}`}>
                { icon ? <img src={IconCamera} alt={'icon'} className="mr-2" /> : null }
                {'เริ่มสนทนา'}
            </button>

            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false); navigate('/')}} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

        </div>
    )
}

export default BtnEnterVideoRoom