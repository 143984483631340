import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { TokenContext } from '../../App'
import {
  BtnPrimary,BtnTransparent
} from '../../components/FormItems'

import Nav from '../../components/Nav'

import IconBack from '../../assets/img/ic-back.svg'
import ItemDoctor from '../../components/ItemDoctor'
import IconFailed from '../../assets/img/ic-failed.svg'


let listArr = []
let callingApi = false
let option = ''
const startAddValue = 20 // for Lazy load
const countValue = 20 // for Lazy load
let searchInput = {
    search: '',
    start: 0, // ''
    count: countValue, // ''
    id: '',
  }

function ResultHealthCheck() {
    const scrollRef = useRef(null)
    const navigate = useNavigate()
    const { token, setCurrentPage } = useContext(TokenContext)
    // const [loading, setLoading] = useState(true)
    const [modalErr, setModalErr] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg /*, setErrSubMsg*/] = useState('กรุณาลองใหม่อีกครั้ง')

    const [fetchMsgAppointment, setFetchMsgAppointment] = useState('...Loading...')

    const [resulthealthcheck, setResulthealthcheck] = useState({})
    const [resulthealthcheckList, setResulthealthcheckList] = useState([])
    const [isLazyLoading, setIsLazyLoading] = useState(false)

    const fetchResultHealthCheckList = useCallback( async(type, startlazy) => {
        // setLoading(true)
        callingApi = true
        searchInput.start = searchInput.start + (startlazy ? Number(startlazy) : 0);

        if(searchInput.start || searchInput.start === 0) {
            option = option+`&start=${searchInput.start}`
        }
        if(searchInput.count) {
            option = option+`&count=${searchInput.count}`
        }
        if(searchInput.id) {
            option = option+`&id=${searchInput.id}`
        }


        const resResultHealthCheck = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/result${!!option?'?'+option.substring(1):''}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + token
            }
        });

        if(resResultHealthCheck.status === 200) {
            resResultHealthCheck
            .json()
            .then((res)=>{
                callingApi = false

                if(res) {
                    // setFetchMsg('')
                    if(!res.total) {
                        setFetchMsgAppointment('ไม่พบข้อมูล')
                    }
                    setResulthealthcheck(res)
                    searchInput['id'] = res.id

                    res.data.map((item,i)=>{
                        return listArr.push(item)
                    })

                    if(type==='lazy') {
                        setResulthealthcheckList(listArr)

                        // scrollRef.current.scrollTo({
                        //     top: 100,
                        //     behavior: 'smooth'
                        // })
                        // fixScollbar()
                    } else {
                        setResulthealthcheckList(res.data)
                    }
                } else {
                    setFetchMsgAppointment('ไม่พบข้อมูล')
                }

                // setLoading(false)
                setIsLazyLoading(false)

            }).catch((err)=> { 
                console.log(err); 
                // setLoading(false); 
                /*navigate('/');*/ 
                setModalErr(true); 
            })
        
        } 

        // clear param
        option = ''

    },[token])

    useEffect(() => {
        // (async()=>{
            // setLoading(true)
            setCurrentPage('/resulthealthcheck')

            // check token
            if(!token) {
                navigate('/')
            }
            
            // if(!callingApi) {
                fetchResultHealthCheckList('list',searchInput.start)
            // }
        // })()
    
        return () => {
            listArr = []
            option = '' // clear fetch option
            searchInput = {
                search: '',
                start: 0, // ''
                count: countValue, // ''
                id: '',
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, setCurrentPage, token])

    const gotoSummaryAppointment = (id) => {
        navigate('/summaryappointment', {
          state: {
            appointment_id: id,
            from_historyappointment: 1,
            type: 'fromresulthealthcheck'
          }
        })
      }

    // Lazy Scrolling 
    const scrolling = () => {

        let scrollH = scrollRef.current.scrollHeight
        let scrollT = scrollRef.current.scrollTop
        let elementH = scrollRef.current.clientHeight
        let scrollD = Math.ceil(scrollT + elementH) + 180

        if(resulthealthcheckList.length >= resulthealthcheck.total) {
            setIsLazyLoading(false)
        } else {
            // console.log('scrollD: ',scrollD)
            // console.log('scrollH: ',scrollH)
            // Scrolling
            if(scrollD >= scrollH) {
                // Scroll Finish
                // alert('Scroll Finish!!')
                setIsLazyLoading(true)

                if(!callingApi) {
                    fetchResultHealthCheckList('lazy',startAddValue) 
                }
            } else {
                setIsLazyLoading(false)
            }
        }
    }
        
    Modal.setAppElement('#root');

    return (
        <div className="p-4 bg-form-bg relative"> 
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ navigate('/home')} }/>
        
            {/* {
            loading ? 
                <p className="text-center grid place-items-center text-gray-2 w-full" style={{height: window.innerHeight - 120+'px' }}>...Loading...</p>
                :  */}
                <div>
                    <div className="mt-2">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-row">
                            <h1 className="font-promptsemibold">ผลการตรวจ</h1>
                            </div>
                        </div>
                        <div id="control-list-box" className="overflow-y-auto mt-2" ref={scrollRef} onScroll={scrolling}  style={isLazyLoading ? {height: window.innerHeight-157+'px', overflowY: 'hidden', overflowX: 'hidden'} : {height: window.innerHeight-157+'px', overflowY: 'scroll', overflowX: 'hidden'}}>
                        {
                            resulthealthcheck?.total ?
                            resulthealthcheckList?.map((item,i)=> {
                                return (        
                                <ItemDoctor key={i} dataItem={item} type="appointment" onclick={()=> gotoSummaryAppointment(item.id)}  />
                                )
                            })
                            : <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsgAppointment }}></p>
                        }
                        </div>
                    </div>
                    {
                      isLazyLoading ? <p className="text-center mt-8 text-gray-2 w-full">...Loading...</p> : null
                    }
                </div>
            {/* } */}

            {/* Nav */}
            <Nav currentPage="resulthealthcheck" />

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} /> 
            </Modal>

            {
                isLazyLoading ? <div className="absolute z-40 bg-black/25 top-0 left-0 w-full h-screen flex items-center justify-center text-white">...Loading...</div> : null
            }
        </div>
    )
}

export default ResultHealthCheck