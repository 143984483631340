import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LiffProvider } from 'react-liff'

// const liffId = process.env.REACT_APP_LIFF_ID;
let liffId = localStorage.getItem('liff_id') || ''
let clinicName = localStorage.getItem('clinic_name') || process.env.REACT_APP_TITLE

// Get & Set LIFF ID
const urlSearchParams = new URLSearchParams(window.location.search);

if(urlSearchParams.has('clinic_name')) {
  clinicName = urlSearchParams.get('clinic_name')

  if(clinicName) {
    localStorage.setItem('clinic_name',clinicName)
  }
  console.log('index clinic_name: ', clinicName)

} else {
  console.log('has not clinic_name')
}

if(urlSearchParams.has('id')) {
  liffId = urlSearchParams.get('id')

  if(liffId) {
    localStorage.setItem('liff_id',liffId)
  }
  console.log('index liff_id: ', liffId)

} else {
  console.log('has not liff_id')
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <LiffProvider liffId={liffId}>
      <App />
    </LiffProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
