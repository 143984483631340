import React, { useContext, useRef, useState } from 'react'
import useFileUpload from 'react-use-file-upload';
import axios from 'axios';
import {
  BtnPrimary, BtnSecondary,
} from '../../components/FormItems'

import { TokenContext } from '../../App'

function VideoFileUpload(props) {
    const { token } = useContext(TokenContext)
    const { appointmentID } = props
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        // totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    let fileName = ''
    const [/*fileDownloadName,*/ setFileDownloadName] = useState('')
    const [/*fileDownloadType,*/ setFileDownloadType] = useState('')
    const [/*fileDownloadNameOriginal,*/ setFileDownloadNameOriginal] = useState('')
    
    const inputRef = useRef();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = createFormData();

        for (let i=0; i<files.length; i++) {
            formData.append('file',files[i])
            console.log('file', files[i])
        }
        
    
        try {
            axios.post(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file`, formData, {
                headers: {
                    // 'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                }
            })

            .then(function (response) {
                console.log(response);
                setFileDownloadName(response.data.filename)
                setFileDownloadType(response.data.mime_type)
                setFileDownloadNameOriginal(response.data.orig_filename)

                fileName = response.data.filename

                console.log(fileName)
            })
            .catch(function (error) {
                console.log(error);
            });

            // const resFileUpload = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file`, {
            //     method: 'POST',
            //     body: formData,
            //     headers: {
            //         "Content-Type": "multipart/form-data",
            //         // "Accept": "application/json",
            //         'Authorization': 'Bearer ' + token
            //     }
            // });

            // resFileUpload
            //     .json()
            //     .then((response)=> {
            //         console.log(response)
            //     })

        } catch (error) {
          console.error('Failed to submit files.');
        }
    };

    // const handleDownload = async (e, f) => {
    //     e.preventDefault();

    //     try {
    //         axios.get(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/chat/file?filename=${fileDownloadName}`, {
    //             headers: {
    //                 // 'content-type': 'multipart/form-data',
    //                 'Authorization': 'Bearer ' + token
    //             }
    //         })

    //         .then(response => {
    //             console.log(response);
    //         })
    //         .then(blob => {
                
    //         })
    //         .catch( error => {
    //             console.log(error);
    //         });

    //     } catch (error) {
    //       console.error('Failed to submit files.');
    //     }
    // }

    return (
        <div className="mt-6">
            {/* <h1>Upload Files</h1> */}

            {/* <p className="mb-4">กรุณาเลือกไฟล์เพื่ออัพโหลด</p> */}

            <div className="form-container mb-6">
                {/* Display the files to be uploaded */}
                <div>
                    {
                        fileNames.length ?
                        <ul>
                            {fileNames.map((name) => (
                                <li key={name}>
                                    <span>ชื่อไฟล์: {name}</span>

                                    <span onClick={() => removeFile(name)}>
                                    <i className="fa fa-times" />
                                    </span>
                                </li>
                            ))}
                        </ul>
                        : <p>กรุณากดปุ่มเลือกไฟล์เพื่อส่ง</p>
                    }
                    
                    
                    <div className="mb-2">
                        {files.length > 0 && (
                            <ul>
                                <li>ประเภท: {fileTypes.join(', ')}</li>
                                <li>ขนาด: {totalSize}</li>
                                {/* <li>File types found: {fileTypes.join(', ')}</li>
                                <li>Total Size: {totalSize}</li>
                                <li>Total Bytes: {totalSizeInBytes}</li> */}

                                <li className="clear-all mt-4 mb-2">
                                    {/* <button onClick={() => clearAllFiles()}>Clear All</button> */}
                                    <BtnSecondary type="button" text="ลบทั้งหมด" addclass="text-black" action={() =>  clearAllFiles() } />
                                </li>
                            </ul>
                        )}
                    </div>
                </div>

                {/* Provide a drop zone and an alternative button inside it to upload files. */}
                <div
                    onDragEnter={handleDragDropEvent}
                    onDragOver={handleDragDropEvent}
                    onDrop={(e) => {
                        handleDragDropEvent(e);
                        setFiles(e, 'a');
                    }}
                >
                    {/* <p>Drag and drop files here</p> */}

                    {/* <button onClick={() => inputRef.current.click()}>เลือกไฟล์</button> */}
                    {
                        !files.length ? <BtnSecondary type="button" text="เลือกไฟล์" addclass="text-black" action={() => inputRef.current.click()}/> : null 
                    }

                    {/* Hide the crappy looking default HTML input */}
                    <input
                        ref={inputRef}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={(e) => {
                        setFiles(e, 'a');
                        inputRef.current.value = null;
                        }}
                    />
                </div>
            </div>

            {
                files.length > 0 ?
                <div className="submit">
                    {/* <button onClick={handleSubmit}>อัพโหลดไฟล์</button> */}
                    <BtnPrimary type="button" text="ส่ง" action={(e)=> {handleSubmit(e)}} />
                </div>
                : null
            }
           

            {/* <div className="submit">
                {/* <button onClick={handleSubmit}>อัพโหลดไฟล์</button> */}
            {/*    <BtnPrimary type="button" text="ดาวน์โหลด" action={(e)=> {handleDownload(e, fileName)}} />
            </div> */}
      </div>
    )
}

export default VideoFileUpload