import React, { useContext, useEffect, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal'
import { TokenContext } from '../../App'
import Nav from '../../components/Nav'
// import SearchAutoComplete from '../../components/SearchAutoComplete'
import PVData from '../../DataProvinces.json'
import APData from '../../DataDistricts.json'
// import TBData from '../../DataSubDistricts.json'
import { BtnPrimary, BtnSecondary, BtnTransparentIconAfter, /*InputTel*/ } from '../../components/FormItems';

// import { format } from 'date-fns'
// import th from "date-fns/locale/th";
import moment from 'moment'

import IconFailed from '../../assets/img/ic-failed.svg'
import IconInfo from '../../assets/img/ic-info.svg'
import IconProfileVdoWhite from '../../assets/img/ic-profile-vdo-white.svg'
import IconEdit from '../../assets/img/ic-edit.svg'
import IconEmail from '../../assets/img/ic-email-active.svg'
import IconEmailRequired from '../../assets/img/ic-email-required.svg'
import IconSuccess from '../../assets/img/ic-success.svg'

function Profile() {
    const { token, setCurrentPage } = useContext(TokenContext)
    const navigate = useNavigate()
    const [modalConfirmTerm, setModalConfirmTerm] = useState(false)
    const [modalConfirmPrivacy, setModalConfirmPrivacy] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)
    const [modalErr, setModalErr] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg /*, setErrSubMsg*/] = useState('กรุณาลองใหม่อีกครั้ง')


    const [profileData, setProfileData] = useState({})

    const { register, handleSubmit, reset, /*watch,*/ formState: { errors }} = useForm({});
    const { register: registerAddr, handleSubmit: handleSubmitAddr, /*watch,*/ /*formState: { errors: errorAddr }*/ } = useForm({});

    const [loading, setLoading] = useState(true)
    const [agreePrivacy, setAgreePrivacy] = useState(0)
    const [agreePrivacyId, setAgreePrivacyId] = useState('')
    const [agreeTermCondition, setAgreeTermCondition] = useState(0)
    const [agreeTermConditionId, setAgreeTermConditionId] = useState('')
    const [editProfile, setEditProfile] = useState(false)
    const [editAddress, setEditAddress] = useState(false)

    const [provinceSelect, setProvinceSelect] = useState(null)
    const [provinceSelectId, setProvinceSelectId] = useState(null)
    // const [districtSelect, setDistrictSelect] = useState(null)
    const [districtSelectId, setDistrictSelectId] = useState('')
    // const [/*subdistrictSelect,*/ setSubdistrictSelect] = useState(null)
    // const [subdistrictOnSearch, setSubdistrictOnSearch] = useState(null)

    // const [subdistrictOnFocus, setSubdistrictOnFocus] = useState(false)
  
    const [districtList, setDistrictList] = useState([])

    const listDay = [ 'วว',
        '01','02','03','04','05','06','07','08','09','10',
        '11','12','13','14','15','16','17','18','19','20',
        '21','22','23','24','25','26','27','28','29','30','31'
    ]

    const listMonth = [ 'ดด',
        'ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'
    ]

    const listYears = (amount) => {
        var max = new Date().getFullYear()
        var min = max - amount
        var years = ['ปปปป']

        for (var i = max; i >= min; i--) {
        years.push(i)
        }
        return years
    }

    const convertDate = (value) => {
        if(!!value) {
            let selectYearValue = Number(moment(value).format('yyyy')) + 543
            let result = moment(value).format('DD') + '/' + moment(value).format('MM') + '/' + selectYearValue
            return result
        } else {
            return ''
        }
    }


    // const handleSubdistrictSelect = useCallback( (item) => {
    //     // console.log(item)
    //     // setSubdistrictOnSearch(null)
    //     // setSubdistrictSelect(item.name)
    // },[])


    const fetchProfile = useCallback(async () => {
        try {
            setLoading(true)

            const resProfile = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                }
            });
            console.log('Profile: Status[',resProfile.status,']',resProfile)

            resProfile
                .json()
                .then((res)=>{
                    setProfileData(res)

                    // Add default value : privacy, term
                    setAgreePrivacy(res.consent_privacy)
                    setAgreeTermCondition(res.consent_term)
                    setAgreePrivacyId(res.consent_privacy_id)
                    setAgreeTermConditionId(res.consent_term_id)

                    // Add default value : gender, birth_date
                    reset({
                        gender: res.gender,
                        birth_date: res.birth_date,
                        birth_date_dd: moment(res.birth_date).format('DD'),
                        birth_date_mm: Number(moment(res.birth_date).format('MM')),
                        birth_date_yyyy: Number(moment(res.birth_date).format('YYYY'))
                    })

                    // Add default value : subdistrict
                    // for(let i=0; i<TBData.length; i++) {
                    //     if(TBData[i].name === res.subdistrict) {
                    //         handleSubdistrictSelect(
                    //             TBData[i]
                    //         )
                    //     }
                    // }

                    // Add default value : province
                    for(let i=0; i<PVData.length; i++) {
                        if(res.province === PVData[i].name) {
                            // console.log(PVData[i].id)
                            setProvinceSelectId(PVData[i].id)
                            // handleProvinceSelectOptionFromFetch(PVData[i].id)

                             // Add default value : district
                            for(let j=0; j<APData.length; j++) {
                                if((res.district === APData[j].name) && (PVData[i].id === APData[j].ProvinceID)) {
                                    // console.log(APData[j].id)
                                    setDistrictSelectId(APData[j].id)
                                    // handleProvinceSelectOptionFromFetch(PVData[i].id)
                                } 
                            }
                        } 
                    }

                   

                    // handleDistrictSelectOptionFromFetch(res.district)

                    // console.log(moment(res.birth_date).format('DD'))
                    // console.log(Number(moment(res.birth_date).format('MM')))
                    // console.log(Number(moment(res.birth_date).format('YYYY')) + 543)

                    setLoading(false)
                }).catch((err)=> { console.log(err); setLoading(false); /*navigate('/');*/ setModalErr(true); })
            
        } catch (error) {
            setModalErr(true)
            setLoading(false)
            // navigate('/');
        }
    },[reset, token])

    useEffect(() => {
        (async()=>{
            setLoading(true)
            setCurrentPage('/profile')

            // check token
            if(!token) {
            navigate('/')
            }
            
            fetchProfile()
        })()
    
        return () => {
            
        }
    }, [fetchProfile, navigate, setCurrentPage, token])

    const handleConsentTermCondition = (e) => {
        // setHasErr(false)
        // setAgreeTermCondition(e.target.checked ? 1 : 0)
        setModalConfirmTerm(true)
    }

    const handleConsentPrivacy = (e) => {
        // setHasErr(false)
        // setAgreePrivacy(e.target.checked ? 1 : 0)
        setModalConfirmPrivacy(true)
    }

    const handleUpdateCondition = (name) => {
        let dataPrivacy = {
            "consent_privacy": agreePrivacy ? 0 : 1,
            "consent_privacy_id": agreePrivacyId
        }
        let dataTerm = {
            "consent_term": agreeTermCondition ? 0 : 1,
            "consent_term_id": agreeTermConditionId
        }

        let data = {}
        if(name==='privacy') {
            data = dataPrivacy
            agreePrivacy ? setAgreePrivacy(0) : setAgreePrivacy(1)
        } else {
            data = dataTerm
            agreeTermCondition ? setAgreeTermCondition(0) : setAgreeTermCondition(1)
        }
        
        fetchUpdateProfile(data, 'condition')
        setModalConfirmTerm(false)
        setModalConfirmPrivacy(false)
    }
    // const handleSubdistrictOnSearch = (string, results) => {
    //     // console.log(string, results)
    //     setSubdistrictSelect(null)
    //     // setSubdistrictOnSearch(string, results)
    // }

    const handleDistrictSelectOption = (e) => {
        let AP_ID = Number(e.target.value)
        // console.log('AP_ID:',AP_ID)
        if(AP_ID) {
          setDistrictSelectId(AP_ID)
        } else {
          setDistrictSelectId(0)
        }
    }

    // const handleDistrictSelectOptionFromFetch = (value) => {
    //     let AP_NAME = value
    //     if(AP_NAME) {
    //       setDistrictSelect(AP_NAME)
    //     } else {
    //       setDistrictSelect(null)
    //     }
    // }
    
    const handleProvinceSelectOption = (e) => {
        // setDistrictSelect(null)
        // console.log(e.target.value)
        let PV_ID = e.target.value
        if(PV_ID) {
          for(let i=0; i<PVData.length; i++) {
            if(Number(PV_ID) === PVData[i].id) {
              setProvinceSelect(PVData[i].name)
            } 
          }
        } else {
          setProvinceSelect(null)
        }
        
        // get district list
        let APlist = []
        for(let i=0; i<APData.length; i++) {
          if(Number(PV_ID) === APData[i].ProvinceID) {
            APlist.push({'name': APData[i].name, 'id': APData[i].id})
          }
        }
        setDistrictList(APlist)
        setDistrictSelectId(0)
    }

    const handleProvinceSelectOptionFromFetch = (value) => {
        // setDistrictSelect(null)
        let PV_ID = value
        if(PV_ID) {
            for(let i=0; i<PVData.length; i++) {
                if(Number(PV_ID) === PVData[i].id) {
                    // console.log(PVData[i].name)
                    setProvinceSelect(PVData[i].name)
                } 
            }
        } else {
            setProvinceSelect(null)
            setProvinceSelectId(null)
        }
        
        // get district list
        let APlist = []
        for(let i=0; i<APData.length; i++) {
            if(Number(PV_ID) === APData[i].ProvinceID) {
                APlist.push({'name': APData[i].name, 'id': APData[i].id})
            }
        }
        setDistrictList(APlist)
    }
    
    const onSubmitProfile = async (data) => {
        if(data.birth_date_mm < 10 && data.birth_date_mm >= 1) {
            data.birth_date_mm = '0'+data.birth_date_mm
        }
    
        if(!data.birth_date_yyyy || !data.birth_date_mm || !data.birth_date_dd) {
            // console.log('invalid date')
            data.birth_date = null
        } else {
            // console.log('correct date')
            data.birth_date = data.birth_date_yyyy+'-'+ data.birth_date_mm +'-'+ data.birth_date_dd
        }

        delete data.birth_date_dd
        delete data.birth_date_mm
        delete data.birth_date_yyyy

        // console.log(data)
        setEditProfile(false)

        fetchUpdateProfile(data)
    }

    const onSubmitAddress = async (data) => {
        // setSubdistrictOnFocus(false)

        let districtSelectName = ''
        // Add default value : district
        // console.log(data)
        for(let i=0; i<APData.length; i++) {
            if(districtSelectId === APData[i].id) {
                // console.log(PVData[i].id)
                // setDistrictSelectId(APData[i].id)
                districtSelectName = APData[i].name
                // handleProvinceSelectOptionFromFetch(PVData[i].id)
            } 
        }

        // data.subdistrict = (subdistrictSelect) ? subdistrictSelect : subdistrictOnSearch
        // data.subdistrict = (subdistrictSelect=== '') ? null : subdistrictSelect
        data.district = (districtSelectName === '') ? null : districtSelectName
        data.province = (provinceSelect === '') ? null : provinceSelect

        // console.log(data)
        setEditAddress(false)

        fetchUpdateProfile(data)
    }

    const fetchUpdateProfile = async (data, type) => {
        // console.log(data)
        try {
            setLoading(true)

            const resUpdateProfile = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + token
                }
            });
            console.log('Profile: Status[',resUpdateProfile.status,']',resUpdateProfile)

            if(resUpdateProfile.status === 200) {
                setLoading(false)
                setModalSuccess(true)

                if(type==='condition') { navigate('/') }
            }
            
        } catch (error) {
            setModalErr(true)
            setLoading(false)
        }


    }

    const gotoConditionDetail = (name, id) => {
        navigate('/conditiondetail', { state: { conditionName: name, conditionId: id} })
    }

    Modal.setAppElement('#root');

    return (
        <div className="">
            {
                loading ? 
                <p className="text-center grid place-items-center text-gray-2 w-full" style={{height: window.innerHeight - 80+'px' }}>...Loading...</p>
                : 
                <div>
                    <div className=" bg-green-soft-light pt-20">

                    </div>
                    <div className="header">
                        <div className="relative bg-white h-full">
                            <div className="bg-green-soft rounded-full -my-16 mx-auto overflow-hidden absolute left-0 right-0 top-0 bottom-0" style={{width: '6rem', height:  '6rem', zIndex: '1'}}>
                                <img src={profileData.line_picture || IconProfileVdoWhite} alt="icon" className="absolute left-0 right-0 top-0 bottom-0 m-auto" />
                            </div>
                            <div className="pt-10 pb-5">
                                <p className="text-center">{profileData.first_name} {profileData.last_name}</p>
                            </div>
                        </div>
                    </div>
            
                    <div className="overflow-auto" style={{height: window.innerHeight - 220+'px'}}>
                        {
                            !editProfile ?
                            <div className="detail px-3">
                                <div className="flex justify-between pt-3">
                                    <p className="font-promptsemibold">ชื่อ</p>
                                    <p className="text-gray">{profileData.first_name}</p>
                                </div>
                                <div className="flex justify-between pt-3">
                                    <p className="font-promptsemibold">นามสกุล</p>
                                    <p className="text-gray">{profileData.last_name}</p>
                                </div>
                                <div className="flex justify-between pt-3">
                                    <p className="font-promptsemibold">เพศ</p>
                                    <p className="text-gray">{profileData.gender}</p>
                                </div>
                                <div className="flex justify-between pt-3">
                                    <p className="font-promptsemibold">วัน/เดือน/ปีเกิด(พ.ศ.)</p>
                                    <p className="text-gray">{convertDate (profileData.birth_date)}</p>
                                </div>
                                <div className="flex justify-between pt-3">
                                    <p className="font-promptsemibold">อีเมล</p>
                                    <p className="text-gray">{profileData.email}</p>
                                </div>
                                {/* <div className="flex justify-between pt-3">
                                    <p className="font-promptsemibold">เบอร์โทรศัพท์</p>
                                    <p className="text-gray">{profileData.telephone}</p>
                                </div> */}
                                <div className="pt-3 pb-3 border-b border-gray-light">
                                    <BtnTransparentIconAfter text="แก้ไขโปรไฟล์" icon={IconEdit} addclass="text-darkblue m-auto" action={()=> setEditProfile(true) } />
                                </div>
                            </div>
                            :
                            <div className="detail px-3">
                                <form onSubmit={handleSubmit(onSubmitProfile)}>
                                    {/* Input First Name */}
                                    <div className="flex flex-col text-left mb-3">
                                        <label className="font-promptsemibold mb-2">ชื่อ</label>
                                        <input 
                                            defaultValue={profileData.first_name}
                                            placeholder="ชื่อจริง" 
                                            className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                                            {...register("first_name", { required: true })} 
                                        />
                                        {
                                        errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                                        }
                                    </div>

                                    {/* Input Last Name */}
                                    <div className="flex flex-col text-left mb-3">
                                        <label className="font-promptsemibold mb-2">นามสกุล</label>
                                        <input 
                                            defaultValue={profileData.last_name}
                                            placeholder="นามสกุล" 
                                            className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.last_name?.type === "required" && 'border-danger'}`}
                                            {...register("last_name", { required: true })} 
                                        />
                                        {
                                        errors?.last_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                                        }
                                    </div>

                                    {/* Choose Gender */}
                                    <p className="font-promptsemibold mb-2">เพศ</p>
                                    <div className="flex flex-row text-left mb-3">
                                        <label className="flex container-radio-btn" htmlFor="gender-male">
                                            ชาย
                                            <input
                                                {...register("gender")}
                                                type="radio"
                                                value="male"
                                                id="gender-male"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="flex container-radio-btn" htmlFor="gender-female">
                                            หญิง
                                            <input
                                                {...register("gender")}
                                                type="radio"
                                                value="female"
                                                id="gender-female"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="flex container-radio-btn" htmlFor="gender-other">
                                            ไม่ระบุ
                                            <input
                                                {...register("gender")}
                                                type="radio"
                                                value="unspecified"
                                                id="gender-other"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    {/* Input Birth Date */}
                                    <p className="font-promptsemibold mb-2">วัน / เดือน / ปีเกิด (พ.ศ.)</p>
                                    <div className="text-left mb-3">
                                        <div className="flex flex-row ">
                                            <div className="select-section grow">
                                                {/* <select className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0`} value={birthDate.dd} name="dd" onChange={handleBirthDate}> */}
                                                <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.birth_date_dd?.type === "required" && 'border-danger')}`} {...register("birth_date_dd" /*, { required: true } */)}  >
                                                    {
                                                    listDay.map((item,i)=> {
                                                        return (
                                                        <option key={i} value={(item==='วว')?'':item}>{item}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                            
                                            <div className="select-section grow mx-1.5">
                                                {/* <select className={`p-2.5 pr-6 mx-1.5 mb-1 w-full border border-form-bd rounded-lg outline-0`} value={birthDate.mm} name="mm" onChange={handleBirthDate}> */}
                                                <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.birth_date_mm?.type === "required" && 'border-danger')}`} {...register("birth_date_mm" /*, { required: true } */)}>
                                                    {
                                                    listMonth.map((item,i)=> {
                                                        return (
                                                        <option key={i} value={(item==='ดด')?'':i}>{item}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>

                                            <div className="select-section grow">
                                                {/* <select className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0`}  value={birthDate.yyyy} name="yyyy" onChange={handleBirthDate}> */}
                                                <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${(errors?.birth_date_yyyy?.type === "required" && 'border-danger')}`} {...register("birth_date_yyyy" /*, { required: true } */)}>
                                                    {
                                                    listYears(100).map((item,i)=> {
                                                        return (
                                                        <option key={i} value={(item==='ปปปป')?'': item}>{(item==='ปปปป')? item : item + 543}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {
                                        errors?.birth_date_dd?.type === "required" && <p className="text-xs text-danger">กรุณาเลือกวันเกิด</p>
                                        }
                                        {
                                        errors?.birth_date_mm?.type === "required" && <p className="text-xs text-danger">กรุณาเลือกเดือนเกิด</p>
                                        }
                                        {
                                        errors?.birth_date_yyyy?.type === "required" && <p className="text-xs text-danger">กรุณากรอกปีเกิด (พ.ศ.)</p>
                                        }
                                    </div>

                                    {/* Input Email */}
                                    <div className="flex flex-col text-left mb-5">
                                        <label className="font-promptsemibold mb-2">อีเมล</label>
                                        <div className={`flex flex-row mb-1 border border-form-bd rounded-lg ${(errors?.email?.type === "required" && 'border-danger') || (errors?.email?.type === "pattern" && 'border-danger') }`}>
                                        <div className="flex pl-3 pr-1">
                                            <img src={IconEmail} alt="icon email" className={`ic-email ${(errors?.email?.type === "required" && 'true') || (errors?.email?.type === "pattern" && 'true') }`} />
                                            <img src={IconEmailRequired} alt="icon email" className={`ic-email-required ${(errors?.email?.type === "required" && 'true') || (errors?.email?.type === "pattern" && 'true') }`}  />
                                        </div>
                                        <input 
                                            defaultValue={profileData.email}
                                            placeholder="ระบุอีเมล" 
                                            className={`p-2.5 pr-6 w-full rounded-r-lg outline-0 `}
                                            {...register("email", { pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                            }, /*required: true */ })} 
                                        />
                                        </div>
                                        {
                                        errors?.email?.type === "pattern" && <p className="text-xs text-danger">กรุณากรอกอีเมลให้ถูกต้อง</p>
                                        }
                                        {
                                        errors?.email?.type === "required" && <p className="text-xs text-danger">กรุณากรอกอีเมล</p>
                                        }
                                    </div>

                                    {/* Input Telephone
                                    <div className="flex flex-col text-left mb-5">
                                        <label className="font-promptsemibold mb-2">เบอร์โทรศัพท์</label>
                                        <InputTel 
                                            defaultvalue={profileData.telephone}
                                            type="tel" addclass="w-full rounded-l-lg" 
                                            textplace="Mobile Number" 
                                            onControl={ register("telephone", { required: true, minLength: {value: 10 }, maxLength: { value: 10 }, pattern: /^[0-9]+$/i  }) }/> */}
                                        {/* error message */}
                                        {/* {errors.telephone?.type === 'required' && <p className="text-danger text-xs">กรุณาใส่หมายเลขโทรศัพท์มือถือ</p>}
                                        {errors?.telephone?.type === 'minLength' && <p className="text-danger text-xs">กรุณาใส่จำนวนตัวเลข 10 หลัก</p>}
                                        {errors?.telephone?.type === 'maxLength' && <p className="text-danger text-xs">กรุณาใส่จำนวนตัวเลขไม่เกิน 10 หลัก</p>}
                                        {errors?.telephone?.type === 'pattern' && <p className="text-danger text-xs">กรุณาใส่ตัวเลข 0-9.</p>}
                                    </div> */}

                                    <div className="pt-3 pb-3 border-b border-gray-light grid grid-cols-2 gap-2">
                                        <BtnSecondary type="button" text="ยกเลิก" action={()=> setEditProfile(false) } />
                                        <BtnPrimary type="submit" text="ยืนยัน" />
                                    </div>
                                </form>
                            </div>
                        }
                        <div className="address px-3">
                            <div className="flex flex-col pt-6">
                                <p className="font-promptsemibold">ที่อยู่</p>
                                
                                {
                                    !editAddress ?
                                    <div className="mt-3">
                                        <p className="text-gray">
                                            {profileData.house_no} {profileData.soi} {profileData.road} {profileData.subdistrict} {profileData.district} {profileData.province}&nbsp;
                                        </p>
                                        <div className="pt-3 pb-3 border-b border-gray-light">
                                            <BtnTransparentIconAfter text="แก้ไขที่อยู่" icon={IconEdit} addclass="text-darkblue m-auto" action={()=> { setEditAddress(true);  handleProvinceSelectOptionFromFetch(provinceSelectId) }} />
                                        </div>
                                    </div>
                                    :
                                    <div className="mt-3">
                                        <form onSubmit={handleSubmitAddr(onSubmitAddress)}>
                                            {/* Input Address */}
                                            <div className="flex flex-col text-left mb-3">
                                                <input 
                                                    defaultValue={profileData.house_no}   
                                                    placeholder="บ้านเลขที่ / อาคาร / หมู่บ้าน" 
                                                    className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 `}
                                                    {...registerAddr("house_no")} 
                                                />
                                                <div className="flex flex-row mt-1">
                                                <input 
                                                    defaultValue={profileData.soi}
                                                    placeholder="ซอย" 
                                                    className={`p-2.5 pr-6 mb-1 mr-1.5 w-1/2 border border-form-bd rounded-lg outline-0 `}
                                                    {...registerAddr("soi")} 
                                                />
                                                <input 
                                                    defaultValue={profileData.road}
                                                    placeholder="ถนน" 
                                                    className={`p-2.5 pr-6 mb-1 w-1/2 border border-form-bd rounded-lg outline-0 `}
                                                    {...registerAddr("road")} 
                                                />
                                                </div>
                                                <div className="flex flex-row mt-1">
                                                    <div className="mb-1 w-1/2 mr-1.5 select-section">
                                                        {/* <SearchAutoComplete zindex="3" dataList={PVData} placeholder="จังหวัด" actionSelect={handleProvinceSelect} actionOnSearch={handleProvinceOnSearch} /> */}
                                                        <select className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 ${!!provinceSelect?'':'text-gray-3'}`} defaultValue={provinceSelectId} onChange={handleProvinceSelectOption}>
                                                            <option value={''}>จังหวัด</option>
                                                            {
                                                                PVData.map((item,i)=> {
                                                                    return (
                                                                        <option key={i} value={item.id}>{item.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="mb-1 w-1/2 select-section">
                                                        {/* <SearchAutoComplete zindex="4" dataList={APData} placeholder="เขต/อำเภอ" actionSelect={handleDistrictSelect} actionOnSearch={handleDistrictOnSearch} /> */}
                                                        
                                                        {
                                                        !!provinceSelect ?
                                                        <select value={districtSelectId} className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 `} onChange={handleDistrictSelectOption}>
                                                            <option value={0}>เขต/อำเภอ</option>
                                                            {
                                                            districtList.map((item,i)=> {
                                                                return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                        :
                                                        <select style={{pointerEvents: 'none'}} className={`p-2.5 pr-6 mb-1 w-full bg-white border border-form-bd rounded-lg outline-0 disabled:bg-gray-light disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-gray-3`} >
                                                            <option value={''}>เขต/อำเภอ</option>
                                                        </select>
                                                        }
                                                    </div>
                                                    {/* <input 
                                                        placeholder="ตำบล" 
                                                        className={`p-2.5 pr-6 mb-1 mr-1.5 w-1/2 border border-form-bd rounded-lg outline-0 `}
                                                        {...registerAddr("subdistrict")} 
                                                    />
                                                    <input 
                                                        placeholder="อำเภอ" 
                                                        className={`p-2.5 pr-6 mb-1 w-1/2 border border-form-bd rounded-lg outline-0 `}
                                                        {...registerAddr("district")} 
                                                    /> */}
                                                </div>
                                                <div className="flex flex-row mt-1">
                                                    <div className="mb-1 w-1/2 mr-1.5">
                                                        <input 
                                                            defaultValue={profileData.subdistrict}
                                                            placeholder="แขวง/ตำบล" 
                                                            className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 `}
                                                            {...registerAddr("subdistrict")} 
                                                        />
                                                        {/* {
                                                            !subdistrictOnFocus ?
                                                            <input 
                                                                defaultValue={profileData.subdistrict}
                                                                placeholder="แขวง/ตำบล" 
                                                                className={`p-2.5 pr-6 mb-1 mr-1.5 w-full border border-form-bd rounded-lg outline-0 `}
                                                                {...registerAddr("subdistrict")} 
                                                                onFocus={()=> setSubdistrictOnFocus(!subdistrictOnFocus)}
                                                            />
                                                            :
                                                            <SearchAutoComplete zindex="5" dataList={TBData} placeholder="แขวง/ตำบล" actionSelect={handleSubdistrictSelect} actionOnSearch={handleSubdistrictOnSearch} inputSearchString={profileData.subdistrict} />
                                                        } */}
                                                    </div>
                                                    {/* <input 
                                                        placeholder="จังหวัด" 
                                                        className={`p-2.5 pr-6 mb-1 mr-1.5 w-1/2 border border-form-bd rounded-lg outline-0 `}
                                                        {...registerAddr("province")} 
                                                    /> */}
                                                    <input 
                                                        placeholder="รหัสไปรษณีย์" 
                                                        className={`p-2.5 pr-6 mb-1 w-1/2 border border-form-bd rounded-lg outline-0 `}
                                                        {...registerAddr("post")} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="pt-3 pb-3 border-b border-gray-light grid grid-cols-2 gap-2">
                                                <BtnSecondary type="button" text="ยกเลิก" action={()=> { setEditAddress(false); /*setSubdistrictOnFocus(false);*/ } } />
                                                <BtnPrimary type="submit" text="ยืนยัน" />
                                            </div>
                                        </form>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="condition px-3 pb-8 border-b border-gray-light">
                            <div className="flex pt-6">
                                <input type="checkbox" id="agreeTermCondition" checked={agreeTermCondition} onChange={handleConsentTermCondition} className="mr-2" />
                                <label htmlFor="agreeTermCondition" className="text-sm">ฉันยอมรับ </label>&nbsp;<span className="text-darkblue text-sm" onClick={()=> gotoConditionDetail('term', profileData.consent_term_id) }>ข้อกำหนดและเงื่อนไขการให้บริการ</span>
                            </div>
                            <div className="flex pt-3">
                                <input type="checkbox" id="agreePrivacy" checked={agreePrivacy} onChange={handleConsentPrivacy} className="mr-2" />
                                <label htmlFor="agreePrivacy" className="text-sm">ฉันยอมรับ </label>&nbsp;<span className="text-darkblue text-sm" onClick={()=> gotoConditionDetail('privacy', profileData.consent_privacy_id) }>นโยบายความเป็นส่วนตัว</span>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* Nav */}
            <Nav currentPage="profile" />

            {/* Modal Confirm Term */}
            <Modal
                isOpen={modalConfirmTerm}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconInfo} alt="icon info" className="mx-auto my-4 w-20" />
                <h1 className="font-promptsemibold mb-0 text-lg">คุณต้องการ{!agreeTermCondition?'ยอมรับ':'ยกเลิก'}</h1>
                <p className="text-gray-2 mb-4 text-md">ข้อกำหนดและเงื่อนไขการให้บริการ หรือไม่ ?</p>
                <div className="grid grid-cols-2 gap-2">
                <BtnSecondary type="button" text="ยกเลิก" addclass="text-black" action={()=> { setModalConfirmTerm(false) }} /> 
                <BtnPrimary type="button" text="ยืนยัน" action={()=> { handleUpdateCondition('term') }} /> 
                </div>
            </Modal>

            {/* Modal Confirm Privacy */}
            <Modal
                isOpen={modalConfirmPrivacy}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconInfo} alt="icon info" className="mx-auto my-4 w-20" />
                <h1 className="font-promptsemibold mb-0 text-lg">คุณต้องการ{!agreePrivacy?'ยอมรับ':'ยกเลิก'}</h1>
                <p className="text-gray-2 mb-4 text-md">นโยบายความเป็นส่วนตัว หรือไม่ ?</p>
                <div className="grid grid-cols-2 gap-2">
                <BtnSecondary type="button" text="ยกเลิก" addclass="text-black" action={()=> { setModalConfirmPrivacy(false) }} /> 
                <BtnPrimary type="button" text="ยืนยัน" action={()=> { handleUpdateCondition('privacy') }} /> 
                </div>
            </Modal>

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false); navigate('/')}} /> 
            </Modal>

            {/* Modal Success */}
            <Modal
                isOpen={modalSuccess}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconSuccess} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-4 text-lg">บันทึกเรียบร้อย</h1>
                {/* <p className="text-gray-2 mb-4 text-md">บันทึกประวัติของ</p> */}
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalSuccess(false); navigate(0)}} />
            </Modal>
        </div>
    )
}

export default Profile