import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal'
import {
  BtnPrimary, BtnSecondary,
} from '../../components/FormItems'

import AgoraRTC from "agora-rtc-sdk-ng"
import VirtualBackgroundExtension from "agora-extension-virtual-background";

import { TokenContext } from '../../App'

import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient as createClientWs } from 'graphql-ws';

import { gql } from "@apollo/client/core";
import { useSubscription } from "@apollo/react-hooks";

import { MdHideImage, MdImage, MdTexture } from "react-icons/md";

import IconSpeakerOn from '../../assets/img/ic-speaker-on.svg'
import IconSpeakerOff from '../../assets/img/ic-speaker-off.svg'
import IconChat from '../../assets/img/ic-chat.svg'
import IconVideocamFlip from '../../assets/img/ic-videocam-flip.svg'
import IconVideocamOnRemote from '../../assets/img/ic-videocam-on-remote.svg'
import IconVideocamOffRemote from '../../assets/img/ic-videocam-off-remote.svg'
import IconVideocamOn from '../../assets/img/ic-videocam-on.svg'
import IconVideocamOff from '../../assets/img/ic-videocam-off.svg'
import IconMicOnRemote from '../../assets/img/ic-mic-on-remote.svg'
import IconMicOffRemote from '../../assets/img/ic-mic-off-remote.svg'
import IconMicOn from '../../assets/img/ic-mic-on.svg'
import IconMicOff from '../../assets/img/ic-mic-off.svg'
import IconCallEnd from '../../assets/img/ic-call-end.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import IconSuccess from '../../assets/img/ic-success.svg'
import IconProfileVdo from '../../assets/img/ic-profile-vdo.svg'
import LogoThaiDoctor from '../../assets/img/logo-thaidoctor-small.svg'
import ImgBackground from '../../assets/img/background.png'
// import { set } from 'date-fns';
import VideoChat from './VideoChat';
import LoadingNoLogo from '../../components/LoadingNoLogo';


// Check Browser Type
let userAgent = navigator.userAgent;
let browserName;

if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent) && !/Google Inc/.test(navigator.vendor)) {
    browserName = "Safari"
}


// GraphQL -----------------------------------------------//
function callgraphql () {
    console.log('*** Call GRAPH QL ***')
    const httpLink = new HttpLink({
        // You should use an absolute URL here
        uri: process.env.REACT_APP_GRAPHQL_HTTPS || '',
        headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
    });

    // Create the subscription websocket link
    const wsLink = new GraphQLWsLink(
        createClientWs({
            url: process.env.REACT_APP_GRAPHQL_WS || '',
            retryAttempts: Infinity,
            shouldRetry: () => true,
            keepAlive: 10000,
            connectionParams: () => {
                return {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                };
            },
        })
    );

    const link = split(
        // split based on operation type
        ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
        },
        wsLink,
        httpLink
    );

    // Create the apollo client
    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: true,
    });

    return apolloClient

}

let isMuteVideo = false
let isMuteMic = false
let isMuteSpeaker = false
// let modalTimeWarning = false
let remoteVdoActive = false

const fullView = { width: window.innerWidth, height: window.innerHeight-60 }
const miniView = { width: (window.innerWidth/3), height: (window.innerHeight/3)-60, }
const halfView = { width: window.innerWidth, height: (window.innerHeight/2)-30 }

let cameraList = []
let facingModeData = 'user'
let virtualBackgroundEnabled = false
let toggleVirtualBackground = false
let virtualBackgroundStatus = 'off'
let toggleChat = false
// let btnVideoCamSwitchActive = false

let prescriptionChoice = ''

const VideoCallV2 = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { token, statusRoom, setStatusRoom, profile } = useContext(TokenContext)
    const [modalErr, setModalErr] = useState(false)
    const [modalErrVirtualBg,setModalErrVirtualBg] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg , setErrSubMsg] = useState('กรุณาลองใหม่อีกครั้ง')
    // const [modalLeave, setModalLeave] = useState(false)
    // const [modalTimeWarning, setModalTimeWaring] = useState(false)

    // const [openChat, setOpenChat] = useState(false)
    // const [prescriptionChoice, setPrescriptionChoice] = useState(null)
    
    // const { vdoChannelName, vdoAppID, vdoToken, vdoUserID, appointmentID } = props
    // const { videoChannelName, videoAppID, videoToken, videoUserID, appointmentID } = location.state || {}

    const { chatChannel, chatAppId, chatUId, chatToken,  } = location?.state

    const [videoChannelName, setVideoChannelName] = useState(location?.state?.videoChannelName || null)
    const [videoAppID, setVideoAppID] = useState(location?.state?.videoAppID || null)
    const [videoToken, setVideoToken] = useState(location?.state?.videoToken || null)
    const [videoUserID, setVideoUserID] = useState(location?.state?.videoUserID || null)
    const [appointmentID] = useState(location?.state?.appointmentID)
    const [doctorFirstName] = useState(location?.state?.doctorFirstName || '')
    const [doctorLastName] = useState(location?.state?.doctorLastName || '')
    const [doctorTitle] = useState(location?.state?.doctorTitle || '')
    const [doctorImg] = useState(location?.state?.doctorImg || '')
    const [patientImg] = useState(location?.state?.patientImg || localStorage.getItem('profileImg'))

    const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    // Init Virsual Background
    const extension = new VirtualBackgroundExtension();
    AgoraRTC.registerExtensions([extension]);
    const processor = extension.createProcessor();

    const createVirtualBackgroundImg = async () => {
        const imgElement = document.createElement('img');
        // await processor.init("../../assets/wasms");
        await processor.init("../../assets/bg");

        imgElement.onload = async () => {
            document.getElementById('local-player-loading-msg').classList.add('hidden')
            
            channelParameters.localVideoTrack.pipe(processor).pipe(channelParameters.localVideoTrack.processorDestination);
            // processor.setOptions({type: 'color', color: '#00ff00'});
            processor.setOptions({type: 'img', source: imgElement});
            await processor.enable();

        }
        imgElement.src = ImgBackground || '../../assets/img/background.png';
    }

    const createVirtualBackgroundBlur = async () => {
        // await processor.init("../../assets/wasms");
        await processor.init("../../assets/bg");

        document.getElementById('local-player-loading-msg').classList.add('hidden')

        channelParameters.localVideoTrack.pipe(processor).pipe(channelParameters.localVideoTrack.processorDestination);
        processor.setOptions({type: 'blur', blurDegree: 2});
        await processor.enable();
    }

    const offVirtualBackground = async () => {
        document.getElementById('local-player-loading-msg').classList.add('hidden')
        channelParameters.localVideoTrack.unpipe();
        await processor.disable();
    }

    // set Toggle Virtual Background
    const toggleControlVirtualBackground = () => {
        if(toggleVirtualBackground) { 
            document.getElementById('control-videocam-bg').classList.remove('outline')
            document.getElementById('control-videocam-virtual').classList.add('hidden') 
            document.getElementById('control-videocam-virtual').classList.remove('grid')
            toggleVirtualBackground = false
        } else {
            document.getElementById('control-videocam-bg').classList.add('outline')
            document.getElementById('control-videocam-virtual').classList.remove('hidden')
            document.getElementById('control-videocam-virtual').classList.add('grid')
            toggleVirtualBackground = true
        }
    }


    const enableVirtualBackground = async (status) => { 
        // if(virtualBackgroundEnabled) {
        //     console.log('*** background disable  ***')
            
        //     channelParameters.localVideoTrack.unpipe();
        //     await processor.disable();

        //     virtualBackgroundEnabled = false

        // } else {
            toggleControlVirtualBackground()

            document.getElementById('local-player-loading-msg').classList.remove('hidden')

            // await processor.init("../../assets/wasms");

            if(status==='img') {
                console.log('*** background IMAGE enable  ***')

                try {
                    await createVirtualBackgroundImg()  
                } catch (error) {
                    document.getElementById('local-player-loading-msg').classList.add('hidden')
                    setModalErrVirtualBg(true)
                }    
                virtualBackgroundEnabled = true

                document.getElementById('control-videocam-bg-off').classList.add('hidden')
                document.getElementById('control-videocam-blur').classList.add('hidden')
                document.getElementById('control-videocam-bg-on').classList.remove('hidden')
                
                document.getElementById('control-videocam-bg-off-menu').classList.remove('hidden')
                document.getElementById('control-videocam-blur-menu').classList.remove('hidden')
                document.getElementById('control-videocam-bg-on-menu').classList.add('hidden')
                
            }

            if(status==='blur') {            
                console.log('*** background BLUR enable  ***')

                try {
                    await createVirtualBackgroundBlur()    
                } catch (error) {
                    document.getElementById('local-player-loading-msg').classList.add('hidden')
                    setModalErrVirtualBg(true)
                }        
                virtualBackgroundEnabled = true

                document.getElementById('control-videocam-bg-off').classList.add('hidden')
                document.getElementById('control-videocam-blur').classList.remove('hidden')
                document.getElementById('control-videocam-bg-on').classList.add('hidden')
                
                document.getElementById('control-videocam-bg-off-menu').classList.remove('hidden')
                document.getElementById('control-videocam-blur-menu').classList.add('hidden')
                document.getElementById('control-videocam-bg-on-menu').classList.remove('hidden')

            }

            if(status==='off') {
                console.log('*** background disable  ***')

                await offVirtualBackground()
                virtualBackgroundEnabled = false

                document.getElementById('control-videocam-bg-off').classList.remove('hidden')
                document.getElementById('control-videocam-blur').classList.add('hidden')
                document.getElementById('control-videocam-bg-on').classList.add('hidden')
                
                document.getElementById('control-videocam-bg-off-menu').classList.add('hidden')
                document.getElementById('control-videocam-blur-menu').classList.remove('hidden')
                document.getElementById('control-videocam-bg-on-menu').classList.remove('hidden')

            }


            virtualBackgroundStatus = status

        // }
    }

    let currentOption = JSON.parse(localStorage.getItem('currentOption')) || null

    let options = {
        // Pass your App ID here.
        appId: videoAppID || (!!currentOption ? currentOption.videoAppID : null), // "58b31596df494ce09f11dd9e8675eb4d", // '2ab908c9615b474bad7c5bfea6247687',
        // Set the channel name.
        channel: videoChannelName || (!!currentOption ? currentOption.videoChannelName : null), // "staging-ThaiDoctor Room 338", // 'TestTD',
        // Pass your temp token here.
        token: videoToken || (!!currentOption ? currentOption.videoToken : null), // "00658b31596df494ce09f11dd9e8675eb4dIAD1kRuCvVFnuEQkFmxBskyFYKZu1fzkjgltQAlJBpOPBgNc9pQNvtUaIgA3WhFlXE3NYwQAAQCwAcxjAgCwAcxjAwCwAcxjBACwAcxj",
        // Set the user ID.
        uid: videoUserID || (!!currentOption ? currentOption.videoUserID : null),
    };

    let channelParameters = {
        // A variable to hold a local audio track.
        localAudioTrack: null,
        // A variable to hold a local video track.
        localVideoTrack: null,
        // A variable to hold a remote audio track.
        remoteAudioTrack: null,
        // A variable to hold a remote video track.
        remoteVideoTrack: null,
        // A variable to hold the remote user id.s
        remoteUid: 0,
    };

    // Dynamically create a container in the form of a DIV element to play the remote video track.
    // const remotePlayerContainer = document.getElementById("remote-player")
    // Dynamically create a container in the form of a DIV element to play the local video track.
    const localPlayerContainer = document.getElementById("local-player")



    useEffect(() => {

        (async()=>{

            console.log('*** statusRoom *** : ', statusRoom,', profile img: ',profile.pictureUrl)
            
            document.getElementById('local-player-loading-msg').classList.remove('hidden')
            // // Init Virsual Background via wasms
            // await processor.init("../../assets/wasms");
            
            // Check & Add Camera Devices
            await AgoraRTC.getCameras().then(devices => {
                // eslint-disable-next-line no-undef
                // Clear list of camera device
                cameraList = []
                console.log('getCamera: ',devices)

                // Check amount device for mobile / pc
                if(devices.length > 1) {
                    // Get camera has text is 'back', 'Back', 'BACK', 'หลัง'
                    let selectBackCameraArr = []
                    for(let i=0; i<devices.length; i++) {
                        console.log('*** BackFrontLabel ***',devices[i].label)
                        if(devices[i].label.includes('back') || devices[i].label.includes('Back') || devices[i].label.includes('BACK') || devices[i].label.includes('หลัง')) {
                            selectBackCameraArr.push(devices[i])
                        }
                    }

                    // Get camera has text is 'front', 'Front', 'FRONT', 'หน้า'
                    let selectFrontCameraArr = []
                    for(let i=0; i<devices.length; i++) {
                        console.log('*** CameraFrontLabel ***',devices[i].label)
                        if(devices[i].label.includes('front') || devices[i].label.includes('Front') || devices[i].label.includes('FRONT') || devices[i].label.includes('หน้า')) {
                            selectFrontCameraArr.push(devices[i])
                        }
                    }

                    console.log('selectBackCameraArr: ',selectBackCameraArr)
                    console.log('selectFrontCameraArr: ',selectFrontCameraArr)

                    // Get camera device to cameraList
                    if(selectBackCameraArr.length > 0) {
                        // cameraList.push({ deviceId: selectBackCameraArr[0].deviceId, label: selectBackCameraArr[0].label})
                        cameraList.splice(0,0,{ deviceId: selectBackCameraArr[0].deviceId, label: selectBackCameraArr[0].label})
                    }

                    if(selectFrontCameraArr.length > 0) {
                        // cameraList.push({ deviceId: selectFrontCameraArr[0].deviceId, label: selectFrontCameraArr[0].label})
                        cameraList.splice(1,0,{ deviceId: selectFrontCameraArr[0].deviceId, label: selectFrontCameraArr[0].label})
                    }
                } else {
                    // Device only one
                    for(let i=0; i<devices.length; i++) {
                        cameraList.push({ deviceId: devices[i].deviceId, label: devices[i].label})
                    }
                }

                console.log('*** cameraList: ***', cameraList)

            }).catch(e => {
                console.log("get camera error!", e);
                setErrSubMsg('ไม่สามารถเปิดกล้องได้ กรุณาลองใหม่อีกครั้ง')
                setModalErr(true)
            })


            // Doctor Join Channel
            if(localStorage.getItem('currentStatusRoom')==='room_start') {
                
                console.log(localPlayerContainer)
                console.log('options: ',options.appId, options.channel, options.token, options.uid)

                // Subscript remote user
                agoraEngine.on("user-published", async (user, mediaType) => {
                    // Subscribe to the remote user when the SDK triggers the "user-published" event.
                    await agoraEngine.subscribe(user, mediaType);
                    console.log('subscribe success / *** remote audio *** : ',user.hasAudio)
                    // Subscribe and play the remote video in the container If the remote user publishes a video track.
                    if (mediaType === "video") {
                        // Retrieve the remote video track.
                        channelParameters.remoteVideoTrack = user.videoTrack;
                        // Retrieve the remote audio track.
                        channelParameters.remoteAudioTrack = user.audioTrack;

                        if(user.audioTrack===undefined) {
                            document.getElementById(`control-speaker`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto"><img src="${IconSpeakerOff}" alt="icon" /></div>`;
                            isMuteSpeaker = true;
                        }
                        // Save the remote user id for reuse.
                        // channelParameters.remoteUid = user.uid.toString();
                        // Specify the ID of the DIV container. You can use the uid of the remote user.
                        // remotePlayerContainer.id = user.uid.toString();
                        // channelParameters.remoteUid = user.uid.toString();
                        // remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
                        // // Append the remote container to the page body.
                        // document.body.append(remotePlayerContainer);
                        // Play the remote video track.
                        console.log('*** user remote audio *** : ',user.hasAudio)
                        console.log('*** user remote video *** : ',user.hasVideo)
                        channelParameters.remoteVideoTrack.play('remote-player');
                        // Check the remote video track on/off
                        if(user.hasVideo) {
                            document.getElementById(`remote-player-videocam`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconVideocamOnRemote}" alt="icon" /></div>`;
                        } else {
                            document.getElementById(`remote-player-videocam`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconVideocamOffRemote}" alt="icon" /></div>`;
                        }
                        // Check the remote audio track on/off
                        if(user.hasAudio) {
                            document.getElementById(`remote-player-mic`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconMicOnRemote}" alt="icon" /></div>`;
                        } else {
                            document.getElementById(`remote-player-mic`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconMicOffRemote}" alt="icon" /></div>`;
                        }

                        remoteVdoActive = true
                    }
                    // Subscribe and play the remote audio track If the remote user publishes the audio track only.
                    if (mediaType === "audio") {
                        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
                        channelParameters.remoteAudioTrack = user.audioTrack;
                        // Play the remote audio track. No need to pass any DOM element.
                        channelParameters.remoteAudioTrack.play();
                        // Check the remote audio track on/off
                        if(user.hasAudio) {
                            document.getElementById(`remote-player-mic`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconMicOnRemote}" alt="icon" /></div>`;
                        } else {
                            document.getElementById(`remote-player-mic`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconMicOffRemote}" alt="icon" /></div>`;
                        }
                    }
                    // Listen for the "user-unpublished" event.
                    agoraEngine.on("user-unpublished", user => {
                        console.log(user.uid + ' has left the channel / *** remote audio *** : ',user.hasAudio)
                        // Check the remote video track on/off
                        if(!user.hasVideo) {
                            document.getElementById(`remote-player-videocam`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconVideocamOffRemote}" alt="icon" /></div>`;
                        }
                        // Check the remote audio track on/off
                        if(!user.hasAudio) {
                            document.getElementById(`remote-player-mic`).innerHTML = `<div class="bg-black/50 rounded-full p-1 w-8 m-auto"><img src="${IconMicOffRemote}" alt="icon" /></div>`;
                        }
                    });
                });


                // Event Join - insert value
                await agoraEngine.join(options.appId, options.channel, options.token, options.uid);

                channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack(/*{facingMode: facingModeData}*/);

                // // Check Faing Camera
                // if(browserName==='Safari' && cameraList[0].label ==='กล้องด้านหลัง') {
                //     channelParameters.localVideoTrack.setDevice(cameraList[1].deviceId)
                // }
                // channelParameters.localVideoTrack.setDevice(cameraList[0].deviceId)

                // Event Publish - go to calling
                await agoraEngine.publish([channelParameters.localAudioTrack, channelParameters.localVideoTrack]);
                // Play the local video track.
                // channelParameters.localVideoTrack.play("local-player");
                console.log("*** publish success! ***");

                // Set layout: half screen
                document.getElementById('local-player').style = `width: ${halfView.width}px; height: ${halfView.height}px; padding: 4px 8px 8px`
                document.getElementById('remote-player').style = `width: ${halfView.width}px; height: ${halfView.height}px; padding: 8px 8px 4px`


                // Set layout: local-player : self screen
                // document.getElementById('local-player').style = `width: ${miniView.width}px; height: ${miniView.height}px; position: absolute; right: 12px; bottom: 72px; margin: auto; z-index: 10;`
                document.getElementById('local-player-waiting-msg').style = `display: none;`
                
                // Set layout: remote-player
                // document.getElementById('remote-player').style = `width: ${fullView.width}px; height: ${fullView.height}px`

                // Show Button Speaker
                document.getElementById('control-speaker').classList.remove('hidden')

                // Check Facing Camera
                if(cameraList.length > 1) {
                    console.log('*** check camera [', cameraList.length,'] device: ***',cameraList)
                    document.getElementById('control-videocam-flip').classList.remove('hidden')
                }

                // Check Virtual Background
                if(virtualBackgroundEnabled) {
                    if(virtualBackgroundStatus==='img') {
                        await createVirtualBackgroundImg()
                    } else {
                        await createVirtualBackgroundBlur()
                    }
                }

                document.getElementById('local-player-loading-msg').classList.add('hidden')

            } else {

                console.log('*** has not app_id ****: (cameraId:)', cameraList[0])

                channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack({facingMode: 'user' });

                // Check Faing Camera
                // if(browserName==='Safari' && cameraList[0].label ==='กล้องด้านหลัง') {
                //     channelParameters.localVideoTrack.setDevice(cameraList[1].deviceId)
                // }
                // channelParameters.localVideoTrack.setDevice(cameraList[0].deviceId)

                // Set layout: local-player : full screen
                document.getElementById('local-player').style = `width: ${fullView.width}px; height: ${fullView.height}px`

                document.getElementById('local-player-loading-msg').classList.add('hidden')
            }

            // Hide button virtual background & speker on safari
            if(browserName === 'Safari') {
                console.log('*** browser : ****', browserName)
                document.getElementById('control-videocam-bg').classList.add('hidden')
                document.getElementById('control-speaker').classList.add('hidden')
            }

            channelParameters.localVideoTrack.play("local-player", { mirror: true });

            if(isMuteVideo === true) {
                // Mute the local video.
                channelParameters.localVideoTrack.setEnabled(false);
                // Update the button text.
                document.getElementById(`control-videocam`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto"><img src="${IconVideocamOff}" alt="icon" /></div>`;
                document.getElementById('local-player-img').classList.remove('hidden')
            }

            if(isMuteMic === true) {
                // Mute the local video.
                channelParameters.localAudioTrack.setEnabled(false);
                // Update the button text.
                document.getElementById(`control-mic`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto"><img src="${IconMicOff}" alt="icon" /></div>`;
            }

            console.log('*** Start Local Player *** : ',channelParameters)
        })()

    
        return async () => {
            // agoraEngine.leave();

            // channelParameters.localAudioTrack.stop();
            // channelParameters.localVideoTrack.stop();

            console.log('left page!! [statusRoom]:',statusRoom, channelParameters)

            await channelParameters.localAudioTrack.close();
            await channelParameters.localVideoTrack.close();

            await agoraEngine.leave();
            console.log("You left the channel");

            // window.addEventListener("popstate", e => {
            //     navigate('/videowaitingroom', { state: { 
            //         appointmentID: appointmentID, 
            //         doctorFirstName: doctorFirstName, 
            //         doctorLastName: doctorLastName, 
            //         doctorTitle: doctorTitle, 
            //         doctorImg: doctorImg 
            //     }})
            // })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusRoom])


    // set Toggle Chat
    const toggleControlChat = () => {
        if(toggleChat) {
            document.getElementById('control-msg').classList.add('hidden')
            toggleChat = false
            // setOpenChat(false)
        } else {
            document.getElementById('control-msg').classList.remove('hidden')
            toggleChat = true
            // setOpenChat(true)
        }
    }

    const closeControlChat = () => {
        document.getElementById('control-msg').classList.add('hidden')
        toggleChat = false
    }

    // Set Facing Camera
    const videoCamSwitch = async () => {
        document.querySelector('.agora_video_player').style.visibility = 'hidden'

        // document.getElementById('control-videocam-flip').classList.add('pointer-events-none opacity-80')

        // Check Browser Type
        // let userAgent = navigator.userAgent;
        // let browserName;
        
        // if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent) && !/Google Inc/.test(navigator.vendor)) {
        //     browserName = "Safari"
        // }

        document.getElementById('control-videocam-flip').classList.add('hidden')
        document.getElementById('control-videocam-flip-off').classList.remove('hidden')
        document.getElementById('local-player-loading-msg').classList.remove('hidden')

        console.log('*** [before] facingMode: ***', facingModeData)
        console.log(cameraList)
        cameraList.forEach((item,i) => {
            console.log('*** camara: '+i+', deviceId: ', item.deviceId,', label: ', item.label,' ***')
        });

        await channelParameters.localVideoTrack.stop()

        if(facingModeData==='user') {
            console.log('*** camera[1] rear label *** ', cameraList[1].label)
            let cameraRear = cameraList[0].deviceId
            // if(browserName === 'Safari') {
            //     cameraRear = cameraList[0].deviceId
            // } else {
            //     cameraRear = cameraList[1].deviceId
            // }
            await channelParameters.localVideoTrack.setDevice(cameraRear)
            facingModeData = 'environment'

            await channelParameters.localVideoTrack.play("local-player", { mirror: false });
            document.querySelector('.agora_video_player').style.visibility = 'visible'

        } else {
            console.log('*** camera[0] front label *** ', cameraList[0].label)

            let cameraFront =  cameraList[1].deviceId
            // if(browserName === 'Safari') {
            //     cameraFront = cameraList[1].deviceId
            // } else {
            //     cameraFront = cameraList[0].deviceId
            // }
            await channelParameters.localVideoTrack.setDevice(cameraFront)
            facingModeData = 'user'

            await channelParameters.localVideoTrack.play("local-player", { mirror: true });
            document.querySelector('.agora_video_player').style.visibility = 'visible'

        }

        console.log('*** [after] facingMode: ***', facingModeData)
        setTimeout(() => {
            document.getElementById('control-videocam-flip-off').classList.add('hidden')
            document.getElementById('control-videocam-flip').classList.remove('hidden')
            document.getElementById('local-player-loading-msg').classList.add('hidden')
        }, 1800);

        
    }

    // Set End Call
    const endCallEvent = async (id) => {
        if(localStorage.getItem('currentStatusRoom')==='room_start') {
            // Fetch Video: end_room
            let appointmentID = id
            const resEndRoom = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/end_room`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + ( token || localStorage.getItem('accessTokenApi') )
                }
            });
    
            resEndRoom
                .json()
                .then((response) => {
                    console.log('*** EndRoom[',appointmentID,'] ***')
                    setStatusRoom('waiting_room')
                    localStorage.setItem('currentStatusRoom','waiting_room')

                    modalEvent('close','modal-leave')
                    // leaveEvent()
                })
                .catch((err)=> { console.log(err); /* setModalErr(true); */ })
        } else {
            leaveEvent()
        }

    }
   
    // Set Leave
    const leaveEvent = async () => {
        console.log(channelParameters)
        // audio and video tracks.
        // channelParameters.localAudioTrack.close();
        // channelParameters.localVideoTrack.close();
        // Remove the containers you created for the local video and remote video.
        // removeVideoDiv(remotePlayerContainer.id);
        // removeVideoDiv(localPlayerContainer.id);
        // Leave the channel
        // Refresh the page for reuse
        // window.location.reload();
        navigate('/')
        // Destroy the local 
    }

    // Set Video Camera
    const videoCamEvent = async () => {
        console.log(channelParameters)
        if(isMuteVideo === false) {
            // Mute the local video.
            channelParameters.localVideoTrack.setEnabled(false);
            // Update the button text.
            document.getElementById(`control-videocam`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto"><img src="${IconVideocamOff}" alt="icon" /></div>`;
            document.getElementById('local-player-img').classList.remove('hidden')
            isMuteVideo = true;
            // setIconMuteVideo(true)
        } else {
            // Unmute the local video.
            channelParameters.localVideoTrack.setEnabled(true);
            // Update the button text.
            document.getElementById(`control-videocam`).innerHTML = `<div class="bg-white rounded-full p-1 w-fit m-auto"><img src="${IconVideocamOn}" alt="icon" /></div>`;
            document.getElementById('local-player-img').classList.add('hidden')
            isMuteVideo = false;
            // setIconMuteVideo(false)
        }
    }

    // Set Microphone
    const micEvent = async () => {
        console.log(channelParameters)
        if(isMuteMic === false) {
            // Mute the local video.
            channelParameters.localAudioTrack.setEnabled(false);
            // Update the button text.
            document.getElementById(`control-mic`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto"><img src="${IconMicOff}" alt="icon" /></div>`;
            isMuteMic = true;
            // setIconMuteVideo(true)
        } else {
            // Unmute the local video.
            channelParameters.localAudioTrack.setEnabled(true);
            // Update the button text.
            document.getElementById(`control-mic`).innerHTML = `<div class="bg-white rounded-full p-1 w-fit m-auto"><img src="${IconMicOn}" alt="icon" /></div>`;
            isMuteMic = false;
            // setIconMuteVideo(false)
        }
    }

    // Set Volume Speaker
    const speakerEvent = async () => {
        // document.getElementById('control-videocam-flip').classList.add('pointer-events-none opacity-80')

        console.log(channelParameters)
        if(isMuteSpeaker === false) {
            // Mute the remote volume.
            channelParameters.remoteAudioTrack.setVolume(0);
            // Update the button text.
            document.getElementById(`control-speaker`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto"><img src="${IconSpeakerOff}" alt="icon" /></div>`;
            isMuteSpeaker = true;

            // document.getElementById('control-speaker').classList.remove('pointer-events-none opacity-80')
        } else {
            // Unmute the remote volume.
            channelParameters.remoteAudioTrack.setVolume(1000);
            // Update the button text.
            document.getElementById(`control-speaker`).innerHTML = `<div class="bg-white rounded-full p-1 w-fit m-auto"><img src="${IconSpeakerOn}" alt="icon" /></div>`;
            isMuteSpeaker = false;

            // document.getElementById('control-speaker').classList.remove('pointer-events-none opacity-80')
        }
    }

    // Set Video Camera Selected
    const videoCamSelected = (username) => {
        if(remoteVdoActive) {
            if(username === 'local') {
                // Set layout: half screen
                document.getElementById('local-player').style = `width: ${halfView.width}px; height: ${halfView.height}px; padding: 4px 8px 8px`
                document.getElementById('remote-player').style = `width: ${halfView.width}px; height: ${halfView.height}px; padding: 8px 8px 4px`
                // Set layout: remote-player : self screen
                // document.getElementById('remote-player').style = `width: ${miniView.width}px; height: ${miniView.height}px; position: absolute; right: 12px; bottom: 72px; margin: auto; z-index: 10;`
                // document.getElementById('remote-player-img').style = `width: ${fullView.width/5.5}px; height: ${fullView.width/5.5}px`
                // document.getElementById('remote-player-control').style = `display: none`
                // Set layout: local-player : full screen
                // document.getElementById('local-player').style = `width: ${fullView.width}px; height: ${fullView.height}px`
                
            } else {
                // Set layout: local-player : self screen
                document.getElementById('local-player').style = `width: ${miniView.width}px; height: ${miniView.height}px; position: absolute; right: 12px; bottom: 72px; margin: auto; z-index: 10;`
                // Set layout: remote-player : full screen
                document.getElementById('remote-player').style = `width: ${fullView.width}px; height: ${fullView.height}px`
                // document.getElementById('remote-player-img').style = `width: ${fullView.width/2.5}px; height: ${fullView.width/2.5}px;`
                document.getElementById('remote-player-control').style = `display: flex`
            
            }
            // console.log('switch camera')
        }
        // console.log('innerWidth: ',fullView.width,', innerHeight: ',fullView.height)
    }

    // Set Modal
    const modalEvent = (action, id) => {
        if(action === 'open') {
            document.getElementById('modal-overlay').style.display = 'block'
            document.getElementById(id).style.display = 'block'

            // Countdown 3 sec for close modal end
            // if(id==='modal-end') {
            //     setTimeout(() => {
            //         navigate('/')
            //     }, 3000);
            // }
        } else {
            document.getElementById('modal-overlay').style.display = 'none'
            document.getElementById(id).style.display = 'none'
        }
    } 

    
    // Call


    // Fetch Video: room_start
    const fetchVideoRoomStart = async (id) => {
        let appointmentID = id
        const resEnterWaitingRoom = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/token`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + ( token || localStorage.getItem('accessTokenApi') )
            }
        });

        resEnterWaitingRoom
            .json()
            .then((response) => {
                // console.log('token room_start : ',response)

                (async()=>{
                    // channelParameters.localAudioTrack.close();
                    // channelParameters.localVideoTrack.close();
                    // await agoraEngine.leave();

                    setVideoAppID(response.app_id)
                    setVideoChannelName(response.video_channel)
                    setVideoToken(response.video_token)
                    setVideoUserID(response.video_user_id)

                    setStatusRoom('room_start')
                    localStorage.setItem('currentStatusRoom','room_start')
                    localStorage.setItem('currentOption', JSON.stringify({
                        videoChannelName: response.video_channel, 
                        videoAppID: response.app_id, 
                        videoToken: response.video_token, 
                        videoUserID: response.video_user_id ,
                        appointmentID: appointmentID,
                        doctorFirstName: doctorFirstName,
                        doctorLastName: doctorLastName,
                        doctorTitle: doctorTitle,
                        doctorImg: doctorImg,
                        chatChannel: response.chat_channel,
                        chatToken: response.chat_token,
                        chatUId: response.chat_user_id,
                        chatAppId: response.app_id,
                    }))
                })()

                console.log('GRAPHQL HTTPS:',process.env.REACT_APP_GRAPHQL_HTTPS)
                console.log('GRAPHQL WS:',process.env.REACT_APP_GRAPHQL_WS)
                console.log('GRAPHQL TOKEN:',localStorage.getItem("accessToken"))

            })
            .catch((err)=> { console.log(err); /* setModalErr(true); */ })
    }

    // Fetch Prescription Choice
    const fetchPrescriptionChoice = async (id) => {
        console.log('prescriptionChoice: ', prescriptionChoice, ', appointmentID: ',id)

        if(!!prescriptionChoice) {
            let appointmentID = id
            const resPrescriptionChoice = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentID}/prescription_choice`, {
                method: 'POST',
                body: JSON.stringify ({
                    "prescription_choice": prescriptionChoice === '' ? null : prescriptionChoice
                }),
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": "application/json",
                    'Authorization': 'Bearer ' + ( token || localStorage.getItem('accessTokenApi') )
                }
            });
    
            if(resPrescriptionChoice.status === 200) {
                console.log('prescriptionChoice[status]: OK')
                modalEvent('close','modal-end'); 
                navigate('/')
            } else {
                console.log('prescriptionChoice[error]: ',resPrescriptionChoice)
            }
        } else {
            modalEvent('close','modal-end'); 
            navigate('/')
        }

       
    }


    // Subscription ------------------------------------------//
    const USERS_ADDED_SUBSCRIPTION = gql`
    subscription onEventAddedType {
        eventAdded {
            event
            doctor_id
            patient_id
            datetime_from
            datetime_to
            appointment_id
        }
    }
    `;

    function EventSubscription() {
        const { loading, error, data } = useSubscription(USERS_ADDED_SUBSCRIPTION);

        if (loading) return null;
            if (error) {
                console.log(`Error! ${error.message}`);
                return false
            }

        if (!loading && data) {
            // const { added } = data.eventAdded;
            console.log('[Event]:', data.eventAdded.event+' !! (Checking VideoCall Status)',)
            // if(data.eventAdded.event === 'keepalive'){
            //     modalEvent('open', 'modal-time-warning')
            // }

            if(data.eventAdded.event === 'room_start'){
                fetchVideoRoomStart(appointmentID)
            }

            if(data.eventAdded.event === 'time_warning') {
                modalEvent('open', 'modal-time-warning')
            }

            if(data.eventAdded.event === 'room_end') {
                modalEvent('open','modal-end')
                localStorage.removeItem('currentStatusRoom')
                localStorage.removeItem('currentOption')
            }

        } else {
            console.log('Listening...')
            return (<h4>Listening...</h4>);
        }
    }
    
    Modal.setAppElement('#root');
    
    return (
        <div className="row" style={{maxWidth: window.innerWidth+'px'}}>
            <ApolloProvider client={callgraphql()}>
                <EventSubscription />

                {/* <div>
                    <button type="button" id="join" onClick={joinEvent}>Join</button>
                    &nbsp; | &nbsp;
                    <button type="button" id="leave" onClick={leaveEvent}>Leave</button>
                </div> */}
                <div id="remote-player" className="relative bg-vdo" onClick={()=> videoCamSelected('remote')} style={{display: 'none'}}>
                    <div id="remote-player-img" className="bg-gray rounded-full m-auto overflow-hidden absolute left-0 right-0 top-0 bottom-0" style={{width: '6rem',height: '6rem'}}>
                        <img src={doctorImg || IconProfileVdo} alt="icon" className="absolute left-0 right-0 top-0 bottom-0 m-auto" />
                    </div>

                    <div id="remote-player-control" className="flex flex-row">
                        <div id="remote-player-name" className="absolute left-4 top-3 z-10 flex items-center" style={{width: (fullView.w-84)+'px'}}>
                            <img className="w-8 h-8" src={LogoThaiDoctor} alt="logo" />
                            <p style={{color: 'white'}}>{doctorTitle} {doctorFirstName} {doctorLastName}</p>
                        </div>

                        <div id="remote-player-status" className="absolute right-4 top-3 z-10 grid grid-cols-2 gap-2">
                            <div id="remote-player-videocam">
                                <div className="bg-black/50 rounded-full p-1 w-8 m-auto">
                                    <img src={IconVideocamOffRemote} alt={'icon'} /> 
                                </div>
                            </div>
                            <div id="remote-player-mic">
                                <div className="bg-black/50 rounded-full p-1 w-8 m-auto">
                                    <img src={IconMicOffRemote} alt={'icon'} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="local-player" className="flex relative bg-vdo" onClick={()=> videoCamSelected('local')} style={{width: fullView.width+'px', height: fullView.height+'px'}}>
                    <div id="local-player-img" className="bg-gray rounded-full m-auto overflow-hidden absolute left-0 right-0 top-0 bottom-0 hidden" style={{width: '6rem', height:  '6rem', zIndex: '1'}}>
                        <img src={ (!!patientImg) ? patientImg : (!!profile.pictureUrl) ? profile.pictureUrl : IconProfileVdo} alt="icon" className="absolute left-0 right-0 top-0 bottom-0 m-auto" />
                    </div>
                    
                    <p className="absolute left-4 bottom-3 z-10" style={{color: 'white'}}>You</p>
                    <div id="local-player-waiting-msg" className="bg-black/50 w-11/12 h-fit rounded-lg absolute left-0 right-0 top-3 m-auto p-4 z-10 text-center">
                        <p className="text-white">กรุณารอแพทย์เชิญเข้าห้องสนทนา </p>
                    </div>
                </div>

                <div id="local-player-loading-msg" style={{width: window.innerWidth+'px', height: window.innerHeight+'px'}} className="bg-black/50 w-full absolute left-0 top-0 m-auto z-10 text-center hidden">
                    {/* <div className="translate-y-20"> */}
                        <LoadingNoLogo status="VideoCallLoading" hidelogo={true} style={{height: window.innerHeight-60+'px'}} />
                    {/* </div> */}
                </div>
               
                <div id="control-player" className="flex p-2 items-center justify-center relative" style={{width: fullView.width+'px', height: '60px', backgroundColor: '#3B3F48'}}>
                    <div id="control-speaker" className="m-auto hidden" onClick={()=> speakerEvent() }>
                        <div className="bg-white rounded-full p-1 w-fit m-auto">
                            <img src={IconSpeakerOn} alt={'icon'} /> 
                        </div>
                    </div>
                    <div id="control-videocam" className="m-auto" onClick={videoCamEvent}>
                        <div className="bg-white rounded-full p-1 w-fit m-auto">
                            <img src={IconVideocamOn} alt={'icon'} /> 
                        </div>
                    </div>
                    <div id="control-mic" className="m-auto" onClick={micEvent}>
                        <div className="bg-white rounded-full p-1 w-fit m-auto">
                            <img src={IconMicOn} alt={'icon'} /> 
                        </div>
                    </div>
                    <div id="control-calloff" className="bg-red rounded-full p-2 w-fit m-auto" onClick={()=> modalEvent('open','modal-leave') /*setModalLeave(!modalLeave)*/}>
                        <img src={IconCallEnd} alt={'icon'} />
                    </div>
                    <div id="control-videocam-flip" className="bg-white rounded-full p-1 w-fit m-auto hidden" onClick={()=> videoCamSwitch() }>
                        <img src={IconVideocamFlip} alt={'icon'} />
                    </div>
                    <div id="control-videocam-flip-off" className="bg-white rounded-full p-1 w-fit m-auto opacity-50 hidden">
                        <img src={IconVideocamFlip} alt={'icon'} />
                    </div>
                    <div id="control-chat" className="bg-white rounded-full p-1 w-fit m-auto" onClick={toggleControlChat}>
                        <img src={IconChat} alt={'icon'} />
                    </div>
                    <div id="control-videocam-bg" className="m-auto relative rounded-full outline-offset-1 outline-white" onClick={toggleControlVirtualBackground}>
                        <div id="control-videocam-bg-off" className={`bg-white rounded-full p-1 w-fit m-auto`}>
                            <MdHideImage color="#616262" size="22.4px" style={{margin: '3.8px'}} />
                        </div>
                        <div id="control-videocam-bg-on" className={`bg-white rounded-full p-1 w-fit m-auto hidden`}>
                            <MdImage color="#616262" size="22.4px" style={{margin: '3.8px'}} />
                        </div>
                        <div id="control-videocam-blur" className={`bg-white rounded-full p-1 w-fit m-auto hidden`}>
                            <MdTexture color="#616262" size="22.4px" style={{margin: '3.8px'}} />
                        </div>
                    </div>

                    <div id="control-videocam-virtual" className="absolute bottom-16 right-4 m-auto hidden grid-row-2 gap-2">
                        <div id="control-videocam-bg-off-menu" className={`bg-white rounded-full p-1 w-fit m-auto hidden`} onClick={()=> enableVirtualBackground('off')}>
                            <MdHideImage color="#616262" size="22.4px" style={{margin: '3.8px'}} />
                        </div>
                        <div id="control-videocam-bg-on-menu" className={`bg-white rounded-full p-1 w-fit m-auto`} onClick={()=> enableVirtualBackground('img')}>
                            <MdImage color="#616262" size="22.4px" style={{margin: '3.8px'}} />
                        </div>
                        <div id="control-videocam-blur-menu" className={`bg-white rounded-full p-1 w-fit m-auto`} onClick={()=> enableVirtualBackground('blur')}>
                            <MdTexture color="#616262" size="22.4px" style={{margin: '3.8px'}} />
                        </div>
                    </div>
                </div>

                <div id="control-msg" className="absolute bottom-0 w-full bg-white rounded-t-lg z-10 p-4 hidden">
                    <VideoChat 
                        openChat={true} 
                        actionClose={closeControlChat} 
                        appointmentID={appointmentID || JSON.parse(localStorage.getItem('currentOption'))?.appointmentID} 
                        chatChannel={chatChannel || JSON.parse(localStorage.getItem('currentOption'))?.chatChannel} 
                        chatUId={chatUId || JSON.parse(localStorage.getItem('currentOption'))?.chatUId} 
                        chatAppId={chatAppId || JSON.parse(localStorage.getItem('currentOption'))?.chatAppId} 
                        chatToken={chatToken || JSON.parse(localStorage.getItem('currentOption'))?.chatToken} 
                        doctorTitle={doctorTitle || JSON.parse(localStorage.getItem('currentOption'))?.doctorTitle} 
                        doctorFirstName={doctorFirstName || JSON.parse(localStorage.getItem('currentOption'))?.doctorFirstName} 
                        doctorLastName={doctorLastName || JSON.parse(localStorage.getItem('currentOption'))?.doctorLastName} 
                        browserName={browserName}
                    />
                    {/* <VideoChat 
                        openChat={openChat} 
                        actionClose={closeControlChat} 
                        appointmentID={appointmentID} 
                        chatChannel={chatChannel} 
                        chatUId={chatUId} 
                        chatAppId={chatAppId} 
                        chatToken={chatToken} 
                        doctorTitle={doctorTitle} 
                        doctorFirstName={doctorFirstName} 
                        doctorLastName={doctorLastName} 
                    /> */}
                    {/* <div className="flex flex-row justify-between">
                        <h1 className="font-promptsemibold">Chat with Doctor</h1>
                        <button onClick={closeControlChat}><img src={IconClose} alt="icon close" /></button>
                    </div>
                    <div id="cotrol-msg-box" className="w-full overflow-auto my-3" style={{height: window.innerHeight/3+'px'}}>
                        <div id="log"></div>
                    </div>
                    <div className="overflow-hidden bg-form-bd rounded-full py-3 px-5 flex flex-row justify-between">
                        <input type="text" placeholder="Send a message" className="bg-form-bd" />
                        <button><img src={IconSend} alt="icon close" /></button>
                    </div> */}
                </div>
            </ApolloProvider>

            {/* Modal & Overlay */}
            <div id="modal-overlay" className="fixed w-full h-full left-0 top-0 right-0 bottom-0 m-auto z-20 p-10 bg-black/50 hidden">

                {/* Modal Time Warning */}
                <div id="modal-time-warning" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">หมดเวลาปรึกษาแล้ว</h1>
                    <p className="text-gray-2 mb-4 text-md">ระบบจะทำการปิดการสนทนาอัตโนมัติในอีก 5:00 นาที</p>

                    <BtnPrimary type="button" text="ตกลง" action={()=> { modalEvent('close','modal-time-warning')}} /> 
                </div>

                {/* Modal Leave */}
                <div id="modal-leave" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">{ localStorage.getItem('currentStatusRoom')==='room_start' ? 'คุณต้องการจบการสนทนาหรือไม่' : 'การสนทนายังไม่เสร็จสิ้น'}</h1>
                    <p className="text-gray-2 mb-4 text-md">{ localStorage.getItem('currentStatusRoom')==='room_start' ? 'คุณจะไม่สามารถกลับเข้ามาใหม่ได้อีก' : 'คุณสามารถกลับเข้ามาเริ่มการสนทนาในห้องนี้ได้อีกครั้ง จนกว่าแพทย์จะทำการปิดเคส' }</p>

                    { localStorage.getItem('currentStatusRoom')==='room_start' ? 
                        <div className="grid grid-cols-2 gap-2">
                            <BtnSecondary type="button" text="จบการสนทนา" addclass="text-black" action={()=> {endCallEvent(appointmentID)}} /> 
                            <BtnPrimary type="button" text="ไม่, สนทนาต่อ" action={()=> { modalEvent('close','modal-leave')}} /> 
                        </div>
                        : 
                        <div className="grid grid-cols-2 gap-2">
                            <BtnSecondary type="button" text="ตกลง" addclass="text-black" action={()=> { leaveEvent() }} /> 
                            <BtnPrimary type="button" text="ยกเลิก" action={()=> { modalEvent('close','modal-leave')}} /> 
                        </div> 
                    }
                </div>

                {/* Modal End */}
                <div id="modal-end" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconSuccess} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">การสนทนาเสร็จสิ้น</h1>
                    <p className="text-gray-2 mb-4 text-sm">แพทย์จะทำการสรุปผลการตรวจให้คุณภายใน 10 นาที</p>

                    <div className="mb-4">
                        <p className="mb-4 text-md">คุณต้องการรับยาอย่างไร (ถ้ามี) ?</p>

                        <div className="flex flex-col text-left mb-3" onChange={(e)=> prescriptionChoice = e.target.value /*setPrescriptionChoice(e.target.value)*/}>
                            <label className="flex container-radio-btn" htmlFor="pickup">
                                รับยาเอง
                                <input
                                    type="radio"
                                    value="pickup"
                                    id="pickup"
                                    name="prescriptionchoice"
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="flex container-radio-btn" htmlFor="delivery">
                                ให้จัดส่ง
                                <input
                                    type="radio"
                                    value="delivery"
                                    id="delivery"
                                    name="prescriptionchoice"
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="flex container-radio-btn" htmlFor="nomedical">
                                ไม่มียา หรือ ไม่ต้องการรับยา
                                <input
                                    type="radio"
                                    value={''}
                                    id="nomedical"
                                    name="prescriptionchoice"
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                        
                    <BtnPrimary type="button" text="ตกลง" action={()=> { fetchPrescriptionChoice(appointmentID) }} /> 
                        
                </div>
            </div>

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false); navigate('/')}} /> 
            </Modal>

            {/* Modal Error Virtual Background */}
            <Modal
                isOpen={modalErrVirtualBg}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">ไม่สามารถใช้งานได้</h1>
                <p className="text-gray-2 mb-4 text-md">เนื่องจากระบบไม่รองรับการใช้งาน</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErrVirtualBg(false);}} /> 
            </Modal>
        </div>
    )
}

export default VideoCallV2