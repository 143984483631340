import React from 'react'

function LoadingNoLogo(props) {
    const { status, color, style } = props
    return (
        <div className={`${status} flex flex-col h-screen justify-center items-center`} style={style}>
            <div className="text-center">
                <div className={`spinner-border ${color?color:'text-darkblue'} animate-spin inline-block w-8 h-8 border-4 rounded-full`} role="status">
                    <span className="visually-hidden">...{status} on Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default LoadingNoLogo