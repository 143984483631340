import React, { useEffect, useContext, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ItemClinic from '../../components/ItemClinic'
import IconBack from '../../assets/img/ic-back.svg'
import IconProfileSmall from '../../assets/img/ic-profile-small.svg'
import { BtnTransparent } from '../../components/FormItems'
import ItemDoctor from '../../components/ItemDoctor'

import { TokenContext } from '../../App'

let option = ''

function SearchResultDoctor() {
  const navigate = useNavigate()
  const location = useLocation()
  const { token, setDataAppointment } = useContext(TokenContext)
  const { searchinput, title, item, clinic_id, specialty_id, keyword, backlink, type } = location?.state || ''
  const [dataItemConsult, setDataItemConsult] = useState({})
  const [searchInput, setSearchInput] = useState({
    search: ''
  })
  const [windowH] = useState(window.innerHeight)
  const [fetchMsg, setFetchMsg] = useState(`...Loading...`)


  const fetchDoctor = useCallback(async () => {
    if(searchInput.search) {
      option = `&search=${searchInput.search}`
    } else {
      option = ''
    }

    let url = ''
    if(type==='clinic') {
      url = `/api/clinic/${clinic_id}/doctor${!!option?'?'+option.substring(1):''}`
    } else if(type==='specialty') {
      url = `/api/search/doctor_by_specialty?specialty=${specialty_id}${!!option?option:''}`
    } else {
      url = `/api/search/doctor_by_keyword?keyword=${keyword}${!!option?option:''}`
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        // "Accept": "application/json",
        'Authorization': 'Bearer ' + token
      }
    });

  res
      .json()
      .then((response) => {
          // console.log('searchResultClinic : ',response)
          if(response.total){
            setDataItemConsult(response)
            setFetchMsg('')
            setDataAppointment('')
          } else {
            setFetchMsg('ไม่พบข้อมูล')
          }
      })
      .catch((err)=> { console.log(err); setFetchMsg('!! ไม่สามารถทำรายการได้ !!') })
  },[clinic_id, keyword, searchInput.search, setDataAppointment, specialty_id, token, type])

  useEffect(() => {
     // check token
    if(!token) {
      navigate('/')
    }

    fetchDoctor()
    // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
    
    return () => {
      option=''
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic_id, navigate, token])

  const handleSearchInput = (e) => {
    setSearchInput({...searchInput, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // console.log(searchInput)
    fetchDoctor()
  }

  const gotoDetailDoctor = (id) => {
    if(type==='clinic') {
      navigate('/detaildoctor', {
        state: {
          doctor_id: id,
          clinic_id: item.id,
          specialty_id: '',
          item: item,
          type: type,
          searchinput: searchinput,
          title: title,
          backlink: backlink,
        }
      })
    } else if(type==='specialty') {
      navigate('/detaildoctor', {
        state: {
          doctor_id: id,
          clinic_id: '',
          specialty_id: specialty_id,
          keyword: '',
          item: '',
          type: type,
          searchinput: searchinput,
          title: title,
          backlink: backlink,
        }
      })
    } else {
      navigate('/detaildoctor', {
        state: {
          doctor_id: id,
          clinic_id: '',
          specialty_id: '',
          keyword: keyword,
          item: '',
          type: type,
          searchinput: searchinput,
          title: title,
          backlink: backlink,
        }
      })
    }

    
  }
  

  return (
    <div className="search-result-page p-5">
      <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" action={()=>{ 
        type==='clinic' || type===undefined ?
          !!searchinput.search && !searchinput.province ?
              navigate('/searchresult', {
                state: {
                  searchinput: searchinput,
                  title: title, 
                }
              })
            : navigate('/searchbyclinic', { state: { searchinput: searchinput }})
          :
          navigate(backlink, { state: { searchinput: searchinput }})
        } 
      }/>

      {
        type === 'clinic' ? <ItemClinic dataItem={item} item={item} title={title} searchinput={searchinput} backlink="/searchresultdoctor" clinic_id={clinic_id} type="clinic" /> : <h1 className="font-promptsemibold"><span dangerouslySetInnerHTML={{__html: title }}></span></h1>
      }

      {/* search  */}
      <form onSubmit={handleSubmit}>
        <div className={`flex flex-row border  ${!!dataItemConsult.total? '':'bg-gray-light'}  border-form-bd rounded-lg mt-5`}>
              <div className="flex pl-3 pr-1">
                <img src={IconProfileSmall} alt="icon search"  />
              </div>
              {
                dataItemConsult.total ?
                  <input 
                    type="search"
                    name="search"
                    placeholder="ค้นหาแพทย์" 
                    className={`p-2.5 pr-2 w-full rounded-r-lg outline-0 `}
                    onChange={handleSearchInput}
                  />

                : <input disabled placeholder="ค้นหาแพทย์" className={`p-2.5 pr-6 w-full rounded-r-lg outline-0 placeholder-form-bd disabled:bg-gray-light disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none`} />
                  
              }
        </div>
      </form>

      <div className="mt-2" style={{height: windowH - (type==='clinic'?290:type==='specialty'?210:type==='symptom'?200:0)+'px', overflowY: 'auto', overflowX: 'hidden'}}>
        {
          dataItemConsult.total ?
            dataItemConsult?.data?.map((item,i)=> {
              return (        
                <ItemDoctor key={i} dataItem={item} searchinput={searchinput} type="consult" backlink={backlink} onclick={()=> gotoDetailDoctor(item.id)} />
              )
            })
          : <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsg }}></p>
        }
      </div>

    </div>
  )
}

export default SearchResultDoctor