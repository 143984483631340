import React, { useEffect, useContext, useCallback, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Nav from '../../components/Nav'
import IconSearch from '../../assets/img/ic-search.svg'

import { TokenContext } from '../../App'
import NavSearch from '../../components/V2NavSearch'

function SearchBySymptom() {
  const searchRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const { token, setCurrentPage } = useContext(TokenContext)
  const { searchinput } = location?.state
  const [openSuggestBox, setOpenSuggestBox] = useState(false)
  const [dataItemSymptom, setDataItemSymptom] = useState([])
  const [searchInput, setSearchInput] = useState({
    search: '',
    start: 0,
    count: 8,
    id: '',
    province: '',
    district: '',
    latitude: '',
    longitude: ''
  })
  // const [windowH] = useState(window.innerHeight)

  const fetchSymptom = useCallback(async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/search/suggest_keyword`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        // "Accept": "application/json",
        'Authorization': 'Bearer ' + token
      }
    });

  res
      .json()
      .then((response) => {
          // console.log('searchResultSpecialty : ',response)
          setDataItemSymptom(response)
      })
      .catch((err)=> console.log(err))
  },[token])

  useEffect(() => {
    setCurrentPage('/searchbysymptom')

    // check token
    if(!token) {
      navigate('/')
    }

    console.log('searchinput: ',searchinput)
    if(!!searchinput) {
      setSearchInput({...searchInput, search: searchinput.search})
    }

    fetchSymptom()

    return () => {
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSymptom, navigate, setCurrentPage, token])

  const handleSearchInput = (e) => {
    setSearchInput({...searchInput, search: e.target.value})
  }

  const gotoSearchResultDoctor = () => {
    navigate('/searchresultdoctor', {
      state: {
        searchinput: searchInput,
        title: `ผลการค้นหาจากอาการ <span class="font-promptregular text-darkblue">"${searchInput.search}"</span>`, 
        keyword: searchInput.search,
        backlink: '/searchbysymptom',
        type: 'symptom'
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log(searchInput)
    // fetchDoctor()

    // goto SearchResultDoctor
    gotoSearchResultDoctor()
  }

  const handleSuggestInput = (e) => {
    console.log(e.target.value)
    setSearchInput({...searchInput, search: (searchInput.search + ' ' + e.target.value).trim()})
    // gotoSearchResultDoctor()
    searchRef.current.focus()
  }

  
  
  return (
    <div className="search-page px-5">
        <NavSearch currentPage="searchbysymptom" />

        <div className="mt-6 mb-16" >
          <form onSubmit={handleSubmit}>
            <div className={`flex flex-row border border-form-bd rounded-lg mt-5`}>
                <div className="flex pl-3 pr-1">
                  <img src={IconSearch} alt="icon search"  />
                </div>
                <input
                  ref={searchRef} 
                  value={searchInput.search}
                  type="search"
                  name="search"
                  placeholder="โควิด, ปวดหัว, ผื่น, ไอ" 
                  className={`p-2.5 pr-2 w-full rounded-r-lg outline-0 `}
                  onChange={handleSearchInput}
                  onFocus={()=> setOpenSuggestBox(true)}
                  // onBlur={()=> setOpenSuggestBox(false)}
                />
            </div>
            {
              !!openSuggestBox ?  
                <div className="p-3 rounded-lg shadow-lg shadow-gray-4 border border-gray-light mt-1">
                  {
                    dataItemSymptom.map((item,i)=>{
                      return (
                        <input type="button" key={i} className="rounded-full m-1 py-1 px-3 w-fit bg-gray-5 text-gray-1 text-sm" onClick={handleSuggestInput} value={item.keyword} />
                      )
                    })
                  }
                </div>
              : null
            }
           
            
          </form>
        </div>
         
        {/* Nav */}
        <Nav currentPage="search" />
    </div>
  )
}

export default SearchBySymptom