import React from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import th from "date-fns/locale/th";
import moment from 'moment'

import IconPin from '../assets/img/ic-pin.svg'
// import IconStar from '../assets/img/ic-star.svg'
import IconCalendar from '../assets/img/ic-calendar.svg'
import IconNoImg from '../assets/img/ic-img.svg'
import { BtnPrimary } from './FormItems';
import BtnEnterVideoRoom from './BtnEnterVideoRoom';

function ItemDoctor(props) {
    const navigate = useNavigate()
    const { searchinput, dataItem, item, keyword, doctor_id, clinic_id, specialty_id, appointment_id, type, onclick, /*btnonclick,*/ backlink, title, from } = props

    const convertStatus = (value) => {
        let result = ''
        
        switch (value) {
            case 'pending approval':
                result = 'รออนุมัติ'
                break;

            case 'pending_approval':
                result = 'รออนุมัติ'
                break;

            case 'need_payment':
                result = 'ชำระเงิน'
                break;

            case 'not_started':
                result = 'ยังไม่เริ่ม'
                break;

            case 'waiting':
                result = 'รอเข้าพบ'
                break;

            case 'waiting_result':
                result = 'รอผลตรวจ'
                break;
    
            case 'in_progress':
                result = 'กำลังเข้าพบ'
                break;

            case 'ended':
                result = 'เสร็จสิ้น'
                break;

            case 'not_arrive':
                result = 'ไม่ได้เข้าพบ'
                break;

            case 'canceled':
                result = 'ยกเลิก'
                break;

            default:
                result = '-'
                break;
        }
        return result
    }

    const checkNow = (value) => {
        let appointment = format(new Date(value), 'dd-MM-yyyy')
        let nowDate = format(new Date(), 'dd-MM-yyyy')
        let result = (appointment === nowDate)? true : false
        // console.log(appointment,'??',nowDate,' => ',result)
        return result
    }

    const checkStartVideoCall = (value) => {
        let appointmentTime = moment(value).add(-5,'minutes').format('YYYY-MM-DD HH:mm:ss')
        let nowDate = moment().format('YYYY-MM-DD HH:mm:ss')
        let result = moment(nowDate).isAfter(appointmentTime)
        // console.log(appointmentTime,', ',nowDate,', ',result)
        // setStartVideoCall(result)

        return result
    }

    const convertDate = (value) => {

        let selectYearValue = Number(format(new Date(value), 'yyyy')) + 543
        let result = format(new Date(value), 'dd MMM', { locale: th}) + ' ' + selectYearValue
        return result
    }

    const convertTime = (value) => {
        let result = value.substring(10,16)
        return result
    }

    const gotoMap = () => {
        // console.log(dataItem)
        if(dataItem.clinic_latitude && dataItem.clinic_longitude) {
            navigate(
                '/map', { state: 
                    { 
                        dataItem: dataItem, 
                        backlink: backlink, 
                        // title: title, 
                        doctor_id: doctor_id, 
                        specialty_id: specialty_id,
                        clinic_id: clinic_id,
                        appointment_id: appointment_id,
                        type: type, 
                        keyword: keyword, 
                        item: item,
                        title: title,
                        searchinput: searchinput,
                        from: from,
                    } 
                }
            )
        }
    }
    
    return (
        <div className="py-4 flex flex-row w-full border-b border-form-bd" onClick={onclick}>
            <div className="relative mb-2 mr-4">
                <div className={`z-10 w-2.5 h-2.5 ${dataItem?.doctor_status==='red'? 'bg-red':dataItem?.doctor_status==='green'? 'bg-green':dataItem?.doctor_status==='blue'? 'bg-blue':'bg-gray'} border bottom-3 border-white absolute right-0.5 top-0.5 rounded-full`}></div>
                <div className="bg-gray-4 overflow-hidden relative h-14 w-14 xs:w-12 xs:h-12 rounded-full ring-2 ring-white mx-auto flex-none">
                    <img className="absolute bg-gray-4 m-auto left-0 right-0 top-0 bottom-0 flex-none" 
                        src={
                            (!!dataItem?.profile_picture_url)? dataItem?.profile_picture_url : (!!dataItem?.doctor_profile_picture_url)? dataItem.doctor_profile_picture_url : IconNoImg 
                        } 
                        alt="" 
                    />
                </div>
            </div>
            <div className="flex flex-col grow">
                <h1 className="mb-2 font-promptsemibold">
                    {!!dataItem?.title?dataItem?.title+' ':null}
                    {!!dataItem?.first_name?dataItem?.first_name+' ':null}
                    {!!dataItem?.last_name?dataItem?.last_name:null}

                    {/* For Home Appointment */}
                    {!!dataItem?.doctor_title?dataItem?.doctor_title+' ':null}
                    {!!dataItem?.doctor_first_name?dataItem?.doctor_first_name+' ':null}
                    {!!dataItem?.doctor_last_name?dataItem?.doctor_last_name:null} 
                </h1>
               
                <div className="flex flex-col">
                    <div className="flex flex-row mb-3">
                        <div className="grow basis-2/3">
                            {
                                !!dataItem?.specialty1_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{dataItem?.specialty1_name}</div>:null
                            }{
                                !!dataItem?.specialty2_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{dataItem?.specialty2_name}</div>:null
                            }{
                                !!dataItem?.specialty3_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{dataItem?.specialty3_name}</div>:null
                            }

                            {/* For Home Appointment */}
                            {
                                !!dataItem?.doctor_specialty1_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{dataItem?.doctor_specialty1_name}</div>:null
                            }{
                                !!dataItem?.doctor_specialty2_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{dataItem?.doctor_specialty2_name}</div>:null
                            }{
                                !!dataItem?.doctor_specialty3_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{dataItem?.doctor_specialty3_name}</div>:null
                            }
                        </div>
                        {
                            type === 'consult' ? 
                            <div className="basis-1/3 text-right">
                                <p className="text-softred font-promptsemibold text-xl"><span className="mr-1">฿</span>{Number(dataItem?.rate).toLocaleString('en-US')}</p>
                            </div>
                            : null
                        }
                        
                    </div>
                    {
                        type === 'consult' ? 
                            <div className="flex flex-row mb-0">
                                {/* <div className="flex flex-row grow">
                                    <img className="w-3 sm:w-4 mr-2" src={IconStar} alt="icon rating" />
                                    <span className="self-center text-gray-2 text-sm sm:text-md">{dataItem?.rate}</span>
                                </div> */}
                                <div className="flex flex-row grow">
                                    <img className="w-3 sm:w-4 mr-2" src={IconPin} alt="icon location" />
                                    <span className="font-promptsemibold self-center text-gray-2 text-sm sm:text-md">{dataItem?.clinic_name}</span>
                                </div>
                                <div className="grow-0">
                                    <p className="text-gray-2 text-sm sm:text-md">{dataItem?.min_consult_time} นาที</p>
                                </div>
                            </div>
                        : type === 'appointment' ?
                            <div className="flex flex-col">
                                <div className="flex flex-row mb-0">
                                    <div className="flex flex-row grow">
                                        <img className="w-3 mr-2" src={IconCalendar} alt="icon calendar" />
                                        <span className={`self-center ${checkNow(dataItem?.appointment_date) && dataItem.display_status !== 'ended' && dataItem.display_status !== 'waiting_result' && dataItem.display_status !== 'not_arrive' && dataItem.display_status !== 'canceled' ? 'text-softred':'text-gray-2 '} text-sm sm:text-md`}>{ convertDate(dataItem?.appointment_date) } | { convertTime(dataItem?.datetime_from)} น. {
                                            (dataItem?.doctor_min_consult_time || dataItem?.min_consult_time) ? 
                                                <span className="text-gray-2 text-sm sm:text-md">({dataItem?.doctor_min_consult_time || dataItem?.min_consult_time} นาที)</span>
                                            : null
                                        }</span>
                                    </div>
                                </div>
                                <div className="pl-4">
                                    <span className="text-gray-2 text-sm sm:text-md">สถานะ: {!!dataItem?.display_status?convertStatus(dataItem.display_status):'-'}</span>
                                    { dataItem.display_status === 'need_payment' ? <span className="text-softred text-sm sm:text-md">&nbsp;(กรุณาชำระเงินภายใน 5 นาที)</span> : null}
                                </div>
                                
                                {
                                    checkStartVideoCall(dataItem?.datetime_from) && (dataItem.display_status === 'not_started' || dataItem.display_status === 'waiting' || dataItem.display_status === 'in_progress') ?
                                        // <BtnPrimary type="button" text="เริ่มสนทนา" icon={IconCamera} addclass="w-fit px-3 py-1.5 text-sm sm:text-md font-promptregular" action={ btnonclick } />    
                                        <BtnEnterVideoRoom appointmentID={dataItem.id} icon={true}  doctorFirstName={dataItem.doctor_first_name} doctorLastName={dataItem.doctor_last_name} doctorTitle={dataItem.doctor_title} doctorImg={dataItem.doctor_profile_picture_url} addclass="w-fit px-3 py-1.5 text-sm sm:text-md font-promptregular" />        
                                    : dataItem.display_status === 'need_payment' ? 
                                        <BtnPrimary text="ชำระเงิน" action={(e)=> {e.stopPropagation(); navigate('/payment', {state: { doctor_id: dataItem.doctor_id, id: dataItem.id, amount: dataItem.patient_amount, currency: dataItem.patient_currency}})}} addclass="w-fit px-3 py-1.5 text-sm sm:text-md font-promptregular" /> 
                                    : null                    
                                }
                            </div>
                        : type === 'needpayment' ?
                            <div className="flex flex-col">
                                <div className="flex flex-row mb-0">
                                    <div className="flex flex-row grow">
                                        <img className="w-3 mr-2" src={IconCalendar} alt="icon calendar" />
                                        <span className={`self-center ${checkNow(dataItem?.appointment_date) && dataItem.meeting_status !== 'ended' && dataItem.meeting_status !== 'waiting_result' && dataItem.meeting_status !== 'not_arrive' && dataItem.meeting_status !== 'canceled' ? 'text-softred':'text-gray-2 '} text-sm sm:text-md`}>{ convertDate(dataItem?.appointment_date) } | { convertTime(dataItem?.datetime_from)} น. {
                                            (dataItem?.doctor_min_consult_time || dataItem?.min_consult_time) ? 
                                                <span className="text-gray-2 text-sm sm:text-md">({dataItem?.doctor_min_consult_time || dataItem?.min_consult_time} นาที)</span>
                                            : null
                                        }</span>
                                    </div>
                                </div>
                                <div className="pl-4">
                                    <span className="text-gray-2 text-sm sm:text-md">สถานะ: {!!dataItem?.meeting_status?convertStatus(dataItem.meeting_status):'-'}</span>
                                </div>
                                {
                                    dataItem.create_status === 'need_payment' ? 
                                        <BtnPrimary text="ชำระเงิน" action={(e)=> {e.stopPropagation(); navigate('/payment', {state: { doctor_id: dataItem.doctor_id, id: dataItem.id, amount: dataItem.patient_amount, currency: dataItem.patient_currency}})}} addclass="w-fit px-3 py-1.5 text-sm sm:text-md font-promptregular" /> 
                                    : null                    
                                }
                            </div>
                        : 
                            <div className="flex flex-col" onClick={()=> gotoMap() } >
                                <div className="flex flex-row mb-0">
                                    <div className="flex flex-row grow-0 ">
                                        <img className="w-3 sm:w-4 mr-2 self-start" src={IconPin} alt="icon location" />
                                    </div>
                                    <div className="grow">
                                        <p className="font-promptsemibold self-center text-gray-2 text-sm sm:text-md">{dataItem?.clinic_name}</p>
                                        <p className="text-blue text-sm sm:text-md">{dataItem?.clinic_address} {dataItem?.clinic_subdistrict} {dataItem?.clinic_district} {dataItem?.clinic_province} {dataItem?.clinic_zipcode}</p>
                                        <p className="text-gray-2 text-sm sm:text-md">{(dataItem?.distance)? Number(dataItem?.distance / 1000).toLocaleString('en-US')+'กม.': null}</p>
                                    </div>
                                </div>
                            </div>
                        
                    }
                </div>
            </div>
        </div>
    )
}

export default ItemDoctor