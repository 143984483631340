import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import AgoraRTC from "agora-rtc-sdk-ng"


import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient as createClientWs } from 'graphql-ws';

import { gql } from "@apollo/client/core";
import { useSubscription } from "@apollo/react-hooks";


// GraphQL -----------------------------------------------//
function callgraphql () {
    const httpLink = new HttpLink({
        // You should use an absolute URL here
        uri: process.env.REACT_APP_GRAPHQL_HTTPS || '',
        headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
    });

    // Create the subscription websocket link
    const wsLink = new GraphQLWsLink(
        createClientWs({
            url: process.env.REACT_APP_GRAPHQL_WS || '',
            retryAttempts: Infinity,
            shouldRetry: () => true,
            keepAlive: 10000,
            connectionParams: () => {
                return {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                };
            },
        })
    );

    const link = split(
        // split based on operation type
        ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
        },
        wsLink,
        httpLink
    );

    // Create the apollo client
    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: true,
    });

    return apolloClient

}

let isMuteVideo = false
let isMuteMic = false
const VideoCallSdk = () => {
    // const location = useLocation()
    const navigate = useNavigate()
    // const { videoChannelName, videoAppID, videoToken, videoUserID } = location?.state

    const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    let options = {
        // Pass your App ID here.
        appId: "58b31596df494ce09f11dd9e8675eb4d",// "58b31596df494ce09f11dd9e8675eb4d", // '2ab908c9615b474bad7c5bfea6247687',
        // Set the channel name.
        channel: "staging-ThaiDoctor Room 405", // "staging-ThaiDoctor Room 338", // 'TestTD',
        // Pass your temp token here.
        token: "00658b31596df494ce09f11dd9e8675eb4dIAAyXI8YFpBejtr5uvRndfF2rbq3AKNG/34b8d6v+C09lvhlJcS379yDIgA0LDT6fEfZYwQAAQDI+9djAgDI+9djAwDI+9djBADI+9dj",
        // Set the user ID.
        uid: 1,
    };

    let channelParameters = {
        // A variable to hold a local audio track.
        localAudioTrack: null,
        // A variable to hold a local video track.
        localVideoTrack: null,
        // A variable to hold a remote audio track.
        remoteAudioTrack: null,
        // A variable to hold a remote video track.
        remoteVideoTrack: null,
        // A variable to hold the remote user id.s
        remoteUid: null,
    };

    // Dynamically create a container in the form of a DIV element to play the remote video track.
    const remotePlayerContainer = document.createElement("div");
    // Dynamically create a container in the form of a DIV element to play the local video track.
    const localPlayerContainer = document.createElement('div');
    // Specify the ID of the DIV container. You can use the uid of the local user.
    localPlayerContainer.id = options.uid;
    // Set the textContent property of the local video container to the local user id.
    localPlayerContainer.textContent = "Local user " + options.uid;
    // Set the local video container size.
    localPlayerContainer.style.width = "640px";
    localPlayerContainer.style.height = "480px";
    localPlayerContainer.style.padding = "15px 5px 5px 5px";
    // Set the remote video container size.
    remotePlayerContainer.style.width = "640px";
    remotePlayerContainer.style.height = "480px";
    remotePlayerContainer.style.padding = "15px 5px 5px 5px";

    useEffect(() => {


        agoraEngine.on("user-published", async (user, mediaType) => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event.
            await agoraEngine.subscribe(user, mediaType);
            console.log("subscribe success");
            console.log(user.hasAudio)
            // Subscribe and play the remote video in the container If the remote user publishes a video track.
            if (mediaType === "video") {
                // Retrieve the remote video track.
                channelParameters.remoteVideoTrack = user.videoTrack;
                // Retrieve the remote audio track.
                channelParameters.remoteAudioTrack = user.audioTrack;
                // Save the remote user id for reuse.
                channelParameters.remoteUid = user.uid.toString();
                // Specify the ID of the DIV container. You can use the uid of the remote user.
                remotePlayerContainer.id = user.uid.toString();
                channelParameters.remoteUid = user.uid.toString();
                remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
                // Append the remote container to the page body.
                document.body.append(remotePlayerContainer);
                // Play the remote video track.
                channelParameters.remoteVideoTrack.play(remotePlayerContainer);
            }
            // Subscribe and play the remote audio track If the remote user publishes the audio track only.
            if (mediaType === "audio") {
                // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
                channelParameters.remoteAudioTrack = user.audioTrack;
                // Play the remote audio track. No need to pass any DOM element.
                channelParameters.remoteAudioTrack.play();
            }
            // Listen for the "user-unpublished" event.
            agoraEngine.on("user-unpublished", user => {
                console.log(user.uid + " has left the channel");
            });

            console.log(channelParameters.remoteAudioTrack)
        });

        // window.onload = function() {
        //     console.log('onLoadEventClick')
        //     // Listen to the Join button click event.
        //     document.getElementById("join").onclick = async function() {
        //         // Join a channel.
        //         await agoraEngine.join(options.appId, options.channel, options.token, options.uid);
        //         // Create a local audio track from the audio sampled by a microphone.
        //         channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        //         // Create a local video track from the video captured by a camera.
        //         channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        //         // Append the local video container to the page body.
        //         document.body.append(localPlayerContainer);
        //         // Publish the local audio and video tracks in the channel.
        //         await agoraEngine.publish([channelParameters.localAudioTrack, channelParameters.localVideoTrack]);
        //         // Play the local video track.
        //         channelParameters.localVideoTrack.play(localPlayerContainer);
        //         console.log("publish success!");
        //     }
        //     // Listen to the Leave button click event.
        //     document.getElementById('leave').onclick = async function() {
        //         // Destroy the local audio and video tracks.
        //         channelParameters.localAudioTrack.close();
        //         channelParameters.localVideoTrack.close();
        //         // Remove the containers you created for the local video and remote video.
        //         removeVideoDiv(remotePlayerContainer.id);
        //         removeVideoDiv(localPlayerContainer.id);
        //         // Leave the channel
        //         await agoraEngine.leave();
        //         console.log("You left the channel");
        //         // Refresh the page for reuse
        //         window.location.reload();
        //     }
        // }

        

    return () => {
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const videoCamEvent = async () => {
        console.log(channelParameters)
        if(isMuteVideo === false) {
            // Mute the local video.
            channelParameters.localVideoTrack.setEnabled(false);
            // Update the button text.
            document.getElementById(`control-videocam`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto">VDO OFF</div>`;;
            isMuteVideo = true;
            // setIconMuteVideo(true)
        } else {
            // Unmute the local video.
            channelParameters.localVideoTrack.setEnabled(true);
            // Update the button text.
            document.getElementById(`control-videocam`).innerHTML = `<div class="bg-white rounded-full p-1 w-fit m-auto">VDO ON</div>`;
            isMuteVideo = false;
            // setIconMuteVideo(false)
        }
    }

    const micEvent = async () => {
        console.log(channelParameters)
        if(isMuteMic === false) {
            // Mute the local video.
            channelParameters.localAudioTrack.setEnabled(false);
            // Update the button text.
            document.getElementById(`control-mic`).innerHTML = `<div class="bg-red rounded-full p-1 w-fit m-auto">MIC OFF</div>`;;
            isMuteMic = true;
            // setIconMuteVideo(true)
        } else {
            // Unmute the local video.
            channelParameters.localAudioTrack.setEnabled(true);
            // Update the button text.
            document.getElementById(`control-mic`).innerHTML = `<div class="bg-white rounded-full p-1 w-fit m-auto">MIC ON</div>`;
            isMuteMic = false;
            // setIconMuteVideo(false)
        }
    }


    const removeVideoDiv = (elementId) => {
        console.log("Removing " + elementId + "Div");
        let Div = document.getElementById(elementId);
        if (Div) {
            Div.remove();
        }
    };

    const joinEvent = async () => {
        // Join a channel.
        await agoraEngine.join(options.appId, options.channel, options.token, options.uid);
        // Create a local audio track from the audio sampled by a microphone.
        channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Create a local video track from the video captured by a camera.
        channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        // Append the local video container to the page body.
        document.body.append(localPlayerContainer);
        // Publish the local audio and video tracks in the channel.
        await agoraEngine.publish([channelParameters.localAudioTrack, channelParameters.localVideoTrack]);
        // Play the local video track.
        channelParameters.localVideoTrack.play(localPlayerContainer);
        console.log("publish success!");
    }

    const leaveEvent = async () => {
        // Destroy the local audio and video tracks.
        channelParameters.localAudioTrack.close();
        channelParameters.localVideoTrack.close();
        // Remove the containers you created for the local video and remote video.
        removeVideoDiv(remotePlayerContainer.id);
        removeVideoDiv(localPlayerContainer.id);
        // Leave the channel
        await agoraEngine.leave();
        console.log("You left the channel");
        // Refresh the page for reuse
        window.location.reload();
        navigate('/videowaitingroom')
    }

    // Subscription ------------------------------------------//
    const USERS_ADDED_SUBSCRIPTION = gql`
        subscription onEventAddedType {
            eventAdded {
                event
                doctor_id
                patient_id
                datetime_from
                datetime_to
                appointment_id
            }
        }
    `;

    function EventSubscription() {
        const { loading, error, data } = useSubscription(USERS_ADDED_SUBSCRIPTION);

        if (loading) return null;
        if (error) {
        console.log(`Error! ${error.message}`);
            return false
        }

        if (!loading && data) {
            // const { added } = data.eventAdded;
            console.log('[Event]:', data.eventAdded.event+'!!',)

        } else {
            console.log('Listening...')
            return (<h4>Listening...</h4>);
        }
    }
    
    return (
        <div className="row">
            <ApolloProvider client={callgraphql()}>
                <EventSubscription />
                <h1>VDO SDK !!!</h1>
                <div className="flex">
                    <button type="button" id="join" onClick={joinEvent}>Join</button>
                    &nbsp; | &nbsp;
                    <button type="button" id="leave" onClick={leaveEvent}>Leave</button>
                    &nbsp; | &nbsp;

                    <div id="control-videocam" className="m-auto" onClick={videoCamEvent}>
                        <div className="bg-white rounded-full p-1 w-fit m-auto">
                            VDO ON
                        </div>
                    </div>
                    <div id="control-mic" className="m-auto" onClick={micEvent}>
                        <div className="bg-white rounded-full p-1 w-fit m-auto">
                            MIC ON
                        </div>
                    </div>
                </div>
            </ApolloProvider>
        </div>
    )
}

export default VideoCallSdk