import React, { useEffect, useContext, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Nav from '../../components/Nav'

import { TokenContext } from '../../App'
import NavSearch from '../../components/V2NavSearch'

function SearchBySpecialty() {
  const navigate = useNavigate()
  const { token, setCurrentPage } = useContext(TokenContext)
  const [dataItemSpecialty, setDataItemSpecialty] = useState([])
  const [showDataItemSpecialty, setShowDataItemSpecialty] = useState([])
  const [showAmount, setShowAmount] = useState((window.innerWidth <= 320)?5:7)
  const [searchInput] = useState({
    search: '',
    start: 0,
    count: 8,
    id: '',
    province: '',
    district: '',
    latitude: '',
    longitude: ''
  })
  const [windowH] = useState(window.innerHeight)

  const genItem = useCallback( (res) => {
    // console.log('gen res: ',res)
    let result = []
    let amountLoop = showAmount

    if(res.length < showAmount) {
      amountLoop = res.length
    } 

    for(let i=0; i<amountLoop; i++) {
      result.push(res[i])
    }
    // console.log('gen result: ',result)
    setShowDataItemSpecialty(result)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showAmount])

  const fetchSpecialty = useCallback(async (startValue) => {
    let start = searchInput.start + startValue
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/search/suggest_specialty?start=${start}&count=${searchInput.count}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        // "Accept": "application/json",
        'Authorization': 'Bearer ' + token
      }
    });

  res
      .json()
      .then((response) => {
          // console.log('searchResultSpecialty : ',response)
          setDataItemSpecialty(response)
          genItem(response)
      })
      .catch((err)=> console.log(err))
  },[genItem, searchInput.count, searchInput.start, token])

  useEffect(() => {
    setCurrentPage('/searchbyspecialty')

    // check token
    if(!token) {
      navigate('/')
    }

    fetchSpecialty(0)

    // if(window.innerWidth <= 320) {
    //   setShowAmount(5)
    // }
  
    return () => {
      
    }
  }, [fetchSpecialty, navigate, setCurrentPage, token])

  // goto SearchResultDoctor
  const gotoSearchResultDoctor = (id, name) => {
    navigate('/searchresultdoctor', {
      state: {
        searchinput: searchInput,
        title: `ผลการค้นหาจากความเชี่ยวชาญ <span class="font-promptregular text-darkblue">"${name}"</span>`, 
        specialty_id: id,
        backlink: '/searchbyspecialty',
        type: 'specialty'
      }
    })
  }
  
  return (
    <div className="search-page px-5">
        <NavSearch currentPage="searchbyspecialty" />

        <div className="mt-6 mb-16" >
          <h1 className="font-promptsemibold">ค้นหาจากความเชี่ยวชาญ</h1>
          <div className="" style={{height: windowH - 388+'px', overflowY: 'auto', overflowX: 'hidden'}}>
          {
            showDataItemSpecialty.length ?
              showDataItemSpecialty.map((item,i)=>{
                return(
                  <button key={i} onClick={()=> gotoSearchResultDoctor(item.id, item.name)} className={` w-1/4 xs:w-1/3 text-center py-2`}>
                    <div className={`border-card-active border bg-gray-4 relative h-12 w-12 rounded-full ring-2 ring-white mb-2 mx-auto `}>
                        {
                          !!item.icon_url ?
                            <img className={`inline-block absolute m-auto left-0 right-0 top-0 bottom-0 `} src={item.icon_url} alt="icon search menu" />
                          : null
                        }
                    </div>
                    <p className="text-xs">{item.name}</p>
                </button>
                )
              })
              : 
                <div className="text-center"><p className="my-5 text-sm">ไม่พบข้อมูล</p></div>
          }
          {
            showAmount < dataItemSpecialty.length ?
              <button className={` w-1/4 xs:w-1/3 text-center py-2`} onClick={()=>{ setShowAmount(dataItemSpecialty.length)}}>
                  <div className={`border-card-active border bg-gray-4 relative h-12 w-12 rounded-full ring-2 ring-white mb-2 mx-auto `}>
                      <p className={`flex items-center justify-center absolute m-auto left-0 right-0 top-0 bottom-0 `} >
                        <span className="text-card-active text-xl">{ dataItemSpecialty.length - showAmount}</span> <span className="text-card-active text-2xl">+</span></p>
                  </div>
                  <p className="text-xs">&nbsp;</p>
              </button>
              : null
          }
          </div>
        </div>

        {/* Nav */}
        <Nav currentPage="search" />
    </div>
  )
}

export default SearchBySpecialty