import { createContext, useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import './App.scss';
import { Helmet } from "react-helmet";

import Home from './pages/patient/V2Home'
import Login from './pages/patient/V2Login'
import Otp from './pages/patient/Otp'
import OtpCode from './pages/patient/OtpCode';
import TermCondition from './pages/patient/TermCondition';
import Signup from './pages/patient/Signup';
import Privacy from './pages/patient/Privacy';
import Search from './pages/patient/Search';
import SearchByClinic from './pages/patient/SearchByClinic';
import SearchBySpecialty from './pages/patient/SearchBySpecialty';
import SearchBySymptom from './pages/patient/SearchBySymptom';
import SearchResult from './pages/patient/SearchResult';
import SearchResultDoctor from './pages/patient/SearchResultDoctor';
import DetailDoctor from './pages/patient/DetailDoctor';
import Map from './pages/patient/Map';
import HistoryMedical from './pages/patient/HistoryMedical';
import HistoryMedicalView from './pages/patient/HistoryMedicalView';
import HistoryAppointment from './pages/patient/HistoryAppointment';
import SummaryAppointment from './pages/patient/SummaryAppointment';
import SummaryHealthCheck from './pages/patient/SummaryHealthCheck';
import VideoWaitingRoom from './pages/patient/VideoWaitingRoom';
import VideoCall from './pages/patient/VideoCallV2';
import VideoCallDoctor from './pages/patient/VideoCallDoctor';
import VideoChatDoctor from './pages/patient/VideoChatDoctor';
import Profile from './pages/patient/Profile';
import ConditionDetail from './pages/patient/ConditionDetail';
import ResultHealthCheck from './pages/patient/ResultHealthCheck';
import V2SearchByDoctor from './pages/patient/V2SearchByDoctor';
import Payment from './pages/patient/Payment';
import PaymentStatus from './pages/patient/PaymentStatus';
import PaymentPromptPay from './pages/patient/PaymentPromptPay';
import GraphQLPaymentStatus from './pages/patient/GraphQLPaymentStatus';
import Download from './pages/patient/Download';

const TokenContext = createContext()

function App() {
  const apiXKey = 'W9nzF7tgFrda5vH9ISpus5GL'
  const [token, setToken] = useState('')
  const [profile, setProfile] = useState({})
  const [consentTerm, setConsentTerm] = useState(1)
  const [consentPrivacy, setConsentPrivacy] = useState(1)
  const [registered, setRegistered] = useState(0)
  const [currentPage, setCurrentPage] = useState('/home')
  const [firstRegister, setFirstRegister] = useState(false)
  const [dataLatLng, setDataLatLng] = useState({
    latitude: null,
    longitude: null
  })
  const [dataAppointment, setDataAppointment] = useState('')
  const [historyMedicalStatus, setHistoryMedicalStatus] = useState(false)
  const [historyMedicalData, setHistoryMedicalData] = useState('')
  const [statusRoom, setStatusRoom] = useState('waiting_room')


  useEffect(() => {
    (async()=>{
        // Get Location Lat, Lng
        const getLocation = () => {
            if (!navigator.geolocation) {
            console.log('Geolocation is not supported by your browser');
            } else {
            console.log('Locating...');
            navigator.geolocation.getCurrentPosition(showPosition, showError, {
                    enableHighAccuracy: false,
                    timeout: 5000,
                    maximumAge: 0
                })
            }
        }

        const showPosition = (position) => {
            console.log(position.coords.latitude,', ',position.coords.longitude);
            setDataLatLng({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            })
    
            // if(!callingApi) {
            //   fetchSearch('list', position.coords.latitude, position.coords.longitude)
            // }
        }
    
        const showError = (error) => {
            // eslint-disable-next-line default-case
            switch(error.code) {
            //   case error.PERMISSION_DENIED:
            //     fetchSearch('list',null,null)
            //     console.warn("Geolocation [warning]: User denied the request for Geolocation.")
            //     break;
            //   case error.POSITION_UNAVAILABLE:
            //     fetchSearch('list',null,null)
            //     console.warn("Geolocation [warning]: Location information is unavailable.")
            //     break;
            //   case error.TIMEOUT:
            //     fetchSearch('list',null,null)
            //     console.warn("Geolocation [warning]: The request to get user location timed out.")
            //     break;
            //   case error.UNKNOWN_ERROR:
            //     fetchSearch('list',null,null)
            //     console.warn("Geolocation [warning]: An unknown error occurred.")
            //     break;
    
            default:
                // fetchSearch('list',null,null)
                console.warn("Geolocation [warning]: An unknown error occurred.")
                break;
    
    
            }
        }
    
        getLocation()
        
  
    })()
    
    return () => {
        
    }
}, [setDataLatLng])


  useEffect(() => {
    console.log('V.1.0.61 | 20230401')
    // Get & Set Clinic ID
    const urlSearchParams = new URLSearchParams(window.location.search);

    if(urlSearchParams.has('clinic_id')) {
      const clinicidValue = urlSearchParams.get('clinic_id')
  
      if(clinicidValue) {
        localStorage.setItem('clinic_id',clinicidValue)
      }
      console.log('clinic_id: ', clinicidValue)

    } else {
      console.log('has not clinic_id')
    }

    
    // eslint-disable-next-line no-unused-vars
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion); 
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset=nAgt.indexOf("OPR"))!==-1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset+4);
    if ((verOffset=nAgt.indexOf("Version"))!==-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In MS Edge, the true version is after "Edg" in userAgent
    else if ((verOffset=nAgt.indexOf("Edg"))!==-1) {
    browserName = "Microsoft Edge";
    fullVersion = nAgt.substring(verOffset+4);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset+7);
    if ((verOffset=nAgt.indexOf("Version"))!==-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
              (verOffset=nAgt.lastIndexOf('/')) ) 
    {
    browserName = nAgt.substring(nameOffset,verOffset);
    fullVersion = nAgt.substring(verOffset+1);
    if (browserName.toLowerCase()===browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!==-1)
      fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!==-1)
      fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
    fullVersion  = ''+parseFloat(navigator.appVersion); 
    majorVersion = parseInt(navigator.appVersion,10);
    }

    console.log('----------------------------------------')
    console.log('Browser name  = '+browserName)
    console.log('Full version  = '+fullVersion)
    console.log('Major version = '+majorVersion)
    console.log('navigator.appName = '+navigator.appName)
    console.log('navigator.userAgent = '+navigator.userAgent)
    console.log('----------------------------------------')
    console.log(JSON.stringify({
      isAndroid: /Android/.test(navigator.userAgent),
      isCordova: !!window.cordova,
      isEdge: /Edge/.test(navigator.userAgent),
      isFirefox: /Firefox/.test(navigator.userAgent),
      isChrome: /Google Inc/.test(navigator.vendor),
      isChromeIOS: /CriOS/.test(navigator.userAgent),
      isChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
      isIE: /Trident/.test(navigator.userAgent),
      isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
      isOpera: /OPR/.test(navigator.userAgent),
      isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
      // eslint-disable-next-line no-undef
      isTouchScreen: ('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch),
      isWebComponentsSupported: 'registerElement' in document && 'import' in document.createElement('link') && 'content' in document.createElement('template')
    }, null, '  '));
    console.log('----------------------------------------')
    return () => {
      
    }
  }, [])
  

  return (
    <TokenContext.Provider value={{ apiXKey, token, consentTerm, consentPrivacy, registered, currentPage, firstRegister, dataLatLng, dataAppointment, historyMedicalStatus, historyMedicalData, profile, statusRoom, setCurrentPage, setRegistered, setToken, setConsentTerm, setConsentPrivacy, setFirstRegister, setDataLatLng, setDataAppointment, setHistoryMedicalStatus, setHistoryMedicalData, setProfile, setStatusRoom, }}>
      <Helmet>
        <title>{ localStorage.getItem('clinic_name') }</title>
      </Helmet>
      <div className="App container max-w-md mx-auto">
        
          <BrowserRouter>
            {/* {
              !!localStorage.getItem('payment') ? 
                (JSON.parse(localStorage.getItem('payment')).status === 'waiting') ? <GraphQLPaymentStatus /> : null
              : null
            } */}
            <GraphQLPaymentStatus />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="otp" element={<Otp />} />
              <Route path="otpcode" element={<OtpCode />} />
              <Route path="termcondition" element={<TermCondition />} />
              <Route path="signup" element={<Signup />} />
              <Route path="privacy" element={<Privacy />} />

              <Route path="*" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="search" element={<Search />} />
              <Route path="searchbyclinic" element={<SearchByClinic />} />
              <Route path="searchbyspecialty" element={<SearchBySpecialty />} />
              <Route path="searchbysymptom" element={<SearchBySymptom />} />
              <Route path="searchresult" element={<SearchResult />} />
              <Route path="searchresultdoctor" element={<SearchResultDoctor />} />
              <Route path="detaildoctor" element={<DetailDoctor />} />
              <Route path="historymedical" element={<HistoryMedical />} />
              <Route path="historymedicalview" element={<HistoryMedicalView />} />
              <Route path="historyappointment" element={<HistoryAppointment />} />
              <Route path="summaryappointment" element={<SummaryAppointment />} />
              <Route path="summaryhealthcheck" element={<SummaryHealthCheck />} />
              <Route path="profile" element={<Profile />} />
              <Route path="conditiondetail" element={<ConditionDetail />} />
              <Route path="resulthealthcheck" element={<ResultHealthCheck />} />

              <Route path="map" element={<Map />} />

              <Route path="videowaitingroom" element={<VideoWaitingRoom />} />
              <Route path="videocall" element={<VideoCall />} />
              <Route path="videocalldoctor" element={<VideoCallDoctor />} />
              <Route path="videochatdoctor" element={<VideoChatDoctor />} />

              {/* V2 */}
              <Route path="searchbydoctor" element={<V2SearchByDoctor />} />

              <Route path="payment" element={<Payment />} />
              <Route path="paymentstatus" element={<PaymentStatus />} />
              <Route path="paymentpromptpay" element={<PaymentPromptPay />} />

              <Route path="download" element={<Download />} />
            
            </Routes>
          </BrowserRouter>
      </div>
    </TokenContext.Provider>
  );
}

export { TokenContext };
export default App;
