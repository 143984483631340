import React, { useEffect, useContext, useState } from 'react';
import Modal from 'react-modal'
import { TokenContext } from '../../App'
import { useNavigate } from 'react-router-dom';
import { useLiff } from 'react-liff';
// import axios from 'axios'
import Logo from '../../assets/img/logo-thaidoctor.svg'
import Loading from '../../components/Loading';
import { BtnPrimary } from '../../components/FormItems'

function V2Login () {
  const navigate = useNavigate()
  const { apiXKey, currentPage, setToken, setConsentTerm, setConsentPrivacy, setRegistered, setProfile } = useContext(TokenContext)
  // const [displayName, setDisplayName] = useState('');
  const { error, isLoggedIn, isReady, liff } = useLiff();
  const [modalErr, setModalErr] = useState(false)
  const [loadingIsNotLoggedIn, setLoadingIsNotLoggedIn] = useState(true)

  useEffect(() => {
    if (!isLoggedIn) return;

    (async () => {
      const clinicid = !!localStorage.getItem('clinic_id') ? Number(localStorage.getItem('clinic_id')) : localStorage.getItem('clinic_id')
      const profile = await liff.getProfile();
      // setDisplayName(profile.displayName);
      const accesstokenValue = liff.getAccessToken()

      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/login/line_access_token`, {
          method: 'POST',          
          body: JSON.stringify({
            clinic_id: clinicid
          }),
          headers: {
            "x-application-secret-key": apiXKey,
            "Content-Type": "application/json",
            "Accept": "application/json",
            'Authorization': 'Bearer ' + accesstokenValue
          }
        });
  
        res
          .json()
          .then(res => {
            setProfile(profile)
            localStorage.setItem('profileImg', profile.pictureUrl)

            console.log('Login: ',res)
            // console.log('line_access_token [status]:',res.status, ', line_access_token [data]:', res)

            if(res.registered) {
              // Call back on registered = 1
              navigate(currentPage, {
                state: {
                  apiToken: res.token
                }
              })

            } else {
              // Call back on registered = 0
              if(res.otp) {
                // Call back on otp = 1
                if(res.consent_term) {
                  // Call back on consent_term = 1
                  navigate('/signup')

                } else {
                  // Call back on consent_term = 0
                  navigate('/termcondition', {
                    state: {
                      apiToken: res.token
                    }
                  })
                }
                
              } else {
                // Call back on otp = 0 & registered = 0
                navigate('/otp', {
                  state: {
                    apiToken: res.token
                  }
                })
              }
            }
            setToken(res.token)
            setConsentTerm(res.consent_term)
            setConsentPrivacy(res.consent_privacy)
            setRegistered(res.registered)

            localStorage.setItem('accessTokenApi',res.token)
            // console.log(liff.getProfile())
          })
          .catch(err => {
            console.log(err);
            // setModalErr(true)
          });

    })();
  }, [liff, isLoggedIn, navigate, apiXKey, setToken, setConsentTerm, setConsentPrivacy, setRegistered, currentPage, setProfile]);

  const showDisplayName = () => {
    if (error) { 
      // console.log('Liff Error: ',error)
      return (
        <>
          <div className="w-full mx-auto">
            <img src={Logo} alt="Logo ThaiDoctor" className="w-48 mx-auto my-8" />
          </div>
          <p>Something is wrong.</p>
         
              <p className="text-sm text-gray-3">{error.toString()}</p>
          <button className="App-button bg-gray-6 px-4 py-2 mt-2 rounded-lg" onClick={()=> liff.logout()}>
            Logout
          </button>
        </>
      );
    }

    if (!isReady) return (
      <Loading status="LiffisNotReady" />
      // <div className="flex flex-col h-screen justify-center items-center">
      //   <div className="-translate-y-24 text-center">
      //     <img src={Logo} alt="Logo ThaiDoctor" className="w-20 mx-auto mb-8" />
      //     <div className="spinner-border text-darkblue animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
      //       <span className="visually-hidden">...Loading...</span>
      //     </div>
      //   </div>
      // </div>
      );

    if (!isLoggedIn) {
      // console.info('hi, isNotLoggedIn')
      setTimeout(() => {
        setLoadingIsNotLoggedIn(false)
      }, 1000);

      if(loadingIsNotLoggedIn) {
        return <Loading status="LiffisNotLoggedIn" color="text-blue" />
      } else {
        return (
          <div className="w-full mx-auto">
            <img src={Logo} alt="Logo ThaiDoctor" className="w-48 mx-auto my-8" />
            <BtnPrimary text="Login with LINE" action={liff.login} />
          </div>
        );
      }
    }
    
    if(isLoggedIn) {
      // console.info('hi, isLoggedIn')
    }
    return (
      <Loading status="LiffisLoggedIn" color="text-green" />
      // <div className="flex flex-col h-screen justify-center items-center">
      //   <div className="-translate-y-24 text-center">
      //     <img src={Logo} alt="Logo ThaiDoctor" className="w-20 mx-auto mb-8" />
      //     <div className="spinner-border text-darkblue animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
      //       <span className="visually-hidden">...ThaiDoctor is Loading...</span>
      //     </div>
      //   </div>
      // </div>
    );
  };

  Modal.setAppElement('#root');

  return (
    <div className="flex flex-col items-center p-4">
      { showDisplayName() }

      {/* Modal Error */}
      <Modal
        isOpen={modalErr}
        className="Modal"
        overlayClassName="Overlay"
      >
        <h1>ไม่สามารถทำรายการได้</h1>
        <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} />
      </Modal>
    </div>
  );
};

export default V2Login;