import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    BtnPrimary,
} from '../../components/FormItems'

import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    split,
  } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient as createClientWs } from 'graphql-ws';

import { gql } from "@apollo/client/core";
import { useSubscription } from "@apollo/react-hooks";

// import IconBack from '../../assets/img/ic-back.svg'
// import IconBankBag from '../../assets/img/ic-bankbag.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import IconSuccess from '../../assets/img/ic-success.svg'


function GraphQLPaymentStatus() {
    const { appointment_id } = !!localStorage.getItem('payment') ? JSON.parse(localStorage.getItem('payment')).appointment_id : ''
    const navigate = useNavigate()
    console.log('GRAPHQL Payment Status')


    // Set Modal ---------------------------------------------//
    const modalEvent = useCallback( (action, id) => {
        if(action === 'open') {
            // Countdown 1 sec waiting for prevent open modal duplicate
            setTimeout(() => {
                document.getElementById('modal-overlay-payment-graphql').style.display = 'block'
                document.getElementById(id).style.display = 'block'
            }, 500);

        } else {
            document.getElementById('modal-overlay-payment-graphql').style.display = 'none'
            document.getElementById(id).style.display = 'none'
        }
    },[])

    const fetchPaymentStatue = useCallback (() => {
        (async () => {
            // Fetch detail doctor
            const resPaymentStatus = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointment_id}/payment/status`, {
                method: 'GET',
                headers: {
                "Content-Type": "application/json",
                // "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem("accessTokenApi")
                }
            });
            
        if(resPaymentStatus.status === 200) {
            resPaymentStatus
            .json()
            .then((response) => {
                console.log('Call API success [PaymentStatus]!!')
                // console.log('payment status : ',response)
                // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
                if(!response.success && !response.pending_credit_card) {
                    if(!!appointment_id) {
                        // type promptpay
                        modalEvent('close','modal-payment-graphql-failure')
                    } else {
                        // type credit
                        modalEvent('open','modal-payment-graphql-failure')
                    }
                    modalEvent('close','modal-payment-graphql-success')
                } 
                if(response.success && !response.pending_credit_card) {
                    modalEvent('open','modal-payment-graphql-success')
                    modalEvent('close','modal-payment-graphql-failure')
                }
            })
            .catch((err)=> { console.log('error [PaymentStatus]: ',err); })
        } else {
            modalEvent('open','modal-payment-graphql-failure')
            modalEvent('close','modal-payment-graphql-success')
        }
            
        })();
    },[appointment_id, modalEvent])
            
    // GraphQL -----------------------------------------------//
    function callgraphql () {
        console.log(!!appointment_id?'*** Call GRAPH QL (Payment PROMPTPAY Status) ***':'*** Call GRAPH QL (Payment Status) ***')
        const httpLink = new HttpLink({
            // You should use an absolute URL here
            uri: process.env.REACT_APP_GRAPHQL_HTTPS || '',
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessTokenApi")}`,
            },
        });

        // Create the subscription websocket link
        const wsLink = new GraphQLWsLink(
            createClientWs({
                url: process.env.REACT_APP_GRAPHQL_WS || '',
                retryAttempts: Infinity,
                shouldRetry: () => true,
                keepAlive: 10000,
                connectionParams: () => {
                    return {
                        Authorization: `Bearer ${localStorage.getItem("accessTokenApi")}`,
                    };
                },
            })
        );

        const link = split(
            // split based on operation type
            ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
            },
            wsLink,
            httpLink
        );

        // Create the apollo client
        const apolloClient = new ApolloClient({
            link,
            cache: new InMemoryCache(),
            connectToDevTools: true,
        });

        return apolloClient

    }

    // Subscription ------------------------------------------//
    const USERS_ADDED_SUBSCRIPTION = gql`
    subscription onEventAddedType {
        eventAdded {
            event
            doctor_id
            patient_id
            datetime_from
            datetime_to
            appointment_id
            clinic_id
        }
    }
    `;

    function EventSubscription() {
        const { loading, error, data } = useSubscription(USERS_ADDED_SUBSCRIPTION);

        if (loading) return null;
            if (error) {
                console.log(`Error! ${error.message}`);
                return false
            }

        if (!loading && data) {
            // const { added } = data.eventAdded;
            console.log('[Event]:', data.eventAdded.event+' !! (Checking Payment Status)',)
            // if(data.eventAdded.event === 'keepalive'){
            //     modalEvent('open', 'modal-payment-graphql-waiting')
            // }

            if(data.eventAdded.event === 'keepalive'){
                modalEvent('close','modal-payment-graphql-failure')
                modalEvent('close', 'modal-payment-graphql-success')
            }

            if(data.eventAdded.event === 'payment_success_credit_card'){
                modalEvent('close','modal-payment-graphql-failure')
                modalEvent('open', 'modal-payment-graphql-success')
            }

            if(data.eventAdded.event === 'payment_success_promptpay') {
                modalEvent('close','modal-payment-graphql-failure')
                modalEvent('open', 'modal-payment-graphql-success')
            }

            if(data.eventAdded.event === 'payment_failure_credit_card') {
                modalEvent('close','modal-payment-graphql-success')
                modalEvent('open','modal-payment-graphql-failure')
            }

            // Fetch Payment status
            if(!!appointment_id) {
                fetchPaymentStatue()
            }

        } else {
            console.log('Listening...')
            return (<h4>Listening...</h4>);
        }
    }

    useEffect(() => {
    
        return () => {
            
        }
    }, [])
    
    return (
        <div>
            <ApolloProvider client={callgraphql()}>
                <EventSubscription />
            </ApolloProvider>

            {/* Modal & Overlay */}
            <div id="modal-overlay-payment-graphql" className="fixed w-full h-full left-0 top-0 right-0 bottom-0 m-auto z-20 p-10 bg-black/50 hidden">
                {/* Modal Payment Failure */}
                <div id="modal-payment-graphql-waiting" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">กำลังดำเนินการชำระ</h1>
                    <p className="text-gray-2 mb-4 text-md">กรุณารอระบบชำระเงิน</p>

                    {/* <BtnPrimary type="button" text="กลับไป"   action={()=>{
                        navigate('/payment', {state: { doctor_id: doctor_id, id: appointment_id, amount: amount, currency: currency}})
                    }} /> */}
                </div>
                
                {/* Modal Payment Failure */}
                <div id="modal-payment-graphql-failure" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconFailed} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">การชำระเงินไม่สำเร็จ</h1>
                    <p className="text-gray-2 mb-4 text-md">กรุณาติดต่อธนาคารของท่านหรือลองอีกครั้ง</p>

                    <BtnPrimary type="button" text="ปิด"   action={()=>{
                        navigate('/payment', {state: { 
                            doctor_id: JSON.parse(localStorage.getItem('payment')).doctor_id, 
                            id: JSON.parse(localStorage.getItem('payment')).appointment_id, 
                            amount: JSON.parse(localStorage.getItem('payment')).amount, 
                            currency: JSON.parse(localStorage.getItem('payment')).currency}
                        })

                        modalEvent('close','modal-payment-graphql-failure')
                    }} />
                </div>

                {/* Modal Payment Success */}
                <div id="modal-payment-graphql-success" className="absolute text-center left-0 top-0 right-0 bottom-0 m-auto hidden w-11/12 h-fit py-5 px-8 bg-white rounded-lg">
                    <img src={IconSuccess} alt="logo thaidoctor error" className="mx-auto my-4" />
                    <h1 className="font-promptsemibold mb-0 text-lg">การชำระเงินสำเร็จ</h1>
                    <p className="text-gray-2 mb-4 text-md">กรุณาอธิบายอาการเบื้องต้น</p>

                    <BtnPrimary type="button" text="ถัดไป"   action={()=>{
                        navigate('/historymedical', {
                            state: {
                                id: JSON.parse(localStorage.getItem('payment')).appointment_id,
                                symptom: null,
                                symptom_time: null,
                                has_allergy: null,
                                allergy_detail: null,
                                chronic_condition: null,
                                height: null,
                                weight: null,
                                type: null
                            }
                        })
                        modalEvent('close','modal-payment-graphql-success')
                    }} />
                </div>
            </div>
        </div>
    )
}

export default GraphQLPaymentStatus