import React, { useState, useContext, useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  BtnPrimary, /*BtnSecondary,*/ InputTel, SelectTelCode,
} from '../../components/FormItems'
// import axios from 'axios'

import logoThaiDoctor from '../../assets/img/logo-thaidoctor.svg'
import LogoOTP from '../../assets/img/logo-otp.svg'

import { TokenContext } from '../../App'

function Otp() {
  const navigate = useNavigate()
  const { token } = useContext(TokenContext)
  const { register, handleSubmit, /*watch,*/ formState: { errors } } = useForm()
  const [modalErr, setModalErr] = useState(false)
  const [checkTelephoneErrMsg, setCheckTelephoneErrMsg] = useState('กรุณาติดต่อผู้ดูแลระบบ')
  // const [checkTelephoneErrMsgEn, setCheckTelephoneErrMsgEn] = useState('')
  // const [sendOtpErrMsg, setSendOtpErrMsg] = useState('')
  // const [errCodeCheckTelephone, setErrCodeCheckTelephone] = useState(0)
  // const [errCodeSendOtp, setErrCodeSendOtp] = useState(0)
  const [telephoneNumber, setTelephoneNumber] = useState('')
  const [telephoneCode, setTelephoneCode] = useState('+66')

  useEffect(() => {
    // check token
    if(!token) {
      navigate('/')
    }
  
    return () => {
      
    }
  }, [navigate, token])

  const onSubmit = (data) => { 
    // console.log(data) 
    // console.log('token context: ',token)

    fetchCheckTelephone(data.telephone)

  }
  // console.log(watch("example"))

  const fetchCheckTelephone = async (telNum) => {

    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/register/check_telephone`, {
        method: 'POST',
        body: JSON.stringify({
          "telephone": telNum
        }),
        headers: {
          "Content-Type": "application/json",
          // "Accept": "application/json",
          'Authorization': 'Bearer ' + token
        }
      });

      // check status code
      // console.log(res.status)
      if(res.status === 200) {
        fetchSendOtp(telNum)
        setTelephoneNumber(telNum)
      } else {
        // setErrCodeCheckTelephone(res.status)
        setModalErr(true)
        console.log('ErrorCode[CheckTelephone]:(',res.status,')')
      }

      // check resposne & error
      res
        .text()
        .then(response => {
            console.log(response)
            // setCheckTelephoneErrMsgEn(response)

            if(response === 'Maximum OTP reached') {
              setCheckTelephoneErrMsg('ยื่นขอรหัส OTP เกินจำนวนที่กำหนด')

            } else if(response === 'Telephone already used') {
              setCheckTelephoneErrMsg('หมายเลขนี้ได้ถูกใช้ไปแล้ว กรุณาใช้หมายเลขใหม่')

            } else if(response === 'Not mobile telephone') {
              setCheckTelephoneErrMsg('หมายเลขโทรศัพท์ไม่ถูกต้อง')

            } else {
              setCheckTelephoneErrMsg('ไม่สามารถทำรายการได้')
            }
        })
        .catch(err => {
          console.log(err);
          setModalErr(true)
        });
  }

  const fetchSendOtp = async (telNum) => {
    console.log(telephoneNumber,', ',telNum)

    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/register/send_otp`, {
      method: 'POST',
      body: JSON.stringify({
        "telephone": telephoneNumber || telNum
      }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': 'Bearer ' + token
      }
    });

    // check status code
    if(res.status === 200) {
      console.log(res)
      res
        .text()
        .then(response => {
          console.log(response)
          const refCodeText = String(response)
          const refCode = refCodeText.substr(refCodeText.length - 4)
          navigate('/otpcode', {
            state: {
              telephoneCode: telephoneCode,
              telephone: telephoneNumber || telNum,
              reference: refCode
            }
          })
        })

    } else {
      setModalErr(true)
      // setErrCodeSendOtp(res.status)
      console.log('ErrorCode[sendOTP]:(',res.status,')')
      res
        .text()
        .then(response => {
          console.log(response)
          // setSendOtpErrMsg('ไม่สามารถขอรหัส OTP ได้')
          // if(response === 'Maximum OTP reached') {
          //   setSendOtpErrMsg('ยื่นขอรหัส OTP เกินจำนวนที่กำหนด')
          // } else {
          //     setSendOtpErrMsg('ไม่สามารถขอรหัส OTP ได้')
          // }
        })

    }

    // check resposne & error
    // res.json()
      // .text()
      // .then(response => {
      //   console.log(response)
      //   setSendOtpErrMsg(response)
        
      // })
      // .catch(err => {
      //   console.log(err);
      //   setModalErr(true)
      // })
  }

  Modal.setAppElement('#root');

  return (
    <div className="otp-page px-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <img src={LogoOTP} alt="Logo-OTP" className="mx-auto" />
        
        <div className="my-6 mx-3 text-center">
          <h1 className="font-semibold text-lg">OTP Verification</h1>
          <p className="text-gray-2">กรุณากรอกหมายเลขโทรศัพท์มือถือเพื่อรับรหัส OTP</p>
        </div>

        {/* error message */}
        <div className="mb-3">
          {errors.telephone?.type === 'required' && <p className="text-danger text-sm text-center">กรุณาใส่หมายเลขโทรศัพท์มือถือ</p>}
          {errors?.telephone?.type === 'minLength' && <p className="text-danger text-sm text-center">กรุณาใส่จำนวนตัวเลข 10 หลัก</p>}
          {errors?.telephone?.type === 'maxLength' && <p className="text-danger text-sm text-center">กรุณาใส่จำนวนตัวเลขไม่เกิน 10 หลัก</p>}
          {errors?.telephone?.type === 'pattern' && <p className="text-danger text-sm text-center">กรุณาใส่ตัวเลข 0-9.</p>}
        </div>

        {/* input form */}
        <div className="flex">
          <SelectTelCode name="code" dfValue={telephoneCode} optValue={['+60','+65','+66']} optName={['+60','+65','+66']} change={(e)=>setTelephoneCode(e.target.value)} />

          <InputTel 
            type="tel" addclass="w-full" 
            textplace="Mobile Number" 
            onControl={ register("telephone", { required: true, minLength: {value: 10 }, maxLength: { value: 10 }, pattern: /^[0-9]+$/i  }) }/>
        </div>
        
        {/* hint */}
        <p className="text-gray-3 text-xs mb-3">+66 0999999999</p>
        
        <BtnPrimary type="submit" text="รับรหัส OTP" />
      </form>

      {/* Modal Error */}
      <Modal
        isOpen={modalErr}
        className="Modal"
        overlayClassName="Overlay"
      >
        <img src={logoThaiDoctor} alt="logo thaidoctor" className="mx-auto my-4" />
        <h1 className="font-promptsemibold mb-0 text-lg">ไม่สามารถทำรายการได้</h1>
        <p className="text-gray-2 mb-4 text-md">
          {
             checkTelephoneErrMsg
          }
        </p>
        <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} /> 
        {/* 
          {
            errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
          }

          {
            errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
          } 
        */}
      </Modal>
    </div>
  )
}

export default Otp