import React, { useContext, useEffect, useState} from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { TokenContext } from '../../App'
import {
  BtnPrimary,
} from '../../components/FormItems'
import IconDocGreen from '../../assets/img/ic-document-green.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import IconSuccess from '../../assets/img/ic-success.svg'

let dataSubmit = ''

function HistoryMedical() {
    const navigate = useNavigate()
    const location = useLocation()
    const { token, dataAppointment, /*setDataAppointment*/ historyMedicalStatus, historyMedicalData, } = useContext(TokenContext)
    const [modalNotice, setModalNotice] = useState(false)
    const [modalErr, setModalErr] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)
    const [errMsg/*, setErrMsg*/] = useState('เกิดข้อผิดพลาด')
    const [errSubMsg /*, setErrSubMsg*/] = useState('กรุณาลองใหม่อีกครั้ง')
    const [hasAllergyStatus, setHasAllergyStatus] = useState(location?.state?.has_allergy)
    const { register, handleSubmit, /*watch,*/ reset, formState: { errors }} = useForm({
        defaultValues: {
            symptom: null,
            symptom_time: null,
            has_allergy: '0',
            allergy_detail: null,
            chronic_condition: null,
            height: null,
            weight: null
        }
    })
    // const [dataSubmit, setDataSubmit] = useState({})

    useEffect(() => {
        // check token
        if(!token) {
            navigate('/')
        }
        // console.log(location?.state)
        if(location?.state) {
            reset({
                symptom: location?.state?.symptom || null,
                symptom_time: location?.state?.symptom_time || null,
                has_allergy: String(location?.state?.has_allergy) || '0',
                allergy_detail: location?.state?.allergy_detail || null,
                chronic_condition: location?.state?.chronic_condition || null,
                height: location?.state?.height || null,
                weight: location?.state?.weight || null
            })
        }
        // if(historyMedicalStatus) {
        //     console.log(historyMedicalData)
        //     reset(
        //         {
        //             symptom: historyMedicalData.symptom,
        //             symptom_time: historyMedicalData.symptom_time,
        //             has_allergy: historyMedicalData.has_allergy?'1':'0',
        //             allergy_detail: historyMedicalData.allergy_detail,
        //             chronic_condition: historyMedicalData.chronic_condition,
        //         }
        //     )
        // }
        setHasAllergyStatus(location?.state?.has_allergy)

        return () => {

        }
    }, [location?.state, historyMedicalData, historyMedicalData.allergy_detail, historyMedicalData.chronic_condition, historyMedicalData.has_allergy, historyMedicalData.symptom, historyMedicalData.symptom_time, historyMedicalStatus, navigate, reset, token])

    const fetchAppointment = async () => {

        let appointmentIDNumber = location?.state?.id || dataAppointment.appointment_id

        // call add medical history
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${appointmentIDNumber}`, {
            method: 'PUT',
            body: JSON.stringify({
                "symptom": dataSubmit.symptom,
                "symptom_time": dataSubmit.symptom_time,
                "has_allergy": Number(dataSubmit.has_allergy) || 0,
                "allergy_detail": dataSubmit.allergy_detail,
                "chronic_condition": dataSubmit.chronic_condition,
                "height": Number(dataSubmit.height),
                "weight": Number(dataSubmit.weight)
            }),
            headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'Authorization': 'Bearer ' + token
            }
        });
    
        // check status code
        if(res.status === 200) {
            // console.log(res)
            res
            .text()
            .then(response => {
                // console.log('medical_history_success: ',response)
                setModalSuccess(true)
                // setTimeout(() => {
                //     setModalSuccess(false)
                //     navigate('/summaryappointment', { state: { appointment_id: appointmentIDNumber}}) 
                // }, 3000);
            })
    
        } else {
            res
            .text()
            .then(response => {
                setModalErr(true)
                console.error('medical_history_err: (',res.status,') ',response)
                // setHasErrMsg(response)
                console.error(res.status,' ',response)
            })
            .catch((err)=> console.err(err))
            // setHasErrCode(res.status)
        }
    }

    const handleHasAllergy = (value) => {
        setHasAllergyStatus(Number(value))
    }

    const onSubmit = async (data) => {
        data.has_allergy = hasAllergyStatus
        // console.log( JSON.stringify(data) )
        // setDataSubmit(data)
        dataSubmit = data

        // Check has allergy - detail
        if(!hasAllergyStatus) {
            data.allergy_detail = ''
        }

        if(!data.symptom) {
            setModalNotice(true)
        } else {
            fetchAppointment()
        }
    }

    const gotoSummaryAppointment = () => {
        setModalNotice(false)
        fetchAppointment()
    }

    Modal.setAppElement('#root');

    return (
        <div className="medical-history-page p-5">
           <div className="flex flex-row mb-5">
                <img src={IconDocGreen} className="mr-2" alt="icon calendar"  />
                <h1 className="font-promptsemibold">ข้อมูลประกอบการนัดหมาย</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}><div className="flex flex-col text-left mb-3">
                    <label className="font-promptsemibold mb-2">อธิบายอาการ</label>
                    <textarea row="4" 
                        placeholder="อธิบายอาการ" 
                        className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                        {...register("symptom")}
                        // {...register("first_name", { required: true })} 
                    ></textarea>
                    {/* {
                    errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                    } */}
                </div>

                <div className="flex flex-col text-left mb-3">
                    <label className="font-promptsemibold mb-2">มีอาการนี้มานานแค่ไหน?</label>
                    <input row="4" 
                        placeholder="ระยะเวลามีอาการ" 
                        className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                        {...register("symptom_time")}
                        // {...register("first_name", { required: true })} 
                    />
                    {/* {
                    errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                    } */}
                </div>

                <div className="flex flex-col text-left mb-3">
                    <div className="grid gap-4 grid-cols-2">
                        <div>
                            <label className="font-promptsemibold mb-2">น้ำหนัก (กก.)</label>
                            <input row="4" type="number"
                                step="0.01"
                                placeholder="ระบุน้ำหนัก" 
                                className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                                {...register("weight")}
                                // {...register("first_name", { required: true })} 
                            />
                            {/* {
                            errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                            } */}
                        </div>
                        <div>
                            <label className="font-promptsemibold mb-2">ส่วนสูง (ซม.)</label>
                            <input row="4" type="number"
                                step="0.01"
                                placeholder="ระบุส่วนสูง" 
                                className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                                {...register("height")}
                                // {...register("first_name", { required: true })} 
                            />
                            {/* {
                            errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                            } */}
                        </div>
                    </div>
                </div>

                <p className="font-promptsemibold mb-2">ยาที่แพ้</p>
                <div className="flex flex-col text-left mb-3">
                    <label className="flex container-radio-btn" htmlFor="has_not_allergy">
                        ไม่มี
                        <input
                            {...register("has_allergy")}
                            type="radio"
                            value={'0'}
                            id="has_not_allergy"
                            onChange={(e)=>handleHasAllergy(e.target.value)}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <div className="flex flex-row">
                        <label className="flex self-end container-radio-btn" htmlFor="has_allergy">
                            มี
                            <input
                                {...register("has_allergy")}
                                type="radio"
                                value={'1'}
                                id="has_allergy"
                                onChange={(e)=>handleHasAllergy(e.target.value)}
                            />
                            <span className="checkmark"></span>
                        </label>
                        {
                            hasAllergyStatus ? 
                            <input row="4" 
                                placeholder="โปรดระบุ" 
                                className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                                {...register("allergy_detail")}
                                // {...register("first_name", { required: true })} 
                            />
                            : 
                            <input row="4" 
                                placeholder="โปรดระบุ" disabled
                                className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                                {...register("allergy_detail")}
                                // {...register("first_name", { required: true })} 
                            />

                        }
                       
                        {/* {
                        errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                        } */}
                    </div>
                </div>

                <div className="flex flex-col text-left mb-3">
                    <label className="font-promptsemibold mb-2">โรคประจำตัว</label>
                    <textarea row="4" 
                        placeholder="อธิบายอาการ" 
                        className={`p-2.5 pr-6 mb-1 w-full border border-form-bd rounded-lg outline-0 ${errors?.first_name?.type === "required" && 'border-danger'}`}
                        {...register("chronic_condition")}
                        // {...register("first_name", { required: true })} 
                    ></textarea>
                    {/* {
                    errors?.first_name?.type === "required" && <p className="text-xs text-danger">กรุณากรอกข้อมูล</p>
                    } */}
                </div>

                <BtnPrimary type="submit" text="ถัดไป" addclass="mb-10" />
            </form>

            {/* Modal Error */}
            <Modal
                isOpen={modalErr}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">{ errMsg}</h1>
                <p className="text-gray-2 mb-4 text-md">{errSubMsg}</p>
                <BtnPrimary type="button" text="ปิด" action={()=> {setModalErr(false)}} /> 
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

            {/* Modal Notice */}
            <Modal
                isOpen={modalNotice}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">กรุณาอธิบายอาการ</h1>
                <p className="text-gray-2 mb-4 text-md">เพื่อให้หมอสามารถให้คำปรึกษาได้ดีขึ้น</p>
                
                <div className="grid grid-cols-2 gap-2">
                    <BtnPrimary type="button" text="กรอกในภายหลัง" action={()=> {gotoSummaryAppointment()}} /> 
                    <BtnPrimary type="button" text="ตกลง" action={()=> {setModalNotice(false)}} /> 
                </div>
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>

            {/* Modal Success */}
            <Modal
                isOpen={modalSuccess}
                className="Modal"
                overlayClassName="Overlay"
            >
                <img src={IconSuccess} alt="logo thaidoctor" className="mx-auto my-4" />
                <h1 className="font-promptsemibold mb-0 text-lg">บันทีกเรียบร้อย</h1>
                {/* <p className="text-gray-2 mb-4 text-md">ข้อมูลประกอบการนัดหมาย</p> */}

                <div className="mt-4">
                    <BtnPrimary type="button" text="ถัดไป" action={()=> {navigate('/summaryappointment', { state: { appointment_id: location?.state?.id || dataAppointment.appointment_id, type: location?.state?.type}}) }} /> 
                </div>
                {/* 
                {
                    errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
                }

                {
                    errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
                } 
                */}
            </Modal>
        </div>
    )
}

export default HistoryMedical