import React, { useEffect, useContext, useCallback, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Nav from '../../components/Nav'
import IconProfileSmall from '../../assets/img/ic-profile-small.svg'

import { TokenContext } from '../../App'
import NavSearch from '../../components/V2NavSearch'
import ItemDoctor from '../../components/ItemDoctor'


let listArr = []
let callingApi = false
let option = ''
// let windowHeight = window.innerHeight
// let navSearchHeight = 0

const startAddValue = 20 // for Lazy load
const countValue = 20 // for Lazy load
// let searchInputText = {
//     search: null,
//     start: 0, // ''
//     count: countValue, // ''
//     id: '',
//   }

function V2SearchByDoctor() {
  const navSearchRef = useRef()
  const listSearchRef = useRef()
  const inputRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { token, setCurrentPage } = useContext(TokenContext)
  const { searchinput } = location?.state || ''
  const [dataItemDoctor, setDataItemDoctor] = useState([])
  const [totalItemDoctor, setTotalItemDoctor] = useState(0)
  const [searchInput, setSearchInput] = useState({
    search: '',
    start: 0,
    count: countValue,
    id: '',
    province: '',
    district: '',
    latitude: '',
    longitude: ''
  })
  const [isLazyLoading, setIsLazyLoading] = useState(false)
  // const [windowH] = useState(window.innerHeight)
  const [fetchMsg, setFetchMsg] = useState(`...Loading...`)

  // const fetchSymptom = useCallback(async () => {
  //   const res = await fetch(`${process.env.REACT_APP_API_URL}/api/clinic/current/doctor`, {
  //     method: 'GET',
  //     headers: {
  //       "Content-Type": "application/json",
  //       // "Accept": "application/json",
  //       'Authorization': 'Bearer ' + token
  //     }
  //   });

  // res
  //     .json()
  //     .then((response) => {
  //         console.log('searchDoctor : ',response)
  //         setDataItemDoctor(response.data)
  //         setTotalItemDoctor(response.total)
  //     })
  //     .catch((err)=> console.log(err))
  // },[token])

  useEffect(() => {
    setCurrentPage('/searchbydoctor')

    // check token
    if(!token) {
      navigate('/')
    }

    // Set height of search doctor list
    // navSearchHeight = navSearchRef.current.clientHeight + 206

    // console.log('searchinput: ',searchinput)
    if(!!searchinput) {
      setSearchInput({...searchInput, search: searchinput.search})
    }

    fetchDoctor('list',searchInput.start)

    return () => {
      listArr = []
      option = '' // clear fetch option
      // searchInputText = {
      //     search: null,
      //     start: 0, // ''
      //     count: countValue, // ''
      //     id: '',
      // }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchDoctor = useCallback(async (type, startlazy) => {
    callingApi = true

    option = ''

    searchInput.start = (type==='list') ? 0 : searchInput.start + (startlazy ? Number(startlazy) : 0);
    
    if(searchInput.search) {
      option = option+`&search=${searchInput.search}`
    }
    if(searchInput.start || searchInput.start === 0) {
      // console.log('type: ', type)
      option = option+`&start=${searchInput.start}`
    }
    if(searchInput.count) {
        option = option+`&count=${searchInput.count}`
    }
    if(searchInput.id) {
        option = option+`&id=${searchInput.id}`
    }

    let url = `/api/clinic/${localStorage.getItem('clinic_id')}/doctor${!!option?'?'+option.substring(1):''}`

    const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        // "Accept": "application/json",
        'Authorization': 'Bearer ' + token
      }
    });

  res
      .json()
      .then((response) => {
          callingApi = false
          

          console.log('searchResultDoctor : ',response)
          if(response.total){

            response.data.map((item,i)=>{
              return listArr.push(item)
            })

            if(type==='lazy') {
              setDataItemDoctor(listArr)
            } else {
              setDataItemDoctor(response.data)
            }

            setFetchMsg('')
            setTotalItemDoctor(response.total)

            setSearchInput({...searchInput, 'id': response.id})
          } else {
            setDataItemDoctor([])
            setFetchMsg('ไม่พบข้อมูล')
            setTotalItemDoctor(0)
          }
      })
      .catch((err)=> { console.log(err); setFetchMsg('!! ไม่สามารถทำรายการได้ !!') })

    
    setIsLazyLoading(false)
  },[searchInput, token])

  const handleSearchInput = (e) => {
    setSearchInput({...searchInput, [e.target.name]: e.target.value, 'start': 0})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('start value:',searchInput.start)
    setSearchInput({...searchInput, 'start': 0})
    listSearchRef.current.scrollTo(0, 0)
    // console.log('listSearchRef.current.scrollTop', listSearchRef.current.scrollTop)
    // console.log(searchInput)
    fetchDoctor('list',0)
    listArr = []

    // Blur input search
    inputRef.current.blur()
  }

  const gotoDetailDoctor = (id) => {
      navigate('/detaildoctor', {
        state: {
          doctor_id: id,
          clinic_id: '',
          specialty_id: '',
          keyword: '',
          item: '',
          type: 'searchbydoctor',
          searchinput: searchinput,
          title: '',
          backlink: 'search',
        }
      })
  }

  // Lazy Scrolling 
  const scrolling = () => {

    let scrollH = listSearchRef.current.scrollHeight
    let scrollT = listSearchRef.current.scrollTop
    let elementH = listSearchRef.current.clientHeight
    let scrollD = Math.ceil(scrollT + elementH) + 420

    if(dataItemDoctor.length >= totalItemDoctor) {
        setIsLazyLoading(false)
    } else {
        // console.log('--------------------')
        // console.log('dataItemDoctor.length: ',dataItemDoctor.length)
        // console.log('totalItemDoctor.length: ',totalItemDoctor)
        // console.log('scrollD: ',scrollD)
        // console.log('scrollH: ',scrollH)
        // Scrolling
        if(scrollD >= scrollH) {
            // Scroll Finish
            // alert('Scroll Finish!!')
            setIsLazyLoading(true)

            if(!callingApi) {
                fetchDoctor('lazy',startAddValue) 
            }
        } else {
            setIsLazyLoading(false)
        }
    }
  }

  
  return (
    <div className="search-page px-5">
        <NavSearch innerRef={navSearchRef} currentPage="searchbydoctor" />

        <div className="mt-6 mb-16" >
          
            {/* search  */}
            <form onSubmit={handleSubmit}>
              <div className={`flex flex-row border  ${!!totalItemDoctor? '':'bg-gray-light'}  border-form-bd rounded-lg mt-5`}>
                    <div className="flex pl-3 pr-1">
                      <img src={IconProfileSmall} alt="icon search"  />
                    </div>
                    {/* {
                      totalItemDoctor ? */}
                        <input 
                          ref={inputRef}
                          type="search"
                          name="search"
                          placeholder="ค้นหาแพทย์" 
                          className={`p-2.5 pr-2 w-full rounded-r-lg outline-0 `}
                          onChange={handleSearchInput}
                        />

                      {/* : <input disabled placeholder="ค้นหาแพทย์" className={`p-2.5 pr-6 w-full rounded-r-lg outline-0 placeholder-form-bd disabled:bg-gray-light disabled:text-slate-420 disabled:border-slate-200 disabled:shadow-none`} />
                        
                    } */}
              </div>
            </form>
            
            <div ref={listSearchRef} onScroll={scrolling}  className="mt-2"style={isLazyLoading ? {height: window.innerHeight-420+'px', overflowY: 'hidden', overflowX: 'hidden'} : {height: window.innerHeight-420+'px', overflowY: 'scroll', overflowX: 'hidden'}}>
              {
                totalItemDoctor ? 
                  dataItemDoctor.map((item,i)=>{
                    return (
                      // <div className="p-3 rounded-lg shadow-lg shadow-gray-4 border border-gray-light mt-1">
                        <ItemDoctor key={i} dataItem={item} type="consult"  onclick={()=> gotoDetailDoctor(item.id)} />
                      // </div>
                      )
                  })
                :
                <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsg }}></p>
              }
            </div>

            {
              isLazyLoading ? <p className="text-center mt-8 text-gray-2 w-full">...Loading...</p> : null
            }
            
        </div>
         
        {/* Nav */}
        <Nav currentPage="search" />


        {
            isLazyLoading ? <div className="absolute z-40 bg-black/25 top-0 left-0 w-full h-screen flex items-center justify-center text-white">...Loading...</div> : null
        }
    </div>
  )
}

export default V2SearchByDoctor