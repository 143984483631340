import React, { useEffect, useContext, useState } from 'react'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import ReadMoreReact from 'read-more-react';
import IconBack from '../../assets/img/ic-back.svg'
import IconCalendarSamll from '../../assets/img/ic-calendar-small.svg'
import IconFailed from '../../assets/img/ic-failed.svg'
import { BtnTransparent, BtnPrimary, BtnSecondary } from '../../components/FormItems'

import "react-datepicker/dist/react-datepicker.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { TokenContext } from '../../App'
import ItemDoctor from '../../components/ItemDoctor'

// import DetailDoctorAction from './DetailDoctorAction'
import DetailDoctorSummary from './DetailDoctorSummary';


import DateTimePicker from 'react-datetime-picker';
// import DatePicker from 'react-date-picker';
// import DatePicker, { registerLocale } from "react-datepicker";
import { format } from 'date-fns'
import th from "date-fns/locale/th";
// registerLocale("th", th);


let optionDetailDoctor = ''
let optionSchedule = ''
let fastTrack = false

function DetailDoctor() {
  const navigate = useNavigate()
  const location = useLocation()
  const { token, dataLatLng, dataAppointment, setDataAppointment } = useContext(TokenContext)
  const { doctor_id, clinic_id, specialty_id, keyword, item, type, searchinput, title, backlink, step } = !!location.state?location.state: ''

  const [detailDoctor, setDetailDoctor] = useState('')
  const [fetchMsg, setFetchMsg] = useState(`...Loading...`)
  const [showSummary, setShowSummary] = useState(false)
  const [showSchedule, setShowSchedule] = useState(true)
  let screenW = window.innerWidth
  let diffH = (screenW>=414)?290:(screenW>=375)?288:286

  const [modalNoSchedule, setModalNoSchedule] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)
  // const [startDate, setStartDate] = useState(new Date());
  const [briefBio, setBriefBio] = useState('')
  // const [briefBio] = useState('In Alexandria, Zidane and Tantalus kidnap Princess Garnet by order of Cid. Garnet does not resist, for she was already planning to flee and warn Cid of Queen Brahnes increasingly erratic behavior.Vivi and Steiner join the party during the escape. En route to Lindblum, the group discovers that Brahne is manufacturing soulless black mage soldiers that look similar to Vivi. In Lindblum, Cid confirms that he hired the group to protect Garnet from Brahnes newfound aggression. After learning that Alexandria has invaded Burmecia with the black mages, Zidane and Vivi join Freya to investigate. Garnet and Steiner secretly return to Alexandria to reason with Brahne.')
  const [fastTrackStatus, setFastTrackStatus] = useState(false)
  const [fastTrackNull, setFastTrackNull] = useState(false)
  const [fastTrackAmount, setFastTrackAmount] = useState('')
  const [fastTrackCurrency, setFastTrackCurrency] = useState('')
  const [appointmentAmount, setAppointmentAmount] = useState('')
  const [appointmentCurrency, setAppointmentCurrency] = useState('')
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentDateTh, setAppointmentDateTh] = useState('-');
  const [scheduleData, setScheduleData] = useState('')
  // const [scheduleNoData, setScheduleNoData] = useState('')
  const [scheduleList, setScheduleList] = useState('')
  const [scheduleMinDate, setScheduleMinDate] = useState('')
  const [scheduleMaxDate, setScheduleMaxDate] = useState('')
  const [selectDate, setSelectDate] = useState('')
  const [selectTime, setSelectTime] = useState('')
  const [selectTimeActive, setSelectTimeActive] = useState('')
  const [selectLanguage, setSelectLanguage] = useState('')
  
  const { /*register,*/ handleSubmit, reset /*watch,*/ /*formState: { errors }*/} = useForm({})

  

  useEffect(() => {
    setOpenCalendar(false)
    // check token
    if(!token) {
      navigate('/')
    }
console.log('[DetailDoctor] dataAppointment: ',dataAppointment)
    if(dataAppointment.step === 'summary' || step === 'payment' ) {
      setShowSummary(true)
    } 

    // console.log('dataAppointment: ', dataAppointment)
    // console.log('fastTrackStatus: ',fastTrackStatus)
    if(dataAppointment) {
      
    }

    if(appointmentDate.date_from && appointmentDate.date_to) {
      optionSchedule =`?date_from=${appointmentDate.date_from}&date_to=${appointmentDate.date_to}`
    }

    (async () => {

      // Fetch schedule
      const resSchedule = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/${doctor_id}/schedule${optionSchedule}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          // "Accept": "application/json",
          'Authorization': 'Bearer ' + token
        }
      });

      resSchedule
          .json()
          .then((response) => {
              // console.log('detailDoctor : ',response)
              // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)

              if(response.length === 0) { 
                // alert('No schedule')
                setShowSchedule(false)
              } else {
                setShowSchedule(true)
                if(response) {
                  // setFetchMsg('')
                  setScheduleData(response)

                  let resNoData = []
                  for(let key in response) {
                    if(response[key].length === 0) {
                      // console.log(key)
                      resNoData.push(key)
                    }
                  }
                  // setScheduleNoData(resNoData)
                  // console.log('resNoData: ',resNoData)
  
                  let dateData = Object.keys(response)
                  setScheduleMinDate(new Date(dateData[0]))
                  setScheduleMaxDate(new Date(dateData[dateData.length -1]))
  
                  // console.log('dataAppointment: ',dataAppointment)
                  // Check Data Appointment Back
                  if(dataAppointment) {
                    // console.log(response[dataAppointment])
                    // handleSelectLanguage(dataAppointment.language)
  
                    if(dataAppointment.type==='fasttrack') {
                      setFastTrackStatus(true)
                    } else {
                      onChangeDatePicker(new Date(dataAppointment.date),response[dataAppointment.date])
                    }
                  }
                } else {
                  setFetchMsg('ไม่พบข้อมูล')
                }
              }
              
        })
        .catch((err)=> console.log(err))
      })();
        

    if(dataLatLng.latitude && dataLatLng.longitude) {
      optionDetailDoctor =`?latitude=${dataLatLng.latitude}&longitude=${dataLatLng.longitude}`
    }

    (async () => {
      // Fetch detail doctor
      const resDetailDoctor = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/${doctor_id}${optionDetailDoctor}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          // "Accept": "application/json",
          'Authorization': 'Bearer ' + token
        }
      });
  
      resDetailDoctor
          .json()
          .then((response) => {
              // console.log('detailDoctor : ',response)
              // console.log('type: ',type,', backlink: ',backlink, ', specialty_id: ',specialty_id,', keyword: ',keyword,', clinic_id: ',clinic_id)
              if(response) {
                // setFetchMsg('')
                setDetailDoctor(response)
                setBriefBio(response.brief_bio)
                setFastTrackAmount(response.rate?.fast_track[0]?.amount || 0)
                setFastTrackCurrency(response.rate?.fast_track[0]?.currency || '')
                setAppointmentAmount(response.rate?.appointment[0]?.amount || 0)
                setAppointmentCurrency(response.rate?.appointment[0]?.currency || '')
                reset(
                  { language: response?.rate?.appointment[0].language}
                )
                setSelectLanguage(response?.rate?.appointment[0].language)
                
                if(!response.rate?.fast_track.length) { setFastTrackNull(true) }

              } else {
                setFetchMsg('ไม่พบข้อมูล')
              }
          })
          .catch((err)=> console.log(err))

    })();
  
    return () => {
        fastTrack = false
        setFastTrackStatus(false)
        setOpenCalendar(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLatLng.latitude, dataLatLng.longitude, doctor_id, navigate, token])


  const handleSelectLanguage = (value) => {
    // console.log(value)
    if(fastTrackStatus || !showSchedule) {
      let detailDoctorRate = detailDoctor.rate.fast_track
      for(let i=0; i<detailDoctorRate.length; i++) {
        if(detailDoctorRate[i].language === value) {
          setFastTrackAmount(detailDoctorRate[i].amount)
          setFastTrackCurrency(detailDoctorRate[i].currency)
          setSelectLanguage(value)
        } 
      }

    } else {
      let detailDoctorRate = detailDoctor.rate.appointment
      for(let i=0; i<detailDoctorRate.length; i++) {
        if(detailDoctorRate[i].language === value) {
          setAppointmentAmount(detailDoctorRate[i].amount)
          setAppointmentCurrency(detailDoctorRate[i].currency)
          setSelectLanguage(value)
        } 
      }
    }
  }

  const handleFastTrack = () => {
    // setFastTrackStatus(!fastTrackStatus); 
    if(fastTrack) {
      setFastTrackStatus(false)
      fastTrack = false

      if(detailDoctor.rate.appointment.length !== detailDoctor.rate.fast_track.length) {
        // Reset
        setSelectLanguage(detailDoctor?.rate?.appointment[0].language)
        setAppointmentAmount(detailDoctor?.rate?.appointment[0].amount)
        setAppointmentCurrency(detailDoctor?.rate?.appointment[0].currency)
      }
    } else {
      setFastTrackStatus(true)
      fastTrack = true

      if(detailDoctor.rate.appointment.length !== detailDoctor.rate.fast_track.length) {
        // Reset
        setSelectLanguage(detailDoctor?.rate?.fast_track[0].language)
      }
    }
    // console.log('fast_track: ',fastTrack)
  }

  const onChangeDatePicker = (value, scheduleListBack) => {
    // setScheduleData('')
    setScheduleList('')
    setSelectTime('')
    setSelectTimeActive('')
    // setOpenCalendar(true)
    // let dateValue = format(new Date(value), 'yyyy-MM-dd')
    // console.log(String(dateValue))

    // if(scheduleNoData.length) {
    //   for(let i=0; i<scheduleNoData.length; i++) {
    //     if(scheduleNoData[i] === dateValue) {
    //       setModalNoSchedule(true)
    //       // setTimeout(() => {
    //       //   setOpenCalendar(true)
    //       // }, 1);
    //     }
    //   }
    // }

    // console.log('onChangeDatePicker: ',value,' scheduleListBack:',scheduleListBack)
    let selectYearValue = Number(format(new Date(value), 'yyyy')) + 543
    let selectDateValue = format(new Date(value), 'yyyy-MM-dd', { locale: th})
    if(scheduleListBack) {
        setScheduleList(scheduleListBack)
        setSelectTime(dataAppointment.time)
        for(let i=0; i<scheduleListBack.length; i++) {
          if((scheduleListBack[i].time+':00')===dataAppointment.time) { /*console.log(scheduleListBack[i].time);*/ setSelectTimeActive(i) }
        }
        // setFastTrackAmount(dataAppointment.amount)
        // setAppointmentAmount(dataAppointment.amount)
        // setFastTrackCurrency(dataAppointment.currency)
        // setAppointmentCurrency(dataAppointment.currency)
        // setSelectLanguage(dataAppointment.language)
    } else {
      if(scheduleData[selectDateValue].length === 0) {
        setScheduleList([])
      } else {
        setScheduleList(scheduleData[selectDateValue])
      }
    }
    
    let appointmentDateValue = format(new Date(value), 'dd MMMM', { locale: th}) + ' ' + selectYearValue
    // console.log(appointmentDateValue)
    setAppointmentDate(value)
    setAppointmentDateTh(appointmentDateValue)
    setSelectDate(selectDateValue)
    // setOpenCalendar(false)
  }
  
  const onSubmit = async (data, event) => { 
    event.preventDefault();
    if(fastTrackStatus) {
      data.fast_track = detailDoctor?.rate?.fast_track[0]?.amount
      data.language = detailDoctor?.rate?.fast_track[0]?.language
    } 
    data.date = selectDate
    data.Time = selectTime
    // console.log(data)
    
    // onclick()

    setDataAppointment({...dataAppointment, 
      doctor_id: detailDoctor.id,
      type: fastTrackStatus?'fasttrack':'appointment',
      currency: !!fastTrackStatus?fastTrackCurrency:appointmentCurrency,
      language: selectLanguage,
      date: selectDate,
      time: selectTime,
      timeconsult: detailDoctor.min_consult_time,
      amount: !!fastTrackStatus?fastTrackAmount:appointmentAmount,
      step: 'summary'
    })

    setShowSummary(true)
  }

  const fetchCancelAppointment =  () => {
    // Fetch Apppoint
    (async () => {
      let resCancelAppointment = ''
      if(!!dataAppointment.appointment_id) {
        // Fetch Cancel Appointment via appointment id
        resCancelAppointment = await fetch(`${process.env.REACT_APP_API_URL}/api/appointment/${dataAppointment.appointment_id}/cancel_reservation`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            // "Accept": "application/json",
            'Authorization': 'Bearer ' + token
          }
        });

        console.log('Cancel Appointment('+dataAppointment.appointment_id+')')
      } else {
        // Fetch Cancel Appointment via doctor id [date, time]
        if(!!dataAppointment.date && !!dataAppointment.time) {
          resCancelAppointment = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/${dataAppointment.doctor_id}/cancel_reservation_fast_track`, {
            method: 'POST',
            body: JSON.stringify({
              date: dataAppointment.date,
              time: dataAppointment.time
            }),
            headers: {
              "Content-Type": "application/json",
              // "Accept": "application/json",
              'Authorization': 'Bearer ' + token
            }
          });

          console.log('Cancel Appointment [Doctor ID via date, time]')
        } else {
          // Fetch Cancel Appointment via doctor id [not have date, time]
          resCancelAppointment = await fetch(`${process.env.REACT_APP_API_URL}/api/doctor/${dataAppointment.doctor_id}/cancel_reservation_fast_track`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              // "Accept": "application/json",
              'Authorization': 'Bearer ' + token
            }
          });

          console.log('Cancel Appointment [Doctor ID]')
        }
      }

      if(resCancelAppointment.status === 200) {
        // console.log(resCancelAppointment)
        console.log('OK!! Cancel Appointment (cancel_reservation), via : ',!!dataAppointment.appointment_id?'appointment_id('+dataAppointment.appointment_id+')':'doctor_id('+dataAppointment.doctor_id+')') 
        setDataAppointment('')
      } else {
        console.log('Error! Cancel Appointment')
        setDataAppointment('')
      }


    })();
  }

  return (
    <div className="detail-doctor-page p-5 relative">
      {
          !showSummary ?
            backlink==='home' ?
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" 
              action={()=>{ 
                navigate('/home');
              }}
            />
            : backlink ==='search' ?
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" 
              action={()=>{ 
                navigate('/search');
              }}
            />
            :
            <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" 
                action={()=>{ navigate('/searchresultdoctor', { 
                  state: { 
                    clinic_id: clinic_id, 
                    specialty_id: specialty_id,
                    keyword: keyword,
                    item: item, 
                    dataItem: item, 
                    type: type,
                    searchinput: searchinput,
                    title: title,
                    backlink: backlink,
                  }});

                }}
            />
          : 
          <BtnTransparent text="ย้อนกลับ" icon={IconBack} addclass="flex items-center pl-0" 
            action={()=>{ 
              setShowSummary(false); 
              setDataAppointment({...dataAppointment, step: 'action'})

              fetchCancelAppointment()
            }}
          />
      }
      

      {
        !!detailDoctor ?
        <React.Fragment>
          
          <ItemDoctor searchinput={searchinput} item={item} dataItem={detailDoctor} clinic_id={clinic_id} keyword={keyword} specialty_id={specialty_id} backlink={backlink} doctor_id={detailDoctor.id} title={title} type={type} from="/detaildoctor" />

          <div className="overflow-y-auto" style={{height: window.innerHeight-diffH+'px'}}>
            {
              !showSummary ?
              // <DetailDoctorAction detailDoctor={detailDoctor} onclick={()=> setShowSummary(true)} />
              
                <div className="detail-doctor-page _action">

                {
                  !!detailDoctor ?
                  <React.Fragment>

                    <div className="py-4 border-b border-form-bd">
                        <h1 className="font-promptsemibold">รายละเอียด</h1>
                        {
                          !!briefBio ?
                            <ReadMoreReact readMoreText={' ... Read more.'} 
                            min={(screenW >= 414)? 150 : (screenW >= 375)? 120 : 70}
                            ideal={(screenW >= 414)? 170 : (screenW >= 375)? 145 : 85}
                            max={200} 
                            text={briefBio || ''} />
                          : ''
                        }
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      {
                        detailDoctor?.rate?.appointment.length ?
                        <div className="pt-4 pb-2 border-b border-form-bd">
                          <h1 className="font-promptsemibold mb-2">ภาษาที่ต้องการเข้าพบ</h1>
                          <div className="flex flex-row text-left">
                            {
                              ((detailDoctor.doctor_mode_appointment) && (!fastTrackStatus) ? detailDoctor?.rate?.appointment : detailDoctor?.rate?.fast_track).map((item,i)=>{
                                return (
                                  <label key={i} className="flex container-radio-btn text-gray-2" htmlFor={`language-${item.language}`}>
                                    {item.language} {/* {item.amount} {item.currency} */}
                                    {/* <input
                                        {...register("language")}
                                        type="radio"
                                        value={item.language}
                                        id={`language-${item.language}`}
                                        onChange={(e)=> handleSelectLanguage(e.target.value)}
                                    /> */}
                                    <input type="radio" id={`language-${item.language}`} value={item.language} checked={item.language === selectLanguage} onChange={(e)=> handleSelectLanguage(e.target.value)} />
                                    <span className="checkmark"></span>
                                  </label>
                                )
                              })
                            }
                          </div>
                        </div>
                        : null
                      }
                      
                      <div className=" mb-3">
                        {
                          (detailDoctor?.doctor_mode_fast_track && (detailDoctor.doctor_status==='blue' || detailDoctor.doctor_status==='green')) ?
                          <div className={`${fastTrackStatus?'border-card-active':'border-gray-light'} border rounded-full p-2 w-full bg-gray-light text-gray-1 text-center mt-6`} onClick={()=> handleFastTrack()}>
                            ปรึกษาทันที&nbsp;
                          </div>
                          : null
                        }

                        {
                          ( (detailDoctor.doctor_mode_appointment) && (!fastTrackStatus) ) ?
                            <React.Fragment>
                            
                            {/* Schedule - date picker  */}
                            {
                              showSchedule ? 
                                <div className="flex flex-row mt-4 mb-4">
                                  <div className="flex flex-col mb-0 grow">
                                    <p className="text-sm">นัดหมายล่วงหน้า</p>
                                    <h1 className="font-promptsemibold">{appointmentDateTh} {!!selectTime? <span className="font-promptregular">|  <b className="font-promptsemibold">{(selectTime).slice(0,5)} น.</b></span> : null}</h1>
                                  </div>
                                  <div className="flex flex-row mb-0 grow-0 text-right">
                                    <div className="rounded-md border border-form-bd py-3 px-3 text-center" onClick={()=> { setOpenCalendar(true) }}>
                                      <img className="m-auto" src={IconCalendarSamll} alt="icon rating" />
                                    </div>
                                  </div>
                                </div>
                              : null
                            }

                           
                            </React.Fragment>
                          : null
                        }
                        <div className="mt-3">
                          {
                            (!!selectTime || fastTrackStatus)  && !fastTrackNull ?
                            <BtnPrimary type="submit" 
                              text={`ถัดไป ${!!fastTrackStatus || !showSchedule?'('+Number(fastTrackAmount).toLocaleString('en-US')+' '+fastTrackCurrency+')':'('+Number(appointmentAmount).toLocaleString('en-US')+' '+appointmentCurrency+')'}`} addclass={`mt-2`} 
                            />
                            :
                            <div className="pointer-events-none">
                              <BtnSecondary type="submit"
                                text={`${fastTrackNull && detailDoctor?.doctor_mode_fast_track ? 'ไม่พบค่าปรึกษา':`ถัดไป ${(!!fastTrackStatus || !showSchedule) &&  detailDoctor?.doctor_mode_fast_track ?'('+Number(fastTrackAmount).toLocaleString('en-US')+' '+fastTrackCurrency+')':'('+Number(appointmentAmount).toLocaleString('en-US')+' '+appointmentCurrency+')'}`} `} addclass={`mt-2`} 
                              />
                            </div>
                          }
                          {
                            !!detailDoctor.min_consult_time?
                            <p className="text-card-active text-xs my-4 text-center w-full">{`-----  ระยะเวลาเข้าพบหมอ ${detailDoctor.min_consult_time} นาที  -----`}</p>
                            : null
                          }</div>
                        
                      </div>
                    </form>
                  </React.Fragment>
                  : <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsg }}></p>
                }
              
               
              </div>
              :
              <DetailDoctorSummary  />
            }
          </div>

          {/* <div className="py-4 border-b border-form-bd">
              <h1 className="font-promptsemibold">ความเชี่ยวชาญเฉพาะทาง</h1>
              <div className="flex flex-row mb-3">
                  <div className="grow basis-2/3">
                      {
                          !!detailDoctor?.specialty1_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{detailDoctor?.specialty1_name}</div>:null
                      }{
                          !!detailDoctor?.specialty2_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{detailDoctor?.specialty2_name}</div>:null
                      }{
                          !!detailDoctor?.specialty3_name?<div className="inline-block w-fit rounded-full border border-form-bd bg-form-bg text-gray-2 text-xs px-2 py-1 mb-1 mr-1">{detailDoctor?.specialty3_name}</div>:null
                      }
                  </div>
                  
              </div>
          </div> */}
        </React.Fragment>
        : <p className="text-center mt-8 text-gray-2 w-full" dangerouslySetInnerHTML={{__html: fetchMsg }}></p>
      }
    

    {/* {
      openCalendar ?
      <div className="Overlay"> */}
        {/* <DateTimePicker
            onChange={onChangeDatePicker} 
            value={appointmentDate} 
            locale="th" 
            isCalendarOpen={true}
            disabled={true}
            closeWidgets={false}
            minDate={new Date(scheduleMinDate)}
            maxDate={new Date(scheduleMaxDate)}
            format={'dd MMMM y'} 
            calendarIcon={null} 
            clearIcon={null} 
            // disableCalendar={true}
          /> */}
        {/* <DatePicker 
            onChange={onChangeDatePicker} 
            value={appointmentDate} 
            locale="th" 
            calendarIcon={null} 
            clearIcon={null} 
            isOpen={openCalendar} 
            disabled={true} 
            format={'dd MMMM y'} 
            minDate={new Date(scheduleMinDate)}
            maxDate={new Date(scheduleMaxDate)}
            onCalendarClose={()=> openCalendar?setOpenCalendar(false):null}
            calendarClassName={'thaid-calendar'}
            closeWidgets={false}
          /> */}
      {/* </div>
      : null
    } */}

     {/* Modal No schedule */}
     <Modal
        isOpen={openCalendar}
        className="Modal modal-calendar"
        overlayClassName="Overlay"
        onRequestClose={()=> setOpenCalendar(false)}
    >
        <DateTimePicker
          onChange={onChangeDatePicker} 
          value={appointmentDate} 
          locale="th" 
          isCalendarOpen={true}
          disabled={true}
          closeWidgets={false}
          minDate={new Date(scheduleMinDate)}
          maxDate={new Date(scheduleMaxDate)}
          format={'dd MMMM y'} 
          calendarIcon={null} 
          clearIcon={null} 
          // disableCalendar={true}
        />

         {/* Schedule - time */}
          <p className="text-sm my-2">เวลานัดหมายล่วงหน้า</p>         
         {
            !!appointmentDate ?
              <div className="w-full flex flex-row overflow-x-auto mb-3">
                { 
                  (scheduleList.length === 0) ?
                    <div className="border-gray-light border rounded-full mx-1 py-1.5 px-3 w-fit bg-gray-light text-gray-1 text-sm">ไม่พบเวลานัดหมาย</div>
                    :  
                    scheduleList.map((item,i) => {
                      return (
                        <div key={i} className={`${selectTimeActive === i?'border-card-active':'border-gray-light'} border rounded-full mx-1 py-1.5 px-3 w-fit bg-gray-light text-gray-1 text-sm`} onClick={()=> {setSelectTime(item.time+':00'); setSelectTimeActive(i); setOpenCalendar(false)}}>{item.time}</div>
                      )
                    })
                }
              </div>
            : 
            <div className="w-full flex flex-row overflow-x-auto mb-3">
              <div className="border-gray-light border rounded-full mx-1 py-1.5 px-3 w-fit bg-gray-light text-gray-1 text-sm"> - </div>
            </div>
          }
    </Modal>

    {/* Modal No schedule */}
    <Modal
        isOpen={modalNoSchedule}
        className="Modal"
        overlayClassName="Overlay"
    >
        <img src={IconFailed} alt="logo thaidoctor" className="mx-auto my-4" />
        <h1 className="font-promptsemibold mb-0 text-lg">{ appointmentDateTh } ไม่พบเวลานัดหมาย </h1>
        <p className="text-gray-2 mb-4 text-md">กรุณาเลือกวันนัดหมายใหม่</p>
        <BtnPrimary type="button" text="ปิด" action={()=> {setModalNoSchedule(false); setOpenCalendar(true)}} /> 
        {/* 
        {
            errCodeCheckTelephone ? <p className="text-gray-3 text-xs mt-2">({errCodeCheckTelephone}) {checkTelephoneErrMsgEn}</p> : null
        }

        {
            errCodeSendOtp ? <p className="text-gray-3 text-xs mt-2">({errCodeSendOtp}) {sendOtpErrMsg}</p> : null
        } 
        */}
    </Modal>

    </div>
  )
}

export default DetailDoctor